import { gql } from "@apollo/client";

export const GET_USER = gql`
  query GetUser {
    user @client
  }
`;

export const ALLPEOPLE = gql`
  query AllPoeple($page: Int!, $size: Int!) {
    allPeople(page: $page, size: $size) {
      id
      firstName
      lastName
    }
  }
`;

export const SEND_TEMP_PASSWORD = gql`
  mutation sendTemporaryPassword($email: String) {
    sendTemporaryPassword(email: $email)
  }
`;

export const CHECK_TEMP_PASSWORD = gql`
  mutation checkTemporaryPassword($userId: ID!, $inputPassword: String) {
    checkTemporaryPassword(userId: $userId, inputPassword: $inputPassword) {
      person {
        dob
        ssn
        sex
        id
        firstName
        lastName
        email
        roles
        avatar
        phone
        education
        experience
        employmentDate {
          iso
        }
        title
        contactinfo {
          id
          country
          streetAddress
          city
          state
          zip
          homePhone
          cellPhone
          workPhone
          email
          preferContact
        }
      }
      token
    }
  }
`;

export const GET_SECURITY_QUESTIONS = gql`
  query getAllSecurityQuestions {
    getAllSecurityQuestions {
      id
      question
    }
  }
`;

export const GET_SECURITY_QUESTIONS_BY_EMAIL = gql`
  mutation getSecurityQuestionsByEmail($email: String) {
    getSecurityQuestionsByEmail(email: $email) {
      userId
      securityQuestions {
        id
        question
      }
    }
  }
`;

export const CHECK_SECURITY_QUESTIONS = gql`
  mutation checkUserSecurityQuestionAnswers(
    $userId: ID!
    $userSQInput: UserSecurityQuestionInput
  ) {
    checkUserSecurityQuestionAnswers(
      userId: $userId
      userSQInput: $userSQInput
    ) {
      person {
        dob
        ssn
        sex
        id
        firstName
        lastName
        email
        roles
        avatar
        phone
        education
        experience
        employmentDate {
          iso
        }
        title
        contactinfo {
          id
          country
          streetAddress
          city
          state
          zip
          homePhone
          cellPhone
          workPhone
          email
          preferContact
        }
      }
      token
    }
  }
`;

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      person {
        dob
        ssn
        sex
        id
        firstName
        lastName
        email
        roles
        avatar
        phone
        education
        experience
        employmentDate {
          iso
        }
        title
        contactinfo {
          id
          country
          streetAddress
          city
          state
          zip
          homePhone
          cellPhone
          workPhone
          email
          preferContact
        }
        patient {
          id
        }
      }
      token
    }
  }
`;

export const LOGOUT = gql`
  mutation logout($msg: String!) {
    logout(msg: $msg)
  }
`;

export const SEND_AUTHORIZATION_CODE = gql`
  mutation sendAuthorizationCode($email: String, $patientId: Long) {
    sendAuthorizationCode(email: $email, patientId: $patientId)
  }
`;

export const CHECK_AUTHORIZATION_CODE = gql`
  mutation checkAuthorizationCode($patientId: Long, $inputCode: String) {
    checkAuthorizationCode(patientId: $patientId, inputCode: $inputCode) {
      person {
        id
      }
      email
      firstName
      lastName
      telNumb
    }
  }
`;
