import React, { useState, useContext, createContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_QUESTIONNAIRE_PTID, UPDATE_QUESTIONNAIRE } from "@cims/common";
import { AuthenticationContext } from "src/services/authentication/authentication.context";
import {
  Container,
  Typography,
  Checkbox,
  Grid,
  Modal,
  Fade,
  Button,
  FormControlLabel,
  Backdrop,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useEffect } from "react";
import { blue } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 20,
    // height: "50px",
  },
  addBtnIcon: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
    color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
    "&:hover": {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#BDC0BA" : blue[700],
      color: "#fff",
    },
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  btn: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  paper: {
    backgroundColor: "#f5f3f0", //theme.palette.background.paper,
    border: "1px solid #666666",
    width: 700,
    maxHeight: 700,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "auto",
  },
}));

export default function Questionnaires() {
  const myStyles = useStyles();

  //get user id from auth context
  const {
    contextHeaders,
    user: {
      person: { id },
    },
  } = useContext(AuthenticationContext);

  //query test results
  const {
    data: questionnaire,
    loading: questionnaireLoading,
    error: questionnaireError,
  } = useQuery(GET_QUESTIONNAIRE_PTID, {
    variables: { patient_id: 1 },
    context: contextHeaders,
  });

  const [checked, setChecked] = useState({});
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    // console.log("useEffect");
    if (questionnaire) {
      // console.log("hi")
      const item = questionnaire.questionnaireByPtId;
      let obj = {};
      for (let i = 0; i < item.length; i++) {
        obj[item[i].id] = item[i].result === "yes" ? true : false;
      }
      setChecked(obj);
      setFlag(true);
    }
  }, [questionnaire]);

  const handleCheck = (index) => {
    setChecked({
      ...checked,
      [index]: !checked[index],
    });
    // console.log(checked);
  };

  const [openAlert, setOpenAlet] = useState(false);

  const [questionResult, setquestionResult] = useState([]);

  const [updateQResultList, { loading, error }] =
    useMutation(UPDATE_QUESTIONNAIRE);

  const submit = (checked) => {
    for (let i = 0; i < checked.length; i++) {
      if (checked != true) {
        questionResult.push({ id: i + 1, result: "no" });
      } else {
        questionResult.push({ id: i + 1, result: "yes" });
      }
      setquestionResult(questionResult);
    }
    updateQResultList({
      variables: { qResultList: questionResult },
    });
  };

  return (
    <Container>
      <Typography variant="body1" gutterBottom component="div">
        Please indicate if you have the following:
      </Typography>

      <Grid container spacing={3}>
        {flag &&
          questionnaire.questionnaireByPtId.map((question, index) => {
            // console.log("result:",question.id,checked[question.id])
            return (
              <Grid key={index} item xs={12} sm={6} md={3}>
                <FormControlLabel
                  label={question.medicalName}
                  control={
                    <Checkbox
                      // checked={true}
                      checked={checked[question.id]}
                      onChange={() => {
                        handleCheck(question.id);
                      }}
                    />
                  }
                />
              </Grid>
            );
          })}
      </Grid>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: 20,
        }}
      >
        <Button
          variant="outlined"
          size="large"
          color="primary"
          onClick={() => {
            setOpenAlet(true);
            submit(checked);
          }}
        >
          Submit Questionnaire
        </Button>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={myStyles.modal}
        open={openAlert}
        onClose={() => setOpenAlet(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAlert}>
          <div className={myStyles.paper}>
            <div className={myStyles.btn}>
              <Typography>
                Thanks for participating in this questionnaire!
              </Typography>
            </div>

            <div className={myStyles.btn}>
              <Button
                style={{ marginLeft: 20 }}
                variant="contained"
                color="primary"
                onClick={() => {
                  setOpenAlet(false);
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </Container>
  );
}
