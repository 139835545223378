/*
 * @Author: your name
 * @Date: 2022-02-02 20:58:53
 * @LastEditTime: 2022-02-02 21:25:10
 * @LastEditors: Please set LastEditors
 * @Description: Ã¦â€°â€œÃ¥Â¼â‚¬koroFileHeaderÃ¦Å¸Â¥Ã§Å“â€¹Ã©â€¦ÂÃ§Â½Â® Ã¨Â¿â€ºÃ¨Â¡Å’Ã¨Â®Â¾Ã§Â½Â®: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /cims-dev/packages/UHX/src/pages/patients/pages/Allergy-details.page.js
 */
//import React, { useContext } from "react";
import React, { useState, useContext } from "react";

import {
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableBody,
  TableHead,
  Paper,
  TablePagination,
  Typography,
  Button,
} from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

import { ALL_ALLERGY_BY_PATIENT_ID } from "@cims/common/";

import { useQuery } from "@apollo/client";
import { AuthenticationContext } from "src/services/authentication/authentication.context";
import { useHistory, useParams } from "react-router-dom";
import { blue } from '@mui/material/colors';

function createData(
  id,
  dataSource,
  reaction,
  serverity,
  startDate,
  status,
  substance
) {
  return {
    id,
    dataSource,
    reaction,
    serverity,
    startDate,
    status,
    substance,
  };
}

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
    // height: "40px",
  },
  addBtnIcon: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
    color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
    "&:hover": {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#BDC0BA" : blue[700],
      color: "#fff",
    },
  },
  btn: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default function AllergyDetails() {
  console.log("==> AllergyDetails()");
  let rowss = [];
  const classes = useRowStyles();
  const history = useHistory();

  const { user, contextHeaders } = useContext(AuthenticationContext);
  const { curPickedPatient } = useContext(AuthenticationContext);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  //query test results
  const {
    data: allergyResults,
    loading: allergyResultsLoading,
    error: allergyResultsError,
  } = useQuery(ALL_ALLERGY_BY_PATIENT_ID, {
    variables: { patient_id: Number(user.person.patient.id) },
    context: contextHeaders,
  });

  //console.log( "test result detail patientId ", Number(user.person.patient.id) );
  //console.log("Allergy Results: ", allergyResults);
  console.log(
    "==> patient_id = " +
      Number(user.person.patient.id) +
      ", allergyResults = " +
      allergyResults
  );

  {
    allergyResults &&
      allergyResults.allAllergyByPatientId.map((item) => {
        rowss.push(
          createData(
            item.id,
            item.dataSource,
            item.reaction,
            item.serverity,
            item.startDate,
            item.status,
            item.substance
          )
        );
      });
  }
  //console.log("==> rowss = " + rowss);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rowss.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
      // backgroundColor: "green",
      // color: theme.palette.common.white,
      color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  console.log("==> Before return");

  return (
    <div className={classes.root}>
      <Typography variant="body1" gutterBottom component="div">
        <h3>Allergy Result:</h3>
      </Typography>
      <Paper className={classes.paper}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Data Source</StyledTableCell>
                <StyledTableCell>Reaction</StyledTableCell>
                <StyledTableCell>Serverity</StyledTableCell>
                <StyledTableCell>Start Date</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Substance</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowss
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <StyledTableRow hover tabIndex={-1}>
                      <StyledTableCell align="left" style={{ width: "2%" }}>
                        {row.id}
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "10%" }}>
                        {row.dataSource}
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "10%" }}>
                        {row.reaction}
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "15%" }}>
                        {row.serverity}
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "10%" }}>
                        {row.startDate}
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "10%" }}>
                        {row.status}
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "15%" }}>
                        {row.substance}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.pagination}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rowss.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
      <div className={classes.btn}>
        <Button
          style={{ marginRight: 50 }}
          variant="contained"
          color="primary"
          onClick={() => {
            //
          }}
        >
          Upload your Allergy Result
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            //
          }}
        >
          Manully input your Allergy Result
        </Button>
      </div>
    </div>
  );
}
