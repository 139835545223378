/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { Container, Typography, Card, CardContent, CardHeader, Avatar } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { blue } from "@mui/material/colors";
import { useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import { PATIENTS_BILLING } from "@cims/common";
import Divider from "@mui/material/Divider";
import { AppointmentContext } from "../../../services/appointment/appointment.context";
import LoadingIndicator from "../../../components/LoadingIndicator";
import ErrorInfo from "../../../components/ErrorInfo";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { AuthenticationContext } from "src/services/authentication/authentication.context";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    marginBottom: "10px",
  },
  avatar: {
    backgroundColor: blue[100],
  },
  info: {
    lineHeight: "25px",
    height: "25px",
    textAlign: "center",
    AccountBoxIcon: {
      verticalAlign: "middle",
      height: "25px",
    },
    span: {
      fontSize: "20px",
      lineHeight: "25px",
      height: "25px",
      verticalAlign: "middle",
    },
  },
}));

export default function BillingInfo() {
  const classes = useStyles();
  const { patientId } = useParams();
  const history = useHistory();
  const { curPickedPatient } = useContext(AuthenticationContext);

  const { data, loading, error } = useQuery(PATIENTS_BILLING, {
    variables: {
      patient_id: Number(curPickedPatient.id),
    },
  });

  const transferDate = (str) => {
    return str.replace("T", " ");
  };

  return (
    <Container>
      <div className={classes.bar}>
        <Typography
          variant="body1"
          className={classes.title}
          component="h2"
          gutterBottom
        >
          {`${curPickedPatient.firstName} ${curPickedPatient.lastName}'s account info`}
        </Typography>
      </div>

      {loading && <LoadingIndicator />}
      {error && <ErrorInfo />}

      <div>
        <Typography variant="h5" gutterBottom>
          Responsible for Payment
        </Typography>

        <Card className={classes.root}>
          <CardHeader
            avatar={
              <Avatar
                className={classes.avatar}
                src={`${curPickedPatient.avatar}`}
              />
            }
            title={
              data &&
              (data.allBillingSummaryByPtId.length === 0
                ? `${curPickedPatient.firstName} ${curPickedPatient.lastName}`
                : `${data.allBillingSummaryByPtId[0].respFirstName} ${data.allBillingSummaryByPtId[0].respLastName}`)
            }
            subheader={
              data &&
              (data.allBillingSummaryByPtId.length === 0
                ? `No information in database`
                : `Guarantor #: ${data.allBillingSummaryByPtId[0].guarantorNumb}`)
            }
          />
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              {data &&
                (data.allBillingSummaryByPtId.length === 0
                  ? `Address: No information in database`
                  : `Address: ${data.allBillingSummaryByPtId[0].respAddress}`)}
            </Typography>

            <Typography variant="body2" color="textSecondary" component="p">
              <PhoneAndroidIcon
                fontSize="small"
                style={{ paddingTop: "6px" }}
              />
              {data &&
                (data.allBillingSummaryByPtId.length === 0
                  ? `CellPhone: No information in database`
                  : `CellPhone: ${data.allBillingSummaryByPtId[0].respCellPhone}`)}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              <MailOutlineIcon fontSize="small" style={{ paddingTop: "8px" }} />
              {`Email: `}
            </Typography>
          </CardContent>
        </Card>

        <Divider variant={"middle"} className={classes.divider} />

        <Typography variant="h5" gutterBottom style={{ paddingTop: "10px" }}>
          Patients Included
        </Typography>
        <Card className={classes.root}>
          <CardHeader
            avatar={
              <Avatar
                className={classes.avatar}
                src={`${curPickedPatient.avatar}`}
              />
            }
            title={
              `${curPickedPatient.firstName} ${curPickedPatient.lastName}` +
              " (You)"
            }
            subheader="Other name (Spouse)"
          />
        </Card>
      </div>
    </Container>
  );
}
