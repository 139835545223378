/*
 * @Author: your name
 * @Date: 2022-03-08 13:26:59
 * @LastEditTime: 2022-03-08 13:26:59
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /cims-dev/packages/common/services/twofactorauthentication.service.js
 */
import { gql } from "@apollo/client";

export const GET_TWOFA_INFO = gql`
  query find2faInfoByPtId($patient_id: ID!) {
    find2faInfoByPtId(patient_id: $patient_id) {
      id
		  loginTime{toString}
	    responseTime{toString}
	    phoneNumber
	    emailAddress
	    durationTime
	    twoFACode
      prefer
      enable
    }
  }
`;

export const UPDATE_TWOFA_PHONE = gql`
  mutation updatePhone($patient_id: ID!, $phoneNumber: String) {
    updatePhone(patient_id: $patient_id, phoneNumber: $phoneNumber) {
      id
      loginTime{toString}
      responseTime{toString}
      phoneNumber
      emailAddress
      durationTime
      twoFACode
      prefer
      enable
    }
  }
`;

export const UPDATE_TWOFA_EMAIL = gql`
  mutation updateEmail($patient_id: ID!, $emailAddress: String) {
    updateEmail(patient_id: $patient_id, emailAddress: $emailAddress) {
      id
      loginTime{toString}
      responseTime{toString}
      phoneNumber
      emailAddress
      durationTime
      twoFACode
      prefer
      enable
    }
  }
`;

export const UPDATE_TWOFA_PREFER = gql`
  mutation updatePrefer($patient_id: ID!, $prefer: String) {
    updatePrefer(patient_id: $patient_id, prefer: $prefer) {
      id
      loginTime{toString}
      responseTime{toString}
      phoneNumber
      emailAddress
      durationTime
      twoFACode
      prefer
      enable
    }
  }
`;

export const UPDATE_TWOFA_ENABLE = gql`
  mutation updateEnable($patient_id: ID!, $enable: String) {
    updateEnable(patient_id: $patient_id, enable: $enable) {
      id
      loginTime{toString}
      responseTime{toString}
      phoneNumber
      emailAddress
      durationTime
      twoFACode
      prefer
      enable
    }
  }
`;

export const SEND_TWOFA_INFO = gql`
  mutation sendTwoFAInfo($patient_id: ID!, $loginTime: String, $durationTime: Long) {
    sendTwoFAInfo(patient_id: $patient_id, loginTime: $loginTime, durationTime: $durationTime) {
      id
      loginTime{toString}
      responseTime{toString}
      phoneNumber
      emailAddress
      durationTime
      twoFACode
      prefer
      enable
    }
  }
`;

export const UPDATE_TWOFA_RESPONSE_TIME = gql`
  mutation updateResponseTime($patient_id: ID!, $responseTime: String, $inputTwoFA: String) {
    updateResponseTime(patient_id: $patient_id, responseTime: $responseTime, inputTwoFA: $inputTwoFA)
  }
`;