//const address = "uhx-portal-service.default.svc.cluster.local:8445";
//const address = "ec2-3-21-207-90.us-east-2.compute.amazonaws.com:8445";
const address = "www.uhxtech.net:8445";
export const serverUrl = `https://${address}`;
export const serverUrlSub = `wss://${address}`;

export const uhx_serverUrl = `https://${address}`;
export const uhx_serverUrlSub = `wss://${address}`;

//const eBinder_address = "ec2-3-21-207-90.us-east-2.compute.amazonaws.com:8445";
const eBinder_address = "www.uhxtech.net:8445";
export const eBinder_serverUrl = `https://${eBinder_address}`;
export const eBinder_serverUrlSub = `wss://${eBinder_address}`;
