import React, { useContext, useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  InputBase,
  Paper,
  TableRow,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useQuery, useMutation } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import { AppointmentContext } from "../../../services/appointment/appointment.context";
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from "@mui/icons-material/Add";
import { AuthenticationContext } from "../../../services/authentication/authentication.context";
import {
  CVS_LOCATION_BY_ASC,
  ADD_PHARMACY_DETAIL,
  ACTIVE_PHARMACY_DETAIL_BY_PATIENTID_DEFAULT,
} from "@cims/common";
import { blue } from '@mui/material/colors';



const useStyles = makeStyles((theme) => ({
    ReturnButton: {
        backgroundColor: localStorage.getItem("isDarkMode") === "true" ? "#828282" : "#87ceeb",
        '&:hover': {
          backgroundColor: localStorage.getItem("isDarkMode") === "true" ? "#BDC0BA" : "#78C2C4",
          color: "#fff",
        },    
    },

    root: {
        padding: "10px 10px",
        display: "flex",
        alignItems: "center",
        width: 1000,
        marginTop:"10px",
    },

    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
      
    iconButton: {
        padding: 10,
    },

    displayLocation:{

        width: 1000,
        marginBottom: '10px',
        padding: '10px',
       
        backgroundColor:
          localStorage.getItem("isDarkMode") === "true" ? "#87AFC7" : "#ace5ee",
        color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
        "&:hover": {
          backgroundColor:
            localStorage.getItem("isDarkMode") === "true" ? "#87AFC7" : blue[700],
          color: "#fff",
        },
    },

    
    }

  
));

export default function SearchPharmacies() {
    const myStyles = useStyles();
    const { patientId } = useParams();
    const history = useHistory();

    const [text, setText] = useState('');
    const [searchZipcode, setSearchZipcode] = useState('');
    const [cvsList, setcvsList] = useState([]);

    const { user, contextHeaders, changeSettings, curPickedPatient } = useContext(
      AuthenticationContext
    );

    // mutation add new pharmacy to current patient
    const [addNewPharmacy, {data1}] = useMutation(
        ADD_PHARMACY_DETAIL,
        {
        refetchQueries: [
            {
            query: ACTIVE_PHARMACY_DETAIL_BY_PATIENTID_DEFAULT,
            variables: { id: Number(user.person.id) },
            },
        ],
        }
        
    );

    //query find the nearest CVS location by ZipCode
    const {
        data: cvsLocation,
        loading: cvsLocationLoading,
        error: cvsLocationError,
    } = useQuery(CVS_LOCATION_BY_ASC, 
        {variables: 
            {zipCode: `${searchZipcode}` }, 
        }
    );

    

    // set zipcode to search query, get pharmacy list by search 
    const onSearch = (e) =>{
        e.preventDefault();
        console.log("hi");
        setSearchZipcode(text);
        console.log(text);
        const newcvsList = cvsLocation.csvLocationByASC;
        setcvsList(newcvsList);
        console.log(newcvsList);
  };

    return (
      <Container>
        <div >
          <Button 
            variant="outlined" 
            onClick={() =>
              history.push(`/patients/${patientId}/phamacies`)
            }
            size="large"
            color="primary"
          >
            Return
          </Button>
        </div>
        
        <Paper component="form" className={myStyles.root}>
            <InputBase
                className={myStyles.input}
                placeholder="Search the nearest pharmacy by Zipcode"
                inputProps={{ "aria-label": "Search the nearest pharmacy by Zipcode" }}
                onChange={e => setText(e.target.value)}
            />
            <IconButton
              type="submit"
              className={myStyles.iconButton}
              aria-label="search"
              onClick={e =>onSearch(e)}
              size="large">
                <SearchIcon />
            </IconButton>
        </Paper>

        {cvsList && cvsList.map((pharmacy, index) => (
              <div
                className={myStyles.displayLocation}
                key={index} 
                onClick={() => {
                  // call mutation (addPharmacybyPatientID) 
                  addNewPharmacy(
                    {variables: {
                      patient_id: `${user.person.id}`,
                      pharmacy:"New Pharmacy",
                      pharmacyAddress: `${pharmacy.cvs_address}`,
                      telNo: `${pharmacy.cvs_telnum}`,
                      status: "active",
                  }}
                  )
                  // return to MyPharmacy page after selection
                  history.push(`/patients/${patientId}/phamacies`)
                 
                  }
                } 
              >
                <AddIcon/> {pharmacy.cvs_address} {pharmacy.cvs_city} {pharmacy.cvs_state} {pharmacy.cvs_telnum}
              </div> 
        ))}
      

      </Container>
    );
}