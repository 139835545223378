import React, { useContext, useState } from "react";
import {
  Container,
  Typography,
  Button,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
  TextField,
  Grid,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useQuery, useMutation } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import { PATIENTS_BILLING } from "@cims/common";
import { AppointmentContext } from "../../../services/appointment/appointment.context";
import LoadingIndicator from "../../../components/LoadingIndicator";
import ErrorInfo from "../../../components/ErrorInfo";
import clsx from "clsx";
import { convertDate } from "../../../utility/commons";
import { AuthenticationContext } from "src/services/authentication/authentication.context";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
    width: "25ch",
  },
  payBtn: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true" ? "#828282" : "#EB7A77",
    height: 211,
    width: 345,
    fontSize: 25,
    "&:hover": {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#BDC0BA" : "#78C2C4",
      color: "#fff",
    },
  },
  btn: {
    textAlign: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  btnApply: {
    color: "#21B1F6",
    borderColor: "#21B1F6",
  },
  btnCancel: {
    color: "#F4A7B9",
    borderColor: "#F4A7B9",
  },
  datePicker: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function AddBilling() {
  const classes = useStyles();
  const { patientId } = useParams();
  const today = new Date();
  const [date, setDate] = useState(convertDate(today));
  const history = useHistory();
  const { curPickedPatient } = useContext(AuthenticationContext);

  const { data, loading, error } = useQuery(PATIENTS_BILLING, {
    variables: {
      patient_id: Number(curPickedPatient.id),
    },
  });

  // detail -> reason

  const [values, setValues] = React.useState({
    amount: "",
    payDate: "",
    dueDate: "",
    siteName: "",
    details: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  return (
    <Container>
      <div className={classes.bar}>
        <Typography
          variant="body1"
          className={classes.title}
          component="h2"
          gutterBottom
        >
          {`Make a new invoice for ${curPickedPatient.firstName} ${curPickedPatient.lastName}`}
        </Typography>
      </div>

      {/* <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}> */}
      <FormControl
        fullWidth
        className={clsx(classes.margin, classes.textField)}
        variant="outlined"
      >
        <form className={classes.container} noValidate>
          <TextField
            id="date"
            label="Choose the invoice date"
            type="date"
            defaultValue={date} //"2017-05-24"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              // console.log("value--->", e.target.value);
              setDate(e.target.value);
            }}
          />
        </form>
      </FormControl>

      <FormControl
        fullWidth
        className={clsx(classes.margin, classes.textField)}
        variant="outlined"
      >
        <form className={classes.container} noValidate>
          <TextField
            id="date"
            label="Choose the invoice date"
            type="date"
            defaultValue={date} //"2017-05-24"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              // console.log("value--->", e.target.value);
              setDate(e.target.value);
            }}
          />
        </form>
      </FormControl>

      <FormControl
        fullWidth
        className={clsx(classes.margin, classes.textField)}
        variant="outlined"
      >
        <InputLabel htmlFor="outlined-adornment-amount">Site Name</InputLabel>
        <OutlinedInput
          id="outlined-adornment-siteName"
          value={values.siteName}
          onChange={handleChange("siteName")}
          labelWidth={85}
        />
      </FormControl>
      {/* </Grid>
        </Grid> */}

      <FormControl fullWidth className={classes.margin} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
        <OutlinedInput
          id="outlined-adornment-amount"
          value={values.amount}
          onChange={handleChange("amount")}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          labelWidth={60}
        />
      </FormControl>

      <FormControl fullWidth className={classes.margin} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-amount">Details</InputLabel>
        <OutlinedInput
          id="outlined-adornment-details"
          value={values.details}
          onChange={handleChange("details")}
          startAdornment={
            <InputAdornment position="start">
              <strong>·</strong>
            </InputAdornment>
          }
          labelWidth={60}
        />
      </FormControl>

      <div className={classes.btn}>
        <Button
          variant="outlined"
          onClick={() => history.push(`/patients/${patientId}/billingOverview`)}
          size="large"
          className={classes.btnCancel}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={() => history.push(`/patients/${patientId}/billingOverview`)}
          size="large"
          className={classes.btnApply}
        >
          Apply
        </Button>
      </div>
    </Container>
  );
}
