import React, { useState, useContext, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from "react-router-dom";
import { AuthenticationContext } from "../../../services/authentication/authentication.context";
import { useQuery, useMutation } from "@apollo/client";
import Alert from '@mui/material/Alert';
import { serverUrl, UPLOAD_ATTACHMENT } from "@cims/common";
import axios from "axios";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  field: {
    marginTop: 20,
    marginBottom: 20,
    display: "block",
  },
});

export const Home = ({ fileCallBack }) => {
  const [newPasscode, setNewPasscode] = useState("");
  const [confirmPasscode, setConfirmPasscode] = useState("");
  const [passwordError, setNewPasscodeError] = useState(false);
  const [passcodeError, setConfirmPasscodeError] = useState(false);
  const [expiration, setExpiration] = useState(0);

  const classes = useStyles();
  const history = useHistory();
  const [formData, setFormData] = useState(null);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const { user, contextHeaders } = useContext(AuthenticationContext);
  const [uploadedFile, setUploadedFile] = useState("");
  const [uploadedFileUrl, setUploadedFileUrl] = useState("");
  const [fileName, setFileName] = useState(null);
  const handleFileCallBack = (childData) => {
    console.log("childData", childData);
    setFileName(childData);
  };

  const [uploadFile, { error: uploadError }] = useMutation(UPLOAD_ATTACHMENT, {
    variables: {
      attachment: selectedFile,
      messageId: 0,
    },
    context: contextHeaders,
  });

  useEffect(() => {
    if (user === null) history.push("/login");
  });

  const handleSubmit = (e) => {
    // e.preventDefault();
  };

  const token = contextHeaders.headers.authorization;
  const siteUrl = serverUrl;
  const webSiteUrl = "http://localhost:3000";

  // const {
  //   data: expirationsData,
  //   loading: apptLoading2,
  //   error: apptError2,
  // } = useQuery(FIND_PATIENTS_BY_PHYSICIAN, {
  //   variables: { physicianId: Number(user.person.id) },
  //   context: contextHeaders,
  // });

  let expirationList = [4, 8, 12, 24, 48, 72];

  const handleChange = (event) => {
    // console.log("event.target.value", event.target.value);
    // setExpiration(Number(event.target.value));
  };

  // if (expirationsData) {
  //   expirationList = expirationsData.patientsByPhysicianId;
  //   // console.log("expirationsData", expirationsData);
  // }

  const checkPasscode = (e) => {
    e.preventDefault();
    setNewPasscodeError(false);
    setConfirmPasscodeError(false);

    if (newPasscode === "" || confirmPasscode === "") {
      setNewPasscodeError(true);
      setAlert(true);
      setAlertSeverity("error");
      setAlertContent("Your Temporary/Confirm Passcode is blank!");
    } else if (newPasscode !== confirmPasscode) {
      setNewPasscodeError(true);
      setConfirmPasscodeError(true);
      setAlert(true);
      setAlertSeverity("error");
      setAlertContent("Your Temporary and Confirm Passcodes are not matched!");
    } else if (newPasscode && confirmPasscode) {
      handleFileUpload();
    }
  };

  const handleFileUpload = () => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute(
      "accept",
      ".xlsx,.xls,image/*,.doc,.docx,.ppt,.pptx,.txt,.pdf"
    );
    input.click();

    input.onchange = async () => {
      var file: any = input.files[0];
      var formData = new FormData();

      // formData.append('image', file);
      formData.append("file", file);
      // console.log("file", file);
      var fileName = file.name;

      setFormData(file);
      setFileName(fileName);

      // console.log("formData", file);
      // console.log("fileName", fileName);

      const res = UploadFiles(formData, fileName);
    };
  };

  const UploadFiles = (formData, filename) => {
    console.log("user.person.id", user.person.id);

    axios
      .post(
        siteUrl +
          "/api/encryptfile/" +
          user.person.id +
          "/" +
          filename +
          "/" +
          newPasscode +
          "/" +
          expiration,
        formData,
        {
          headers: {
            "Content-Type": "application/form-data",
            Authorization: token,
          },
        }
      )
      .then((response) => {
        console.log("response", response.data.fileUrl);
        setUploadedFile(filename);

        // Replace hostname
        var url = response.data.fileUrl;
        url = url.replace(siteUrl, webSiteUrl);
        setUploadedFileUrl(url);

        setAlert(true);
        setAlertSeverity("success");
        setAlertContent(
          "The file [" +
            filename +
            "] has been encrypted and uploaded successfully!"
        );
        console.log("uploadFile, filename: ", filename);
        fileCallBack = { handleFileCallBack };
        //fileCallBack(filename);
      })
      .catch((error) => {
        console.log("uploaded", false);
        setAlert(true);
        setAlertSeverity("error");
        setAlertContent("" + error);
      });
  };

  return (
    <Container size="sm">
      <Typography
        variant="h6"
        color="textSecondary"
        component="h2"
        gutterBottom
      >
        Encrypt File With Temporary Passcode
      </Typography>

      <div>
        {alert ? <Alert severity={alertSeverity}>{alertContent}</Alert> : <></>}
      </div>

      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <TextField
          type="password"
          className={classes.field}
          onChange={(e) => setNewPasscode(e.target.value)}
          label="Temporary Passcode"
          variant="outlined"
          color="secondary"
          fullWidth
          required
          error={passwordError}
        />

        <TextField
          type="password"
          className={classes.field}
          onChange={(e) => setConfirmPasscode(e.target.value)}
          label="Confirm Passcode"
          variant="outlined"
          color="secondary"
          fullWidth
          required
          error={passcodeError}
        />

        <TextField
          id="outlined-select-currency-native"
          select
          label="Passcode Expiration"
          value={expiration}
          onChange={(e) => setExpiration(e.target.value)}
          SelectProps={{
            native: true,
          }}
          // helperText="Please select your expiration"
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                Passcode will be expired in :{" "}
              </InputAdornment>
            ),
          }}
        >
          {expirationList?.map((val) => {
            return (
              <option key={val} value={val}>
                &nbsp;&nbsp;&nbsp;{val} Hours
              </option>
            );
          })}
        </TextField>

        <center>
          <div>
            {uploadedFileUrl ? (
              <div>
                {/* <p>Encrypted File URL: <Link to="{uploadedFileUrl}"> {uploadedFileUrl} </Link></p> */}
                <p>
                  Encrypted File URL: <u>{uploadedFileUrl}</u>
                </p>
              </div>
            ) : (
              <p></p>
            )}
          </div>
          <div>
            <Button
              className={classes.iconButton}
              aria-label="Attach"
              color="primary"
              onClick={checkPasscode}
              color="primary"
              variant="contained"
            >
              Encrypt File
            </Button>
          </div>
        </center>
      </form>
    </Container>
  );
};

export default Home;
