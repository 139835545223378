import React, { useContext, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Grid, Paper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Link, useHistory } from "react-router-dom";
import { AuthenticationContext } from "../../../services/authentication/authentication.context";
// import { CustomTable } from "@cims/common/";
import { GET_USER } from "@cims/common/";
import { useQuery } from "@apollo/client";
import { userVar } from "src/App";
import Button from "src/pages/communication/components/controls/Button";

const useStyles = makeStyles({
  field: {
    marginTop: 20,
    marginBottom: 20,
    display: "block",
  },
  hsCard: {
    display: "flex",
    alignItems: "center",
    padding: 10,
  },
});

function EMRCard({ src = "/UHX-Logo.png", name, linkto = "#" }) {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={12} lg={12} style={{ maxWidth: 800 }}>
      <Link to={linkto}>
        <Paper className={classes.hsCard}>
          <img src={src} alt="logo" height={60} />
          <Typography variant="h4" color="primary" style={{ marginLeft: 50 }}>
            {name}
          </Typography>
        </Paper>
      </Link>
    </Grid>
  );
}

export default function Home() {
  const history = useHistory();

  const { user } = useContext(AuthenticationContext);
  const { data: testuser } = useQuery(GET_USER);
  // const testuser = useReactiveVar(userVar);
  console.log("testuser--->", testuser);
  const setUser = () => {
    userVar({
      person: {
        firstName: "Jun",
        lastName: "Liu",
      },
      token: "dfadfjasfjlsd",
    });
  };
  useEffect(() => {
    if (user === null) history.push("/login");
  });

  return (
    <Container size="sm">
      <Typography
        variant="h6"
        color="textSecondary"
        component="h2"
        gutterBottom
      >
        Welcome to Universal Health Exchange Platform! You can choose any
        provider below:
      </Typography>
      <Button onClick={setUser}>Set user</Button>
      {/* <CustomTable
        headCells={headCells}
        rows={rows}
        title={title}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rowsPerPageOptions={[5, 10, 20, 30]}
        page={page}
        setPage={setPage}
        totalSize={rows.length}
        dataHasPagination={false}
      /> */}
      <Grid container spacing={3}>
        <EMRCard src="/img/logo-curemd.jpeg" name="CureMD" />
        <EMRCard name="EMR A" />
        <EMRCard name="EMR B" />
        <EMRCard name="EMR C" />
        <EMRCard name="EDC A" />
        <EMRCard name="EDC B" />
        <EMRCard name="EDC C" />
        <EMRCard name="MMR(My Medical Record)" linkto="/mmr" />
      </Grid>
    </Container>
  );
}
