import { useMutation } from "@apollo/client";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { NOTIFICATIONS_BY_PERSON, UPDATE_NOTIFICATION } from "@cims/common";
import { AuthenticationContext } from "../services/authentication/authentication.context";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
  },
  textBold: {
    fontWeight: 700,
  },
  textLight: {
    fontWeight: 300,
  },
}));

export default function NotificationPiece({
  notification,
  changeOpenNotification,
}) {
  const classes = useStyles();
  // const [font, setFont] = useState(
  //   notification.status === "unread" ? 700 : 300
  // );
  const { user } = useContext(AuthenticationContext);

  const [updateNotificationStatus, { data: updatedData, error: updatedError }] =
    useMutation(UPDATE_NOTIFICATION, {
      refetchQueries: [
        {
          query: NOTIFICATIONS_BY_PERSON,
          variables: {
            person_id: Number(user.person.id),
          },
        },
      ],
    });

  return (
    <div>
      <Link
        to={`${notification.webLink}`}
        onClick={() => {
          changeOpenNotification();
          updateNotificationStatus({
            variables: {
              id: Number(notification.id),
              status: "in-active",
            },
          });
          // setFont(300);
          //change notification status read
        }}
      >
        {/* <Typography variant="caption" style={{ fontWeight: `${font}` }}> */}
        <Typography variant="caption">{notification.message}</Typography>
      </Link>
    </div>
  );
}
