import React, { useState, useContext, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import makeStyles from '@mui/styles/makeStyles';
import TextField from "@mui/material/TextField";

import { useHistory } from "react-router-dom";
import { AuthenticationContext } from "../../../services/authentication/authentication.context";

import { UPDATE_PASSCODE } from "@cims/common";
import { useMutation } from "@apollo/client";
import Alert from '@mui/material/Alert';

const useStyles = makeStyles({
  field: {
    marginTop: 20,
    marginBottom: 20,
    display: "block",
  },
});

export default function Home() {
  const classes = useStyles();
  const history = useHistory();
  const [newPasscode, setNewPasscode] = useState("");
  const [confirmPasscode, setConfirmPasscode] = useState("");
  const [passwordError, setNewPasscodeError] = useState(false);
  const [passcodeError, setConfirmPasscodeError] = useState(false);


  const { user, contextHeaders } = useContext(AuthenticationContext);

  const [updatePasscode, { error: updatePasscodeError }] = useMutation(
    UPDATE_PASSCODE,
    {
      variables: {
        personId: Number(user.person.id),
        input: {
          originalPassword: "password",
          newPassword: newPasscode,
        },
      },
      context: contextHeaders,
    }
  );

  useEffect(() => {
    if (user === null) history.push("/login");
  });

  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setNewPasscodeError(false);
    setConfirmPasscodeError(false);

    if (newPasscode === "" || confirmPasscode === "") {
      setNewPasscodeError(true);
      setAlert(true);
      setAlertSeverity("error");
      setAlertContent("Your New/Confirm Passcode is blank!");
    } else if (newPasscode !== confirmPasscode) {
      setNewPasscodeError(true);
      setConfirmPasscodeError(true);
      setAlert(true);
      setAlertSeverity("error");
      setAlertContent("Your New and Confirm Passcodes are not matched!");
    } else if (newPasscode && confirmPasscode) {
      updatePasscode()
        .then(() => {
          setAlert(true);
          setAlertSeverity("success");
          setAlertContent("Your passcode has been updated successfully!");
        })
        .catch((e) => {
          setAlert(true);
          setAlertSeverity("error");
          setAlertContent("" + e);
          // console.log("error: ", e);
        });
    }
  };

  return (
    <Container size="sm">
      <Typography
        variant="h6"
        color="textSecondary"
        component="h2"
        gutterBottom
      >
        Change Passcode
      </Typography>

      <div>
        {alert ? <Alert severity={alertSeverity}>{alertContent}</Alert> : <></>}
      </div>

      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <TextField
          type="password"
          className={classes.field}
          onChange={(e) => setNewPasscode(e.target.value)}
          label="New Passcode"
          variant="outlined"
          color="secondary"
          fullWidth
          required
          error={passwordError}
        />

        <TextField
          type="password"
          className={classes.field}
          onChange={(e) => setConfirmPasscode(e.target.value)}
          label="Confirm Passcode"
          variant="outlined"
          color="secondary"
          fullWidth
          required
          error={passcodeError}
        />
        <center>
          <Button type="submit" color="primary" variant="contained">
            Submit
          </Button>
        </center>
      </form>
    </Container>
  );
}
