import React, { useState, useContext } from "react";
import {
  TableCell,
  TableRow,
  Typography,
  Container,
  Fab,
  Modal,
  Backdrop,
  Fade,
  Button,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useForm } from "../../communication/components/useForm";

import AddIcon from "@mui/icons-material/Add";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_STUDIES,
  ADD_STUDIES,
} from "src/services/mmr/patient-matcher.service";
import { AuthenticationContext } from "src/services/authentication/authentication.context";
import { useHistory, useParams } from "react-router-dom";
import { CustomTable } from "@cims/common/";
import { blue } from '@mui/material/colors';

const initialFValues = {
  sponsor: "",
  titleofstudy: "",
  protocolnumber: "",
  inclusion: "",
  exclusion: "",
};

function createData(
  title,
  protocolNum,
  sponsor,
  inclusionCriteria,
  exclusionCriteria
) {
  return {
    title,
    protocolNum,
    sponsor,
    inclusionCriteria,
    exclusionCriteria,
  };
}
const useStyles = makeStyles((theme) => ({
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 20,
    // height: "50px",
  },
  addBtnIcon: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
    color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
    "&:hover": {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#BDC0BA" : blue[700],
      color: "#fff",
    },
  },

  btn: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  paper: {
    backgroundColor: "#f5f3f0", //theme.palette.background.paper,
    border: "1px solid #666666",
    width: 700,
    maxHeight: 700,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "auto",
  },

  htyCard: {
    maxWidth: 345,
    marginBottom: "10px",
  },

  cardHeader: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true"
        ? "rgba(0,26,72, 0.3)"
        : "rgba(126,211,205,0.5)",
  },

  divider: {
    backgroundColor: "#d9e2ee",
    margin: "0 20px",
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
    // height: "40px",
  },
  addBtnIcon: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
    color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
    "&:hover": {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#BDC0BA" : blue[700],
      color: "#fff",
    },
  },
});

const useModalStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "#f5f3f0", //theme.palette.background.paper,
    border: "1px solid #666666",
    width: 700,
    maxHeight: 700,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "auto",
  },
  tf: {
    marginBottom: 15,
  },
  btn: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function PatientMatcher() {
  let rowss = [];
  const classes = useRowStyles();
  const myStyles = useStyles();
  const modalClasses = useModalStyles();
  const [showError, setShowError] = useState(false);
  const headCells = [
    {
      id: "sponsor",
      numeric: false,
      disablePadding: true,
      label: "Sponsor",
      align: "left",
      path: ["sponsor"],
    },
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: "Title of Study",
      align: "right",
      path: ["title"],
    },
    {
      id: "protocolNum",
      numeric: true,
      disablePadding: false,
      label: "Protocol Number",
      align: "right",
      path: ["protocolNum"],
    },
    {
      id: "inclusionCriteria",
      numeric: true,
      disablePadding: false,
      label: "Inclusion Criteria",
      align: "right",
      path: ["inclusionCriteria"],
    },
    {
      id: "exclusionCriteria",
      numeric: true,
      disablePadding: false,
      label: "Exclusion Criteria",
      align: "right",
      path: ["exclusionCriteria"],
    },
  ];

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const { user } = useContext(AuthenticationContext);
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true);

  const title = "Search History";
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();
  const {
    data: studydata,
    loading: studydataloading,
    error: studydataerror,
  } = useQuery(GET_STUDIES, {
    variables: { uId: Number(user.person.id), page: page, size: size },
  });

  // console.log("Retrieve data from Server: ", studydata);
  studydata &&
    studydata.getStudiesCTsIECriteriasByStudiesCTsId !== null &&
    studydata.getStudiesCTsIECriteriasByStudiesCTsId.studiesCTsIECriterias.map(
      (item) => {
        // console.log("organization: name  " + item.name);
        // console.log("organization: type  " + item.type);
        // console.log(" organization, locations  " + item.locations);
        rowss.push(
          createData(
            item.title,
            item.protocolNum,
            item.sponsor,
            item.inclusionCriteria,
            item.exclusionCriteria
          )
        );
      }
    );
  // console.log("rowss", rowss);

  //console.log("studydata: ", studydata);
  const [AddStudy, { data: addStudyData, error: addStudyErroe }] = useMutation(
    ADD_STUDIES,
    {
      refetchQueries: [
        {
          query: GET_STUDIES,
          variables: { uId: Number(user.person.id), page: page, size: size },
        },
      ],
    }
  );

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const changeSize = (size) => {
    setPage(0);
    setSize(size);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, rowss.length - (page + 1) * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
      // backgroundColor: "green",
      // color: theme.palette.common.white,
      color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  return (
    <Container>
      <div className={classes.root}>
        <Typography variant="body1" gutterBottom component="div">
          Patient Matcher
        </Typography>
      </div>

      {studydata &&
        studydata.getStudiesCTsIECriteriasByStudiesCTsId !== null && (
          <CustomTable
            headCells={headCells}
            rows={rowss}
            title={title}
            rowsPerPage={size}
            setRowsPerPage={setSize}
            rowsPerPageOptions={[10, 20, 30]}
            page={page}
            setPage={setPage}
            totalSize={
              studydata.getStudiesCTsIECriteriasByStudiesCTsId.totalSize
            }
          />
        )}

      <div className={myStyles.addBtn}>
        <Fab
          className={myStyles.addBtnIcon}
          aria-label="add a new health system button"
          onClick={() => setOpenModal(true)}
        >
          <AddIcon />
        </Fab>
      </div>

      {/** add loc */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={modalClasses.modal}
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={modalClasses.paper}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  Enter the Protocol Synopsis Here:
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ width: "100%" }}>
                  <TextField
                    className={modalClasses.tf}
                    fullWidth
                    required
                    name="sponsor"
                    id="Sponsor"
                    label="Sponsor"
                    variant="outlined"
                    value={values.sponsor}
                    onChange={handleInputChange}
                    error={errors.sponsor}
                  />
                  <TextField
                    className={modalClasses.tf}
                    fullWidth
                    required
                    name="titleofstudy"
                    id="title-of-study"
                    label="Title Of Study"
                    variant="outlined"
                    value={values.titleofstudy}
                    onChange={handleInputChange}
                    error={errors.titleofstudy}
                  />

                  <TextField
                    className={modalClasses.tf}
                    fullWidth
                    required
                    name="protocolnumber"
                    id="protocol-number"
                    label="Protocol Number"
                    variant="outlined"
                    value={values.protocolnumber}
                    onChange={handleInputChange}
                    error={errors.protocolnumber}
                  />
                  <TextField
                    className={modalClasses.tf}
                    fullWidth
                    required
                    name="inclusion"
                    id="inclusion"
                    label="Inclusion Criteria"
                    multiline
                    maxRows={5}
                    variant="outlined"
                    value={values.inclusion}
                    onChange={handleInputChange}
                    error={errors.inclusion}
                  />
                  <TextField
                    className={modalClasses.tf}
                    fullWidth
                    required
                    name="exclusion"
                    id="exclusion"
                    label="Exclusion Criteria"
                    multiline
                    maxRows={5}
                    variant="outlined"
                    value={values.exclusion}
                    onChange={handleInputChange}
                    error={errors.exclusion}
                  />
                </div>
              </AccordionDetails>
            </Accordion>

            <div className={modalClasses.btn}>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => {
                  e.preventDefault();

                  let pass = true;
                  for (let key in values) {
                    if (values[key] === "") {
                      pass = false;
                      setShowError(true);
                    }
                  }
                  if (pass) {
                    setShowError(false);
                    AddStudy({
                      variables: {
                        uId: Number(user.person.id),
                        title: values.titleofstudy,
                        protocolNum: values.protocolnumber,
                        sponsor: values.sponsor,
                        inclusionCriteria: values.inclusion,
                        exclusionCriteria: values.exclusion,
                      },
                    }).then((response) => {
                      resetForm();
                      handleClose();
                      //console.log("==========addstudydata: ", response.data);
                      history.push({
                        pathname: `/clinicalstudies/patientmatcherresult`,
                        state: {
                          studydata:
                            response.data.addStudiesCTsIECriteriasByStudyCTsID,
                          searchTitle: values.titleofstudy,
                          searchProtocol: values.protocolnumber,
                          searchSponsor: values.sponsor,
                          searchInclusion: values.inclusion,
                          searchExclusion: values.exclusion,
                        },
                      });
                      console.log("successful!");
                    });
                  }
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </Container>
  );
}
