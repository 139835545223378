import React, { useRef, useEffect, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { AuthenticationContext } from "src/services/authentication/authentication.context";
import { Document, Page } from "react-pdf";
import FileViewer from "react-file-viewer";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    marginBottom: "10px",
  },
  divider: {
    backgroundColor: "#d9e2ee",
    margin: "0 20px",
  },
}));

export default function Overview({ apptData = {} }) {
  // const { user, setUser, curPickedPatient } = useContext(AuthenticationContext);
  const myIframe = useRef();
  const [link, setLink] = useState("/CIMS-logo.png");
  const [text, setText] = useState("View HL7 C-CDA Data");
  const [pdfFile, setPdfFile] = useState("null");
  const [doc, setDoc] = useState(null);
  const [table, setTable] = useState(null);
  const [jsonText, setJsonText] = useState(null);

  const headers = new Headers();
  headers.set("Authorization", `Bearer ${localStorage.getItem("auth_token")}`);

  function fetchWithAuthentication(url) {
    // const headers = new Headers();
    // headers.set("Authorization", `Bearer ${authToken}`);
    return fetch(url, { headers });
  }

  const processCSV = (str, delim = ",") => {
    const headers = str.slice(0, str.indexOf("\n")).split(delim);
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");

    const newArray = rows.map((row) => {
      const values = row.split(delim);
      const eachObject = headers.reduce((obj, header, i) => {
        obj[header] = values[i];
        return obj;
      }, {});
      return eachObject;
    });
    console.log("csv--->", newArray);
    // setCsvArray(newArray);
  };

  const parseCSV = (str) => {
    const arr = str.split("\n");
    console.log("csv--->", arr);
    return (
      <table style={{ border: "solid 1px" }}>
        {arr.map((row) => {
          if (row === "") return null;
          return (
            <tr style={{ border: "solid 1px" }}>
              {row.split(",").map((cell) => {
                return <td style={{ border: "solid 1px" }}>{cell}</td>;
              })}
            </tr>
          );
        })}
      </table>
    );
  };

  function fetchTxt(url) {
    fetch(url, { headers })
      .then((response) => {
        console.log("response--->", response);
        return response.text();
      })
      .then((text) => {
        console.log("text--->", text);
        setText(text);
      })
      .catch((e) => console.log(e));
  }

  function fetchCsv(url) {
    fetch(url, { headers })
      .then((response) => {
        console.log("response--->", response);
        return response.text();
      })
      .then((text) => {
        console.log("text--->", text);
        setTable(parseCSV(text));
        // setText(text);
      })
      .catch((e) => console.log(e));
  }

  function fetchJson(url) {
    fetch(url, { headers })
      .then((response) => {
        console.log("response--->", response);
        return response.text();
      })
      .then((text) => {
        console.log("text--->", text);
        setJsonText(text);
      })
      .catch((e) => console.log(e));
  }

  function fetchDoc(url) {
    fetch(url, { headers })
      .then((response) => {
        return response.text();
      })
      .then((text) => {
        console.log("doc text---->", text);
      })
      // .then((r) => r.blob())
      // .then((response) => {
      //   const file = new Blob([response], { type: "application/docx" });
      //   console.log("doc blob--->", file.text());
      //   console.log("doc url---------->", URL.createObjectURL(file));
      //   // setDoc(URL.createObjectURL(file));

      //   setDoc(
      //     `https://docs.google.com/viewer?url=${URL.createObjectURL(
      //       file
      //     )}&embedded=true`
      //   );
      // })
      .catch((e) => console.log(e));
  }

  const DocIframe = ({ source }) => {
    if (!source) {
      return <div>Loading...</div>;
    }

    const src = source;
    return (
      <div>
        <iframe
          src={"https://docs.google.com/viewer?url=" + src + "&embedded=true"}
          title="file"
          width="100%"
          height="600"
        ></iframe>
      </div>
    );
  };

  const base64 = require("base64topdf");

  function fetchPdf(url) {
    fetch(url, { headers })
      .then((r) => r.blob())
      .then((response) => {
        const file = new Blob([response], { type: "application/pdf" });
        console.log("blob--->", file);
        // console.log("blob url--->", URL.createObjectURL(file));
        setPdfFile(URL.createObjectURL(file));
      })
      .catch((e) => console.log(e));
  }

  function fetchImg(url) {
    fetch(url, { headers })
      .then(function (response) {
        return response.blob();
      })
      .then(function (myBlob) {
        var objectURL = URL.createObjectURL(myBlob);
        setLink(objectURL);
        console.log("blob--->", objectURL);
      })
      .catch((e) => console.log(e));
  }

  function arrayBufferToBase64(buffer) {
    return btoa(String.fromCharCode(...new Uint8Array(buffer)));
  }

  const txtUrl =
    "http://52.154.73.29/api/downloadDecryptXML/337/Test/MyText_enc.txt/";
  const pdfUrl =
    "http://52.154.73.29/api/downloadDecryptFile/337/Test/10DLC_Registration_Form_enc.pdf/";
  const imageUrl =
    "http://52.154.73.29/api/downloadDecryptFile/337/Test/Dr.Sue_enc.png/";
  const docUrl =
    "http://52.154.73.29/api/downloadDecryptFile/337/Test/10DLC_Registration_Form_TV._enc.docx/";
  const csvUrl =
    "http://52.154.73.29/api/downloadDecryptFile/337/Test/MyCVS_enc.csv/";
  const jsonUrl =
    "http://52.154.73.29/api/downloadDecryptFile/337/Test/testJson._enc.json/";

  useEffect(() => {
    // fetchImg(imageUrl, authToken);
    // displayProtectedImage(imageUrl);
    // fetchTxt(txtUrl);
    // fetchImg(imageUrl);
    fetchPdf(pdfUrl);
    fetchDoc(docUrl);
    fetchCsv(csvUrl);
    fetchJson(jsonUrl);
  }, []);

  return (
    <div>
      <Typography variant="h6" gutterBottom style={{ paddingTop: "10px" }}>
        {text}
      </Typography>

      <img alt="test" src={link} />
      <div style={{ margin: 10 }}>{doc}</div>
      <div>{table}</div>
      <div>{jsonText}</div>
      {/* <FileViewer
        fileType="docx"
        filePath={`blob:http://localhost:3001/9e4c16d2-518f-4090-aecb-81005515e475`}
      /> */}
      <iframe
        title="doc viewer"
        // ref={myIframe}
        src={doc}
        width="100%"
        height="700"
        // frameBorder="0"
      />
    </div>
  );
}
