import React, { useContext, useState, useRef, useEffect } from "react";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import clsx from "clsx";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  serverUrl,
  MyModal,
  FIND_ENCRYPT_FILES,
  DELETE_ENCRYPT_FILE,
} from "@cims/common";

import { UPLOAD_MEDICAL_RECORD } from "@cims/common/services/upload-medical-record.service";

import { useQuery, useMutation } from "@apollo/client";
import { AuthenticationContext } from "../../../services/authentication/authentication.context";

import TextField from "@mui/material/TextField";

import "react-quill/dist/quill.snow.css";
import { Snackbar } from "@mui/material";
import axios from "axios";

import CloseIcon from "@mui/icons-material/Close";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { motion } from "framer-motion";
import { Alert } from '@mui/material';
import LoadingIndicator from "src/components/LoadingIndicator";
import ErrorInfo from "src/components/ErrorInfo";

import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import FilterFramesIcon from "@mui/icons-material/FilterFrames";
import NoteOutlinedIcon from "@mui/icons-material/NoteOutlined";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import ColorizeIcon from "@mui/icons-material/Colorize";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";

const useModalStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 680,
    height: 660,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  papermodal: {
    backgroundColor: "#f5f3f0", //theme.palette.background.paper,
    border: "1px solid #666666",
    width: "100%",
    maxHeight: 700,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    margin: 10,
    overflow: "auto",
  },
  tf: {
    marginBottom: 15,
  },
  btn: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
  },
  // heading: {
  //   fontSize: theme.typography.pxToRem(15),
  //   fontWeight: theme.typography.fontWeightMedium,
  // },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

var globalContextHeaders;
var globalUser;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // position: "relative",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  viewTab: {
    position: "absolute",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

export default function EncryptFileListTable() {
  const classes = useStyles();

  const { user, contextHeaders, curPickedPatient } = useContext(
    AuthenticationContext
  );
  console.log("Cur patient--->", curPickedPatient);

  globalContextHeaders = contextHeaders;
  globalUser = user;

  const [size, setSize] = useState(50);
  const [page, setPage] = useState(0);

  const modalClasses = useModalStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [alert, setAlert] = useState(false);
  const sources = ["myPenn", "CVS", "Walgreen", "cureMD", "other"];
  const [dataSource, setDataSource] = useState(sources[0]);
  const [uploadError, setUploadError] = useState(false);
  const [uploadErrorText, setUploadErrorText] = useState(null);
  const [bodyText, setBodyText] = useState("");
  const [uploadedFile, setUploadedFile] = useState("");
  const [uploadedFileUrl, setUploadedFileUrl] = useState("");
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [folderValue, setFolderValue] = React.useState("LR");

  const handleChangeFolder = (event) => {
    setFolderValue(event.target.value);
  };

  const [uploadMR, { error: uplaodMRError }] = useMutation(
    UPLOAD_MEDICAL_RECORD,
    {
      variables: {
        personId: Number(user.person.id),
        pdfFile: uploadedFileName,
        sourceOfData: dataSource,
        folderName: folderValue,
        content: bodyText,
      },
      context: contextHeaders,
      refetchQueries: [
        {
          query: FIND_ENCRYPT_FILES,
          variables: {
            person_id: Number(user.person.id),
          },
          context: globalContextHeaders,
        },
      ],
    }
  );

  const [formData, setFormData] = useState(null);
  const [fileName, setFileName] = useState(null);

  const handleUploadFile = (option) => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute(
      "accept",
      ".xlsx,.xls,image/*,.doc,.docx,.ppt,.pptx,.txt,.pdf,.zip,.xml,.csv,.json"
    );
    input.click();

    input.onchange = async () => {
      var file = input.files[0];
      var formData = new FormData();

      // formData.append('image', file);
      formData.append("file", file);
      // formData.append("attachment", file);
      var fileName = file.name;

      setFormData(file);
      setFileName(fileName);

      const res = UploadFiles(formData, folderValue, dataSource, fileName, "1");
    };
  };

  const token = contextHeaders.headers.authorization;
  const siteUrl = serverUrl;

  const UploadFiles = (formData, folderName, source, filename, option) => {
    axios
      .post(
        `${siteUrl}/api/uploadMMRByFolder/${user.person.id}/${folderName}/${source}/${filename}/${option}`,
        formData,
        {
          headers: {
            "Content-Type": "application/form-data",
            Authorization: token,
          },
        }
      )
      .then((response) => {
        if (
          response.data.fileUrl === "" ||
          response.data.message.includes("Passcode is Null")
        ) {
          setAlert(true);
        } else {
          setUploadedFile(filename);
          setUploadedFileUrl(response.data.fileUrl);
          var fileUrl = response.data.fileUrl;
          var fileUrlSplit = fileUrl.split("/");
          var maskedfileName = fileUrlSplit[fileUrlSplit.length - 1];
          //setUploadedFileName(maskedfileName);
          //set uploadedFileName to empty, server will not update the record
          setUploadedFileName("");
          uploadMR();
        }
      })
      .catch((error) => {
        setUploadError(true);
        setUploadErrorText(error.toString());
      });
  };

  const {
    data: encryptedFile,
    loading: encryptedFileLoading,
    error: encryptedFileError,
  } = useQuery(FIND_ENCRYPT_FILES, {
    variables: { person_id: Number(curPickedPatient.id) },
  });

  const [
    deleteMedicalRecordById,
    { data: deleteEncryptFile, error: deleteError },
  ] = useMutation(DELETE_ENCRYPT_FILE, {
    refetchQueries: [
      {
        query: FIND_ENCRYPT_FILES,
        variables: {
          person_id: Number(user.person.id),
        },
        context: globalContextHeaders,
      },
    ],
  });

  const getLabel = (name) => {
    let label = "";
    folderTotal.forEach((item) => {
      if (name === item.name)
        label = (
          <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            {item.icon}
            <span>{item.label}</span>
          </div>
        );
    });
    return label;
  };

  const folderTotal = [
    {
      name: "LR",
      label: "Lab Results",
      icon: <EventNoteOutlinedIcon />,
    },
    {
      name: "MD",
      label: "Medications",
      icon: <NoteOutlinedIcon />,
    },
    {
      name: "CN",
      label: "Clinical Notes",
      icon: <FilterFramesIcon />,
    },
    {
      name: "AL",
      label: "Allergy",
      icon: <FlashOnIcon />,
    },
    {
      name: "IR",
      label: "Immunization Record",
      icon: <ColorizeIcon />,
    },
    {
      name: "HS",
      label: "Health Summary",
      icon: <LocalHospitalIcon />,
    },
  ];

  const [sortedFiles, setSortedFiles] = useState(null);
  const [folderNames, setFolderNames] = useState([]);
  const [folderOpen, setFolderOpen] = useState({});
  useEffect(() => {
    if (encryptedFile) {
      let fileObj = {};
      let tempArr = [...folderNames];
      let openStatus = {};
      encryptedFile.findEncryptFiles.forEach((element) => {
        if (!tempArr.includes(element.folder)) {
          if (element.folder !== null) tempArr.push(element.folder);
          openStatus = {
            ...openStatus,
            [element.folder]: false,
          };
        }
        const item = {
          id: element.id,
          folder: element.fileLocation,
          date: element.timestamp.split("T")[0],
        };
        if (fileObj[element.folder]) {
          fileObj = {
            ...fileObj,
            [element.folder]: [...fileObj[element.folder], item],
          };
        } else {
          fileObj = {
            ...fileObj,
            [element.folder]: [item],
          };
        }
      });
      setSortedFiles(fileObj);
      setFolderNames([...tempArr].sort());
      setFolderOpen(openStatus);
    }
  }, [encryptedFile]);
  console.log("sortedFiles--->", sortedFiles);

  const closeAllFolder = (p) => {
    for (let key in p) {
      if (p.hasOwnProperty(key)) {
        p[key] = false;
      }
    }
  };

  const [openDataSource, setOpenDataSource] = useState(false);

  /**iframe modal*/

  const myIframe = useRef();

  const headers = new Headers();
  headers.set("Authorization", `Bearer ${localStorage.getItem("auth_token")}`);

  const sendXMLInfo = (info) => {
    myIframe.current.contentWindow.postMessage(info, "*");
  };

  const hideAllDiv = () => {
    setXmlDivVisible("hidden");
    setImgDivVisible("hidden");
    setPdfDivVisible("hidden");
    setTxtDivVisible("hidden");
    setDocDivVisible("hidden");
    setCsvDivVisible("hidden");
    setJsonDivVisible("hidden");
  };

  const viewerVariants = {
    hidden: {
      opacity: 0,
      x: "100vw",
    },
    visible: {
      opacity: 1,
      x: 50,
    },
  };

  const [move, setMove] = useState("hidden");

  const [pdfLink, setPdfLink] = useState(null);
  const [imgLink, setImgLink] = useState(null);
  const [txtText, setTxtText] = useState("");
  const [csvText, setCsvText] = useState(null);
  const [jsonText, setJsonText] = useState(null);

  const [xmlDivVisible, setXmlDivVisible] = useState("visible");
  const [imgDivVisible, setImgDivVisible] = useState("visible");
  const [pdfDivVisible, setPdfDivVisible] = useState("visible");
  const [txtDivVisible, setTxtDivVisible] = useState("visible");
  const [docDivVisible, setDocDivVisible] = useState("visible");
  const [csvDivVisible, setCsvDivVisible] = useState("visible");
  const [jsonDivVisible, setJsonDivVisible] = useState("visible");

  const parseCSV = (str) => {
    const arr = str.split("\n");
    return (
      <table style={{ border: "solid 1px" }}>
        {arr.map((row) => {
          if (row === "") return null;
          return (
            <tr style={{ border: "solid 1px" }}>
              {row.split(",").map((cell) => {
                return <td style={{ border: "solid 1px" }}>{cell}</td>;
              })}
            </tr>
          );
        })}
      </table>
    );
  };

  const viewFile = (viewId) => {
    hideAllDiv();
    setMove(move === "hidden" ? "visible" : "hidden");
    const arr = encryptedFile.findEncryptFiles.filter(
      (item) => viewId === item.id
    );
    const token = localStorage.getItem("auth_token");
    const id = curPickedPatient.id;
    const host = "http://52.154.73.29/api/downloadDecryptFileByFolder/";
    const url = `${host}${id}/${arr[0].folder}/${arr[0].sourceOfData}/${arr[0].fileLocation}/`;
    console.log("url--->", url);
    const headers = new Headers();
    headers.set("Authorization", `Bearer ${token}`);

    if (arr[0].fileLocation.includes(".XML")) {
      setXmlDivVisible("visible");
      sendXMLInfo({
        item: arr,
        token,
        id,
        host,
      });
    }

    if (arr[0].fileLocation.includes(".pdf")) {
      setPdfDivVisible("visible");

      fetch(url, { headers })
        .then((r) => r.blob())
        .then((response) => {
          const file = new Blob([response], { type: "application/pdf" });
          setPdfLink(URL.createObjectURL(file));
        })
        .catch((e) => console.log(e));
    }

    if (
      arr[0].fileLocation.includes(".png") ||
      arr[0].fileLocation.includes(".jpg") ||
      arr[0].fileLocation.includes(".jpeg")
    ) {
      setImgDivVisible("visible");
      fetch(url, { headers })
        .then(function (response) {
          return response.blob();
        })
        .then(function (myBlob) {
          var objectURL = URL.createObjectURL(myBlob);
          setImgLink(objectURL);
        })
        .catch((e) => console.log(e));
    }

    if (arr[0].fileLocation.includes(".txt")) {
      setTxtDivVisible("visible");
      fetch(url, { headers })
        .then((response) => {
          return response.text();
        })
        .then((text) => {
          setTxtText(text);
        })
        .catch((e) => console.log(e));
    }

    if (arr[0].fileLocation.includes(".csv")) {
      setCsvDivVisible("visible");
      fetch(url, { headers })
        .then((response) => {
          return response.text();
        })
        .then((text) => {
          setCsvText(parseCSV(text));
        })
        .catch((e) => console.log(e));
    }

    if (arr[0].fileLocation.includes(".json")) {
      setJsonDivVisible("visible");
      fetch(url, { headers })
        .then((response) => {
          return response.text();
        })
        .then((text) => {
          setJsonText(text);
        })
        .catch((e) => console.log(e));
    }
  };

  const deleteFile = (viewId) => {
    console.log("id---->", viewId);
    deleteMedicalRecordById({
      variables: {
        id: Number(viewId),
      },
    })
      .then(() => {
        console.log("delete successfully!");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const [expanded, setExpanded] = useState({});

  const handleExpandClick = (folder) => {
    setExpanded({
      ...expanded,
      [folder]: !expanded[folder],
    });
  };

  return (
    <div className={classes.root}>
      {encryptedFileLoading && <LoadingIndicator />}
      {encryptedFileError && <ErrorInfo />}
      <Grid container spacing={3}>
        {sortedFiles &&
          folderNames.map((folder) => {
            console.log("folder----->", folder);
            return (
              <Grid key={folder} item xs={12} md={12} lg={6}>
                <Card>
                  <CardHeader
                    title={getLabel(folder)}
                    action={
                      <IconButton
                        className={clsx(classes.expand, {
                          [classes.expandOpen]: expanded[folder]
                            ? expanded[folder]
                            : false,
                        })}
                        onClick={() => handleExpandClick(folder)}
                        aria-expanded={
                          expanded[folder] ? expanded[folder] : false
                        }
                        size="large">
                        <ExpandMoreIcon />
                      </IconButton>
                    }
                  />
                  <Collapse
                    in={expanded[folder] ? expanded[folder] : false}
                    timeout="auto"
                    unmountOnExit
                  >
                    <CardContent>
                      {sortedFiles[folder].map((item) => {
                        return (
                          <div
                            key={item.id}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}
                          >
                            <IconButton
                              size="small"
                              onClick={() => viewFile(item.id)}
                            >
                              <VisibilityIcon />
                            </IconButton>
                            <IconButton
                              size="small"
                              onClick={() => deleteFile(item.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                            <Typography>{item.date}</Typography>
                            <Typography>{item.folder}</Typography>
                          </div>
                        );
                      })}
                    </CardContent>
                  </Collapse>
                </Card>
              </Grid>
            );
          })}
      </Grid>

      <div style={{ marginTop: 30 }}>
        {sortedFiles &&
          folderNames.map((folder) => (
            <div key={folder}>
              {folderOpen[folder] &&
                sortedFiles[folder].map((item) => {
                  return (
                    <div
                      key={item.id}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <IconButton
                        size="small"
                        onClick={() => viewFile(item.id)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => deleteFile(item.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <Typography>{item.date}</Typography>
                      <Typography>{item.folder}</Typography>
                    </div>
                  );
                })}
            </div>
          ))}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 30,
        }}
      >
        <Button
          // type="submit"
          variant="contained"
          color="primary"
          onClick={() => setOpenDataSource(true)}
        >
          Upload Your Medical Record
        </Button>
      </div>
      {alert && (
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
        >
          <Alert
            severity="info"
            style={{ width: 500 }}
            onClose={() => setAlert(false)}
          >
            Go to Account Settings, Change Passcode
          </Alert>
        </div>
      )}
      {uploadError && (
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
        >
          <Alert
            severity="error"
            style={{ width: 500 }}
            onClose={() => setUploadError(false)}
          >
            {uploadErrorText}
          </Alert>
        </div>
      )}

      {/* File viewer */}
      <motion.div
        style={{
          position: "absolute",
          top: 80,
          width: "1100px",
          height: "750px",
          backgroundColor: "white",
          border: "solid 1px",
          borderRadius: "5px",
          overflow: "auto",
        }}
        variants={viewerVariants}
        initial="hidden"
        animate={move}
      >
        <div style={{ width: "100%", height: "750px" }}>
          <div
            style={{
              visibility: `${xmlDivVisible}`,
              position: "absolute",
              width: "100%",
            }}
          >
            <iframe
              title="xml viewer"
              ref={myIframe}
              width="100%"
              height="747"
              frameBorder="0"
              src="/CDA_Viewer/index.htm"
            />
          </div>
          <div
            style={{
              visibility: `${imgDivVisible}`,
              position: "absolute",
              width: "100%",
            }}
          >
            <img
              alt="img"
              src={imgLink}
              style={{ maxWidth: "1100px", maxHeight: "750px" }}
            />
          </div>
          <div
            style={{
              visibility: `${pdfDivVisible}`,
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
          >
            <iframe
              title="pdf viewer"
              width="100%"
              height="100%"
              frameBorder="0"
              src={pdfLink}
            />
          </div>
          <div style={{ visibility: `${txtDivVisible}`, position: "absolute" }}>
            <div style={{ margin: 10 }}>{txtText}</div>
          </div>
          <div
            style={{ visibility: `${docDivVisible}`, position: "absolute" }}
          ></div>
          <div style={{ visibility: `${csvDivVisible}`, position: "absolute" }}>
            <div style={{ margin: 10 }}>{csvText}</div>
          </div>
          <div
            style={{ visibility: `${jsonDivVisible}`, position: "absolute" }}
          >
            <div style={{ margin: 10 }}>{jsonText}</div>
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            border: "solid 1px",
          }}
        >
          <IconButton
            aria-label="view"
            onClick={() => {
              setMove("hidden");
            }}
            size="large">
            <HighlightOffIcon />
          </IconButton>
        </div>
      </motion.div>

      <div>
        <MyModal
          openModal={openDataSource}
          setOpenModal={setOpenDataSource}
          width={800}
          close={false}
        >
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Pick a folder to save your file:
            </FormLabel>
            <RadioGroup value={folderValue} onChange={handleChangeFolder}>
              {folderTotal.map((item) => {
                return (
                  <FormControlLabel
                    key={item.name}
                    value={item.name}
                    control={<Radio />}
                    label={item.label}
                  />
                );
              })}
            </RadioGroup>
            <FormLabel component="legend">
              Pick a folder to save your file:
            </FormLabel>
            <RadioGroup
              value={dataSource}
              onChange={(e) => setDataSource(e.target.value)}
            >
              {sources.map((item) => {
                return (
                  <FormControlLabel
                    key={item}
                    value={item}
                    control={<Radio />}
                    label={item}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
          <div className={modalClasses.btn}>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              onClick={() => {
                setOpenDataSource(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => {
                setOpenDataSource(false);
                handleUploadFile("LAST_FOUR");
              }}
            >
              Please Select File
            </Button>
          </div>
          {/* <div className={modalClasses.papermodal}>
            <Typography style={{ marginBottom: 10 }}>
              Enter the source of your file:
            </Typography>
            <TextField
              className={modalClasses.tf}
              fullWidth
              required
              name="sourceofdata"
              id="sourceofdata"
              label="Source of Data"
              variant="outlined"
              value={dataSource}
              onChange={(e) => setDataSource(e.target.value)}
            />
            <div className={modalClasses.btn}>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                onClick={() => {
                  setOpenDataSource(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => {
                  setOpenDataSource(false);
                  handleUploadFile("LAST_FOUR");
                }}
              >
                Please Select File
              </Button>
            </div>
          </div> */}
        </MyModal>
      </div>
    </div>
  );
}
