/*
 * @Author: your name
 * @Date: 2022-02-03 09:50:58
 * @LastEditTime: 2022-03-02 02:43:16
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /cims-dev/packages/common/services/clinical-notes.service.js
 */
import { gql } from "@apollo/client";

export const FIND_CLINICAL_NOTES_BY_ID = gql`
  query findClinicalNotesById($patientId: ID!) {
    findClinicalNotesById(patientId: $patientId) {
      id
      orderControlORC
      placerOrderNumberORC
      dateTimeOfTransactionORC
      orderingProviderORC
      placerOrderNumberOBR
      fillerOrderNumberORB
      priorityOBR
      observationDateTimeOBR
      orderingProviderOBR
      resultsRptStatusChngDateTimeOBR
      resultStatusOBR
      noteName
	    noteInfo
	    dataSource
      patient {
        id
      }
      cliObservationResult {
        id
        setIDOBX
        valueTypeOBX
        observationIdentifierOBX
        unitsOBX
        referencesRangeOBX
        abnormalFlagsOBX
        observResultStatusOBX
        obsValue
      }
    }
  }
`;
