import React, { useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Button, CardContent, Modal } from "@mui/material";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    marginBottom: "10px",
  },
  btn: {
    marginTop: 10,
  },
  cancelBtn: {
    marginRight: 20,
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modalBtns: {
    display: "flex",
    justifyContent: "space-evenly",
  },
}));

export default function ApptCard({ appt = {}, cancelAppt, rescheduleAppt }) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);

  const cancelAlert = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <p id="simple-modal-description">
        Are you sure you want to cancel the appointment?
      </p>
      <div className={classes.modalBtns}>
        <Button variant="outlined" onClick={handleClose}>
          No
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            handleClose();
            cancelAppt(appt.id);
          }}
        >
          Yes
        </Button>
      </div>
    </div>
  );

  const { id, provider, status, startTime, locations, patient } = appt;
  // console.log("appt--->", appt);

  return (
    <Card className={classes.root}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <CardContent>
        <Typography variant="body1" component="p">
          {`Patient name: ${patient.firstName} ${patient.lastName}`}
        </Typography>
        <Typography variant="body1" component="p">
          {`Provider name: ${provider.firstName} ${provider.lastName}`}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {`Time: ${startTime.toString.split("T").toString()}`}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {`Address: ${locations.address}`}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {locations.scheduleappt.name}
        </Typography>
        {status === "cancelled" && (
          <Typography variant="body2" component="p">
            This appointment was cancelled.
          </Typography>
        )}
        {status === "reschedule" && (
          <Typography variant="body2" component="p">
            This appointment was cancelled and rescheduled.
          </Typography>
        )}
        {status === "booked" && (
          <div className={classes.btn}>
            <Button
              className={classes.cancelBtn}
              variant="outlined"
              onClick={() => {
                cancelAlert(id);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                console.log(
                  "originTime--->",
                  `${startTime.toString.split("T")[0]} ${
                    startTime.toString.split("T")[1]
                  }:00`
                );
                rescheduleAppt(
                  id,
                  `${startTime.toString.split("T")[0]} ${
                    startTime.toString.split("T")[1]
                  }:00`,
                  locations.address,
                  locations.id,
                  locations.scheduleappt.id,
                  `${provider.firstName} ${provider.lastName}`,
                  provider.id,
                  patient.id
                );
              }}
            >
              Reschedule
            </Button>
          </div>
        )}
        {/* {cancelError && (
          <Text variant="error">
            Something went wrong to cancel the appointment. Pleae try again
          </Text>
        )} */}
      </CardContent>
    </Card>
  );
}
