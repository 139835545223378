/*
 * @Author: your name
 * @Date: 2022-01-27 10:31:13
 * @LastEditTime: 2022-01-27 13:34:10
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /cims-dev/packages/common/services/shared-medical-record.service.js
 */
import { gql } from "@apollo/client";

export const UPDATE_SHARED_MEDICAL_RECORD = gql`
  mutation updateShareMedicalRecordAgreement(
    $personId: ID!
    $shId: Long
    $choosedId: [Long]
  ) {
    updateShareMedicalRecordAgreement(
      personId: $personId
      shId: $shId
      choosedId: $choosedId
    )
  }
`;

export const GET_ALL_SHARED_PHYSICIANS_BY_ID = gql`
  query getAllSharedMedicalRecordById($patientId: ID!) {
    getAllSharedMedicalRecordById(patientId: $patientId) {
      id
      userId
      patientId
      shareRecordAgreement
      shareAgreeDate
      sharedPersonId
      sharedPersonFirstName
      sharedPersonLastName
      sharedPersonEmail
      person {
        id
      }
    }
  }
`;
