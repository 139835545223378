import { gql } from "@apollo/client";

export const eBinder_GET_SPECIFIC_TEMP_SDC_DATA = gql`
  query getSpecificTempSDCData($formVisitLocationId: Long) {
    getSpecificTempSDCData(formVisitLocationId: $formVisitLocationId) {
      maxSeqNo
      formVisitLocation {
        fileName
        fileStatus
      }
      emptyTempSdcEditVoList {
        tempSdcId
        fieldLabel
        fieldName
        fieldValue
        fieldControlType
        sdtmFieldOptions {
          id
          sequenceNo
          fieldKey
          fieldValue
          studyId
          studyFormId
          standard
        }
        seqNo
      }
      tempSdcEditVoList {
        tempSdcId
        fieldLabel
        fieldName
        fieldValue
        fieldControlType
        sdtmFieldOptions {
          id
          sequenceNo
          fieldKey
          fieldValue
          studyId
          studyFormId
          standard
        }
        seqNo
        studyFormDesign {
          id
          standard
          partNo
          sequenceNo
          fieldLabel
          fieldName
          fieldControlType
          fieldValue
          studyId
          studyFormId
        }
        tempSdc {
          tempSdcId
          fieldName
          fieldValue
          lineSeq
          sdtmFieldName
        }
      }
    }
  }
`;

export const eBinder_GET_SPECIFIC_SDC_DATA = gql`
  query getSpecificSdcData($formVisitLocationId: Long) {
    getSpecificSdcData(formVisitLocationId: $formVisitLocationId) {
      maxSeqNo
      formVisitLocation {
        id
        fileName
        fileStatus
      }
      sdcEditVoList {
        sdcId
        fieldLabel
        fieldName
        fieldValue
        fieldControlType
        sdtmFieldOptions {
          id
          sequenceNo
          fieldKey
          fieldValue
          studyId
          studyFormId
          standard
        }
        seqNo
        studyFormDesign {
          id
          standard
          partNo
          sequenceNo
          fieldLabel
          fieldName
          fieldControlType
          fieldValue
          studyId
          studyFormId
        }
        sdcModel {
          sdcId
          fieldName
          fieldValue
          lineSeq
          sdtmFieldName
        }
        queryList {
          smQuery {
            id
            sdcTableName
            sdcId
            fieldName
            queryType
            oldValue
            newValue
            queryStatus
            createRole
            answerRole
            groupId
            createUserId
            createRemark
            createTime {
              toString
            }
            answerUserId
            answerRemark
            answerTime {
              toString
            }
            closeUserId
            closeRemark
            closeTime {
              toString
            }
            studyId
            siteId
            subjectInfoId
            formVisitLocationId
          }
          userAccess
        }
      }
    }
  }
`;

export const eBinder_BATCH_UPDATE_TEMPSDC = gql`
  mutation updateTempSdcData(
    $formVisitLocationId: Long
    $inputList: [TempSdcFormInput]
    $operatorInfoInput: OperatorInfoInput
  ) {
    updateTempSdcData(
      formVisitLocationId: $formVisitLocationId
      inputList: $inputList
      operatorInfoInput: $operatorInfoInput
    ) {
      success
      result
    }
  }
`;

export const eBinder_TEMPSDC_DATA_COMFIRM = gql`
  mutation tempSdcDataConfirm($formVisitLocationId: Long) {
    tempSdcDataConfirm(formVisitLocationId: $formVisitLocationId) {
      success
      result
    }
  }
`;

export const eBinder_UPDATE_SDC_DATA = gql`
  mutation updateSdcData(
    $formVisitLocationId: Long
    $inputList: [SdcFormInput]
    $operatorInfoInput: OperatorInfoInput
  ) {
    updateSdcData(
      formVisitLocationId: $formVisitLocationId
      inputList: $inputList
      operatorInfoInput: $operatorInfoInput
    ) {
      result
      success
    }
  }
`;

export const eBinder_ADD_NEW_SDV_QUERY = gql`
  mutation addNewSdvQuery(
    $sdvQueryInput: SdvQueryInput
    $operatorInfoInput: OperatorInfoInput
  ) {
    addNewSdvQuery(
      sdvQueryInput: $sdvQueryInput
      operatorInfoInput: $operatorInfoInput
    ) {
      result
      success
    }
  }
`;

export const eBinder_GET_CURRENT_SDC_ALL_SDV_QUERY = gql`
  query getCurrentSdcAllSdvQuery($sdcId: Long, $formVisitLocationId: Long) {
    getCurrentSdcAllSdvQuery(
      sdcId: $sdcId
      formVisitLocationId: $formVisitLocationId
    ) {
      id
      fieldName
      comment
      commentType
      commentStatus
      commentUserId
      commentTime {
        toString
      }
    }
  }
`;

export const eBinder_GET_CURRENT_SDC_LATEST_QUERY = gql`
  query getCurrentSdcLatestQuery($sdcId: Long, $formVisitLocationId: Long) {
    getCurrentSdcLatestQuery(
      sdcId: $sdcId
      formVisitLocationId: $formVisitLocationId
    ) {
      id
      fieldName
      comment
      commentType
      commentStatus
      commentUserId
      commentTime {
        toString
      }
    }
  }
`;

export const eBinder_SM_QUERY_OPERATION = gql`
  mutation smQueryOperation(
    $smQueryInput: SmQueryInput
    $operatorInfoInput: OperatorInfoInput
  ) {
    smQueryOperation(
      smQueryInput: $smQueryInput
      operatorInfoInput: $operatorInfoInput
    ) {
      result
      success
    }
  }
`;

export const eBinder_GET_CURRENT_SDC_ALL_QUERY = gql`
  query getCurrentSdcAllSmQuery($sdcId: Long, $formVisitLocationId: Long) {
    getCurrentSdcAllSmQuery(
      sdcId: $sdcId
      formVisitLocationId: $formVisitLocationId
    ) {
      id
      sdcTableName
      sdcId
      fieldName
      queryType
      oldValue
      newValue
      queryStatus
      createRole
      answerRole
      groupId
      createUserId
      createTime {
        toString
      }
      createRemark
      answerUserId
      answerTime {
        toString
      }
      answerRemark
      closeUserId
      closeTime {
        toString
      }
      closeRemark
      studyId
      siteId
      subjectInfoId
      formVisitLocationId
    }
  }
`;

export const eBinder_UPDATE_FVL_FEEZE_STATUS = gql`
  mutation updateFvlFreezeStatus(
    $formVisitLocationId: Long
    $operatorInfoInput: OperatorInfoInput
  ) {
    updateFvlFreezeStatus(
      formVisitLocationId: $formVisitLocationId
      operatorInfoInput: $operatorInfoInput
    ) {
      success
      result
    }
  }
`;

export const eBinder_UPDATE_FVL_LOCKED_STATUS = gql`
  mutation updateFvlLockedStatus(
    $formVisitLocationId: Long
    $operatorInfoInput: OperatorInfoInput
  ) {
    updateFvlLockedStatus(
      formVisitLocationId: $formVisitLocationId
      operatorInfoInput: $operatorInfoInput
    ) {
      success
      result
    }
  }
`;

export const eBinder_UPDATE_FVL_SIGNED_STATUS = gql`
  mutation updateFvlSignedStatus(
    $formVisitLocationId: Long
    $operatorInfoInput: OperatorInfoInput
  ) {
    updateFvlSignedStatus(
      formVisitLocationId: $formVisitLocationId
      operatorInfoInput: $operatorInfoInput
    ) {
      success
      result
    }
  }
`;
