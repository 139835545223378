import { gql } from "@apollo/client";

export const GET_ALL_APPTS = gql`
  query getApptPatientByIdDesc($patientId: ID!) {
    allApptByPtIdDesc(patientId: $patientId) {
      id
      startTime {
        toString
      }
      provider {
        id
        lastName
        firstName
      }
      patient {
        id
        lastName
        firstName
      }
      locations {
        id
        address
        scheduleappt {
          id
          name
        }
      }
      status
    }
  }
`;

export const GET_ALL_PHYSICIAN_APPTS = gql`
  query allApptByProviderId($providerId: ID!) {
    allApptByProviderId(providerId: $providerId) {
      id
      startTime {
        toString
      }
      provider {
        id
        lastName
        firstName
      }
      locations {
        id
        address
        scheduleappt {
          id
          name
        }
      }
      patient {
        id
        lastName
        firstName
      }
      status
    }
  }
`;
