import React, { useState, useContext } from "react";
import {
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableBody,
  TableHead,
  Paper,
  TablePagination,
  Typography,
  Container,
  Fab,
  Modal,
  Backdrop,
  Fade,
  Button,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { useHistory } from "react-router-dom";
import { blue } from '@mui/material/colors';

const useModalStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true"
        ? "rgba(0,26,72, 0.3)"
        : "rgba(126,211,205,0.5)",
    border: "1px solid #666666",
    width: 1000,
    maxHeight: 700,
    boxShadow: theme.shadows[4],
    padding: theme.spacing(2, 4, 3),
    overflow: "auto",
    marginBottom: 10,
  },
  tf: {
    marginBottom: 15,
  },
  btn: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  margin: {
    marginBottom: 10,
  },
}));

function createData(firstname, lastname, dob, sex, id) {
  return {
    firstname,
    lastname,
    dob,
    sex,
    id,
  };
}

export default function PatientMatcherResult() {
  let rowss = [];
  const history = useHistory();
  const modalClasses = useModalStyles();
  const studydata = history.location.state.studydata;
  console.log("studydata:", studydata);
  const searchTitle = history.location.state.searchTitle;
  const searchProtocol = history.location.state.searchProtocol;
  const searchSponsor = history.location.state.searchSponsor;
  const searchInclusion = history.location.state.searchInclusion;
  const searchExclusion = history.location.state.searchExclusion;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
      // backgroundColor: "green",
      // color: theme.palette.common.white,
      color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);
  // console.log("gettitle", history.location.state.searchTitle);
  {
    studydata &&
      studydata.map((item) => {
        // console.log("organization: name  " + item.name);
        // console.log("organization: type  " + item.type);
        // console.log(" organization, locations  " + item.locations);
        rowss.push(
          createData(
            item.person.firstName,
            item.person.lastName,
            item.person.dob,
            item.person.sex,
            item.id
          )
        );
      });
  }
  console.log(rowss);
  const handleRowClick = (event, row) => {
    history.push({
      pathname: `/clinicalstudies/patientmatcherstudies`,
      state: {
        id: row.id,
        firstUserName: row.firstName,
        lastUserName: row.lastname,
      },
    });
  };
  const useRowStyles = makeStyles({
    root: {
      "& > *": {
        borderBottom: "unset",
      },
    },
    addBtn: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: 10,
      // height: "40px",
    },
    addBtnIcon: {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
      color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
      "&:hover": {
        backgroundColor:
          localStorage.getItem("isDarkMode") === "true" ? "#BDC0BA" : blue[700],
        color: "#fff",
      },
    },
    btn: {
      marginTop: 20,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  });
  const classes = useRowStyles();
  return (
    <Container>
      <div className={modalClasses.margin}>
        <Button
          variant="outlined"
          onClick={() => history.push(`/clinicalstudies/patientmatcher`)}
          size="large"
          color="primary"
        >
          Return
        </Button>
      </div>
      <Paper variant="outlined" className={modalClasses.paper}>
        <Typography variant="body2" color="primary">
          Applied Filter:
        </Typography>
        <FormGroup row>
          <FormControlLabel
            disabled
            control={<Checkbox checked name="checkedTitle" />}
            label={searchTitle}
          />
          <FormControlLabel
            disabled
            control={<Checkbox checked name="checkedSponsor" />}
            label={searchSponsor}
          />
          <FormControlLabel
            disabled
            control={<Checkbox checked name="checkedProtocol" />}
            label={searchProtocol}
          />
          <FormControlLabel
            disabled
            control={<Checkbox checked name="checkedInclu" />}
            label={searchInclusion}
          />
          <FormControlLabel
            disabled
            control={<Checkbox checked name="checkedExclu" />}
            label={searchExclusion}
          />
        </FormGroup>
        <Typography variant="body2" color="error">
          (Click row to view studies associated with the participant)
        </Typography>
      </Paper>

      <Paper className={classes.paper}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Participant's Name </StyledTableCell>
                <StyledTableCell>Data of Birth</StyledTableCell>
                <StyledTableCell align="left">Gender</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowss
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <StyledTableRow
                      hover
                      tabIndex={-1}
                      onClick={(event) => handleRowClick(event, row, row.id)}
                    >
                      <StyledTableCell align="left" style={{ width: "15%" }}>
                        {row.firstname} {row.lastname}
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "15%" }}>
                        {row.dob}
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "15%" }}>
                        {row.sex}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.pagination}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rowss.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
    </Container>
  );
}
