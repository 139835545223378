import React, { useState, useContext, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from "react-router-dom";
import { AuthenticationContext } from "../../../services/authentication/authentication.context";
import { useQuery, useMutation } from "@apollo/client";
import Alert from '@mui/material/Alert';
import { serverUrl, UPLOAD_ATTACHMENT } from "@cims/common";
import axios from "axios";

const useStyles = makeStyles({
  field: {
    marginTop: 20,
    marginBottom: 20,
    display: "block",
  },
});

export const Home = ({ fileCallBack }) => {
  const classes = useStyles();
  const history = useHistory();
  const [formData, setFormData] = useState(null);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const { user, contextHeaders } = useContext(AuthenticationContext);
  const [uploadedFile, setUploadedFile] = useState("");
  const [uploadedFileUrl, setUploadedFileUrl] = useState("");
  const [fileName, setFileName] = useState(null);

  const [uploadFile, { error: uploadError }] = useMutation(UPLOAD_ATTACHMENT, {
    variables: {
      attachment: selectedFile,
      messageId: 0,
    },
    context: contextHeaders,
  });

  useEffect(() => {
    if (user === null) history.push("/login");
  });

  const handleSubmit = (e) => {
    // e.preventDefault();
  };

  const token = contextHeaders.headers.authorization;
  const siteUrl = serverUrl;
  //const siteUrl = "http://localhost:8080";

  const handleFileUpload = () => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute(
      "accept",
      ".xlsx,.xls,image/*,.doc,.docx,.ppt,.pptx,.txt,.pdf"
    );
    input.click();

    input.onchange = async () => {
      var file: any = input.files[0];
      var formData = new FormData();

      // formData.append('image', file);
      formData.append("file", file);
      // console.log("file", file);
      var fileName = file.name;

      setFormData(file);
      setFileName(fileName);

      // console.log("formData", file);
      // console.log("fileName", fileName);

      const res = UploadFiles(formData, fileName);
    };
  };

  const UploadFiles = (formData, filename) => {
    console.log("user.person.id", user.person.id);

    axios
      .post(
        siteUrl + "/api/uploadfile/" + user.person.id + "/" + filename + "/1",
        formData,
        {
          headers: {
            "Content-Type": "application/form-data",
            Authorization: token,
          },
        }
      )
      .then((response) => {
        // console.log("response", response.data.fileUrl);
        setUploadedFile(filename);
        setUploadedFileUrl(response.data.fileUrl);
        setAlert(true);
        setAlertSeverity("success");
        setAlertContent(
          "The following file has been encrypted and uploaded successfully!"
        );
        console.log("uploadFile, filename: ", filename);
        fileCallBack(filename);
      })
      .catch((error) => {
        console.log("uploaded", false);
        setAlert(true);
        setAlertSeverity("error");
        setAlertContent("" + error);
      });
  };

  return (
    <Container size="sm">
      <Typography
        variant="h6"
        color="textSecondary"
        component="h2"
        gutterBottom
      >
        Download Encryption Tool
      </Typography>

      <div>
        {alert ? <Alert severity={alertSeverity}>{alertContent}</Alert> : <></>}
      </div>

      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <center>
          <div>
            {/* <input type="file" name="file" onChange={changeHandler} /> */}
            {formData ? (
              <div>
                <p>File Nme: {formData.name}</p>
                <p>File Type: {formData.type}</p>
                <p>File Size: {formData.size} bytes</p>
              </div>
            ) : (
              <p></p>
            )}
            {formData && formData.lastModifiedDate ? (
              <div>
                <p>
                  Last Modified Date:{" "}
                  {formData.lastModifiedDate.toLocaleDateString()}
                </p>
              </div>
            ) : (
              <p></p>
            )}
          </div>
          <div>
            <Button
              className={classes.iconButton}
              aria-label="Attach"
              color="primary"
              onClick={handleFileUpload}
              color="primary"
              variant="contained"
            >
              Download Encryption Tool
            </Button>
          </div>
        </center>
      </form>
    </Container>
  );
};

export default Home;
