import React, { useState, useContext } from "react";

import { Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { PHYSICIANS_BY_PATIENT_ID } from "@cims/common/";
import { AuthenticationContext } from "src/services/authentication/authentication.context";
import { GlobalContext } from "src/services/global/global.context";
import LoadingIndicator from "src/components/LoadingIndicator";
import ErrorInfo from "src/components/ErrorInfo";
import TableComponent from "src/components/TableComponent";

export default function SharedPhysicians() {
  const { user } = useContext(AuthenticationContext);
  const { pageSize } = useContext(GlobalContext);

  const [sharedPhysiciansData, setSharedPhysiciansData] = useState(null);
  const [size, setSize] = useState(pageSize);
  const [page, setPage] = useState(0);

  const { loading: sharedPhysiciansLoading, error: sharedPhysiciansError } =
    useQuery(PHYSICIANS_BY_PATIENT_ID, {
      variables: { patient_id: Number(user.person.patient.id) },
      onCompleted: (data) => {
        const results = data.physiciansByPatientId.map((item) => {
          const obj = {
            id: item.id,
            firstName: item.firstName,
            lastName: item.lastName,
            title: item.title,
            role: item.roles[0],
            education: item.education,
            experience: item.experience,
            phone: item.phone,
          };
          return obj;
        });
        setSharedPhysiciansData(results);
      },
    });

  const headCells = [
    {
      id: "id",
      numeric: false,
      label: "ID",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "firstName",
      numeric: false,
      label: "First Name",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "lastName",
      numeric: false,
      label: "Last Name",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "title",
      numeric: false,
      label: "Title",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "role",
      numeric: false,
      label: "Role",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "education",
      numeric: false,
      label: "Education",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "experience",
      numeric: false,
      label: "Experience",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "phone",
      numeric: false,
      label: "Phone",
      headerAlign: "left",
      align: "left",
    },
  ];

  return (
    <div>
      <Typography variant="body1" gutterBottom component="div">
        <h3>Share Physicians</h3>
      </Typography>
      {sharedPhysiciansLoading && <LoadingIndicator />}
      {sharedPhysiciansError && <ErrorInfo />}
      {sharedPhysiciansData && (
        <TableComponent
          headCells={headCells}
          rows={sharedPhysiciansData}
          rowsPerPage={size}
          setRowsPerPage={setSize}
          page={page}
          setPage={setPage}
          totalSize={sharedPhysiciansData.length}
        />
      )}
    </div>
  );
}
