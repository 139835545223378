import React, { useState, createContext } from "react";
import { blueGrey } from "@mui/material/colors";

export const ThemeContext = createContext();

export const ThemeContextProvider = ({ children }) => {
  const isDark = localStorage.getItem("isDarkMode") === "true" ? true : false;
  const [darkMode, setDarkMode] = useState(isDark);
  const [buttonColor, setButtonColor] = useState(isDark ? "error" : "primary");

  const changeTheme = (pickedTheme) => {
    setDarkMode(pickedTheme);
    setButtonColor(pickedTheme ? "error" : "primary");
  };

  return (
    <ThemeContext.Provider
      value={{
        darkMode,
        buttonColor,
        changeTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
