import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import { PROVIDER_BY_ADDRESS } from "@cims/common";
import { Link, useHistory, useParams } from "react-router-dom";
import { AuthenticationContext } from "../../../services/authentication/authentication.context";
import { AppointmentContext } from "../../../services/appointment/appointment.context";
import {
  Card,
  CardHeader,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import LoadingIndicator from "../../../components/LoadingIndicator";
import ErrorInfo from "../../../components/ErrorInfo";

export default function ScheduleAppointmentProvider() {
  const { locationId } = useParams();
  const history = useHistory();
  const { contextHeaders, curPickedPatient } = useContext(
    AuthenticationContext
  );
  const { yourAppointment, pickingAppointment } =
    useContext(AppointmentContext);

  const {
    data: providers,
    loading: providersLoading,
    error: providersError,
  } = useQuery(PROVIDER_BY_ADDRESS, {
    variables: { address: yourAppointment.location },
    context: contextHeaders,
  });
  //   console.log("locations--->", locations);
  return (
    <Container size="sm">
      <Typography
        variant="body1"
        component="p"
        gutterBottom
        style={{ marginBottom: 20 }}
      >
        {`Making an appointment for ${curPickedPatient.firstName} ${curPickedPatient.lastName} | Please choose one physician`}
      </Typography>

      {providersLoading && <LoadingIndicator />}
      {providersError && <ErrorInfo />}

      <Grid container spacing={3}>
        {providers &&
          providers.providerByAddress.map((item) => {
            return (
              <Grid key={item.id} item xs={12} sm={6} md={4}>
                <Link
                  to={`/patients/${curPickedPatient.id}/visits/scheduling/${yourAppointment.subjectId}/${locationId}/${item.id}`}
                  onClick={() => {
                    pickingAppointment({
                      provider: `${item.firstName} ${item.lastName}`,
                      providerId: item.id,
                    });
                  }}
                >
                  <Card>
                    <CardHeader
                      titleTypographyProps={{ variant: "body1" }}
                      //   subheaderTypographyProps={{ variant: "body2" }}
                      title={`${item.firstName} ${item.lastName}`}
                      //   subheader={`${location.address}`}
                    />
                  </Card>
                </Link>
              </Grid>
            );
          })}
      </Grid>
    </Container>
  );
}
