import React, { useEffect, useContext } from "react";
import { ZoomMtg } from "@zoomus/websdk";
import { useQuery, useMutation } from "@apollo/client";
import {
  ALL_ZOOM_MEETINGS,
  serverUrl,
  UPDATE_ZOOM_MEETING,
} from "@cims/common";
import { AuthenticationContext } from "../../../services/authentication/authentication.context";
import { AppointmentContext } from "../../../services/appointment/appointment.context";
import axios from "axios";

const crypto = require("crypto"); // crypto comes with Node.js
// const siteUrl = "http://localhost:4000";
const siteUrl = `${serverUrl}:3000`;

function generateSignature(apiKey, apiSecret, meetingNumber, role) {
  axios
    .post(siteUrl, { meetingNumber: meetingNumber, role: role }, {})
    .then((response) => {
      console.log("signature10", response);
    });
}

function generateSignature3(apiKey, apiSecret, meetingNumber, role) {
  ZoomMtg.generateSignature({
    meetingNumber: meetingNumber,
    apiKey: apiKey,
    apiSecret: apiSecret,
    role: role,
    success(res) {
      return res.result;
    },
  });
}

function generateSignature1(apiKey, apiSecret, meetingNumber, role) {
  // Prevent time sync issue between client signature generation and zoom
  const timestamp = new Date().getTime() - 30000;
  console.log("timestamp", timestamp);
  const msg = Buffer.from(apiKey + meetingNumber + timestamp + role).toString(
    "base64"
  );
  console.log("msg", msg);
  const hash = crypto
    .createHmac("sha256", apiSecret)
    .update(msg)
    .digest("base64");
  console.log("hash", hash);
  const signature = Buffer.from(
    `${apiKey}.${meetingNumber}.${timestamp}.${role}.${hash}`
  ).toString("base64");
  console.log("signature", signature);

  return signature;
}

function generateSignature2(apiKey, apiSecret, meetingNumber, role) {
  // Prevent time sync issue between client signature generation and zoom
  return new Promise((res, rej) => {
    const timestamp = new Date().getTime() - 30000;
    const msg = Buffer.from(apiKey + meetingNumber + timestamp + role).toString(
      "base64"
    );
    const hash = crypto
      .createHmac("sha256", apiSecret)
      .update(msg)
      .digest("base64");
    const signature = Buffer.from(
      `${apiKey}.${meetingNumber}.${timestamp}.${role}.${hash}`
    ).toString("base64");

    res(signature);
  });
}

// var apiKey = "AOTB-4bHTxqrK66eEj-YnQ";
// var apiSecret = "phdxKPDzrI2Uv32EqC5hdrrSEdAvowVysnNt";
var apiKey = "yxHhk5WESmGIrJHH0A03PQ";
var apiSecret = "JJ19YSrRLNn4hkT4kzdBrTGIv3WfXWEXpTOB";

var meetingNumber = 96948035576; // #5 Passcode 546046
var role = 0;
var leaveUrl = "http://localhost:3000";
var userName = "Tester123";
var userEmail = "";
var passWord = "R0c1WXUybzVhZmEwZXVBcUx2bG9WZz09";

var signature = "";
generateSignature2(apiKey, apiSecret, meetingNumber, role).then((res) => {
  signature = res;
});

const getMeeting = (id, objArr) => {
  let meeting = {};

  for (let i = 0, n = objArr.length; i < n; i++) {
    if (
      objArr[i].callerId === "0" &&
      objArr[i].calleeId === "0" &&
      objArr[i].status === "IDLE"
    ) {
      meeting.zoomId = objArr[i].zoomId;
      meeting.url = objArr[i].url;
      meeting.starter = true;
    }
    if (objArr[i].callerId === id || objArr[i].calleeId === id) {
      meeting.zoomId = objArr[i].zoomId;
      meeting.url = objArr[i].url;
      if (objArr[i].calleeId === id) meeting.starter = false;
      else meeting.starter = true;
      break;
    }
  }

  return meeting;
};

const extractDataFromJoinLink = (link) => {
  console.log("link----->", link);
  const links = link.split("?pwd=");
  const password = links[1];
  const meetingNumber = links[0].split("/j/")[1];
  console.log("meetingNumber", meetingNumber);

  return {
    password,
    meetingNumber,
  };
};

export default function Zoom() {
  const { user, contextHeaders, curPickedPatient } = useContext(
    AuthenticationContext
  );
  const {
    data: meetingData,
    loading: meetingLoading,
    error: meetingError,
  } = useQuery(ALL_ZOOM_MEETINGS, {
    context: contextHeaders,
  });

  const [updateZoomMeeting, { data: updateMeetingData, error: updateError }] =
    useMutation(UPDATE_ZOOM_MEETING, {
      context: contextHeaders,
      refetchQueries: [
        {
          query: ALL_ZOOM_MEETINGS,
          context: contextHeaders,
        },
      ],
    });

  let meeting = {},
    meetingObj = {};

  console.log("meetingData--->", meetingData);
  if (meetingData !== undefined) {
    meeting = getMeeting(user.person.id, meetingData.allZoomMeetings);
    // console.log("meeting--->", meeting);
    meetingObj = extractDataFromJoinLink(meeting.url);
    meetingNumber = Number(meetingObj.meetingNumber);
    passWord = meetingObj.password;
  }
  console.log("meetingObj--->", meetingObj);
  console.log("meetingNumber--->", meetingNumber);
  console.log("passWord--->", passWord);

  useEffect(() => {
    showZoomDiv();

    // ZoomMtg.setZoomJSLib("node_modules/@zoomus/websdk/dist/lib", "/av");
    ZoomMtg.setZoomJSLib("https://source.zoom.us/1.9.9/lib", "/av");

    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareJssdk();
    initMeeting();
  }, []);

  const showZoomDiv = () => {
    document.getElementById("zmmtg-root").style.display = "block";
  };

  var meetingConfig = {
    signatureEndpoint: "http://localhost:3000",
    apiKey: apiKey,
    meetingNumber: meetingNumber,
    userName: userName,
    passWord: passWord,
    leaveUrl: "http://localhost:3000/patients",
    userEmail: "",
    lang: "",
    signature: "",
    passcode: 719482,
    role: 0, // 1 for host; 0 for attendee
  };

  console.log(
    "checkSystemRequirements",
    JSON.stringify(ZoomMtg.checkSystemRequirements())
  );

  const initMeeting = () => {
    ZoomMtg.init({
      leaveUrl: meetingConfig.leaveUrl,
      // webEndpoint: meetingConfig.webEndpoint,
      isSupportAV: true, //optional,
      isSupportChat: true, //optional,
      isSupportQA: false, //optional,
      isSupportCC: false, //optional,
      screenShare: true, //optional,
      sharingMode: "both",
      disableInvite: true, //optional
      disableRecord: false, //optional
      audioPanelAlwaysOpen: true,
      success: function () {
        console.log("meetingConfig", meetingConfig);

        // signature = generateSignature(meetingConfig.apiKey, meetingConfig.apiSecret, meetingConfig.meetingNumber, meetingConfig.role);
        // console.log("signature3", signature);

        const value = localStorage.getItem("auth_token");
        if (value !== null) {
          var token = `Bearer ${value}`;
          console.log("token2", token);

          axios
            .post(siteUrl, { meetingNumber: meetingNumber, role: role }, {})
            .then((response) => {
              console.log("signature10", response);
              meetingConfig.signature = response.data.signature;
              // .post( siteUrl + "/api/signature/" + meetingNumber + "/" + role + "/", {
              // ({
              //   url: "serverUrl/api/signature/93441117633/0/",
              //   method: 'POST',
              //   headers: {
              //   "Authorization": token,
              //   }
              // })
              // .then((response) => {
              //   console.log("signature11", response);
              //   meetingConfig.signature = response.data;

              // $.i18n.reload(meetingConfig.lang);
              ZoomMtg.join({
                meetingNumber: meetingConfig.meetingNumber,
                userName: meetingConfig.userName,
                signature: meetingConfig.signature,
                apiKey: meetingConfig.apiKey,
                userEmail: meetingConfig.userEmail,
                passWord: meetingConfig.passWord,
                success: function (res) {
                  console.log("join meeting success");
                  console.log("get attendeelist");
                  ZoomMtg.getAttendeeslist({});
                  ZoomMtg.showPureSharingContent({
                    show: true,
                  });
                  ZoomMtg.getCurrentUser({
                    success: function (res) {
                      console.log(
                        "success getCurrentUser",
                        res.result.currentUser
                      );
                    },
                  });
                  if (meeting.starter) {
                    updateZoomMeeting({
                      variables: {
                        zoomId: Number(meeting.zoomId),
                        callerId: Number(user.person.id),
                        calleeId: Number(curPickedPatient.id),
                      },
                    });
                  } else {
                    updateZoomMeeting({
                      variables: {
                        zoomId: Number(meeting.zoomId),
                        callerId: Number(curPickedPatient.id),
                        calleeId: Number(user.person.id),
                      },
                    });
                  }
                },
                error: function (res) {
                  console.error(res);
                },
              });
            });
        }
      },
      error: function (res) {
        console.error(res);
      },
    });

    ZoomMtg.inMeetingServiceListener("onUserJoin", function (data) {
      console.log("inMeetingServiceListener onUserJoin", "*", data);
      // parent.postMessage({source: "FROM_ZOOM_CLIENT", data}, "*");
    });

    ZoomMtg.inMeetingServiceListener("onUserLeave", function (data) {
      console.log("inMeetingServiceListener onUserLeave", "*", data);
      // parent.postMessage({source: "FROM_ZOOM_CLIENT", data}, "*");
    });

    ZoomMtg.inMeetingServiceListener("onUserIsInWaitingRoom", function (data) {
      console.log("inMeetingServiceListener onUserIsInWaitingRoom", data);
      // parent.postMessage({source: "FROM_ZOOM_CLIENT", data}, '*');
    });

    ZoomMtg.inMeetingServiceListener("onMeetingStatus", function (data) {
      console.log("inMeetingServiceListener onMeetingStatus", data);
      // parent.postMessage({source: "FROM_ZOOM_CLIENT", data}, '*');
    });
  };

  return <div>Zoom meeting...</div>;
}
