import React, { useContext, useState } from "react";
import {
  Container,
  Grid,
  Button,
  OutlinedInput,
  InputLabel,
  FormControl,
  InputAdornment,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import Avatar from "@mui/material/Avatar";
import { AuthenticationContext } from "../../../services/authentication/authentication.context";
import PhoneEnabledOutlinedIcon from "@mui/icons-material/PhoneEnabledOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import HomeIcon from "@mui/icons-material/Home";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import PublicIcon from "@mui/icons-material/Public";
import MapIcon from "@mui/icons-material/Map";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import AddToHomeScreenIcon from "@mui/icons-material/AddToHomeScreen";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import WcIcon from "@mui/icons-material/Wc";
import CakeIcon from "@mui/icons-material/Cake";
import TitleIcon from "@mui/icons-material/Title";
import TimelineIcon from "@mui/icons-material/Timeline";
import SchoolIcon from "@mui/icons-material/School";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import { UPDATE_CONTACTINFO2, UPDATE_PERSON } from "@cims/common";

import { useMutation } from "@apollo/client";
import { blue } from "@mui/material/colors";

const useStyles = makeStyles((theme) => ({
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  AvatarandButton: {
    flexDirection: "row",
  },

  addBtnIcon: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
    color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
    "&:hover": {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#BDC0BA" : blue[700],
      color: "#fff",
    },
  },
  margin: {
    margin: theme.spacing(2),
  },

  smallmargin: {
    margin: theme.spacing(2),
  },

  mediummargin: {
    margin: theme.spacing(2),
  },

  inputlabel: {
    top: -15,
    left: -15,
    fontSize: 20,
  },

  editIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 20,
  },

  saveIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 20,
  },

  cancelIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 20,
    marginRight: 20,
  },

  large: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    position: "absolute",
    left: "50%",
  },
}));

export default function PersonalInfo() {
  const { user, changeSettings } = useContext(AuthenticationContext);

  // console.log("user.person.contactinfo[0]", user.person.contactinfo[0]);

  const [myCellTel, setMyCellTel] = useState(
    user?.person?.contactinfo[0]?.cellPhone || ""
    // ? user.person.contactinfo[0].cellPhone
    // : ""
  );
  const [myHomeTel, setMyHomeTel] = useState(
    user?.person?.contactinfo[0]?.homePhone || ""
    // ? user.person.contactinfo[0].homePhone
    // : ""
  );

  const [myWorkTel, setMyWorkTel] = useState(
    user?.person?.contactinfo[0]?.workPhone || ""
    // ? user.person.contactinfo[0].workPhone
    // : ""
  );

  const [myAddress, setMyAddress] = useState(
    user?.person?.contactinfo[0]?.streetAddress || ""
    // ? user.person.contactinfo[0].streetAddress
    // : ""
  );

  const [myCity, setMyCity] = useState(
    user?.person?.contactinfo[0]?.city || ""
    // ? user.person.contactinfo[0].city
    // : ""
  );

  const [myZipCode, setMyZipCode] = useState(
    user?.person?.contactinfo[0]?.zip || ""
    // ? user?.person?.contactinfo[0]?.zip
    // : ""
  );

  const [myCountry, setMyCountry] = useState(
    user?.person?.contactinfo[0]?.country || ""
    // ? user?.person?.contactinfo[0]?.country
    // : ""
  );

  const [myState, setMyState] = useState(
    user?.person?.contactinfo[0]?.state || ""
    // ? user?.person?.contactinfo[0]?.state
    // : ""
  );

  const [mySSN, setMySSN] = useState(user?.person?.ssn || "");
  const [myDOB, setMyDOB] = useState(user?.person?.dob || "");

  const [myGender, setMyGender] = useState(user?.person?.sex || "");

  const [myID, setMyID] = useState(
    user?.person?.contactinfo[0]?.id || "" // ? user?.person?.contactinfo[0]?.id : ""
  );

  const [myExperience, setMyExperience] = useState(
    user?.person?.experience || "" // ? user.person.experience : ""
  );

  const [myEmployment, setMyEmployment] = useState(
    user?.person?.employmentDate?.iso || "" //? user.person.employmentDate.iso : ""
  );

  const [myEducation, setMyEducation] = useState(
    user?.person?.education || "" //? user.person.education : ""
  );

  const [myPhone, setMyPhone] = useState(
    user?.person?.phone || "" //? user.person.phone : ""
  );

  const [myTitle, setMyTitle] = useState(
    user?.person?.title || "" //? user.person.title : ""
  );

  const [myPreferContact, setMyPreferContact] = useState(
    user?.person?.contactinfo[0]?.preferContact
      ? user?.person?.contactinfo[0]?.preferContact
      : ""
  );

  const [editable, setEditable] = useState(false);

  // console.log(user.person.employmentDate.toString());

  const [updateContactInfo, { data1 }] = useMutation(UPDATE_CONTACTINFO2, {
    variables: {
      contactInfoInput: {
        id: `${myID}`,
        country: `${myCountry}`,
        streetAddress: `${myAddress}`,
        city: `${myCity}`,
        state: `${myState}`,
        zip: `${myZipCode}`,
        homePhone: `${myHomeTel}`,
        cellPhone: `${myCellTel}`,
        workPhone: `${myWorkTel}`,
        email: `${user.person.email}`,
        preferContact: `${myPreferContact}`,
      },
      personId: `${user.person.id}`,
    },
  });

  const [updatePersonInfo, { data2 }] = useMutation(UPDATE_PERSON, {
    variables: {
      input: {
        firstName: `${user.person.firstName}`,
        lastName: `${user.person.lastName}`,
        employmentDate: `${myEmployment}`,
        phone: `${myPhone}`,
        education: `${myEducation}`,
        experience: `${myExperience}`,
        title: `${myTitle}`,
        ssn: `${mySSN}`,
        dob: `${myDOB}`,
        sex: `${myGender}`,
      },
    },
  });

  const handleSubmit = () => {
    user.person.employmentDate = myEmployment;
    user.person.phone = myPhone;
    user.person.education = myEducation;
    user.person.experience = myExperience;
    user.person.title = myTitle;
    user.person.ssn = mySSN;
    user.person.dob = myDOB;
    user.person.sex = myGender;
    user.person.contactinfo[0].cellPhone = myCellTel;
    user.person.contactinfo[0].homePhone = myHomeTel;
    user.person.contactinfo[0].workPhone = myWorkTel;
    user.person.contactinfo[0].streetAddress = myAddress;
    user.person.contactinfo[0].city = myCity;
    user.person.contactinfo[0].zip = myZipCode;
    user.person.contactinfo[0].country = myCountry;
    user.person.contactinfo[0].state = myState;
    user.person.contactinfo[0].preferContact = myPreferContact;
    changeSettings(user);
  };
  /*
  const [values, setValues] = React.useState({
    id: `${user.person.id}`,
    country: `${user.person.contactinfo[0].country}`,
    streetAddress: `${user.person.contactinfo[0].streetAddress}`,
    city: `${user.person.contactinfo[0].city}`,
    state: `${user.person.contactinfo[0].state}`,
    zip: `${user.person.contactinfo[0].zip}`,
    homePhone: `${user.person.contactinfo[0].homePhone}`,
    cellPhone: `${user.person.contactinfo[0].cellPhone}`,
    workPhone: `${user.person.contactinfo[0].workPhone}`,
    email: `${user.person.email}`,
  });

  const handleChange = (prop) => (event) => {
      setValues({ ...values, [prop]: event.target.value });
  };*/
  const myStyles = useStyles();
  const userName = user.person.firstName + " " + user.person.lastName;
  // console.log("user.person", user.person);

  return (
    <Container>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "center",
          padding: 20,
        }}
      >
        <div className={myStyles.addBtn}>
          <Avatar
            alt="Remy Sharp"
            src={user.person.avatar}
            style={{ height: 140, width: 140 }}
          />
        </div>
      </div>

      <Grid container spacing={2} style={{ marginBottom: "20px" }}>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">
              User Name
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={userName}
              readOnly
              startAdornment={
                <InputAdornment position="start">
                  <AccountCircleIcon />
                </InputAdornment>
              }
              labelWidth={80}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">Email</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={user.person.email}
              readOnly
              startAdornment={
                <InputAdornment position="start">
                  <AlternateEmailIcon />
                </InputAdornment>
              }
              labelWidth={40}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">Address</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={myAddress}
              disabled={!editable}
              onChange={(e) => setMyAddress(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <HomeIcon />
                </InputAdornment>
              }
              labelWidth={60}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ marginBottom: "20px" }}>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">City</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={myCity}
              disabled={!editable}
              onChange={(e) => setMyCity(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <LocationCityIcon />
                </InputAdornment>
              }
              labelWidth={30}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">State</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={myState}
              disabled={!editable}
              onChange={(e) => setMyState(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <MapIcon />
                </InputAdornment>
              }
              labelWidth={35}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">
              Zip Code
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={myZipCode}
              disabled={!editable}
              onChange={(e) => setMyZipCode(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <LabelImportantIcon />
                </InputAdornment>
              }
              labelWidth={70}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">Country</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={myCountry}
              disabled={!editable}
              onChange={(e) => setMyCountry(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <PublicIcon />
                </InputAdornment>
              }
              labelWidth={60}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ marginBottom: "20px" }}>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">
              Home Phone
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={myHomeTel}
              disabled={!editable}
              onChange={(e) => setMyHomeTel(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <AddToHomeScreenIcon />
                </InputAdornment>
              }
              labelWidth={90}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">
              Work Phone
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={myWorkTel}
              disabled={!editable}
              onChange={(e) => setMyWorkTel(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <WorkOutlineIcon />
                </InputAdornment>
              }
              labelWidth={80}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">
              Cell Phone
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={myCellTel}
              disabled={!editable}
              onChange={(e) => setMyCellTel(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <PhoneEnabledOutlinedIcon />
                </InputAdornment>
              }
              labelWidth={70}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ marginBottom: "20px" }}>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">SSN</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={mySSN}
              disabled={!editable}
              onChange={(e) => setMySSN(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <AccountBalanceIcon />
                </InputAdornment>
              }
              labelWidth={30}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">Gender</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={myGender}
              disabled={!editable}
              onChange={(e) => setMyGender(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <WcIcon />
                </InputAdornment>
              }
              labelWidth={50}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">
              Date of Birth
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={myDOB}
              disabled={!editable}
              onChange={(e) => setMyDOB(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <CakeIcon />
                </InputAdornment>
              }
              labelWidth={90}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">
              Preferred Contact
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={myPreferContact}
              disabled={!editable}
              onChange={(e) => setMyPreferContact(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <ContactPhoneIcon />
                </InputAdornment>
              }
              labelWidth={130}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">Title</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={myTitle}
              disabled={!editable}
              onChange={(e) => setMyTitle(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <TitleIcon />
                </InputAdornment>
              }
              labelWidth={35}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">
              Experience
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={myExperience}
              disabled={!editable}
              onChange={(e) => setMyExperience(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <TimelineIcon />
                </InputAdornment>
              }
              labelWidth={80}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">
              Employment Date
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={myEmployment}
              disabled={!editable}
              onChange={(e) => setMyEmployment(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <CalendarTodayIcon />
                </InputAdornment>
              }
              labelWidth={125}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">
              Education
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={myEducation}
              disabled={!editable}
              onChange={(e) => setMyEducation(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SchoolIcon />
                </InputAdornment>
              }
              labelWidth={75}
            />
          </FormControl>
        </Grid>
      </Grid>
      <div
        style={{
          flexDirection: "row",
          justifyContent: "center",
          display: "flex",
          padding: 20,
        }}
      >
        {/* <Grid container spacing={1}> */}
        {!editable && (
          <Button
            onClick={() => setEditable(true)}
            className={myStyles.editIcon}
            variant="contained"
            color="primary"
          >
            Edit
          </Button>
        )}

        {editable && (
          <Button
            onClick={() => {
              setEditable(false);
              // window.location.reload();
            }}
            className={myStyles.cancelIcon}
            variant="contained"
            color="primary"
          >
            Cancel
          </Button>
        )}

        {editable && (
          <Button
            onClick={() => {
              setEditable(false);
              updateContactInfo();
              updatePersonInfo();
              handleSubmit();
            }}
            className={myStyles.saveIcon}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        )}
        {/* </Grid> */}
      </div>
    </Container>
  );
}
