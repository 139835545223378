import { gql } from "@apollo/client";

export const ALL_TEST_RESULTS = gql`
query getAllTestResultByPatientId($patient_id: ID!) {
  allTestResultByPatientId(patient_id: $patient_id) {
    id
    name
    orderBy
    orderDate{toString}
    resultDate{toString}
    status
    patient{lastName
            firstName
    }
    provider {
      lastName
      firstName
    }
    testresultcomp {
      id
      compName
      compNumb
      version
      value
      valueStr
      refRLow
      refRHigh
      refRange
      resultUnit
      description
      status
    }
  }
}
`;
export const GET_TEST_RESULT_COMP = gql`
query getTestResultCompByResultId($result_id: ID!) {
  testResultCompByResultId(result_id: $result_id) {
      id
      compName
      compNumb
      version
      value
      valueStr
      refRLow
      refRHigh
      refRange
      resultUnit
      description
      status}
}
`;
