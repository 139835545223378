import React, { useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import clsx from "clsx";
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { blue, grey } from "@mui/material/colors";
import menu_items from "./menu";
import Avatar from "@mui/material/Avatar";
import { AuthenticationContext } from "../services/authentication/authentication.context";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import {
  Backdrop,
  Badge,
  Fade,
  FormControlLabel,
  Modal,
  Paper,
  Switch,
  Button,
} from "@mui/material";
import { ThemeContext } from "../services/theme/theme.context";
import Notification from "../components/Notification";
import { useQuery } from "@apollo/client";
import { NOTIFICATIONS_BY_PERSON } from "@cims/common";
import { useIdleTimer } from "react-idle-timer";

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  menuItems: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(3),
  },
  nested2: {
    paddingLeft: theme.spacing(6),
  },
  active: {
    paddingLeft: theme.spacing(3),
    background: blue[100],
  },
  listItemText: {
    fontSize: "1em",
    color: grey[600],
    marginLeft: "10px",
  },
  listItemTextWeight: {
    fontWeight: 400,
  },
  ppc: {
    flexGrow: 1,
  },
  avatar: {
    marginLeft: theme.spacing(2),
  },
  toolbar: {
    height: "50px",
  },
  logout: {
    marginRight: 10,
  },
  notification: {
    position: "absolute",
    width: 300,
    maxHeight: 500,
    // height: 600,
    right: 30,
    top: 75,
    zIndex: 99,
    overflow: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#f5f3f0", //theme.palette.background.paper,
    border: "1px solid #666666",
    width: 400,
    maxHeight: 700,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "auto",
  },
  tf: {
    marginBottom: 8,
  },
  btn: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function Layout({ children }) {
  const {
    user,
    onLogout,
    curPickedPatient,
    curPickedPhysician,
    openTopMenu,
    setOpenTopMenu,
    patientAllow,
  } = useContext(AuthenticationContext);
  const { darkMode, changeTheme } = useContext(ThemeContext);

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const { data: notificationData } = useQuery(NOTIFICATIONS_BY_PERSON, {
    variables: {
      person_id: user ? Number(user.person.id) : 1,
    },
  });

  const changeOpenNotification = () => {
    setOpenNotification(!openNotification);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClick = (item) => {
    let topMenuFlag = false;
    menu_items.forEach((menu) => {
      if (menu.open === item) topMenuFlag = true;
    });
    if (topMenuFlag) {
      menu_items.forEach((menu) => {
        if (menu.open !== item) openTopMenu[menu.open] = false;
      });
    }

    openTopMenu[item] = openTopMenu[item] ? false : true;
    if (item === "patients" && curPickedPatient === null)
      openTopMenu[item] = false;
    if (item === "physicians" && curPickedPhysician === null)
      openTopMenu[item] = false;

    setOpenTopMenu({
      ...openTopMenu,
      item: openTopMenu[item],
    });
    console.log("open--->", openTopMenu);

    switch (item) {
      case "patients":
        if (user.person.roles.includes("PATIENT"))
          history.push(`/patients/${user.person.id}`);
        else history.push("/patients");
        break;

      case "physicians":
        history.push("/physicians");
        break;

      case "healthsystem":
        history.push("/healthsystem");
        break;

      default:
        break;
    }
  };

  //idle timer
  const timeout = 1000 * 60 * 20;
  const [lastEvent, setLastEvent] = useState("Events Emitted on Leader");

  const handleOnActive = () => setLastEvent("active");

  const handleOnIdle = () => {
    setLastEvent("idle");
    // setUser(null);
    history.push("/login");
    //logout
    onLogout();

    setOpenModal(false);
  };

  const { reset, pause, resume, getRemainingTime, getElapsedTime, isIdle } =
    useIdleTimer({
      timeout,
      onActive: handleOnActive,
      onIdle: handleOnIdle,
      crossTab: {
        emitOnAllTabs: true,
      },
    });

  // useEffect(() => {
  //   setRemaining(getRemainingTime());

  //   setInterval(() => {
  //     setRemaining(Math.floor(getRemainingTime() / 1000));
  //     if (getRemainingTime() <= 1000 * 10 && getRemainingTime() > 1000) {
  //       setOpenModal(true);
  //     }
  //   }, 1000);
  // }, []);

  // const tempAllowed = [3, 4];

  return (
    <div className={classes.root}>
      <CssBaseline />
      {/* app bar */}
      <Paper>
        <AppBar
          color="default"
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
              size="large">
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap className={classes.ppc}>
              Universal Health Exchange Console
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={darkMode}
                  onChange={() => {
                    localStorage.setItem("isDarkMode", (!darkMode).toString());
                    changeTheme(!darkMode);
                  }}
                />
              }
              label=""
            />
            {user && (
              <>
                <IconButton className={classes.logout} onClick={onLogout} size="large">
                  <Badge>
                    <ExitToAppIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  className={classes.logout}
                  onClick={() => {
                    setOpenNotification(!openNotification);
                  }}
                  size="large">
                  <Badge
                    badgeContent={
                      notificationData
                        ? notificationData.allNotificationsByPersonId.length
                        : 0
                    }
                    color="secondary"
                  >
                    <NotificationsNoneIcon />
                  </Badge>
                </IconButton>

                {/* <Typography>{user.person.firstName}</Typography> */}
                <Avatar className={classes.avatar} src={user.person.avatar} />
              </>
            )}
          </Toolbar>
        </AppBar>
      </Paper>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <img src="/UHX-Logo.png" alt="logo" height={65} />
          <Typography variant="h4" color="primary" style={{ marginLeft: 40 }}>
            UHX
          </Typography>

          <IconButton onClick={handleDrawerClose} size="large">
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />

        {user && (
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.menuItems}
          >
            <ListItem button onClick={() => history.push("/")}>
              <ListItemIcon>
                <HomeOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemTextWeight }}
                primary="HOME"
              />
            </ListItem>
            {menu_items.map((menu) => {
              let patientName = "";
              if (menu.groupName === "Patients" && curPickedPatient)
                patientName = `(${curPickedPatient?.firstName} ${curPickedPatient?.lastName})`;
              return (
                <div key={menu.groupName}>
                  <ListItem button onClick={() => handleClick(menu.open)}>
                    <ListItemText
                      classes={{ primary: classes.listItemTextWeight }}
                      primary={
                        user.person.roles.includes("PATIENT") &&
                        menu.groupName === "Patients"
                          ? "My Medical Record"
                          : `${menu.groupName}${patientName}`
                      }
                    />
                    {menu.open !== "healthsystem" ? (
                      openTopMenu[menu.open] ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    ) : null}
                  </ListItem>
                  <Collapse
                    in={openTopMenu[menu.open]}
                    // in={true}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {menu.items.map((item) => {
                        if (
                          !patientAllow.includes(item.id) &&
                          menu.groupName === "Patients"
                        )
                          return null;
                        else
                          return (
                            <div key={item.text}>
                              <ListItem
                                button
                                onClick={() => {
                                  if (item.for && item.for === "patients") {
                                    if (curPickedPatient)
                                      history.push(
                                        `/${item.for}/${curPickedPatient.id}${item.path}`
                                      );
                                  } else if (
                                    item.for &&
                                    item.for === "physicians"
                                  ) {
                                    if (curPickedPhysician) {
                                      history.push(
                                        `/${item.for}/${curPickedPhysician.provider.id}${item.path}`
                                        // `/${item.for}/${curPickedPhysician.id}${item.path}`
                                      );
                                    }
                                  } else if (
                                    item.for &&
                                    item.for === "patientmatcher"
                                  ) {
                                    if (curPickedPhysician) {
                                      history.push(
                                        `/${item.for}/${curPickedPhysician.id}/${item.path}`
                                      );
                                      handleClick(item.open);
                                    }
                                  } else if (
                                    item.for &&
                                    (item.for === "billing" ||
                                      item.for === "medicalandfamilyhistory" ||
                                      item.for === "accountsettings" ||
                                      item.for === "dataprotection" ||
                                      item.for === "clinicaltrials")
                                  ) {
                                    handleClick(item.open);
                                  } else history.push(item.path);
                                }}
                                className={
                                  location.pathname === item.path ||
                                  location.pathname.includes(item.path)
                                    ? classes.active
                                    : classes.nested
                                }
                              >
                                {item.icon}
                                <ListItemText
                                  classes={{ primary: classes.listItemText }}
                                  primary={item.text}
                                />
                                {item.items &&
                                  (openTopMenu[item.open] ? (
                                    <ExpandLess />
                                  ) : (
                                    <ExpandMore />
                                  ))}
                              </ListItem>

                              {item.items && (
                                <Collapse
                                  in={openTopMenu[item.open]}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <List component="div" disablePadding>
                                    {item.items.map((submenu) => {
                                      return (
                                        <ListItem
                                          key={submenu.text}
                                          button
                                          onClick={() => {
                                            if (submenu.for === "billing")
                                              history.push(
                                                `/patients/${curPickedPatient.id}${submenu.path}`
                                              );
                                            else if (
                                              submenu.for ===
                                              "medicalandfamilyhistory"
                                            )
                                              history.push(
                                                `/patients/${curPickedPatient.id}${submenu.path}`
                                              );
                                            else if (
                                              submenu.for === "clinicaltrials"
                                            )
                                              history.push(
                                                `/physicians/${curPickedPhysician.id}${submenu.path}`
                                              );
                                            else
                                              history.push(`${submenu.path}`);
                                          }}
                                          className={
                                            location.pathname ===
                                              submenu.path ||
                                            location.pathname.includes(
                                              submenu.path
                                            )
                                              ? classes.active
                                              : ""
                                          }
                                          style={{ marginLeft: 40 }}
                                        >
                                          {submenu.icon}
                                          <ListItemText
                                            classes={{
                                              primary: classes.listItemText,
                                            }}
                                            primary={submenu.text}
                                          />
                                        </ListItem>
                                      );
                                    })}
                                  </List>
                                </Collapse>
                              )}
                            </div>
                          );
                      })}
                    </List>
                  </Collapse>
                </div>
              );
            })}
          </List>
        )}
      </Drawer>

      {/* main content */}
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        {openNotification && (
          <Paper className={classes.notification}>
            <Notification
              data={notificationData.allNotificationsByPersonId}
              changeOpenNotification={changeOpenNotification}
            />
          </Paper>
        )}
        <div className={classes.toolbar}></div>
        {children}
        {/* <Box mt={5}>
          <Copyright />
        </Box> */}
      </main>

      <Modal
        className={classes.modal}
        open={openModal}
        onClose={() => setOpenModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.paper}>
            <Typography>Do you want to stay or logout?</Typography>
            <div className={classes.btn}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  reset();
                  setOpenModal(false);
                }}
              >
                Stay
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: 20 }}
                onClick={() => {
                  // setUser(null);
                  history.push("/login");
                  onLogout();
                  setOpenModal(false);
                }}
              >
                Logout
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
