import { gql } from "@apollo/client";

export const GET_MEDICATIONS_PTID = gql`
  query allMedicationsByPtId($patient_id: ID!){
    allMedicationsByPtId(patient_id:$patient_id){
      id, isPrescripted, prescriptionName, knownAsName, dose, typeMedication, quantity, prescriptionTime,
      description, RefillDetails, canRequestRefill,
      pharmacy, pharmacyAddress, telNo,
      personalNote, doctorNote, provider{lastName,firstName}
    }
  }
`;

export const UPDATE_MEDICATION = gql`
    mutation  updateQResultList($qResultList: [QuestionnaireResult]){
      updateQResultList(qResultList: $qResultList){id, medicalName, result, category}
    }
`;