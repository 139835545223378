import React, { useState, useContext, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import makeStyles from '@mui/styles/makeStyles';
import TextField from "@mui/material/TextField";

import { useHistory } from "react-router-dom";
import { AuthenticationContext } from "../../../services/authentication/authentication.context";

import { UPDATE_PASSWORD } from "@cims/common";
import { useMutation } from "@apollo/client";
import Alert from '@mui/material/Alert';

const useStyles = makeStyles({
  field: {
    marginTop: 20,
    marginBottom: 20,
    display: "block",
  },
  submitBtn: {
    marginTop: 20,
  },
});

export default function ChangePassword2() {
  const classes = useStyles();
  const history = useHistory();
  const oldPassword = "password";
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const { user, contextHeaders } = useContext(AuthenticationContext);

  const [updatePasscode, { error: updatePasscodeError }] = useMutation(
    UPDATE_PASSWORD,
    {
      variables: {
        personId: Number(user.person.id),
        input: {
          originalPassword: oldPassword,
          newPassword: newPassword,
        },
      },
      context: contextHeaders,
    }
  );

  useEffect(() => {
    if (user === null) history.push("/login");
  });

  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setNewPasswordError(false);
    setConfirmPasswordError(false);

    if (newPassword === "" || confirmPassword === "") {
      setNewPasswordError(true);
      setAlert(true);
      setAlertSeverity("error");
      setAlertContent("Your New/Confirm Password is blank!");
    } else if (newPassword !== confirmPassword) {
      setNewPasswordError(true);
      setConfirmPasswordError(true);
      setAlert(true);
      setAlertSeverity("error");
      setAlertContent("Your New and Confirm Passwords are not matched!");
    } else if (newPassword && confirmPassword) {
      updatePasscode()
        .then(() => {
          setAlert(true);
          setAlertSeverity("success");
          setAlertContent("Your password has been updated successfully!");
        })
        .catch((e) => {
          setAlert(true);
          setAlertSeverity("error");
          setAlertContent("" + e);
          // console.log("error: ", e);
        });
    }
  };

  return (
    <Container size="sm">
      <Typography
        variant="h6"
        color="textSecondary"
        component="h2"
        gutterBottom
      >
        Change Password
      </Typography>

      <div>
        {alert ? <Alert severity={alertSeverity}>{alertContent}</Alert> : <></>}
      </div>

      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <TextField
          type="password"
          className={classes.field}
          onChange={(e) => setNewPassword(e.target.value)}
          label="New Password"
          variant="outlined"
          color="secondary"
          fullWidth
          required
          error={newPasswordError}
        />
        <TextField
          type="password"
          className={classes.field}
          onChange={(e) => setConfirmPassword(e.target.value)}
          label="Confirm Password"
          variant="outlined"
          color="secondary"
          fullWidth
          required
          error={confirmPasswordError}
        />
        {/* {updatePasscodeError && (
          <Typography color="error">
            {updatePasscodeError.toString()}
          </Typography>
        )} */}
        <center>
          <Button
            className={classes.submitBtn}
            type="submit"
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
        </center>
      </form>
    </Container>
  );
}
