import { gql } from "@apollo/client";

export const eBinder_GET_CURRENT_STUDY_SETTINGS = gql`
  query getCurrentStudySettings($studyId: Long!) {
    getCurrentStudySettings(studyId: $studyId) {
      id
      generateIdentifier
      prefixIdentifier
      sequenceIdentifier
      subjectIdLengthSize
      subjectIdLength
      craQueryAccess
      dmQueryAccess
      modifyTime {
        toString
      }
      sdtmVersion
      study {
        studyName
      }
    }
  }
`;

export const eBinder_UPSERT_CURRENT_STUDY_SETTINGS = gql`
  mutation upsertCurrentStudySettings(
    $studySettingInput: StudySettingInput
    $operatorInfoInput: OperatorInfoInput
  ) {
    upsertCurrentStudySettings(
      studySettingInput: $studySettingInput
      operatorInfoInput: $operatorInfoInput
    ) {
      id
      generateIdentifier
      prefixIdentifier
      sequenceIdentifier
      subjectIdLengthSize
      subjectIdLength
      modifyTime {
        toString
      }
      craQueryAccess
      dmQueryAccess
      sdtmVersion
      study {
        studyName
      }
    }
  }
`;

export const eBinder_GET_ALL_SDTM_CODE_BY_VERSION = gql`
  query getAllSdtmCodeByVersion($verGuid: String) {
    getAllSdtmCodeByVersion(verGuid: $verGuid) {
      codeId
      nciCode
      verGuid
      codelistExtensible
      codelistName
      cdiscValue
      cdiscynonym
      cdiscDefinition
      preferredTerm
      dataSource
    }
  }
`;

export const eBinder_GET_ALL_CODELIST_VERGUID_AND_CODELIST_CODE = gql`
  query getAllCodeListVerGuidAndCodelistCode(
    $verGuid: String
    $codelistCode: String
  ) {
    getAllCodeListVerGuidAndCodelistCode(
      verGuid: $verGuid
      codelistCode: $codelistCode
    ) {
      codeId
      nciCode
      verGuid
      codelistCode
      codelistName
      cdiscValue
      cdiscSynonym
      cdiscDefinition
      preferredTerm
      dataSource
    }
  }
`;
