import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "../pages/home/page/Home";
import Physicians from "../pages/physicians/pages/Physicians.page";
import ScheduleAppointment from "../pages/patients/pages/ScheduleAppointment.page";
import Login from "../pages/account/pages/login.page";
import PatientScheduleList from "../pages/patients/pages/Patient-schedule-list.page";
import MessageList from "../pages/communication/pages/Message-list.page";
import ContactCareTeam from "../pages/communication/pages/Contact-Care-Team.page";
import CareteamDetails from "../pages/communication/pages/Careteam-detail.page";
import OrganizationList from "../pages/healthsystem/pages/Organization-list.page";
import ScheduleAppointmentLocation from "../pages/patients/pages/ScheduleAppointment-location.page";
import ScheduleAppointmentProvider from "../pages/patients/pages/ScheduleAppointment-provider.page";
import ScheduleAppointmentTimeslot from "../pages/patients/pages/ScheduleAppointment-timeslot.page";
import Patients from "../pages/patients/pages/Patients.page";
import PatientDetails from "../pages/patients/pages/Patient-details.page";
import PhysicianDetails from "../pages/physicians/pages/Physician-details.page";
import BillingOverview from "../pages/patients/pages/Billing_Overview.page";
import BillingHistory from "../pages/patients/pages/Billing_History.page";
import BillingInfo from "../pages/patients/pages/Billing_Info.page";
import PhysicianScheduleList from "../pages/physicians/pages/Physician-shedule-list.page";
import ManageTime from "../pages/physicians/pages/Manage-time.page";
import Insurance from "../pages/patients/pages/Insurance.page";
import AddPayment from "../pages/patients/pages/Billing_Add.page";
import PersonalInfo from "../pages/settings/pages/PersonalInfo.page";
import ChangePassword from "../pages/settings/pages/ChangePassword.page";
import ChangePasscode from "../pages/settings/pages/ChangePasscode.page";
import UploadFile from "../pages/settings/pages/UploadFile.page";
import BillingHistoryDetail from "../pages/patients/pages/Billing-history-detail.page";
import HealthSystem from "../pages/healthsystem/pages/HealthSystem.page";
import ManualDataMasking from "../pages/utilities/pages/Manual-DataMasking-list.page";
import AutoDataMasking from "../pages/utilities/pages/Auto-DataMasking-list.page";
import EncryptFile from "../pages/utilities/pages/EncryptAFile-list.page";
import DecryptFile from "../pages/utilities/pages/DecryptAFile.page";
import EncryptTool from "../pages/utilities/pages/DownloadEncryptTool.page";
import EncryptFileWithExpiration from "../pages/utilities/pages/EncryptFileWithExpiration.page";
import DecryptFileWithExpiration from "../pages/utilities/pages/DecryptFileWithExpiration.page";
import AuthorizeAccess from "../pages/utilities/pages/AuthorizeAccess.page";
import MyPharmacy from "../pages/patients/pages/MyPharmacy.page";
import TestResult from "../pages/patients/pages/Test-Result.page";
import TestResultDetails from "../pages/patients/pages/Test-Result-Detail.page";
import LabResultsDetails from "../pages/patients/pages/Lab-Results-Detail.page";
//import SharedPhysicians from "../pages/patients/pages/SharedMedicalRecord.page";
import SharedPhysicians from "../pages/patients/pages/SharedPhysicians.page";
import AllergyResults from "../pages/patients/pages/Allergy-details.page";
import ImmunizationResults from "../pages/patients/pages/Immunization-details.page";
import LabResults from "../pages/patients/pages/Lab-results.page";
import ClinicalNotes from "../pages/patients/pages/Clinical-Notes.page";
import ClinicalNotesDetails from "../pages/patients/pages/Clinical-Notes-Details.page";
import SearchPharmacy from "../pages/patients/pages/MyPharmacy-Search.page";
import Questionnaires from "../pages/patients/pages/Questionnaires.page";
import AddPaymentHistoryDetail from "../pages/patients/pages/AddPay-history-detail.page";
import Medications from "../pages/patients/pages/Medications.page";
import MedicalandFamilyHistory from "../pages/patients/pages/Medical-Family-History.page";
import SocialHistory from "src/pages/patients/pages/Social-History.page";
import MedicalHistory from "src/pages/patients/pages/Medical-History.page";
import SurgicalHistory from "src/pages/patients/pages/Surgical-History.page";
import StudySearch from "../pages/mmr/page/Patient-Matcher-Clincal-Trials.page";
import ClinicalTrialsAdvancedSearch from "src/pages/mmr/page/Clincal-Trials-Advanced-Search";
import Download from "../pages/account/pages/download.page";
import ClincalTrialsSearchResult from "../pages/mmr/page/Patient-Matcher-Search-Result.page";
import SignUp from "src/pages/account/pages/signup.page";
import UploadMedicalRecord from "../pages/patients/pages/Upload-Medical-Record.page";
import ViewMedicalRecord from "../pages/patients/pages/View-Medical-Record.page";
import PatientMatcher from "../pages/mmr/page/Patient-Matcher.page";
import PatientMatcherStudies from "../pages/mmr/page/Patient-Matcher-Studies.page";
import PatientMatcherResult from "../pages/mmr/page/Patient-Matcher-Result.page";
import StudyAdvanceSearch from "../pages/mmr/page/Clincal-Trials-Advanced-Search";
import StudySearchResult from "../pages/mmr/page/Patient-Matcher-Search-Result.page";
import ResetPassword from "src/pages/account/pages/resetpw.page";
import ChangePassword2 from "src/pages/settings/pages/ChangePassword2.page";
import TwoFactorAuthentication from "src/pages/utilities/pages/TwoFactorAuthentication.page";
import EnableTwoFA from "src/pages/settings/pages/EnableTwoFA.page";
import VitalSign from "src/pages/patients/pages/Vital-Sign.page";
import Demographic from "src/pages/patients/pages/Demographic.page";

function Routes() {
  return (
    <>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/login" component={Login} />
        <Route path="/signup" component={SignUp} />
        <Route path="/resetpwd" component={ResetPassword} />
        <Route path="/download" component={Download} />
        <Route exact path="/healthsystem" component={HealthSystem} />
        <Route
          exact
          path="/clinicalstudies/patientmatcher"
          component={PatientMatcher}
        />
        <Route
          exact
          path="/clinicalstudies/patientmatcherresult"
          component={PatientMatcherResult}
        />
        <Route
          exact
          path="/clinicalstudies/patientmatcherstudies"
          component={PatientMatcherStudies}
        />
        {/* Patients routers */}
        <Route exact path="/patients" component={Patients} />
        <Route exact path="/patients/:patientId" component={PatientDetails} />
        <Route
          exact
          path="/patients/:patientId/visits/scheduling"
          component={ScheduleAppointment}
        />
        <Route
          exact
          path="/patients/:patientId/visits/scheduling/:subjectId"
          component={ScheduleAppointmentLocation}
        />
        <Route
          exact
          path="/patients/:patientId/visits/scheduling/:subjectId/:locationId"
          component={ScheduleAppointmentProvider}
        />
        <Route
          path="/patients/:patientId/visits/scheduling/:subjectId/:locationId/:providerId"
          component={ScheduleAppointmentTimeslot}
        />
        <Route
          path="/patients/:patientId/billingOverview"
          component={BillingOverview}
        />
        <Route
          exact
          path="/patients/:patientId/paymentHistory"
          component={BillingHistory}
        />
        <Route
          exact
          path="/patients/:patientId/paymentHistory/:itemId"
          component={BillingHistoryDetail}
        />
        <Route
          exact
          path="/patients/:patientId/paymentDetail/:itemId"
          component={AddPaymentHistoryDetail}
        />
        <Route
          path="/patients/:patientId/billingInfo"
          component={BillingInfo}
        />
        <Route path="/patients/:patientId/results" component={TestResult} />
        <Route
          path="/patients/:patientId/shared-physicians"
          component={SharedPhysicians}
        />
        <Route
          path="/patients/:patientId/allergy-results"
          component={AllergyResults}
        />
        <Route
          path="/patients/:patientId/immunization-results"
          component={ImmunizationResults}
        />
        <Route path="/patients/:patientId/lab-results" component={LabResults} />
        <Route
          path="/patients/:patientId/clinical-notes"
          component={ClinicalNotes}
        />
        <Route
          exact
          path="/patients/:patientId/resultDetails/:itemId"
          component={TestResultDetails}
        />
        <Route
          exact
          path="/patients/:patientId/labResultDetails/:itemId"
          component={LabResultsDetails}
        />
        <Route
          exact
          path="/patients/:patientId/clinicalNotesDetails/:itemId"
          component={ClinicalNotesDetails}
        />
        <Route
          path="/patients/:patientId/questionnaires"
          component={Questionnaires}
        />
        <Route
          path="/patients/:patientId/upload-medicalrecord"
          component={UploadMedicalRecord}
        />
        <Route
          path="/patients/:patientId/view-medicalrecord"
          component={ViewMedicalRecord}
        />
        <Route
          path="/patients/:patientId/medications"
          component={Medications}
        />
        <Route path="/patients/:patientId/vitalsign" component={VitalSign} />
        <Route
          path="/patients/:patientId/demographic"
          component={Demographic}
        />
        <Route
          path="/patients/:patientId/history"
          component={MedicalandFamilyHistory}
        />
        <Route
          path="/patients/:patientId/socialhistory"
          component={SocialHistory}
        />
        <Route
          path="/patients/:patientId/surgicalhistory"
          component={SurgicalHistory}
        />

        <Route
          path="/patients/:patientId/medicalhistory"
          component={MedicalHistory}
        />
        <Route path="/patients/:patientId/phamacies" component={MyPharmacy} />
        <Route
          path="/patients/:patientId/searchPharmacies"
          component={SearchPharmacy}
        />
        <Route path="/patients/:patientId/insurance" component={Insurance} />
        <Route path="/patients/:patientId/addPayment" component={AddPayment} />
        {/* Physicians routers */}
        <Route exact path="/physicians" component={Physicians} />
        <Route
          exact
          path="/physicians/:providerId"
          component={PhysicianDetails}
        />
        <Route
          exact
          path="/physicians/:providerId/physicianschedule"
          component={PhysicianScheduleList}
        />
        <Route
          exact
          path="/physicians/:providerId/managetime"
          component={ManageTime}
        />
        <Route
          exact
          path="/clinicalstudies/studysearch"
          component={StudySearch}
        />
        <Route
          path="/clinicalstudies/studyadvancesearch"
          component={StudyAdvanceSearch}
        />
        <Route
          path="/clinicalstudies/studysearchresult"
          component={StudySearchResult}
        />
        <Route exact path="/communication/messages" component={MessageList} />
        <Route
          exact
          path="/communication/contactTeam"
          component={ContactCareTeam}
        />
        <Route
          exact
          path="/communication/:personId"
          component={CareteamDetails}
        />

        <Route exact path="/personinfo" component={PersonalInfo} />
        <Route
          exact
          path="/healthsystem/organizations/:hsId"
          component={OrganizationList}
        />
        {/* <Route exact path="/settings" component={AccountSettings} /> */}
        <Route exact path="/settings/password" component={ChangePassword} />
        <Route exact path="/settings/password2" component={ChangePassword2} />
        <Route exact path="/settings/passcode" component={ChangePasscode} />
        <Route exact path="/settings/uploadfile" component={UploadFile} />
        <Route exact path="/settings/enableTwoFA" component={EnableTwoFA} />

        <Route
          exact
          path="/utilities/protection/manual-masking"
          component={ManualDataMasking}
        />
        <Route
          exact
          path="/utilities/protection/auto-masking"
          component={AutoDataMasking}
        />
        <Route
          exact
          path="/utilities/protection/encrypt-files"
          component={EncryptFile}
        />
        <Route
          exact
          path="/utilities/protection/decrypt-files"
          component={DecryptFile}
        />
        {/* <Route exact path="/utilities/protection/encrypt-tool" component={EncryptTool} /> */}
        <Route
          exact
          path="/utilities/protection/encrypt-file-expiration"
          component={EncryptFileWithExpiration}
        />
        <Route
          exact
          path="/utilities/protection/decrypt-file-expiration"
          component={DecryptFileWithExpiration}
        />
        <Route
          exact
          path="/utilities/protection/authorize-access"
          component={AuthorizeAccess}
        />
        <Route
          exact
          path="/utilities/protection/two-factor-authentication"
          component={TwoFactorAuthentication}
        />
      </Switch>
    </>
  );
}

export default Routes;
