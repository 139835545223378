import {
  Avatar,
  Badge,
  Button,
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import makeStyles from '@mui/styles/makeStyles';
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import HouseIcon from "@mui/icons-material/House";
import EventIcon from "@mui/icons-material/Event";
import Zoom from "../components/Zoom";
import { useMutation, useQuery } from "@apollo/client";
import {
  ALL_ZOOM_MEETINGS,
  UPDATE_ZOOM_MEETING,
  PATIENT_DETAILS_BYID,
  CONVERSATION,
  CREATE_CHAT,
  CHAT_SUBSCRIPTION,
  FIND_PATIENT_BY_PERSON_ID,
  GET_PATIENT_ACCESS,
} from "@cims/common";
import { AuthenticationContext } from "../../../services/authentication/authentication.context";
import EmailBody from "./Email_Body.page";

import WcOutlinedIcon from "@mui/icons-material/WcOutlined";
import { useParams } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { capitalizeFirstLetter } from "@cims/utils";

import { blue, red } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
    padding: 20,
    maxWidth: 800,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
  },
  hr: {
    marginTop: 20,
    marginBottom: 20,
  },
  avatar: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  content: {
    display: "flex",
    alignItems: "center",
    margin: 10,
  },
  addBtnIcon: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
    color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
    "&:hover": {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#BDC0BA" : blue[700],
      color: "#fff",
    },
  },
  texting: {
    position: "absolute",
    width: 450,
    // maxHeight: 500,
    height: 500,
    right: 30,
    bottom: 10,
    zIndex: 99,
    display: "flex",
    flexDirection: "column",
    // overflow: "auto",
  },
  textContent: {
    flexGrow: 1,
    // backgroundColor: "navy",
    // color: "white",
    overflow: "auto",
    padding: 10,
    display: "flex",
    flexDirection: "column-reverse",
  },
  inputField: {
    // position: "absolute",
    // bottom: 0,
  },
}));

function Info({ icon, content }) {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      {icon}
      <Typography className={classes.avatar}>{content}</Typography>
    </div>
  );
}

export default function PatientDetails() {
  const { patientId } = useParams();
  const classes = useStyles();
  const {
    user,
    contextHeaders,
    curPickedPatient,
    pickingPatient,
    setPatientAllow,
  } = useContext(AuthenticationContext);

  const {
    data: patientData,
    loading: patientLoading,
    error: patientError,
  } = useQuery(PATIENT_DETAILS_BYID, {
    variables: {
      personId: patientId,
    },
  });
  console.log("patient--------------->", patientData);

  const {
    data: patientData2,
    loading: patientLoading2,
    error: patientError2,
  } = useQuery(FIND_PATIENT_BY_PERSON_ID, {
    variables: {
      personId: patientId,
    },
  });
  console.log("patient2--------------->", patientData2);
  const { data: accessData } = useQuery(GET_PATIENT_ACCESS, {
    variables: {
      personId: patientId,
      sharedPersonId: user.person.id,
    },
  });

  useEffect(() => {
    if (accessData) setPatientAllow(accessData.getChooseCategoriesById);
  }, [accessData]);

  useEffect(() => {
    if (patientData) pickingPatient(patientData.patientByPersonId);
  }, [patientData]);

  const {
    data: meetingData,
    loading: meetingLoading,
    error: meetingError,
  } = useQuery(ALL_ZOOM_MEETINGS, {
    context: contextHeaders,
  });

  const [updateZoomMeeting, { data: updateMeetingData, error: updateError }] =
    useMutation(UPDATE_ZOOM_MEETING, {
      context: contextHeaders,
      refetchQueries: [
        {
          query: ALL_ZOOM_MEETINGS,
          context: contextHeaders,
        },
      ],
    });

  const getMeeting = (id, objArr) => {
    let meeting = {};

    for (let i = 0, n = objArr.length; i < n; i++) {
      if (
        objArr[i].callerId === "0" &&
        objArr[i].calleeId === "0" &&
        objArr[i].status === "IDLE"
      ) {
        meeting.zoomId = objArr[i].zoomId;
        meeting.url = objArr[i].url;
        meeting.starter = true;
      }
      if (objArr[i].callerId === id || objArr[i].calleeId === id) {
        meeting.zoomId = objArr[i].zoomId;
        meeting.url = objArr[i].url;
        if (objArr[i].calleeId === id) meeting.starter = false;
        else meeting.starter = true;
        break;
      }
    }

    return meeting;
  };

  const {
    data: textingData,
    loading: textsLoading,
    error: textsError,
    subscribeToMore,
  } = useQuery(CONVERSATION, {
    variables: {
      fromUserId: Number(user.person.id),
      toUserId: Number(patientId),
    },
  });

  const [createChat, { data: chatData, error: sendTextError }] =
    useMutation(CREATE_CHAT);
  const sendText = () => {
    if (text !== "")
      createChat({
        variables: {
          fromUserId: Number(user.person.id),
          toUserId: Number(patientId),
          text: text,
        },
      }).then(() => {
        setText("");
      });
  };

  const subscribeToNewText = () =>
    subscribeToMore({
      document: CHAT_SUBSCRIPTION,
      variables: {
        fromUserId: Number(user.person.id),
        toUserId: Number(patientId),
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newText = subscriptionData.data.chatAdded;
        const obj = {};
        obj.conversation = [newText, ...prev.conversation];
        return obj;
      },
    });

  const [showZoom, setShowZoom] = useState(false);
  const [emailPopup, setEmailPopup] = useState(false);
  const [openText, setOpenText] = useState(false);
  const [text, setText] = useState("");

  function addScript(script_src) {
    const script = document.createElement("script");
    script.src = script_src;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }

  useEffect(() => {
    subscribeToNewText();
  }, []);

  return <>
    {/* <div className="Patient-Details">
    {addScript('/pages/zoom/index.js')}
    {addScript('/pages/zoom/meeting.js')}
    {addScript('/pages/zoom/tool.js')}
    {addScript('/pages/zoom/vconsole.min.js')}
  </div> */}
    <Paper className={classes.root}>
      <div className={classes.toolbar}>
        <Avatar className={classes.avatar} src={curPickedPatient.avatar} />
        <Typography>{`${curPickedPatient.firstName} ${curPickedPatient.lastName}`}</Typography>
      </div>
      {/* <Divider /> */}
      <hr className={classes.hr} />
      <Info
        icon={<WcOutlinedIcon />}
        content={`Gender: ${
          patientData?.patientByPersonId?.sex || "Not provided"
        }`}
      />
      <Info
        icon={<EventIcon />}
        content={`DOB: ${
          patientData?.patientByPersonId?.dob || "Not provided"
        }`}
      />
      <Info
        icon={<PhoneAndroidIcon />}
        content={`Phone: ${
          patientData?.patientByPersonId?.phone || "Not provided"
        }`}
      />
      <Info
        icon={<MailOutlineIcon />}
        content={`Email: ${
          patientData?.patientByPersonId?.email || "Not provided"
        }`}
      />
      <Info
        icon={<HouseIcon />}
        content={`Address: ${
          patientData2?.findPatientByPersonId?.address || "Not provided"
        }`}
      />
    </Paper>
    {/* <center> */}
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    {!user.person.roles.includes("PATIENT") && (
      <>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          id="join_meeting"
          onClick={() => {
            setShowZoom(true);
            const curMeeting = getMeeting(
              user.person.id,
              meetingData.allZoomMeetings
            );
            updateZoomMeeting({
              variables: {
                zoomId: Number(curMeeting.zoomId),
                callerId: Number(user.person.id),
                calleeId: Number(curPickedPatient.id),
              },
            });
            // window.open(
            //   curMeeting.url
            //   // "https://zoom.us/j/91323437178?pwd=c1ZlNDMvZUdTdjQ3YjIyNVd6UVFBZz09"
            // );
          }}
        >
          Join Zoom Meeting
        </Button>
        {showZoom && <Zoom />}
        <Button
          style={{ marginLeft: 20 }}
          variant="contained"
          color="primary"
          onClick={() => setEmailPopup(true)}
        >
          Send Message
        </Button>
        <Button
          style={{ marginLeft: 20 }}
          variant="contained"
          color="primary"
          onClick={() => setOpenText(!openText)}
        >
          Text
        </Button>
      </>
    )}
    <EmailBody
      trigger={emailPopup}
      setTrigger={setEmailPopup}
      toEmail={curPickedPatient}
    >
      <p> this is email button triggered popup </p>
    </EmailBody>
    {openText && (
      <Paper className={classes.texting}>
        {/* <div> */}
        <IconButton
          onClick={() => setOpenText(false)}
          style={{ width: 20, height: 20 }}
          size="large">
          <Badge>
            <HighlightOffIcon />
          </Badge>
        </IconButton>

        <div className={classes.textContent}>
          {textingData &&
            [...textingData.conversation].map((item) => {
              const times = item.timestamp.toString.split("T");
              let name = "Me";
              let color = red[500];
              if (item.fromUserId === patientId) {
                name = capitalizeFirstLetter(curPickedPatient.firstName);
                color = blue[500];
              }
              return (
                <div key={item.id}>
                  <Typography variant="caption">
                    {`${times[0]} ${times[1].slice(0, 5)}`}
                  </Typography>
                  <div style={{ display: "flex", marginTop: -8 }}>
                    <Typography
                      style={{
                        fontWeight: 600,
                        marginRight: 10,
                        color: `${color}`,
                      }}
                    >
                      {`${name}:`}
                    </Typography>
                    <Typography>{item.text}</Typography>
                  </div>
                </div>
              );
            })}
        </div>
        <FormControl
          // className={clsx(classes.margin, classes.textField)}
          className={classes.inputField}
          variant="filled"
          fullWidth
        >
          {/* <InputLabel htmlFor="filled-adornment-password">Password</InputLabel> */}
          <FilledInput
            id="filled-adornment-password"
            type="text"
            multiline
            fullWidth
            value={text}
            onChange={(e) => setText(e.target.value)}
            // onSubmit={() => sendText()}
            onKeyDown={(e) => {
              if (e.keyCode === 13) sendText();
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => sendText()}
                  edge="end"
                  size="large">
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        {/* </div> */}
      </Paper>
    )}
  </>;
}
