import { makeStyles } from "@mui/styles";

export const useGenealStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 10,
  },
}));
