/*
 * @Author: your name
 * @Date: 2022-03-08 13:30:07
 * @LastEditTime: 2022-03-10 10:45:55
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /cims-dev/packages/UHX/src/pages/settings/pages/EnableTwoFA.page.js
 */
import React, { useState, useContext, useEffect } from "react";
import {
  Container,
  Typography,
  TextField,
  Grid,
  Button,
  Modal,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useQuery, useMutation } from "@apollo/client";
import { 
  GET_TWOFA_INFO,
  UPDATE_TWOFA_ENABLE
} from "@cims/common";
import { AuthenticationContext } from "src/services/authentication/authentication.context";


const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: 20,
    marginBottom: 20,
    display: "block",
  },
  radioGroup: {
    margin: theme.spacing(1),
  },
  radioItem: {
    margin: theme.spacing(1),
  },
  radioButton: {
    margin: theme.spacing(1),
  },
}));

export const EnableTwoFA = ({}) => {
  
  const classes = useStyles();

  const [enable, setEnable] = useState("false");
  const [enableFlag, setEnableFlag] = useState(true);

  const { user, contextHeaders } = useContext(AuthenticationContext);
  
  console.log(user);

  const {
    data: twofaData,
    loading: twofaLoading,
    error: twofaError,
  } = useQuery(GET_TWOFA_INFO, {
    variables: { patient_id: user.person.patient ? Number(user.person.patient.id) : 0},
    context: contextHeaders,
  });



  useEffect(() => {
    if (twofaData && twofaData.find2faInfoByPtId.length !== 0 && user.person.patient) {
      console.log("twofaData: ", twofaData.find2faInfoByPtId[0]);
      setEnable(twofaData.find2faInfoByPtId[0].enable);
    }
  }, [twofaData]);

  const [updateEnable, { dataEnable, loadingEnable, errorEnable }] = useMutation(
    UPDATE_TWOFA_ENABLE,
    {
      variables: {
        patient_id:  Number(user.person.patient.id),
        enable: enable,
      },
      context: contextHeaders,
      refetchQueries: [
        {
          query: GET_TWOFA_INFO,
          variables: {
            patient_id:  Number(user.person.patient.id)
          },
          context: contextHeaders,
        },
      ],
    }
  );

  const handleEnableChange = (e) => {
    setEnable(e.target.value);
    setEnableFlag(false);
  }

  const handleEnableUpdate = (e) => {
    setEnableFlag(true);
    updateEnable();
  }

  return (
    <Container size="sm">
      <Typography
        variant="h6"
        color="textSecondary"
        component="h2"
        gutterBottom
      >
        Enable your 2FA Function
      </Typography>
      
      {user.person.patient == null ? 
        <Typography
          variant="h7"
          color="textSecondary"
          component="h2"
          gutterBottom
        >
          You are not patient.
        </Typography> : null
      }

      {twofaData && user.person.patient &&
      <FormControl component="fieldset">
          <RadioGroup
            row 
            aria-label="enableWay" 
            name="enableWay" 
            value={enable} 
            onChange={handleEnableChange}
            className={classes.radioGroup}
          >
            <FormControlLabel 
              className={classes.radioItem}
              value="true" 
              control={<Radio />} 
              label="True" 
            />

            <FormControlLabel 
              className={classes.radioItem}
              value="false" 
              control={<Radio />} 
              label="False" 
            />

            <div style={{ paddingLeft: 30 }}>
              <Button 
                className={classes.radioButton}
                variant="contained" 
                color="secondary" 
                disabled={enableFlag}
                onClick={() => {
                  handleEnableUpdate();
                }}
              >
                Update
              </Button>
            </div>

          </RadioGroup>
        </FormControl>
        }

    </Container>
  );
}

export default EnableTwoFA;
