import React, { useContext, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Divider,
  styled,
  IconButton,
  CardActions,
  Avatar,
  Collapse,
  Button,
} from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import { AuthenticationContext } from "src/services/authentication/authentication.context";
import { GET_MEDICATIONS_PTID } from "@cims/common/services/medications.service";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { blue } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 20,
    // height: "50px",
  },
  addBtnIcon: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
    color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
    "&:hover": {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#BDC0BA" : blue[700],
      color: "#fff",
    },
  },

  btn: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  paper: {
    backgroundColor: "#f5f3f0", //theme.palette.background.paper,
    border: "1px solid #666666",
    width: 700,
    maxHeight: 700,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "auto",
  },

  htyCard: {
    maxWidth: 345,
    marginBottom: "10px",
  },

  cardHeader: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true"
        ? "rgba(0,26,72, 0.3)"
        : blue[100], //"rgba(126,211,205,0.5)",
  },

  divider: {
    backgroundColor: "#d9e2ee",
    margin: "0 20px",
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} size="large" />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Questionnaires() {
  //get user id from auth context
  const { user, contextHeaders } = useContext(AuthenticationContext);
  const myStyles = useStyles();
  const classes = useStyles();

  //query medications
  const {
    data: medications,
    loading: medicationsLoading,
    error: medicationsError,
  } = useQuery(GET_MEDICATIONS_PTID, {
    //variables: { patient_id: Number(user.person.id) },
    variables: { patient_id: Number(user.person.patient.id) },
    context: contextHeaders,
  });

  const [expanded, setExpanded] = useState({});

  const handleExpandClick = (index) => {
    setExpanded({
      ...expanded,
      [index]: expanded[index] ? false : true,
    });
    console.log(expanded);
  };

  return (
    <Container>
      <Typography variant="body1" gutterBottom component="div">
        <h3>Your Prescribed Medications</h3>
      </Typography>
      <hr></hr>
      <Grid container spacing={3}>
        {medications &&
          medications.allMedicationsByPtId.map((medication, id) => {
            if (medication.isPrescripted === "yes")
              return (
                <Grid key={id} item xs={12} sm={6} md={4}>
                  <Card className={myStyles.htyCard}>
                    <CardHeader
                      avatar={
                        <Avatar
                          sx={{ bgcolor: "primary" }}
                          aria-label="recipe"
                          src="https://www.vitafoodsinsights.com/sites/vitafoodsinsights.com/files/styles/article_featured_standard/public/Plant%20based%20capsule.png?itok=VXvoqouG"
                        />
                      }
                      className={myStyles.cardHeader}
                      title={`${medication.prescriptionName} (${medication.typeMedication})`}
                      subheader={`Prescribed Date: ${medication.prescriptionTime}`}
                    />

                    <Divider variant={"middle"} className={myStyles.divider} />

                    <CardContent>
                      <Typography variant="body2" color="text.secondary">
                        Approved By: {medication.provider.firstName}{" "}
                        {medication.provider.lastName}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Pharmacy: {medication.pharmacy}
                      </Typography>
                    </CardContent>

                    <Divider variant={"middle"} className={myStyles.divider} />

                    <CardActions disableSpacing>
                      <ExpandMore
                        expand={expanded[medication.id] ? true : false}
                        onClick={() => handleExpandClick(medication.id)}
                        aria-expanded={expanded}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </CardActions>

                    <Divider variant={"middle"} className={myStyles.divider} />

                    <Collapse
                      in={expanded[medication.id] ? true : false}
                      timeout="auto"
                      unmountOnExit
                    >
                      <CardContent>
                        <Typography variant="body2" color="text.secondary">
                          Instructions: {medication.description}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Pharmacy Name: {medication.pharmacy}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Pharmacy Address: {medication.pharmacyAddress}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Pharmacy Tel: {medication.telNo}
                        </Typography>

                        <Divider
                          variant={"middle"}
                          className={myStyles.divider}
                        />

                        <Typography variant="body2" color="text.secondary">
                          Refill Details: {medication.quantity}{" "}
                          {medication.typeMedication}
                        </Typography>
                      </CardContent>
                    </Collapse>
                  </Card>
                </Grid>
              );
          })}
      </Grid>

      <div style={{ padding: 20 }}>
        <Grid container spacing={5}>
          <Typography variant="body1" gutterBottom component="div"></Typography>
        </Grid>
      </div>

      <Divider variant={"middle"} className={classes.divider} />

      <Typography variant="body1" gutterBottom component="div">
        Your None-Prescribed Medications
      </Typography>

      <Grid container spacing={3}>
        {medications &&
          medications.allMedicationsByPtId.map((medication, id) => {
            if (medication.isPrescripted === "no")
              return (
                <Grid key={id} item xs={12} sm={6} md={4}>
                  <Card className={myStyles.htyCard}>
                    <CardHeader
                      avatar={
                        <Avatar
                          sx={{ bgcolor: "primary" }}
                          aria-label="recipe"
                          src="https://www.vitafoodsinsights.com/sites/vitafoodsinsights.com/files/styles/article_featured_standard/public/Plant%20based%20capsule.png?itok=VXvoqouG"
                        />
                      }
                      className={myStyles.cardHeader}
                      title={`${medication.prescriptionName} (${medication.typeMedication})`}
                      subheader={`Started Taking: ${medication.prescriptionTime}`}
                    />

                    <Divider variant={"middle"} className={myStyles.divider} />

                    <CardContent>
                      <Typography variant="body2" color="text.secondary">
                        Documented By: {medication.provider.firstName}{" "}
                        {medication.provider.lastName}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Pharmacy: None
                      </Typography>
                    </CardContent>

                    <Divider variant={"middle"} className={myStyles.divider} />

                    <CardActions disableSpacing>
                      <ExpandMore
                        expand={expanded[medication.id] ? true : false}
                        onClick={() => handleExpandClick(medication.id)}
                        aria-expanded={expanded}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </CardActions>

                    <Divider variant={"middle"} className={myStyles.divider} />

                    <Collapse
                      in={expanded[medication.id] ? true : false}
                      timeout="auto"
                      unmountOnExit
                    >
                      <CardContent>
                        <Typography variant="body2" color="text.secondary">
                          Instructions: {medication.description}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Pharmacy Name: {medication.pharmacy}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Pharmacy Address: {medication.pharmacyAddress}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Pharmacy Tel: {medication.telNo}
                        </Typography>

                        <Divider
                          variant={"middle"}
                          className={myStyles.divider}
                        />

                        <Typography variant="body2" color="text.secondary">
                          Refill Details: {medication.quantity}{" "}
                          {medication.typeMedication}
                        </Typography>
                      </CardContent>
                    </Collapse>
                  </Card>
                </Grid>
              );
          })}
      </Grid>

      <div style={{ padding: 20 }}>
        <Grid container spacing={5}>
          <Typography variant="body1" gutterBottom component="div"></Typography>
        </Grid>
      </div>

      <div className={myStyles.btn}>
        <Button
          style={{ marginRight: 50 }}
          variant="contained"
          color="primary"
          onClick={() => {
            //
          }}
        >
          Upload your prescription
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            //
          }}
        >
          Manully input your prescription
        </Button>
      </div>
    </Container>
  );
}
