/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Modal,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { useQuery, useMutation } from "@apollo/client";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  PATIENTS_BILLING,
  ADD_NEW_INVOICE,
  ADD_NEW_PAYMENT,
} from "@cims/common";
import { AppointmentContext } from "../../../services/appointment/appointment.context";
import LoadingIndicator from "../../../components/LoadingIndicator";
import ErrorInfo from "../../../components/ErrorInfo";
import { convertDate } from "../../../utility/commons";
import Controls from "../../communication/components/controls/Controls";
import { Form, useForm } from "../../communication/components/useForm";
import { AuthenticationContext } from "src/services/authentication/authentication.context";
import { blue } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  bar: {
    display: "flex",
    marginBottom: 20,
  },
  title: {
    flexGrow: 1,
  },
  cards: {
    display: "flex",
    marginBottom: 200,
    justifyContent: "space-between",
  },
  root: {
    maxWidth: 345,
    marginBottom: "10px",
    textAlign: "center",
  },
  divider: {
    backgroundColor: "#d9e2ee",
    margin: "0 20px",
    marginTop: 20,
  },
  payBtn: {
    height: 40,
    width: 240,
    fontSize: 12,
    margin: 10,
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
    color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
    "&:hover": {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#BDC0BA" : blue[700],
      color: "#fff",
    },
  },
  htyCard: {
    maxWidth: 345,
    marginBottom: "10px",
  },
  payDDL: {
    margin: theme.spacing(1),
  },
  cardHeader: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true"
        ? "rgba(0,26,72, 0.3)"
        : "rgba(126,211,205,0.5)",
  },
  cardHeaderM: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true"
        ? "rgba(0,26,72, 0.7)"
        : "#FAEBD7",
  },
  detDivider: {
    backgroundColor: "#d9e2ee",
    margin: theme.spacing(2),
  },
  paper: {
    position: "absolute",
    width: 680,
    height: 450,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "15%",
    left: "35%",
    textAlign: "center",
  },
  invoiceBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "60px",
  },
}));

const initialFValues = {
  id: 0,
  guarantorNumb: "",
  paidDate: "",
  totalBalance: "",
  serviceDept: "",
  providerName: "",
  charge: "",
  insuranceAdjustment: "",
  paymentDate: "",
  paymentBalance: "",
  paymentTo: "",
  paymentFrom: "",
};

const paymentValues = {
  id: 0,
  guarantorNumb: "",
  paymentDate: "",
  paymentBalance: "",
  paymentTo: "",
  paymentFrom: "",
  providerName: "",
};

export default function BillingOverview() {
  const classes = useStyles();
  const { patientId } = useParams();
  const history = useHistory();
  const { curPickedPatient } = useContext(AuthenticationContext);

  const { data, loading, error } = useQuery(PATIENTS_BILLING, {
    variables: {
      patient_id: Number(curPickedPatient.id),
    },
  });
  console.log("data--->", data);

  const today = new Date();
  const [date, setDate] = useState(convertDate(today));

  const transferDate = (str) => {
    return str.replace("T", " ");
  };

  const addManual = false;

  const [invoiceFlag, setInvoiceFlag] = useState(false);
  const [paymentFlag, setPaymentFlag] = useState(false);

  const handleInvoice = () => {
    setInvoiceFlag(true);
  };

  const handlePayment = () => {
    setPaymentFlag(true);
  };

  const handleClose = () => {
    resetForm();
    setPaymentFlag(false);
    setInvoiceFlag(false);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true);

  const {
    payValues,
    setPayValues,
    payErrors,
    setPayErrors,
    payHandleInputChange,
    payResetForm,
  } = useForm(paymentValues, true);

  const handleSubmit = (e) => {
    e.preventDefault();
    createNewInvoice(values);
    handleClose();
    resetForm();
  };

  const handleSubmitPayment = (e) => {
    e.preventDefault();
    createNewPayment(values);
    handleClose();
    resetForm();
  };

  const [addNewInvoice, { dataNew, loadingNew, errorNew }] = useMutation(
    ADD_NEW_INVOICE,
    {
      refetchQueries: [
        {
          query: PATIENTS_BILLING,
          variables: {
            patient_id: Number(curPickedPatient.id),
          },
        },
      ],
    }
  );

  const createNewInvoice = (values) => {
    addNewInvoice({
      variables: {
        patient_id: curPickedPatient.id,
        guarantorNumb: values.guarantorNumb,
        paidDate: transferDate(values.paidDate),
        totalBalance: values.totalBalance,
        serviceDept: values.serviceDept,
        providerName: values.providerName,
        charge: values.charge,
        insuranceAdjustment: values.insuranceAdjustment,
      },
    });
  };

  const [addNewPayment, { dataPay, loadingPay, errorPay }] = useMutation(
    ADD_NEW_PAYMENT,
    {
      refetchQueries: [
        {
          query: PATIENTS_BILLING,
          variables: {
            patient_id: Number(curPickedPatient.id),
          },
        },
      ],
    }
  );

  const createNewPayment = (values) => {
    addNewPayment({
      variables: {
        patient_id: curPickedPatient.id,
        guarantorNumb: values.guarantorNumb,
        paymentDate: transferDate(values.paymentDate),
        paymentBalance: values.paymentBalance,
        paymentTo: values.paymentTo,
        paymentFrom: values.paymentFrom,
        providerName: values.providerName,
      },
    });
  };

  const addPayment = (
    <div className={classes.paper}>
      <h2 id="add-invoice-title">Add a New Payment</h2>
      <Form onSubmit={handleSubmitPayment}>
        <Grid container>
          <Grid item xs={6}>
            <Controls.Date
              label="Choose the Payment date"
              name="paymentDate"
              value={values.paymentDate}
              onChange={handleInputChange}
              error={errors.paymentDate}
              fontSize="small"
            />
            <Controls.Input
              label="Guarantor Number"
              name="guarantorNumb"
              value={values.guarantorNumb}
              onChange={handleInputChange}
              error={errors.guarantorNumb}
            />
            <Controls.Input
              label="Payment Balance"
              name="paymentBalance"
              value={values.paymentBalance}
              onChange={handleInputChange}
              error={errors.paymentBalance}
            />
          </Grid>
          <Grid item xs={6}>
            <Controls.Input
              label="Provider Name"
              name="providerName"
              value={values.providerName}
              onChange={handleInputChange}
              error={errors.providerName}
            />
            <Controls.Input
              label="Payment To"
              name="paymentTo"
              value={values.paymentTo}
              onChange={handleInputChange}
              error={errors.paymentTo}
            />
            <Controls.Input
              label="Payment From"
              name="paymentFrom"
              value={values.paymentFrom}
              onChange={handleInputChange}
              error={errors.paymentFrom}
            />
          </Grid>
        </Grid>
        <div className={classes.invoiceBtn}>
          <Controls.Button
            type="submit"
            text="Submit"
            style={{ width: "94px" }}
          />
          <Controls.Button
            text="Cancel"
            color="default"
            onClick={handleClose}
            style={{ width: "94px" }}
          />
        </div>
      </Form>
    </div>
  );

  const addInvoice = (
    <div className={classes.paper}>
      <h2 id="add-invoice-title">Add a New Invoice</h2>
      <Form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={6}>
            <Controls.Date
              label="Choose the invoice date"
              name="paidDate"
              value={values.paidDate}
              onChange={handleInputChange}
              error={errors.paidDate}
              fontSize="small"
            />
            <Controls.Input
              label="Guarantor Number"
              name="guarantorNumb"
              value={values.guarantorNumb}
              onChange={handleInputChange}
              error={errors.guarantorNumb}
            />
            <Controls.Input
              label="Total Balance"
              name="totalBalance"
              value={values.totalBalance}
              onChange={handleInputChange}
              error={errors.totalBalance}
            />
            <Controls.Input
              label="Service Department"
              name="serviceDept"
              value={values.serviceDept}
              onChange={handleInputChange}
              error={errors.serviceDept}
            />
          </Grid>
          <Grid item xs={6}>
            <Controls.Input
              label="Provider Name"
              name="providerName"
              value={values.providerName}
              onChange={handleInputChange}
              error={errors.providerName}
            />
            <Controls.Input
              label="Charge"
              name="charge"
              value={values.charge}
              onChange={handleInputChange}
              error={errors.charge}
            />
            <Controls.Input
              label="Insurance Adjustment"
              name="insuranceAdjustment"
              value={values.insuranceAdjustment}
              onChange={handleInputChange}
              error={errors.insuranceAdjustment}
            />
          </Grid>
        </Grid>
        <div className={classes.invoiceBtn}>
          <Controls.Button
            type="submit"
            text="Submit"
            style={{ width: "94px" }}
          />
          <Controls.Button
            text="Cancel"
            color="default"
            onClick={handleClose}
            style={{ width: "94px" }}
          />
        </div>
      </Form>
    </div>
  );

  return (
    <Container>
      <div className={classes.bar}>
        <Typography
          variant="body1"
          className={classes.title}
          // color="textSecondary"
          component="h2"
          gutterBottom
        >
          {`${curPickedPatient.firstName} ${curPickedPatient.lastName}'s account overview:`}
        </Typography>
      </div>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <Card className={classes.root}>
            <CardHeader title="Amount Due" />
            <CardContent
              style={{
                textAlign: "center",
              }}
            >
              <Typography variant="h2" component="p">
                {data &&
                  (data.allBillingSummaryByPtId.length === 0
                    ? `0.00`
                    : `$ ${
                        data.allBillingSummaryByPtId[
                          data.allBillingSummaryByPtId.length - 1
                        ].amountDueNow
                      }`)}
              </Typography>
              <Typography
                variant="body2"
                component="p"
                className={classes.payDDL}
              >
                {data &&
                  (data.allBillingSummaryByPtId.length === 0
                    ? `Payment Due Date: No information in database`
                    : `Payment Due Date: ` +
                      transferDate(
                        data.allBillingSummaryByPtId[
                          data.allBillingSummaryByPtId.length - 1
                        ].paymentDueDate.toString
                      ))}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4} lg={3} style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            // onClick={() =>
            //   history.push(`/patients/${patientId}/addPayment`)
            // }
            onClick={handleInvoice}
            className={classes.payBtn}
          >
            Add a New Invoice
          </Button>
          <Button
            variant="contained"
            // onClick={() =>
            //   history.push(`/patients/${patientId}/addPayment`)
            // }
            onClick={handlePayment}
            className={classes.payBtn}
          >
            Add a New Payment
          </Button>
        </Grid>
      </Grid>

      <div>
        <Modal open={invoiceFlag} onClose={handleClose}>
          {addInvoice}
        </Modal>
      </div>

      <div>
        <Modal open={paymentFlag} onClose={handleClose}>
          {addPayment}
        </Modal>
      </div>

      <Divider variant={"middle"} className={classes.divider} />

      {loading && <LoadingIndicator />}
      {error && <ErrorInfo />}

      <div style={{ paddingTop: "15px" }}>
        <Typography variant="h5" gutterBottom>
          Recent Invoices
        </Typography>
      </div>

      <Grid container spacing={3}>
        {data &&
          data.allBillingSummaryByPtId.length !== 0 &&
          data.allBillingSummaryByPtId
            .filter((item, billingDetailId) => {
              return (
                item.addPayManually !== true &&
                date.substr(0, 7) === item.paymentDueDate.toString.substr(0, 7)
              );
            })
            .map((item, billingDetailId) => {
              return (
                <Grid key={item.billDetail.id} item xs={12} sm={6} md={4}>
                  <Card className={classes.htyCard}>
                    <CardHeader
                      title="Patient Payment"
                      subheader={transferDate(item.paymentDueDate.toString)}
                      titleTypographyProps={{ variant: "body1" }}
                      subheaderTypographyProps={{ variant: "body2" }}
                      className={
                        item.addPayManually
                          ? classes.cardHeaderM
                          : classes.cardHeader
                      }
                    />
                    <Divider variant={"middle"} className={classes.divider} />
                    <CardContent>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {`Total Balance: ${item.billDetail[0].totalBalance}`}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {`Service Department: ${item.billDetail[0].serviceDept}`}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {`Provider Name: ${item.billDetail[0].providerName}`}
                      </Typography>

                      <Divider className={classes.detDivider} />

                      <Link
                        to={
                          item.addPayManually
                            ? `/patients/${patientId}/paymentDetail/${parseInt(
                                item.id
                              )}`
                            : `/patients/${patientId}/paymentHistory/${parseInt(
                                item.id
                              )}`
                        }
                      >
                        <div style={{ textAlign: "center" }}>
                          {addManual ? (
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                            >
                              {`This record is added manually by ${curPickedPatient.firstName} ${curPickedPatient.lastName}.`}
                            </Typography>
                          ) : (
                            <Button
                              style={{
                                backgroundColor:
                                  localStorage.getItem("isDarkMode") === "true"
                                    ? "#828282"
                                    : blue[200],
                              }}
                            >
                              {`View Details`}
                            </Button>
                          )}
                        </div>
                      </Link>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
      </Grid>

      <Divider variant={"middle"} className={classes.divider} />

      {loading && <LoadingIndicator />}
      {error && <ErrorInfo />}

      <div style={{ paddingTop: "15px" }}>
        <Typography variant="h5" gutterBottom>
          Recent Payments
        </Typography>
      </div>

      <Grid container spacing={3}>
        {data &&
          data.allBillingSummaryByPtId.length !== 0 &&
          data.allBillingSummaryByPtId
            .filter((item, billingDetailId) => {
              return (
                item.addPayManually === true &&
                date.substr(0, 7) === item.paymentDueDate.toString.substr(0, 7)
              );
            })
            .map((item, i) => {
              return (
                <Grid key={item.billDetail.id} item xs={12} sm={6} md={4}>
                  <Card className={classes.htyCard}>
                    <CardHeader
                      title="Patient Payment"
                      subheader={transferDate(item.paymentDueDate.toString)}
                      titleTypographyProps={{ variant: "body1" }}
                      subheaderTypographyProps={{ variant: "body2" }}
                      className={
                        item.addPayManually
                          ? classes.cardHeaderM
                          : classes.cardHeader
                      }
                    />
                    <Divider variant={"middle"} className={classes.divider} />
                    <CardContent>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {`Payment Balance: ${item.addPayDetail[0].paymentBalance}`}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {`Payment To: ${item.addPayDetail[0].paymentTo}`}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {`Payment From: ${item.addPayDetail[0].paymentFrom}`}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {`Provider Name: ${item.addPayDetail[0].providerName}`}
                      </Typography>

                      <Divider className={classes.detDivider} />

                      <Link
                        to={
                          item.addPayManually
                            ? `/patients/${patientId}/paymentDetail/${parseInt(
                                item.id
                              )}`
                            : `/patients/${patientId}/paymentHistory/${parseInt(
                                item.id
                              )}`
                        }
                      >
                        <div style={{ textAlign: "center" }}>
                          {addManual ? (
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                            >
                              {`This record is added manually by ${curPickedPatient.firstName} ${curPickedPatient.lastName}.`}
                            </Typography>
                          ) : (
                            <Button
                              style={{
                                backgroundColor:
                                  localStorage.getItem("isDarkMode") === "true"
                                    ? "#828282"
                                    : blue[200],
                              }}
                            >
                              {`View Details`}
                            </Button>
                          )}
                        </div>
                      </Link>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
      </Grid>
    </Container>
  );
}
