/*
 * @Author: your name
 * @Date: 2022-02-03 09:50:01
 * @LastEditTime: 2022-03-02 01:43:36
 * @LastEditors: Please set LastEditors
 * @Description: æ‰“å¼€koroFileHeaderæŸ¥çœ‹é…ç½® è¿›è¡Œè®¾ç½®: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /cims-dev/packages/UHX/src/pages/patients/pages/Clinical-notes.page.js
 */
import React, { useState, useContext } from "react";

import {
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableBody,
  TableHead,
  Paper,
  TablePagination,
  Typography,
  Button,
} from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

//import { FIND_CLINICAL_NOTES_BY_ID } from "@cims/common/";
import { GET_INSURANCE_DETAIL_BY_PATIENT_ID } from "@cims/common/";
import { useMutation, useQuery } from "@apollo/client";
import { AuthenticationContext } from "src/services/authentication/authentication.context";
import { shouldCanonizeResults } from "@apollo/client/cache/inmemory/helpers";
import { useHistory, useParams } from "react-router-dom";
import { ThemeContext } from "src/services/theme/theme.context";
import { pink, blue } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ContactsOutlined } from "@mui/icons-material";

function createData(
  id,
  groupName,
  groupNumber,
  insuranceName,
  planName,
  subscriberAddress,
  subscriberId,
  subscriberName,
  subscriberSSN
) {
  return {
    id,
    groupName,
    groupNumber,
    insuranceName,
    planName,
    subscriberAddress,
    subscriberId,
    subscriberName,
    subscriberSSN,
  };
}

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
    // height: "40px",
  },
  addBtnIcon: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
    color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
    "&:hover": {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#BDC0BA" : blue[700],
      color: "#fff",
    },
  },
  btn: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default function Insurance() {
  console.log("==> Insurance()");
  let rowss = [];
  const classes = useRowStyles();

  const { patientId } = useParams();
  const history = useHistory();
  const { contextHeaders, user } = useContext(AuthenticationContext);
  const { buttonColor, darkMode } = useContext(ThemeContext);
  const { curPickedPatient } = useContext(AuthenticationContext);

  const [expanded, setExpanded] = useState({});

  const handleExpandClick = (index) => {
    setExpanded({
      ...expanded,
      [index]: expanded[index] ? false : true,
    });
    console.log(expanded);
  };

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const {
    data: insuranceDetail,
    loading: insuranceDetailLoading,
    error: insuranceDetailError,
  } = useQuery(GET_INSURANCE_DETAIL_BY_PATIENT_ID, {
    variables: { patientId: Number(user.person.patient.id) },
    context: contextHeaders,
  });
  console.log(
    "patientId = " +
      Number(user.person.patient.id) +
      ", insuranceDetail = " +
      insuranceDetail
  );

  {
    insuranceDetail &&
      insuranceDetail.getInsuranceDetailByPatientId.map((item) => {
        rowss.push(
          createData(
            item.id,
            item.groupName,
            item.groupNumber,
            item.insuranceName,
            item.planName,
            item.subscriberAddress,
            item.subscriberId,
            item.subscriberName,
            item.subscriberSSN
          )
        );
      });
  }
  //console.log("==> rowss = " + rowss);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rowss.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
      // backgroundColor: "green",
      // color: theme.palette.common.white,
      color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  console.log("==> Before return");

  return (
    <div className={classes.root}>
      <Typography variant="body1" gutterBottom component="div">
        <h3>Insurance Information: </h3>
      </Typography>
      <Paper className={classes.paper}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Group Name</StyledTableCell>
                <StyledTableCell>Group Number</StyledTableCell>
                <StyledTableCell>Insurance Name</StyledTableCell>
                <StyledTableCell>Plan Name</StyledTableCell>
                <StyledTableCell>Subscriber Address</StyledTableCell>
                <StyledTableCell>Subscriber ID</StyledTableCell>
                <StyledTableCell>Subscriber Name</StyledTableCell>
                <StyledTableCell>Subscriber SSN</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowss
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <StyledTableRow hover tabIndex={-1}>
                      <StyledTableCell align="left" style={{ width: "2%" }}>
                        {row.id}
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "10%" }}>
                        {row.groupName}
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "15%" }}>
                        {row.groupNumber}
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "15%" }}>
                        {row.insuranceName}
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "15%" }}>
                        {row.planName}
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "20%" }}>
                        {row.subscriberAddress}
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "10%" }}>
                        {row.subscriberId}
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "15%" }}>
                        {row.subscriberName}
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "20%" }}>
                        {row.subscriberSSN}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.pagination}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rowss.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
      <div className={classes.btn}>
        <Button
          style={{ marginRight: 50 }}
          variant="contained"
          color="primary"
          onClick={() => {
            //
          }}
        >
          Upload your Insurance Info
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            //
          }}
        >
          Manully input your Insurance Info
        </Button>
      </div>
    </div>
  );
}
