import { Avatar, Button, Paper, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import HouseIcon from "@mui/icons-material/House";
import EventIcon from "@mui/icons-material/Event";
import WcOutlinedIcon from "@mui/icons-material/WcOutlined";

import EmailBody from "../../patients/pages/Email_Body.page";
import { AuthenticationContext } from "src/services/authentication/authentication.context";
import { PERSON_BYID } from "@cims/common";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
    padding: 20,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
  },
  hr: {
    marginTop: 20,
    marginBottom: 20,
    maxWidth: 800,
  },
  avatar: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  content: {
    display: "flex",
    alignItems: "center",
    margin: 10,
  },
}));

function Info({ icon, content }) {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      {icon}
      <Typography className={classes.avatar}>{content}</Typography>
    </div>
  );
}

export default function CareteamDetails() {
  const { personId } = useParams();
  const classes = useStyles();
  const { curPickedPhysician } = useContext(AuthenticationContext);
  console.log("curPickedStaff--->", curPickedPhysician);
  const [emailPopup, setEmailPopup] = useState(false);
  //Subject
  //const [isPhysician, setIsPhysician] = useState(true);
  const [isStaff, setIsStaff] = useState(true);
  //console.log("Physician-detail: isPhysician ", isPhysician);
  const {
    data: personData,
    loading: personLoading,
    error: personError,
  } = useQuery(PERSON_BYID, {
    variables: {
      personId: personId,
    },
  });

  return (
    <>
      <Paper className={classes.root}>
        <div className={classes.toolbar}>
          <Avatar className={classes.avatar} src={curPickedPhysician.avatar} />
          <Typography>{`${curPickedPhysician.firstName} ${curPickedPhysician.lastName}`}</Typography>
        </div>
        {/* <Divider /> */}
        <hr className={classes.hr} />
        <Info
          icon={<WcOutlinedIcon />}
          content={`Gender: ${personData ? personData.person.sex : ""}`}
        />
        <Info
          icon={<EventIcon />}
          content={`DOB: ${personData ? personData.person.dob : ""}`}
        />
        <Info
          icon={<PhoneAndroidIcon />}
          content={`Phone: ${personData ? personData.person.phone : ""}`}
        />
        <Info
          icon={<MailOutlineIcon />}
          content={`Email: ${personData ? personData.person.email : ""}`}
        />
        <Info
          icon={<HouseIcon />}
          content={`Address: ${personData ? personData.person.address : ""}`}
        />
      </Paper>

      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          variant="contained"
          color="primary"
          onClick={() => setEmailPopup(true)}
        >
          {" "}
          Send Message{" "}
        </Button>
        <EmailBody
          trigger={emailPopup}
          setTrigger={setEmailPopup}
          toEmail={curPickedPhysician}
          select={isStaff}
        >
          <p> this is email button triggered popup </p>
        </EmailBody>
      </div>
    </>
  );
}
