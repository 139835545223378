import { gql } from "@apollo/client";

export const ALL_DEMOGRAPHICS_BY_ID = gql`
  query getAllDemographicsByPatientId($patientId: ID!) {
    allDemographicsByPatientId(patientId: $patientId) {
      dateOfBirth
      dateOfDeath
      race
      ethnicity
      sex
      country
      preferredLanguage
      dataSource
      patient {
        id
        person {
          id
        }
      }
    }
  }
`;
