import { gql } from "@apollo/client";

export const eBinder_GET_CURRENT_STUDY_OPERATION_LOG_BY_OPERATION_OBJECT = gql`
  query getCurrentStudyOperationLogByOperationObject(
    $studyId: Long
    $operationObject: String
    $page: Int!
    $size: Int!
  ) {
    getCurrentStudyOperationLogByOperationObject(
      studyId: $studyId
      operationObject: $operationObject
      page: $page
      size: $size
    ) {
      totalSize
      operationLogs {
        id
        operationType
        oldValue
        newValue
        changedItem
        studyId
        operatorId
        operatorIp
        operatorBrowser
      }
    }
  }
`;

export const eBinder_GET_CURRENT_TEMP_SDC_ALL_LOG = gql`
  query getCurrentTempSdcAllLog($tempSdcId: Long, $page: Int!, $size: Int!) {
    getCurrentTempSdcAllLog(tempSdcId: $tempSdcId, page: $page, size: $size) {
      id
      tempSdcId
      tempSdcDataTableName
      oldValue
      newValue
      uploadedFileName
      operationTime {
        toString
      }
      studyId
      siteId
      subjectInfoId
      visitId
      formId
      formVisitLocationId
      operatorId
      operatorIp
      operatorBrowser
    }
  }
`;

export const eBinder_CURRENT_FILE_ALL_TEMP_SDC_LOG = gql`
  query getCurrentFileAllTempSdcLog(
    $formVisitLocationId: Long
    $page: Int!
    $size: Int!
  ) {
    getCurrentFileAllTempSdcLog(
      formVisitLocationId: $formVisitLocationId
      page: $page
      size: $size
    ) {
      id
      tempSdcId
      tempSdcDataTableName
      oldValue
      newValue
      uploadedFileName
      operationTime {
        toString
      }
      studyId
      siteId
      subjectInfoId
      visitId
      formId
      formVisitLocationId
      operatorId
      operatorIp
      operatorBrowser
    }
  }
`;

export const eBinder_CURRENT_SUBJECT_ALL_TEMP_SDC_LOG = gql`
  query getCurrentSubjectAllTempSdcLog(
    $subjectInfoId: Long
    $page: Int!
    $size: Int!
  ) {
    getCurrentSubjectAllTempSdcLog(
      subjectInfoId: $subjectInfoId
      page: $page
      size: $size
    ) {
      totalSize
      tempSdcLogs {
        id
        sdcId
        sdcDataTableName
        oldValue
        newValue
        fieldName
        uploadedFileName
        operationTime
        studyId
        siteId
        subjectInfoId
        visitId
        formId
        formVisitLocationId
        operatorId
        operatorIp
        operatorBrowser
      }
    }
  }
`;

export const eBinder_GET_CURRENT_SUBJECT_ALL_SDC_LOG = gql`
  query getCurrentSubjectAllSdcLog(
    $subjectInfoId: Long
    $page: Int!
    $size: Int!
  ) {
    getCurrentSubjectAllSdcLog(
      subjectInfoId: $subjectInfoId
      page: $page
      size: $size
    ) {
      totalSize
      sdcLogList {
        id
        sdcId
        sdcDataTableName
        oldValue
        newValue
        fieldName
        uploadedFileName
        operationTime {
          toString
        }
        studyId
        siteId
        subjectInfoId
        visitId
        formId
        formVisitLocationId
        operatorId
        operatorIp
        operatorBrowser
      }
    }
  }
`;

export const eBinder_GET_CURRENT_FILE_AND_SDC_LOG = gql`
  query getCurrentFileAllSdcLog(
    $formVisitLocationId: Long
    $page: Int!
    $size: Int!
  ) {
    getCurrentFileAllSdcLog(
      formVisitLocationId: $formVisitLocationId
      page: $page
      size: $size
    ) {
      totalSize
      sdcLogList {
        id
        sdcId
        sdcDataTableName
        oldValue
        newValue
        fieldName
        uploadedFileName
        operationTime {
          toString
        }
        studyId
        siteId
        subjectInfoId
        visitId
        formId
        formVisitLocationId
        operatorId
        operatorIp
        operatorBrowser
      }
    }
  }
`;

export const eBinder_GET_CURRENT_FVL_ALL_SMQUERY = gql`
  query getCurrentFvlAllSmQuery(
    $formVisitLocationId: Long
    $page: Int!
    $size: Int!
  ) {
    getCurrentFvlAllSmQuery(
      formVisitLocationId: $formVisitLocationId
      page: $page
      size: $size
    ) {
      totalSize
      smQueryList {
        id
        sdcTableName
        sdcId
        fieldName
        queryType
        oldValue
        newValue
        queryStatus
        createRole
        answerRole
        groupId
        createUserId
        createTime {
          toString
        }
        createRemark
        answerUserId
        answerTime {
          toString
        }
        answerRemark
        closeUserId
        closeTime {
          toString
        }
        closeRemark
        studyId
        siteId
        subjectInfoId
        formVisitLocationId
      }
    }
  }
`;
