import React, { useState, useContext, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import Container from "@mui/material/Container";
import { useHistory } from "react-router";
import { AuthenticationContext } from "../../../services/authentication/authentication.context";
import LoadingIndicator from "src/components/LoadingIndicator";
import { ThemeContext } from "src/services/theme/theme.context";
import { grey } from "@mui/material/colors";
import { Alert } from '@mui/material';
import {
  GET_SECURITY_QUESTIONS_BY_EMAIL,
  CHECK_SECURITY_QUESTIONS,
  SEND_TEMP_PASSWORD,
  CHECK_TEMP_PASSWORD,
} from "@cims/common/";
import { useMutation } from "@apollo/client";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.cims.com/">
        www.cims.com
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ResetPassword() {
  const classes = useStyles();
  const history = useHistory();
  const { darkMode, buttonColor } = useContext(ThemeContext);

  const [username, setUsername] = useState("");
  const [tempPassword, setTempPassword] = useState("");
  const [qestionAnswers, setQuestionAnswers] = useState({
    q1Ans: "",
    q2Ans: "",
    q3Ans: "",
    q1Id: 1,
    q2Id: 2,
    q3Id: 3,
  });

  const [usernameError, setUsernameError] = useState(false);
  const [temppwdError, setTemppwdError] = useState(false);
  const [showQuestions, setShowQuestions] = useState(false);

  const handleChange = (e, id) => {
    const tf = e.target.name;
    setQuestionAnswers({
      ...qestionAnswers,
      [tf]: e.target.value,
      [`q${tf[1]}Id`]: Number(id),
    });
  };

  console.log("qa--->", qestionAnswers);

  const {
    onLogin,
    loginError,
    loginLoading,
    user,
    setUser,
    pickingPhysician,
    pickingPatient,
  } = useContext(AuthenticationContext);

  const [
    getSecurityQuestionsByEmail,
    { data: questionData, loading: questionLoading, error: questionError },
  ] = useMutation(GET_SECURITY_QUESTIONS_BY_EMAIL);

  const [
    sendTemporaryPassword,
    {
      data: tempPasswordData,
      loading: tempPasswordLoading,
      error: tempPasswordError,
    },
  ] = useMutation(SEND_TEMP_PASSWORD);

  const [
    checkTemporaryPassword,
    {
      data: checkTempPasswordData,
      loading: checkTempPasswordLoading,
      error: checkTempPasswordError,
    },
  ] = useMutation(CHECK_TEMP_PASSWORD);

  const [
    checkUserSecurityQuestionAnswers,
    {
      data: checkQuestionData,
      loading: checkQuestionLoading,
      error: checkQuestionError,
    },
  ] = useMutation(CHECK_SECURITY_QUESTIONS);

  /***************** */
  const storeToken = (value) => {
    localStorage.setItem("auth_token", value);
  };
  const storeUserData = (value) => {
    const jsonValue = JSON.stringify(value);
    localStorage.setItem("user", jsonValue);
  };

  /***************** */

  const checkQuestion = (e) => {
    e.preventDefault();

    const { q1Ans, q2Ans, q3Ans, q1Id, q2Id, q3Id } = qestionAnswers;
    if (q1Ans && q2Ans && q3Ans) {
      checkUserSecurityQuestionAnswers({
        variables: {
          userId: questionData.getSecurityQuestionsByEmail.userId,
          userSQInput: {
            q1Ans,
            q2Ans,
            q3Ans,
            q1Id,
            q2Id,
            q3Id,
          },
        },
      })
        .then((result) => {
          console.log(result);
          const user = result.data.checkUserSecurityQuestionAnswers;
          storeToken(user.token);
          storeUserData(user);
          setUser(user);
          if (user.person.roles.includes("PATIENT")) {
            pickingPatient(user.person);
          }
          if (user.person.roles.includes("PHYSICIAN")) {
            pickingPhysician(user.person);
          }
          setTimeout(function () {
            history.push("/settings/password2");
          }, 3000);
        })
        .catch((e) => console.log("error--->", e));
    }
  };

  const submitEmail = (e) => {
    e.preventDefault();

    if (username === "") {
      setUsernameError(true);
    } else {
      getSecurityQuestionsByEmail({
        variables: {
          email: username,
        },
      })
        .then((result) => {
          setShowQuestions(true);
          setUsernameError(false);
          //   console.log("result--->", result);
        })
        .catch((e) => console.log("error--->", e));
    }
  };

  const getTempPassword = (e) => {
    e.preventDefault();

    if (username === "") {
      setUsernameError(true);
    } else {
      sendTemporaryPassword({
        variables: {
          email: username,
        },
      })
        .then((result) => {
          setShowQuestions(true);
          setUsernameError(false);
          //   console.log("result--->", result);
        })
        .catch((e) => console.log("error--->", e));
    }
  };

  const checkTempPassword = (e) => {
    e.preventDefault();

    if (tempPassword === "") {
      setTemppwdError(true);
    } else {
      checkTemporaryPassword({
        variables: {
          userId: tempPasswordData.sendTemporaryPassword,
          inputPassword: tempPassword,
        },
      })
        .then((result) => {
          console.log(result);
          const user = result.data.checkTemporaryPassword;
          storeToken(user.token);
          storeUserData(user);
          setUser(user);
          if (user.person.roles.includes("PATIENT")) {
            pickingPatient(user.person);
          }
          if (user.person.roles.includes("PHYSICIAN")) {
            pickingPhysician(user.person);
          }
          setTimeout(function () {
            history.push("/settings/password2");
          }, 3000);
        })
        .catch((e) => console.log("error--->", e));
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        {!showQuestions && (
          <form className={classes.form} noValidate onSubmit={getTempPassword}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => setUsername(e.target.value)}
              error={usernameError}
            />

            {tempPasswordLoading && <LoadingIndicator />}
            {tempPasswordError && (
              <Typography
                className={classes.error}
                variant="body1"
                color="error"
                gutterBottom
              >
                {tempPasswordError.toString()}
              </Typography>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color={buttonColor}
              className={classes.submit}
            >
              Submit
            </Button>
          </form>
        )}
        {showQuestions && (
          <form
            className={classes.form}
            noValidate
            onSubmit={checkTempPassword}
          >
            {/* {questionData.getSecurityQuestionsByEmail.securityQuestions.map(
              (item, index) => {
                return (
                  <TextField
                    key={`item${index}`}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label={item.question}
                    name={`q${index + 1}Ans`}
                    onChange={(e) => {
                      handleChange(e, item.id);
                    }}
                    error={questionError1}
                  />
                );
              }
            )} */}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label={"Type your temporary password here"}
              name={`tempPassword`}
              onChange={(e) => setTempPassword(e.target.value)}
              error={temppwdError}
            />

            {checkTempPasswordLoading && <LoadingIndicator />}
            {checkTempPasswordError !== undefined && (
              <Alert severity="error">
                {checkTempPasswordError.toString()}
              </Alert>
            )}
            {!checkTempPasswordData && tempPasswordData && (
              <Alert severity="success">
                {
                  "A temporary password was sent to your email. Please check your email and use the temporary password to login."
                }
              </Alert>
            )}
            {checkTempPasswordData && (
              <Alert severity="success">
                {"Your temporarily login. Now please reset your password!"}
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color={buttonColor}
              className={classes.submit}
            >
              Submit
            </Button>
            {/* <Alert severity="success">
            A temporary passward has been sent to your email address. Please use
            the temporary password to login and reset your password!
          </Alert> */}
          </form>
        )}
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
