import { gql } from "@apollo/client";

export const eBinder_LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      person {
        sex
        id
        firstName
        lastName
        email
        avatar
        title
        employmentDate {
          toString
        }
        phone
        cellPhone
        education
        experience
        ssn
        dob
        roles
        contactinfo {
          id
          city
          country
          cellPhone
          state
          streetAddress
          preferContact
        }
        streetAddress
        city
        state
        zipCode
        country
      }
      userName
      studyList {
        id
        studyName
      }
      userSiteRoleAccessList {
        id
        site {
          id
        }
        access
      }
      userSiteRoleDefaultAccessList {
        defaultAccess
        study {
          id
        }
      }
      studyUserRoleList {
        id
        userRoleList
      }
    }
  }
`;

export const eBinder_LOGOUT = gql`
  mutation logout($msg: String!) {
    logout(msg: $msg)
  }
`;

//{"email": "l456456lgd@gmail.com", "password": "password"}
export const eBinder_LOGIN_WITH_SENDING_TWOFA = gql`
  mutation loginWithSendingTwoFa($email: String!, $password: String!) {
    loginWithSendingTwoFa(email: $email, password: $password) {
      person {
        id
        email
        cellPhone
      }
      userName
      studyList {
        id
        studyName
      }
      userSiteRoleAccessList {
        id
        site {
          id
        }
        access
      }
      userSiteRoleDefaultAccessList {
        defaultAccess
        study {
          id
        }
      }
      studyUserRoleList {
        id
        userRoleList
      }
      newUserFlag
    }
  }
`;

//{"userId": 39, "prefer": "1"} "0": phone; "1": email
export const eBinder_SEND_TWOFA_CODE = gql`
  mutation sendTwoFAInfo($userId: Long, $prefer: String) {
    sendTwoFAInfo(userId: $userId, prefer: $prefer) {
      Id
      prefer
      enable
      phoneNumber
      emailAddress
    }
  }
`;

/**
 * {"twoFaLoginInput":{
  "email":"liujun110@gmail.com",
  "password":"password",
  "twoFaCode":"068480"
}}
 */
export const eBinder_LOGIN_WITH_CODE = gql`
  mutation loginWithVerifyingTwoFa($twoFaLoginInput: TwoFaLoginInput) {
    loginWithVerifyingTwoFa(twoFaLoginInput: $twoFaLoginInput) {
      person {
        sex
        id
        firstName
        lastName
        email
        avatar
        title
        employmentDate {
          toString
        }
        phone
        cellPhone
        education
        experience
        ssn
        dob
        roles
        contactinfo {
          id
          city
          country
          cellPhone
          state
          streetAddress
          preferContact
        }
        streetAddress
        city
        state
        zipCode
        country
      }
      userName
      studyList {
        id
        studyName
      }
      userSiteRoleAccessList {
        id
        site {
          id
        }
        access
      }
      userSiteRoleDefaultAccessList {
        defaultAccess
        study {
          id
        }
      }
      studyUserRoleList {
        id
        userRoleList
      }
      token
    }
  }
`;
