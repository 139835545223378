/*
 * @Author: your name
 * @Date: 2022-02-03 09:50:35
 * @LastEditTime: 2022-03-02 02:44:17
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /cims-dev/packages/UHX/src/pages/patients/pages/Clinical-Notes-Details.page.js
 */
import React, { useContext } from "react";
import {
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";

import { FIND_CLINICAL_NOTES_BY_ID } from "@cims/common/";
import { useQuery } from "@apollo/client";
import { AuthenticationContext } from "src/services/authentication/authentication.context";
import { useHistory, useParams } from "react-router-dom";

export default function TestResultDetails() {
  const history = useHistory();

  const { user, contextHeaders } = useContext(AuthenticationContext);   
  const { curPickedPatient } = useContext(AuthenticationContext);
  //query test results
  const {
    data: clinicalNotes, 
    loading: clinicalNotesLoading,
    error: clinicalNotesError,
  } = useQuery(FIND_CLINICAL_NOTES_BY_ID, {
    variables: { patientId: Number(user.person.patient.id) },
    context: contextHeaders,
  })

  // get current test result id
  const currentURL = window.location.href;
  const currentIndex = Number(currentURL.split("/").pop());
  const { patientId } = useParams();
  //jyu const patientId  = Number (curPickedPatient.patient.id);

  console.log( "test result detail patientId233 ", patientId );
  console.log("current index:", currentIndex);
//   console.log( " test result detail patient_id ", Number(curPickedPatient.patient.id));

  return (
    <Container>
      <div>
        <Button
          variant="outlined"
          onClick={() => history.push(`/patients/${patientId}/clinical-notes`)}
          size="large"
          color="primary"
        >
          Return
        </Button>
      </div>

      <Typography variant="body1" gutterBottom component="div">
        Detailed Clinical Notes:
      </Typography>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {/* <TableCell align="right">Set ID</TableCell> */}
              <TableCell align="right">Result Status</TableCell>
              <TableCell>Observation Identifier</TableCell>
              <TableCell>Observation Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clinicalNotes && clinicalNotes.findClinicalNotesById[
              currentIndex
            ].cliObservationResult.map((currentResult, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {/* <TableCell align="right">
                  {currentResult.setIDOBX}
                </TableCell> */}
                <TableCell align="left">
                  {currentResult.observResultStatusOBX}
                </TableCell>
                <TableCell component="th" scope="row">
                  {currentResult.observationIdentifierOBX}
                </TableCell>
                <TableCell component="th" scope="row">
                  {currentResult.obsValue}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
