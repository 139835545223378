import React, { useContext, useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Button,
  Backdrop,
  Card,
  CardContent,
  CardHeader,
  Modal,
  Fade,
  Fab,
  IconButton,
  Checkbox,
  FormControlLabel,
  CardActions,
} from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import { useHistory, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

import { AppointmentContext } from "../../../services/appointment/appointment.context";
import { AuthenticationContext } from "../../../services/authentication/authentication.context";
import {
  ACTIVE_PHARMACY_DETAIL_BY_PATIENTID_DEFAULT,
  DEL_PHARMACY_DETAIL,
  CVS_LOCATION_BY_ASC,
} from "@cims/common";
import { useMutation, useQuery } from "@apollo/client";
import Divider from "@mui/material/Divider";
import DeleteForever from "@mui/icons-material/DeleteForever";
import { blue } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 20,
    // height: "50px",
  },
  addBtnIcon: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
    color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
    "&:hover": {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#BDC0BA" : blue[700],
      color: "#fff",
    },
  },

  btn: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  paper: {
    backgroundColor: "#f5f3f0", //theme.palette.background.paper,
    border: "1px solid #666666",
    width: 700,
    maxHeight: 700,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "auto",
  },

  htyCard: {
    maxWidth: 345,
    marginBottom: "10px",
  },

  cardHeader: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true"
        ? "rgba(0,26,72, 0.3)"
        : "rgba(126,211,205,0.5)",
  },

  divider: {
    backgroundColor: "#d9e2ee",
    margin: "0 20px",
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function MyPharmacy() {
  const myStyles = useStyles();

  const { user } = useContext(
    AuthenticationContext
  );

  const [searchZipcode, setSearchZipcode] = useState("");

  const [deleteID, setDeleteId] = useState(0);


  console.log(Number(user.person.id));
  //query activePharmacybyPatientID
  const {
    data: patientPharmacies,
    loading: patientPharmaciesLoading,
    error: patientPharmaciesError,
  } = useQuery(ACTIVE_PHARMACY_DETAIL_BY_PATIENTID_DEFAULT, 
    {variables: 
      { id:Number(user.person.id) }, 
    }
  );

  //query find the nearest CVS location by ZipCode
  const {
    data: cvsLocation,
    loading: cvsLocationLoading,
    error: cvsLocationError,
  } = useQuery(CVS_LOCATION_BY_ASC, {
    variables: { zipCode: `${searchZipcode}` },
  });

  // delete a pharmacy for current patient
  const [deletePharmacyDetail, { data }] = useMutation(DEL_PHARMACY_DETAIL, {
    refetchQueries: [
      {
        query: ACTIVE_PHARMACY_DETAIL_BY_PATIENTID_DEFAULT,
      },
    ],
  });

  const { patientId } = useParams();
  const history = useHistory();

  const [openAlert, setOpenAlet] = useState(false);

  return (
    <Container>
      <Typography variant="body1" gutterBottom component="div">
        Add the pharmacies where you like to get prescriptions. Your care team
        uses this list to make refill requests and appointment check-in faster
      </Typography>

      <div className={myStyles.addBtn}>
        <Fab
          className={myStyles.addBtnIcon}
          aria-label="add a new health system button"
          onClick={() =>
            history.push(`/patients/${patientId}/searchPharmacies`)
          }
        >
          <AddIcon />
        </Fab>
      </div>

      <Grid container spacing={3}>
        {patientPharmacies &&
          patientPharmacies.activePharmacyDetailByPatientIdDefault.map(
            (listPharmacy, index) => {
              return (
                <Grid key={index} item xs={12} sm={6} md={4}>
                  <Card className={myStyles.htyCard}>
                    <CardHeader
                      title="CVS Pharmacy"
                      titleTypographyProps={{ variant: "body1" }}
                      className={myStyles.cardHeader}
                      action={
                        <IconButton
                          onClick={() => {
                            setOpenAlet(true);
                            setDeleteId(Number(listPharmacy.id));
                          }}
                          aria-label="settings"
                          size="large">
                          <DeleteForever />
                        </IconButton>
                      }
                    />

                    <Divider variant={"middle"} className={myStyles.divider} />

                    <CardContent>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {`Address: ${listPharmacy.pharmacyAddress}`}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {`Status: ${listPharmacy.status}`}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {`ContactInfo: ${listPharmacy.telNo}`}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <FormControlLabel
                        value="set primary pharmacy"
                        control={<Checkbox />}
                        label="Set Primary Pharmacy"
                        labelPlacement="end"
                      />
                    </CardActions>
                  </Card>
                </Grid>
              );
            }
          )}
      </Grid>

      {/* Delete Pharmacy Modal*/}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={myStyles.modal}
        open={openAlert}
        onClose={() => setOpenAlet(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAlert}>
          <div className={myStyles.paper}>
            <div className={myStyles.btn}>
              <Typography>
                Are you sure you want to delete this pharmacy?
              </Typography>
            </div>

            <div className={myStyles.btn}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  console.log("delete...");
                  deletePharmacyDetail({
                    variables: {
                      id: deleteID,
                    },
                  }).then(() => {
                    setOpenAlet(false);
                    window.location.reload();
                  });
                }}
              >
                Yes
              </Button>
              <Button
                style={{ marginLeft: 20 }}
                variant="contained"
                color="primary"
                onClick={() => {
                  setOpenAlet(false);
                }}
              >
                No
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </Container>
  );
}
