import React from "react";
import { Modal, Fade, Backdrop, Box } from "@mui/material";

export default function MyModal({
  children,
  openModal = false,
  setOpenModal,
  close = true,
  width = 700,
  maxHeight = 800,
  minHeight = 300,
  hasHeight = false,
  height = 300,
}) {
  const styleWithHeight = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width,
    height,
    bgcolor: "background.paper",
    border: "1px solid #666666",
    boxShadow: 24,
    p: 4,
    overflow: "auto",
  };

  const styleNoHeight = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width,
    maxHeight,
    minHeight,
    bgcolor: "background.paper",
    border: "1px solid #666666",
    boxShadow: 24,
    p: 4,
    overflow: "auto",
  };

  const style = hasHeight ? styleWithHeight : styleNoHeight;

  return (
    <div>
      <Modal
        open={openModal}
        onClose={() => (close ? setOpenModal(false) : setOpenModal(true))}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box sx={style}>{children}</Box>
        </Fade>
      </Modal>
    </div>
  );
}
