import { gql } from "@apollo/client";

export const GET_QUESTIONNAIRE_PTID = gql`
    query questionnaireByPtId($patient_id: ID!){
      questionnaireByPtId(patient_id: $patient_id){
        id, medicalName, result, category
      }
    }
`;

export const UPDATE_QUESTIONNAIRE = gql`
    mutation  updateQResultList($qResultList: [QuestionnaireResult]){
      updateQResultList(qResultList: $qResultList){id, medicalName, result, category}
    }
`;