import React, { useContext, useState } from "react";
import { Quill } from "react-quill";
import axios from "axios";
import { AuthenticationContext } from "../../../services/authentication/authentication.context";
import { PDFObject } from "react-pdfobject";
import { serverUrl } from "@cims/common/";

// Custom Undo button icon component for Quill editor. You can import it directly
// from 'quill/assets/icons/undo.svg' but I found that a number of loaders do not
// handle them correctly
const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

// Redo button icon component for Quill editor
const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);

// Undo and redo functions for Custom Toolbar
function undoChange() {
  this.quill.history.undo();
}

function redoChange() {
  this.quill.history.redo();
}

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida",
];
Quill.register(Font, true);

const ImageHandler = () => {
  const input = document.createElement("input");

  input.setAttribute("type", "file");
  input.setAttribute(
    "accept",
    ".xlsx,.xls,image/*,.doc,.docx,.ppt,.pptx,.txt,.pdf"
  );
  input.click();

  input.onchange = async () => {
    var file: any = input.files[0];
    var formData = new FormData();

    // formData.append('image', file);
    formData.append("file", file);
    // console.log("file", file);
    var fileName = file.name;
    const res = UploadFiles(formData, fileName);
  };
};

var quillObj = null;
var globalContextHeaders;
var globalUser;
// var globaToken;

const transformValue = (value) => {
  var handleArr = value.split("\n");
  handleArr = handleArr.map((e) =>
    e.replace(/^[\s]+/, "").replace(/[\s]+$/, "")
  );
  return handleArr.join("");
};

const UploadFiles = (formData, filename) => {
  const siteUrl = serverUrl;
  const value = localStorage.getItem("auth_token");
  if (value !== null) {
    var token = `Bearer ${value}`;
    // console.log("Authorization", token);

    axios
      .post(
        siteUrl +
          "/api/uploadfile/" +
          globalUser.person.id +
          "/" +
          filename +
          "/pdf",
        formData,
        {
          headers: {
            "Content-Type": "application/form-data",
            Authorization: token,
          },
        }
      )
      .then((response) => {
        // axios.get(response.headers.location + '?api/download').then(function (response) {
        //     console.log(response);
        // });
        console.log("uploaded", true);
        var filename_html =
          filename.substr(0, filename.lastIndexOf(".")) + ".html";

        axios({
          url:
            siteUrl +
            "/api/download/" +
            globalUser.person.id +
            "/" +
            filename_html,
          method: "GET",
          // responseType: 'blob',
          headers: {
            Authorization: token,
          },
        })
          .then((response) => {
            // console.log("response", response);
            // console.log("url", response.config.url);
            // console.log("downloaded", true);
            var fileUrl =
              siteUrl +
              "/api/download/" +
              globalUser.person.id +
              "/" +
              filename_html;

            // var editor = new Quill('#editor');  // First matching element will be used
            // console.log("editor", editor);

            // const cursorPosition = quillObj.getSelection().index;
            // console.log("cursorPosition", cursorPosition);

            // const range = quillObj.getEditorSelection();
            // console.log("range", range);
            // quillObj.getEditor().insertEmbed(range.index, 'image', response.config.url);

            // quillObj.getEditor().insertText(100, 'Attached File: ' + filename, 'link', fileUrl);
            // quillObj.getEditor().insertText(10, 'Attached File: ' + filename);
            // var html = '<PDFObject url="serverUrl/api/download/2/pdf-export.pdf">';
            // var html = '<script>PDFObject.embed("serverUrl/api/download/2/pdf-export.pdf", "#example1");</script><b>test</b>';
            // var html = '<b>test</b>';
            // quillObj.getEditor().root.innerHTML = '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.1//EN" "http://www.w3.org/TR/xhtml11/DTD/xhtml11.dtd"> <html xmlns="http://www.w3.org/1999/xhtml"><head><meta content="text/html;charset=utf-8" http-equiv="content-type" /><title>PDF Document</title><style type="text/css">.page{position:relative; border:1px solid blue;margin:0.5em}.p,.r{position:absolute;}@supports(-webkit-text-stroke: 1px black) {.p{text-shadow:none !important;}}</style></head><body><div class="page" id="page_0" style="width:612.0pt;height:792.0pt;overflow:hidden;"><div class="p" id="p0" style="top:85.871994pt;left:72.0pt;line-height:10.799995pt;font-family:Times;font-size:12.0pt;width:21.335999pt;">This</div><div class="p" id="p1" style="top:85.871994pt;left:96.336pt;line-height:10.799995pt;font-family:Times;font-size:12.0pt;width:8.003998pt;">is</div><div class="p" id="p2" style="top:85.871994pt;left:109.67999pt;line-height:10.799995pt;font-family:Times;font-size:12.0pt;width:8.664001pt;">A</div><div class="p" id="p3" style="top:85.871994pt;left:121.343994pt;line-height:10.799995pt;font-family:Times;font-size:12.0pt;width:22.007996pt;">PDF</div></div></body></html>';
            // quillObj.getEditor().root.innerHTML = transformValue('<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.1//EN" "http://www.w3.org/TR/xhtml11/DTD/xhtml11.dtd"><html xmlns="http://www.w3.org/1999/xhtml">    <head>        <meta content="text/html;charset=utf-8" http-equiv="content-type" />        <title>PDF Document</title>        <style type="text/css">.page{position:relative; border:1px solid blue;margin:0.5em}.p,.r{position:absolute;}@supports(-webkit-text-stroke: 1px black) {.p{text-shadow:none !important;}}</style>    </head>    <body>\n        <div>ABC</div> \n <h2>This is a test!</h2>  </body></html>\n');
            // quillObj.getEditor().root.innerHTML = transformValue(response.data);
            // quillObj.getEditor().root.emit('set-html', response.data);
            // quillObj.getEditor().scroll.emitter.emit("blot-mount", quillObj.getEditor());

            // quillObj.getEditor().root.innerHTML = '';
            // quillObj.getEditor().clipboard.matchVisual = false;
            // quillObj.getEditor().clipboard.dangerouslyPasteHTML(0, transformValue(response.data));

            // const value = `<h1>New content here</h1>`;
            // const delta = quillObj.clipboard.convert(value);
            // quillObj.setContents(delta, 'silent');

            quillObj.clipboard.dangerouslyPasteHTML(
              0,
              transformValue(response.data)
            );
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log("uploaded", false);
      });
  }
};

// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      undo: undoChange,
      redo: redoChange,
      image: ImageHandler,
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};

// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block",
];

// Quill Toolbar component
export const QuillToolbar = ({ quillObject }) => {
  quillObj = quillObject;

  const { user, contextHeaders } = useContext(AuthenticationContext);
  // const token = contextHeaders.headers.authorization;

  globalContextHeaders = contextHeaders;
  globalUser = user;
  // globaToken = token;

  return (
    <div id="toolbar">
      <span className="ql-formats">
        <select className="ql-font" defaultValue="arial">
          <option value="arial">Arial</option>
          <option value="comic-sans">Comic Sans</option>
          <option value="courier-new">Courier New</option>
          <option value="georgia">Georgia</option>
          <option value="helvetica">Helvetica</option>
          <option value="lucida">Lucida</option>
        </select>
        <select className="ql-size" defaultValue="medium">
          <option value="extra-small">Size 1</option>
          <option value="small">Size 2</option>
          <option value="medium">Size 3</option>
          <option value="large">Size 4</option>
        </select>
        <select className="ql-header" defaultValue="3">
          <option value="1">Heading</option>
          <option value="2">Subheading</option>
          <option value="3">Normal</option>
        </select>
      </span>
      <span className="ql-formats">
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <button className="ql-strike" />
      </span>
      <span className="ql-formats">
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
        <button className="ql-indent" value="-1" />
        <button className="ql-indent" value="+1" />
      </span>
      <span className="ql-formats">
        <button className="ql-script" value="super" />
        <button className="ql-script" value="sub" />
        <button className="ql-blockquote" />
        <button className="ql-direction" />
      </span>
      <span className="ql-formats">
        <select className="ql-align" />
        <select className="ql-color" />
        <select className="ql-background" />
      </span>
      <span className="ql-formats">
        <button className="ql-link" />
        <button className="ql-image" />
        <button className="ql-video" />
      </span>
      <span className="ql-formats">
        <button className="ql-formula" />
        <button className="ql-code-block" />
        <button className="ql-clean" />
      </span>
      <span className="ql-formats">
        <button className="ql-undo">
          <CustomUndo />
        </button>
        <button className="ql-redo">
          <CustomRedo />
        </button>
      </span>
    </div>
  );
};

export default QuillToolbar;
