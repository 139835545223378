import React, { useState, useContext } from "react";

import { Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { SOCIAL_HISTORY_BYPTID } from "@cims/common/services/medical-history.service";
import { AuthenticationContext } from "src/services/authentication/authentication.context";
import { GlobalContext } from "src/services/global/global.context";
import LoadingIndicator from "src/components/LoadingIndicator";
import ErrorInfo from "src/components/ErrorInfo";
import TableComponent from "src/components/TableComponent";

export default function SocialHistory() {
  const { user } = useContext(AuthenticationContext);
  const { pageSize } = useContext(GlobalContext);

  const [socialHistoryData, setSocialHistoryData] = useState(null);
  const [size, setSize] = useState(pageSize);
  const [page, setPage] = useState(0);

  const { loading: socialhistoryLoading, error: socalhistoryError } = useQuery(
    SOCIAL_HISTORY_BYPTID,
    {
      variables: { patient_id: Number(user.person.patient.id) },
      onCompleted: (data) => {
        const results = data.socialHistoryByPtId.map((item) => {
          const obj = {
            id: item.id,
            socialEvent: item.socialEvent,
            result: item.result,
            personalNote: item.personalNote,
          };
          return obj;
        });
        setSocialHistoryData(results);
      },
    }
  );

  const headCells = [
    {
      id: "id",
      numeric: false,
      label: "ID",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "socialEvent",
      numeric: false,
      label: "Social Event",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "result",
      numeric: false,
      label: "Result",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "personalNote",
      numeric: false,
      label: "Personal Note",
      headerAlign: "left",
      align: "left",
    },
  ];

  return (
    <div>
      <Typography variant="body1" gutterBottom component="div">
        <h3>Social History</h3>
      </Typography>
      {socialhistoryLoading && <LoadingIndicator />}
      {socalhistoryError && <ErrorInfo />}
      {socialHistoryData && (
        <TableComponent
          headCells={headCells}
          rows={socialHistoryData}
          rowsPerPage={size}
          setRowsPerPage={setSize}
          page={page}
          setPage={setPage}
          totalSize={socialHistoryData.length}
        />
      )}
    </div>
  );
}
