/*
 * @Author: your name
 * @Date: 2022-02-02 20:59:26
 * @LastEditTime: 2022-03-02 01:38:38
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /cims-dev/packages/UHX/src/pages/patients/pages/Lab-results.page.js
 */
import React, { useState, useContext } from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Button,
  Divider,
  Link,
  Collapse,
  CardActions,
  styled,
  IconButton,
} from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import { ALL_TEST_RESULTS, FIND_LAB_RESULTS_BY_ID } from "@cims/common/";
import { useMutation, useQuery } from "@apollo/client";
import { AuthenticationContext } from "src/services/authentication/authentication.context";
import { shouldCanonizeResults } from "@apollo/client/cache/inmemory/helpers";
import { useHistory, useParams } from "react-router-dom";
import { ThemeContext } from "src/services/theme/theme.context";
import { pink, blue } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ContactsOutlined } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 20,
    // height: "50px",
  },
  addBtnIcon: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
    color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
    "&:hover": {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#BDC0BA" : blue[700],
      color: "#fff",
    },
  },

  btn: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  paper: {
    backgroundColor: "#f5f3f0", //theme.palette.background.paper,
    border: "1px solid #666666",
    width: 700,
    maxHeight: 700,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "auto",
  },

  htyCard: {
    maxWidth: 345,
    marginBottom: "10px",
  },

  cardHeader: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true"
        ? "rgba(0,26,72, 0.3)"
        : "rgba(126,211,205,0.5)",
  },

  divider: {
    backgroundColor: "#d9e2ee",
    margin: "0 20px",
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} size="large" />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function LabResults() {
  const myStyles = useStyles();
  const { patientId } = useParams();
  const history = useHistory();
  const { contextHeaders, user } = useContext(AuthenticationContext);
  const { buttonColor, darkMode } = useContext(ThemeContext);
  const { curPickedPatient } = useContext(AuthenticationContext);
  
  const [expanded, setExpanded] = useState({});

  const handleExpandClick = (index) => {
    setExpanded({
      ...expanded,
      [index]: expanded[index] ? false : true,
    });
    console.log(expanded);
  };

  const {
    data: labResults, 
    loading: labResultsLoading,
    error: labResultsError,
  } = useQuery(FIND_LAB_RESULTS_BY_ID, {
    //variables: { patient_id: patientId },
    variables: { patient_id: Number(user.person.patient.id) },
    context: contextHeaders,
  })
  
  //console.log( "test result patientId ", patientId );
  // console.log( " test result patient_id ", Number(curPickedPatient.patient.id));
  console.log("==> patient_id = " + Number(user.person.patient.id) + ", labResults = " + labResults);

  const transferDate = (str) => {
    return str.substr(0, 4) + "-" + str.substr(4, 2) + "-" + str.substr(6, 2)
  };

  return (
    <Container>

      <Typography variant="body1" gutterBottom component="div">
        <h3>Your Lab Results: </h3>
        <hr></hr>
      </Typography>

      <Grid container spacing={3}>
        {labResults &&
          labResults.findLabResultsById.map((results, id) => {
            return (
              <Grid key={Math.round(Math.random() * 20) - id} item xs={12} sm={6} md={4}>
                <Card className={myStyles.htyCard}>
                  <CardHeader
                     className={myStyles.cardHeader}
                     title={"Lab Result - " + (id + 1)}
                     subheader={`Prescribed Date: ${transferDate(results.dateTimeOfTransactionORC)}`}
                  />
                    <Divider variant={"middle"} className={myStyles.divider} />

                    <CardContent>
                      <Typography variant="body2" color="text.secondary">
                        View more details via clicking button below.
                      </Typography>

                      <Divider variant={"middle"} className={myStyles.divider} />

                      <div style={{ textAlign: "center", padding: 20 }}>
                        <Button
                          onClick={() =>
                            history.push(
                              `/patients/${patientId}/labResultDetails/${id}`
                            )
                          }
                          style={{
                            backgroundColor:
                              localStorage.getItem("isDarkMode") === "true"
                                ? "#828282"
                                : blue[200],
                          }}
                        >
                          {`View Details`}
                        </Button>
                      </div>
                    </CardContent>

                    <Divider variant={"middle"} className={myStyles.divider} />

                    <CardActions disableSpacing>
                      <ExpandMore
                        expand={expanded[results.id] ? true : false}
                        onClick={() => handleExpandClick(results.id)}
                        aria-expanded={expanded}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </CardActions>

                    <Divider variant={"middle"} className={myStyles.divider} />

                    <Collapse
                      in={expanded[results.id] ? true : false}
                      timeout="auto"
                      unmountOnExit
                    >
                      <CardContent>
                        {/* <Typography variant="body2" color="text.secondary">
                          Order Control ORC: {results.orderControlORC}
                        </Typography> */}
                        {/* <Typography variant="body2" color="text.secondary">
                          Placer Order Number ORC: {results.placerOrderNumberORC}
                        </Typography> */}
                        <Typography variant="body2" color="text.secondary">
                          Result Status: {results.resultStatusOBR}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Date Time Of Transaction: {transferDate(results.dateTimeOfTransactionORC)}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Observation Date Time: {transferDate(results.observationDateTimeOBR)}
                        </Typography>
                        {/* <Typography variant="body2" color="text.secondary">
                          Ordering Provider: {results.orderingProviderORC}
                        </Typography> */}
                        {/* <Typography variant="body2" color="text.secondary">
                          Placer Order Number: {results.placerOrderNumberOBR}
                        </Typography> */}
                        <Typography variant="body2" color="text.secondary">
                          Order Number: {results.fillerOrderNumberORB}
                        </Typography>
                        {/* <Typography variant="body2" color="text.secondary">
                          Priority OBR: {results.priorityOBR}
                        </Typography> */}
                        {/* <Typography variant="body2" color="text.secondary">
                          Results Rpt Status Chng Date TimeOBR: {results.resultsRptStatusChngDateTimeOBR}
                        </Typography> */}

                      </CardContent>
                    </Collapse>
                </Card>
              </Grid>
            );
          })}
      </Grid>

    </Container>
  );
}
