import { gql } from "@apollo/client";

export const UPLOAD_MEDICAL_RECORD = gql`
  mutation uploadMedicalRecord(
    $personId: ID!
    $pdfFile: String
    $folderName: String
    $sourceOfData: String
  ) {
    uploadMedicalRecord(
      personId: $personId
      pdfFile: $pdfFile
      folderName: $folderName
      sourceOfData: $sourceOfData
    ) {
      id
      personId
      fileLocation
      timestamp
      sourceOfData
      folder
    }
  }
`;
