import { gql } from "@apollo/client";

export const ADD_STUDIES = gql`
    mutation addStudiesCTsIECriteriasByStudyCTsID ($uId:ID!, $title:String,$protocolNum:Long,$sponsor:String,$inclusionCriteria:String,$exclusionCriteria:String){
        addStudiesCTsIECriteriasByStudyCTsID (uId: $uId, title:$title,protocolNum:$protocolNum,sponsor:$sponsor,inclusionCriteria:$inclusionCriteria,exclusionCriteria:$exclusionCriteria)
        {id,person{firstName,lastName,sex,dob}}
    }
`;

export const GET_STUDIES = gql`
    query getStudiesCTsIECriteriasByStudiesCTsId ($uId:ID!, $page: Int, $size:Int){
        getStudiesCTsIECriteriasByStudiesCTsId (uId:$uId,page:$page,size:$size)
            {totalSize,studiesCTsIECriterias{uId,id,title,protocolNum,sponsor,inclusionCriteria,exclusionCriteria,timestamp}
        }
    }
`;