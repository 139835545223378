import { ApolloClient, InMemoryCache, HttpLink, split } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import { setContext } from "@apollo/client/link/context";
import { uhx_serverUrl, uhx_serverUrlSub } from "@cims/common/";

const httpLink = new HttpLink({
  uri: `${uhx_serverUrl}/graphql`,
});

const wsLink = new WebSocketLink({
  uri: `${uhx_serverUrlSub}/subscriptions`,
  options: {
    reconnect: true,
  },
});

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
export const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

export const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("auth_token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// export default new ApolloClient({
//   link: authLink.concat(splitLink),
//   // link: splitLink,
//   cache: new InMemoryCache(),
//   clientState: {
//     defaults: {
//       tester: "TESTING...",
//       user: null,
//     },
//   },
// });
