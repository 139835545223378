/*
 * @Author: Gengda Li
 * @Date: 2022-01-26 20:21:49
 * @LastEditTime: 2022-03-02 02:43:52
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /cims-dev/packages/UHX/src/pages/patients/pages/Lab-Results-Detail.page.js
 */
import React, { useState, useContext } from "react";
import {
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { FIND_LAB_RESULTS_BY_ID, CustomTable } from "@cims/common/";
import { useQuery } from "@apollo/client";
import { AuthenticationContext } from "src/services/authentication/authentication.context";
import { useHistory, useParams } from "react-router-dom";
import { blue } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 20,
    // height: "50px",
  },
  addBtnIcon: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
    color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
    "&:hover": {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#BDC0BA" : blue[700],
      color: "#fff",
    },
  },

  btn: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  paper: {
    backgroundColor: "#f5f3f0", //theme.palette.background.paper,
    border: "1px solid #666666",
    width: 700,
    maxHeight: 700,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "auto",
  },

  htyCard: {
    maxWidth: 345,
    marginBottom: "10px",
  },

  cardHeader: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true"
        ? "rgba(0,26,72, 0.3)"
        : "rgba(126,211,205,0.5)",
  },

  divider: {
    backgroundColor: "#d9e2ee",
    margin: "0 20px",
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function TestResultDetails() {
  const history = useHistory();

  const { contextHeaders } = useContext(AuthenticationContext);   
  const { curPickedPatient } = useContext(AuthenticationContext);

  // get current test result id
  const currentURL = window.location.href;
  const currentIndex = Number(currentURL.split("/").pop());
  const { patientId } = useParams();

  //query test results
  const {
    data: labResults, 
    loading: labResultsLoading,
    error: labResultsError,
  } = useQuery(FIND_LAB_RESULTS_BY_ID, {
    variables: { patient_id: patientId },
    context: contextHeaders,
  })

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);

  // const headCells= [
  //   {
  //     id: "setIDOBX",
  //     numeric: false,
  //     disablePadding: true,
  //     label: "Set ID",
  //     path: ["findLabResultsById", "obxObservationResult", "setIDOBX"],
  //   },
  // ];

  //jyu const patientId  = Number (curPickedPatient.patient.id);

  console.log( "test result detail patientId ", patientId );
  // console.log( " test result detail patient_id ", Number(curPickedPatient.patient.id));

  const testDelete = (Ids) => {
    console.log("IDs--->", Ids);
  };

  const testView = (Ids) => {
    console.log("IDs--->", Ids);
  };

  return (
    <Container>
      <div>
        <Button
          variant="outlined"
          onClick={() => history.push(`/patients/${patientId}/lab-results`)}
          size="large"
          color="primary"
        >
          Return
        </Button>
      </div>

      <Typography variant="body1" gutterBottom component="div">
        Detailed Test Result:
      </Typography>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {/* <TableCell align="right">Set ID</TableCell> */}
              <TableCell>Observation Identifier</TableCell>
              <TableCell align="right">Units</TableCell>
              <TableCell align="right">References Range</TableCell>
              <TableCell align="right">Abnormal Flags</TableCell>
              <TableCell align="right">Result Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {labResults && labResults.findLabResultsById[
              currentIndex
            ].obxObservationResult.map((currentResult, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {/* <TableCell align="right">
                  {currentResult.setIDOBX}
                </TableCell> */}
                <TableCell component="th" scope="row">
                  {currentResult.observationIdentifierOBX}
                </TableCell>
                <TableCell align="right">
                  {currentResult.unitsOBX}
                </TableCell>
                <TableCell align="right">
                  {currentResult.referencesRangeOBX}
                </TableCell>
                <TableCell align="right">
                  {currentResult.abnormalFlagsOBX}
                </TableCell>
                <TableCell align="right">
                  {currentResult.observResultStatusOBX}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
