import { useMutation } from "@apollo/client";
import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useState } from "react";
import { AppointmentContext } from "../../../services/appointment/appointment.context";
import { INPUT_TIMESLOTS } from "@cims/common";
import {
  convertDate,
  getTimeslots,
  // getEndTime,
} from "../../../utility/commons";
import Slot from "../components/Slot";
import { AuthenticationContext } from "src/services/authentication/authentication.context";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: 20,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radio: {
    marginTop: 20,
  },
  confirmBtn: {
    margin: 20,
    width: 300,
  },
}));

export default function ManageTime() {
  const { curPickedPhysician } = useContext(AuthenticationContext);
  const classes = useStyles();
  const today = new Date();
  const [date, setDate] = useState(convertDate(today));
  // console.log("date--->", date);
  const [timeInterval, setTimeInterval] = useState("30");
  const [address, setAddress] = useState(
    curPickedPhysician.provider.locations[0].id
  );

  const [timeslots, setTimeslots] = useState(getTimeslots(timeInterval));

  const changeStatus = (timeslot) => {
    //change time
    console.log("timeslot--->", timeslot);
    const newTimeslots = timeslots.map((time) => {
      if (time.startTime === timeslot.startTime) {
        time.status = timeslot.status;
      }
      return time;
    });
    setTimeslots(newTimeslots);
  };

  const [inputTimeSlots, { data: inputData, error: inputDataError }] =
    useMutation(INPUT_TIMESLOTS);

  return (
    <Container>
      <Typography
        variant="body1"
        component="p"
        gutterBottom
        style={{ marginBottom: 20 }}
      >
        {`Manage time for ${curPickedPhysician.firstName} ${curPickedPhysician.lastName}`}
      </Typography>

      <form className={classes.container} noValidate>
        <TextField
          id="date"
          label="Pick a date"
          type="date"
          defaultValue={date} //"2017-05-24"
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setDate(e.target.value);
          }}
        />
      </form>

      <div className={classes.radio}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Pick a time interval</FormLabel>
          <RadioGroup
            row
            aria-label="position"
            name="position"
            defaultValue="30"
            onChange={(e) => {
              setTimeInterval(e.target.value);
              setTimeslots(getTimeslots(e.target.value));
            }}
          >
            <FormControlLabel
              value="15"
              control={<Radio color="primary" />}
              label="15"
            />
            <FormControlLabel
              value="30"
              control={<Radio color="primary" />}
              label="30"
            />
            <FormControlLabel
              value="60"
              control={<Radio color="primary" />}
              label="60"
            />
          </RadioGroup>
        </FormControl>
      </div>

      <div className={classes.radio}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Pick a location</FormLabel>
          <RadioGroup
            aria-label="position"
            name="position"
            defaultValue={curPickedPhysician.provider.locations[0].id}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          >
            {curPickedPhysician.provider.locations.map((location) => {
              return (
                <FormControlLabel
                  key={location.id}
                  value={location.id}
                  control={<Radio color="primary" />}
                  label={location.locationName}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </div>

      <Grid container spacing={3} className={classes.radio}>
        {timeslots.map((time, i) => {
          return (
            <Grid key={i} item xs={12} md={4}>
              <Slot time={time} changeStatus={changeStatus} />
            </Grid>
          );
        })}
      </Grid>

      <Button
        size="large"
        className={classes.confirmBtn}
        variant="outlined"
        onClick={(e) => {
          const savedTimeslots = timeslots.map((time) => {
            return {
              ...time,
              startTime: `${date} ${time.startTime}`,
              endTime: `${date} ${time.endTime}`,
            };
          });
          // console.log("savedTimeslots--->", savedTimeslots);
          inputTimeSlots({
            variables: {
              providerId: curPickedPhysician.provider.id,
              locationId: address,
              timeslots: savedTimeslots,
            },
          });
        }}
      >
        Confirm
      </Button>
    </Container>
  );
}
