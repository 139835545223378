import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import { LOCATIONS_BY_APPTID } from "@cims/common";
import { Link, useHistory, useParams } from "react-router-dom";
import { AuthenticationContext } from "../../../services/authentication/authentication.context";
import { AppointmentContext } from "../../../services/appointment/appointment.context";
import {
  Card,
  CardHeader,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import LoadingIndicator from "../../../components/LoadingIndicator";
import ErrorInfo from "../../../components/ErrorInfo";

export default function ScheduleAppointmentLocation() {
  const { subjectId } = useParams();
  const history = useHistory();
  const { contextHeaders, curPickedPatient } = useContext(
    AuthenticationContext
  );
  const { yourAppointment, pickingAppointment } =
    useContext(AppointmentContext);

  const {
    data: locations,
    loading: locationsLoading,
    error: locationsError,
  } = useQuery(LOCATIONS_BY_APPTID, {
    variables: { apptId: subjectId },
    context: contextHeaders,
  });
  // console.log("yourAppointment--->", yourAppointment);
  return (
    <Container size="sm">
      <Typography
        variant="body1"
        component="p"
        gutterBottom
        style={{ marginBottom: 20 }}
      >
        {`Making an appointment for ${curPickedPatient.firstName} ${curPickedPatient.lastName} | Please choose one location`}
      </Typography>

      {locationsLoading && <LoadingIndicator />}
      {locationsError && <ErrorInfo />}

      <Grid container spacing={3}>
        {locations &&
          locations.locationByApptId.map((location) => {
            return (
              <Grid key={location.id} item xs={12} sm={6} md={4}>
                <Link
                  to={`/patients/${curPickedPatient.id}/visits/scheduling/${subjectId}/${location.id}`}
                  onClick={() => {
                    pickingAppointment({
                      location: location.address,
                      locationId: location.id,
                    });
                  }}
                >
                  <Card>
                    <CardHeader
                      titleTypographyProps={{ variant: "body1" }}
                      subheaderTypographyProps={{ variant: "body2" }}
                      title={`${location.locationName}`}
                      subheader={`${location.address}`}
                    />
                  </Card>
                </Link>
              </Grid>
            );
          })}
      </Grid>
    </Container>
  );
}
