/*
 * @Author: your name
 * @Date: 2022-02-02 20:59:10
 * @LastEditTime: 2022-02-03 22:31:52
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /cims-dev/packages/UHX/src/pages/patients/pages/Immunization-details.page.js
 */
/*
 * @Author: your name
 * @Date: 2022-02-02 20:58:53
 * @LastEditTime: 2022-02-02 21:18:02
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /cims-dev/packages/UHX/src/pages/patients/pages/Allergy-details.page.js
 */
//import React, { useContext } from "react";
import React, { useState, useContext } from "react";
import { Typography } from "@mui/material";
import { ALL_IMMUNIZATION_BY_PATIENT_ID } from "@cims/common/";
import { useQuery } from "@apollo/client";
import { AuthenticationContext } from "src/services/authentication/authentication.context";
import { GlobalContext } from "src/services/global/global.context";
import LoadingIndicator from "src/components/LoadingIndicator";
import ErrorInfo from "src/components/ErrorInfo";
import TableComponent from "src/components/TableComponent";

export default function ImmunizationDetails() {
  const { user } = useContext(AuthenticationContext);
  const { pageSize } = useContext(GlobalContext);

  const [immunizationData, setImmunizationData] = useState(null);
  const [size, setSize] = useState(pageSize);
  const [page, setPage] = useState(0);

  const {
    loading: immunizationResultsLoading,
    error: immunizationResultsError,
  } = useQuery(ALL_IMMUNIZATION_BY_PATIENT_ID, {
    variables: { patient_id: Number(user.person.patient.id) },
    onCompleted: (data) => {
      const results = data.allImmunizationByPatientId.map((item) => {
        const obj = {
          id: item.id,
          dataSource: item.dataSource,
          status: item.status,
          vaccine: item.vaccine,
          vaccineDate: item.vaccineDate,
        };
        return obj;
      });
      setImmunizationData(results);
    },
  });

  const headCells = [
    {
      id: "id",
      numeric: false,
      label: "ID",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "dataSource",
      numeric: false,
      label: "Data Source",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "status",
      numeric: false,
      label: "Status",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "vaccine",
      numeric: false,
      label: "Vaccine",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "vaccineDate",
      numeric: false,
      label: "Vaccine Date",
      headerAlign: "left",
      align: "left",
    },
  ];

  return (
    <div>
      <Typography variant="body1" gutterBottom component="div">
        <h3>Immunization Result:</h3>
      </Typography>
      {immunizationResultsLoading && <LoadingIndicator />}
      {immunizationResultsError && <ErrorInfo />}
      {immunizationData && (
        <TableComponent
          headCells={headCells}
          rows={immunizationData}
          rowsPerPage={size}
          setRowsPerPage={setSize}
          page={page}
          setPage={setPage}
          totalSize={immunizationData.length}
        />
      )}
    </div>
  );
}
