import React, { useContext, createContext } from "react";

import { ALL_SCHEDULE_APPT } from "@cims/common";
import { useQuery } from "@apollo/client";

import { AuthenticationContext } from "../authentication/authentication.context";

// import { useLocalStorage } from "../../utility/commons";
import { useLocalStorage } from "@cims/utils";

export const AppointmentContext = createContext();

export const AppointmentContextProvider = ({ children }) => {
  const [yourAppointment, setYourAppointment] = useLocalStorage(
    "yourAppointment",
    {}
  );
  const [isReschedule, setIsReschedule] = useLocalStorage(
    "isReschedule",
    false
  );
  const [rescheduledFrom, setRescheduledFrom] = useLocalStorage(
    "rescheduledFrom",
    ""
  );

  const pickingAppointment = (appt) => {
    setYourAppointment({
      ...yourAppointment,
      ...appt,
    });
  };

  const changeIsReschedule = (status) => {
    setIsReschedule(status);
  };

  const changeRescheduledFrom = (person) => {
    setRescheduledFrom(person);
  };

  //get user id from auth context
  const {
    contextHeaders,
    // user: {
    //   person: { id },
    // },
  } = useContext(AuthenticationContext);

  //query appointment
  const {
    data: appointments,
    loading: appointmentsLoading,
    error: appointmentsError,
  } = useQuery(ALL_SCHEDULE_APPT, {
    context: contextHeaders,
  });

  return (
    <AppointmentContext.Provider
      value={{
        appointments,
        appointmentsLoading,
        appointmentsError,
        yourAppointment,
        isReschedule,
        rescheduledFrom,
        changeIsReschedule,
        pickingAppointment,
        changeRescheduledFrom,
      }}
    >
      {children}
    </AppointmentContext.Provider>
  );
};
