import React, { useContext, useState } from "react";
import { render } from "react-dom";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import "./styles.css";
import { AuthenticationContext } from "../../../services/authentication/authentication.context";
import { serverUrl } from "@cims/common/";

const CustomHeart = () => <span>♥</span>;

var fileName;
var quillObj = null;
var globalUser;

const HtmlHandler = () => {
  const input = document.createElement("input");

  input.setAttribute("type", "file");
  input.setAttribute(
    "accept",
    ".xlsx,.xls,image/*,.doc,.docx,.ppt,.pptx,.txt,.pdf"
  );
  input.click();

  input.onchange = async () => {
    var file: any = input.files[0];
    var formData = new FormData();

    // formData.append('image', file);
    formData.append("file", file);
    fileName = file.name;
    // console.log("fileName", fileName);
    const res = UploadFiles(formData, fileName);
  };
};

function handleHTML() {
  quillObj = this.quill;
  HtmlHandler();

  // const cursorPosition = this.quill.getSelection().index;
  // this.quill.insertText(cursorPosition, "♥");
  // this.quill.setSelection(cursorPosition + 1);
  // this.quill.clipboard.dangerouslyPasteHTML(0, '<div>Test</div><div>is</div>');
}

function handleStrike() {
  quillObj = this.quill;
  console.log("Strike", "...");
}

export const CustomToolbar = ({ user, quillCallBack, fileCallBack }) => {
  const [filename, setFileName] = useState();

  globalUser = user;

  return (
    <div id="toolbar">
      <select className="ql-font">
        <option value="arial" selected>
          Arial
        </option>
        <option value="comic-sans">Comic Sans</option>
        <option value="courier-new">Courier New</option>
        <option value="georgia">Georgia</option>
        <option value="helvetica">Helvetica</option>
        <option value="lucida">Lucida</option>
      </select>
      <select className="ql-size">
        <option value="extra-small">Size 1</option>
        <option value="small">Size 2</option>
        <option value="medium" selected>
          Size 3
        </option>
        <option value="large">Size 4</option>
      </select>
      <select className="ql-align" />
      <select className="ql-color" />
      <select className="ql-background" />
      <button className="ql-clean" />
      {/* <button className="ql-insertHeart">
        <CustomHeart />
      </button> */}
      <button
        className="ql-strike"
        onClick={() => {
          fileCallBack(fileName);
        }}
      />
      <button class="ql-html">PDF</button>
    </div>
  );
};

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida",
];
Quill.register(Font, true);

const Block = Quill.import("blots/block");
Block.tagName = "DIV";
Block.className = "div-inline";
Quill.register(Block, true);

export const modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      html: handleHTML,
      // strike: handleStrike
    },
  },
};

export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];

/*
 * Editor component with custom toolbar and content containers
 */
class Editor extends React.Component {
  state = { editorHtml: "" };

  handleChange = (html) => {
    this.setState({ editorHtml: html });
  };

  render() {
    return (
      <div className="text-editor">
        <CustomToolbar />
        <ReactQuill
          value={this.state.editorHtml}
          onChange={this.handleChange}
          placeholder={this.props.placeholder}
          modules={Editor.modules}
          formats={Editor.formats}
        />
      </div>
    );
  }
}

const EditorToolbar2 = () => {
  return (
    <div className="custom-toolbar-example">
      <h3>Custom Toolbar with React Quill</h3>
      <Editor placeholder={"Write something or insert a heart ♥"} />
    </div>
  );
};

// render(<EditorToolbar2 />, document.getElementById("root"));

const transformValue = (value) => {
  // var handleArr = value.split('\n');
  // handleArr = handleArr.map(e => e.replace(/^[\s]+/, '')
  //   .replace(/[\s]+$/, ''));
  // return handleArr.join('')
  return value.replace(/PDF Document/, "");
};

const UploadFiles = (formData, filename) => {
  const siteUrl = serverUrl;
  const value = localStorage.getItem("auth_token");
  if (value !== null) {
    var token = `Bearer ${value}`;
    // console.log("Authorization", token);

    axios
      .post(
        siteUrl +
          "/api/uploadfile/" +
          globalUser.person.id +
          "/" +
          filename +
          "/pdf",
        formData,
        {
          headers: {
            "Content-Type": "application/form-data",
            Authorization: token,
          },
        }
      )
      .then((response) => {
        console.log("uploaded", true);
        var filename_html =
          filename.substr(0, filename.lastIndexOf(".")) + ".html";

        axios({
          url:
            siteUrl +
            "/api/download/" +
            globalUser.person.id +
            "/" +
            filename_html,
          method: "GET",
          // responseType: 'blob',
          headers: {
            Authorization: token,
          },
        })
          .then((response) => {
            // console.log("response", response);
            // console.log("url", response.config.url);
            console.log("downloaded", true);
            var fileUrl =
              siteUrl +
              "/api/download/" +
              globalUser.person.id +
              "/" +
              filename_html;

            quillObj.clipboard.dangerouslyPasteHTML(
              0,
              transformValue(response.data)
            );

            var content = quillObj.root.innerHTML;
            console.log("content", content);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log("uploaded", false);
      });
  }
};

export default CustomToolbar;
