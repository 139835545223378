import React, { useContext } from "react";
import {
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";

import { ALL_TEST_RESULTS } from "@cims/common/";
import { useQuery } from "@apollo/client";
import { AuthenticationContext } from "src/services/authentication/authentication.context";
import { useHistory, useParams } from "react-router-dom";

export default function TestResultDetails() {
  const history = useHistory();

  const { curPickedPatient } = useContext(AuthenticationContext);
  //query test results
  const {
    data: testresults,
    loading: testresultsLoading,
    error: testresultsError,
  } = useQuery(ALL_TEST_RESULTS, {
    variables: { patient_id: Number(curPickedPatient.patient.id) },
    //variables: { patient_id: 1 },
    // context: contextHeaders,
  });

  const { patientId, itemId } = useParams();
  //jyu const patientId  = Number (curPickedPatient.patient.id);
  // console.log("itemId--->", itemId);
  // console.log("test result detail patientId ", patientId);
  // console.log(
  //   " test result detail patient_id ",
  //   Number(curPickedPatient.patient.id)
  // );

  return (
    <Container>
      <div>
        <Button
          variant="outlined"
          onClick={() => history.push(`/patients/${patientId}/results`)}
          size="large"
          color="primary"
        >
          Return
        </Button>
      </div>

      <Typography variant="body1" gutterBottom component="div">
        Detailed Test Result:
      </Typography>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Value</TableCell>
              <TableCell align="right">Reference</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {testresults?.allTestResultByPatientId
              .filter((item) => item.id === itemId)[0]
              .testresultcomp.map((currentResult, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {currentResult.compName}
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    {currentResult.valueStr !== null
                      ? currentResult.valueStr
                      : currentResult.value}{" "}
                    {currentResult.resultUnit}
                  </TableCell>
                  <TableCell align="right">
                    {currentResult.refRange !== null
                      ? currentResult.refRange
                      : `${currentResult.refRLow} - ${currentResult.refRHigh}`}{" "}
                    {currentResult.resultUnit}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
