import { gql } from "@apollo/client";

export const ALL_SCHEDULE_APPT = gql`
  query AllScheduleAppt {
    allScheduleAppt {
      id
      name
      status
      locations {
        id
        locationName
        address
        provider {
          id
          lastName
          firstName
        }
      }
    }
  }
`;

export const LOCATIONS_BY_APPTID = gql`
  query LocationsByApptId($apptId: ID!) {
    locationByApptId(apptId: $apptId) {
      id
      locationName
      address
      provider {
        id
        lastName
        firstName
      }
    }
  }
`;

export const PROVIDER_BY_ADDRESS = gql`
  query providerByAddress($address: String!) {
    providerByAddress(address: $address) {
      id
      lastName
      firstName
      specialty {
        specialty
      }
    }
  }
`;

export const GET_TIMESLOTS = gql`
  query getApptByStartEndTime(
    $providerId: ID!
    $locationId: ID!
    $startTime: String!
    $endTime: String!
  ) {
    apptByStartEndTime(
      providerId: $providerId
      locationId: $locationId
      startTime: $startTime
      endTime: $endTime
    ) {
      id
      status
      startTime {
        toString
      }
      provider {
        lastName
        firstName
      }
      patient {
        lastName
        firstName
      }
      locations {
        id
        address
        scheduleappt {
          id
          name
        }
      }
    }
  }
`;

export const ADD_ONE_APPT = gql`
  mutation addOneApptPatient(
    $patientId: ID!
    $providerId: ID!
    $apptTime: String!
    $apptLocation: String!
  ) {
    createOneApptPatient(
      patientId: $patientId
      providerId: $providerId
      apptTime: $apptTime
      apptLocation: $apptLocation
    ) {
      id
      startTime {
        toString
      }
      provider {
        lastName
        firstName
      }
      patient {
        lastName
        firstName
      }
      locations {
        id
        address
        scheduleappt {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_APPT = gql`
  mutation updateApptPatientStatusById(
    $apptId: ID!
    $status: String!
    $notes: String
  ) {
    updateApptPatientStatusById(
      apptId: $apptId
      status: $status
      notes: $notes
    ) {
      id
      startTime {
        toString
      }
      provider {
        lastName
        firstName
      }
      patient {
        lastName
        firstName
      }
      locations {
        id
        address
        scheduleappt {
          id
          name
        }
      }
    }
  }
`;

export const RESCHEDULE_APPT = gql`
  mutation rescheduleApptPatient(
    $originalApptId: ID!
    $originalApptTime: String!
    $newApptTime: String!
    $notes: String
  ) {
    rescheduleApptPatient(
      originalApptId: $originalApptId
      originalApptTime: $originalApptTime
      newApptTime: $newApptTime
      notes: $notes
    ) {
      id
      startTime {
        toString
      }
      provider {
        lastName
        firstName
      }
      patient {
        lastName
        firstName
      }
      locations {
        id
        address
        scheduleappt {
          id
          name
        }
      }
    }
  }
`;

export const NOTIFICATIONS_BY_PERSON = gql`
  query allNotificationsByPersonId($person_id: ID!) {
    allNotificationsByPersonId(person_id: $person_id) {
      id
      webLink
      mobileLink
      message
      status
      person {
        id
      }
    }
  }
`;

export const ALL_NOTIFICATIONS = gql`
  query allNotificationsUnRead {
    allNotificationsUnRead {
      id
      webLink
      mobileLink
      message
      status
    }
  }
`;

export const UPDATE_NOTIFICATION = gql`
  mutation updateNotificationStatus($id: ID!, $status: String) {
    updateNotificationStatus(id: $id, status: $status) {
      id
      webLink
      mobileLink
      message
      status
    }
  }
`;

export const CREATE_NOTIFICATION = gql`
  mutation createOneNotification(
    $person_id: ID!
    $message: String
    $webLink: String
    $mobileLink: String
    $status: String
  ) {
    createOneNotification(
      person_id: $person_id
      message: $message
      webLink: $webLink
      mobileLink: $mobileLink
      status: $status
    ) {
      id
      webLink
      mobileLink
      message
      status
    }
  }
`;

export const INPUT_TIMESLOTS = gql`
  mutation inputTimeSlots(
    $providerId: ID!
    $locationId: ID!
    $timeslots: [TimeSlot]
  ) {
    inputTimeSlots(
      providerId: $providerId
      locationId: $locationId
      timeslots: $timeslots
    ) {
      id
      startTime {
        toString
      }
      endTime {
        toString
      }
      status
      provider {
        id
        lastName
        firstName
      }
      locations {
        id
        locationName
      }
    }
  }
`;
