import { gql } from "@apollo/client";

export const UHX_SIGNUP2 = gql`
  mutation signUpUXH(
    $userInfo: CreateUserInput!
    $personInput: CreatePersonInput!
    $passcode: String!
    $role: String
    $consentAgreement: String
    $userSQInput: UserSecurityQuestionInput
  ) {
    signUpUXH(
      userInfo: $userInfo
      personInput: $personInput
      passcode: $passcode
      role: $role
      consentAgreement: $consentAgreement
      userSQInput: $userSQInput
    ) {
      id
      firstName
      lastName
    }
  }
`;

export const UHX_SIGNUP = gql`
  mutation signUpUXH(
    $userInfo: CreateUserInput!
    $personInput: CreatePersonInput!
    $passcode: String!
    $role: String
    $consentAgreement: String
  ) {
    signUpUXH(
      userInfo: $userInfo
      personInput: $personInput
      passcode: $passcode
      role: $role
      consentAgreement: $consentAgreement
    ) {
      id
      firstName
      lastName
    }
  }
`;
