import React, { useContext } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { AppointmentContext } from "../../../services/appointment/appointment.context";
import { PATIENTS_BILLING } from "@cims/common";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@material-ui/data-grid";
import { AuthenticationContext } from "src/services/authentication/authentication.context";
import { blue } from '@mui/material/colors';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "paymentBalance",
    numeric: false,
    disablePadding: true,
    label: "Payment Balance",
  },
  {
    id: "paymentTo",
    numeric: true,
    disablePadding: false,
    label: "Payment To",
  },
  {
    id: "paymentFrom",
    numeric: true,
    disablePadding: false,
    label: "Payment From",
  },
  {
    id: "providerName",
    numeric: true,
    disablePadding: false,
    label: "Provider Name",
  },
];

// BillHisDetailTable
function BillHisDetailTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

BillHisDetailTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const BillHisDetailTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Payment Details
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" size="large">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list" size="large">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

BillHisDetailTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

// =======================================================================
// =======================================================================
// =======================================================================
// =======================================================================
// =======================================================================
// =======================================================================

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  btnCancel: {
    color: "#21B1F6",
    borderColor: blue[300],
    marginTop: 20,
    margin: 10,
  },
  bar: {
    display: "flex",
    marginBottom: 20,
    justifyContent: "space-between",
  },
  title: {
    paddingTop: 5,
    flexGrow: 1,
  },
}));

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function PaymentHisDetailTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const { itemId } = useParams();
  const history = useHistory();
  const { curPickedPatient } = useContext(AuthenticationContext);
  const { data, loading, error } = useQuery(PATIENTS_BILLING, {
    variables: {
      patient_id: Number(curPickedPatient.id),
    },
  });
  const transferDate = (str) => {
    return str.replace("T", " ");
  };

  console.log("data-> ", data);
  console.log("id-> ", itemId);

  let rows, arrId;
  for (let i = 0; i < data.allBillingSummaryByPtId.length; i++) {
    if (data.allBillingSummaryByPtId[i].id === itemId) {
      rows = data.allBillingSummaryByPtId[i].addPayDetail;
      arrId = i;
      break;
    }
  }

  console.log("rows: ", rows);

  const columns = [
    {
      field: "paymentBalance",
      headerName: "Payment Balance",
      // type: 'number',
      width: 170,
      editable: false,
    },
    // {
    //   field: 'serviceDate.toString',
    //   headerName: 'Service Date',
    //   type: 'string',
    //   width: 150,
    //   editable: false,
    // },
    {
      field: "paymentTo",
      headerName: "Payment To",
      width: 170,
      editable: false,
    },
    {
      field: "paymentFrom",
      headerName: "Payment From",
      width: 170,
      editable: false,
    },
    {
      field: "providerName",
      headerName: "Provider Name",
      width: 200,
      editable: false,
    },
  ];

  console.log("columns: ", columns);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, name) => {};

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <div className={classes.bar}>
        <Typography
          variant="body1"
          className={classes.title}
          // color="textSecondary"
          component="h2"
          gutterBottom
        >
          {`${curPickedPatient.firstName} ${
            curPickedPatient.lastName
          }'s payment detail on ${transferDate(
            data.allBillingSummaryByPtId[arrId].paymentDueDate.toString
          )}`}
        </Typography>
      </div>
      <div style={{ width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 25]}
          disableSelectionOnClick={false}
          autoHeight={true}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>

      <div style={{ textAlign: "center" }}>
        <Button
          variant="outlined"
          onClick={() =>
            history.push(`/patients/${curPickedPatient.id}/paymentHistory`)
          }
          size="large"
          className={classes.btnCancel}
        >
          Back To History
        </Button>
        <Button
          variant="outlined"
          onClick={() =>
            history.push(`/patients/${curPickedPatient.id}/billingOverview`)
          }
          size="large"
          className={classes.btnCancel}
        >
          Back To Overview
        </Button>
      </div>
    </div>
  );
}
