import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import NoteOutlinedIcon from "@mui/icons-material/NoteOutlined";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import HistoryIcon from "@mui/icons-material/History";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import HttpsIcon from "@mui/icons-material/Https";
import BrightnessAuto from "@mui/icons-material/BrightnessAuto";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import SecurityIcon from "@mui/icons-material/Security";
import NoEncryptionIcon from "@mui/icons-material/NoEncryption";
import PostAddIcon from "@mui/icons-material/PostAdd";
import PanToolOutlinedIcon from "@mui/icons-material/PanToolOutlined";
import AssistantOutlinedIcon from "@mui/icons-material/AssistantOutlined";
import ColorizeIcon from "@mui/icons-material/Colorize";
import FaceIcon from "@mui/icons-material/Face";
import FilterFramesIcon from "@mui/icons-material/FilterFrames";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import HealingIcon from "@mui/icons-material/Healing";
import VpnKeyIcon from "@mui/icons-material/VpnKey";

export const PATIENT_MENUS = [
  {
    id: 1,
    menu: "Medical Records",
    name: "medical_records",
  },
  {
    id: 2,
    menu: "View HL7 C-CDA Data",
    name: "view_hl7",
  },
  {
    id: 3,
    menu: "Test Results",
    name: "test_results",
  },
  {
    id: 4,
    menu: "Medications",
    name: "medications",
  },
  {
    id: 5,
    menu: "Questionnaires",
    name: "questionnaires",
  },
  {
    id: 6,
    menu: "Medical/Family History",
    name: "medical_history",
  },
  {
    id: 7,
    menu: "My Phamacies",
    name: "my_phamacies",
  },
  {
    id: 8,
    menu: "Billing Summary",
    name: "billing_summary",
  },
  {
    id: 9,
    menu: "Insurance Summary",
    name: "insurance_summary",
  },
  {
    id: 10,
    menu: "Shared Physicians",
    name: "Shared_Physicians",
  },
  {
    id: 11,
    menu: "HL7 Lab Result",
    name: "HL7_Lab_Result",
  },
  {
    id: 12,
    menu: "Clinical Notes",
    name: "Clinical_Notes",
  },
  {
    id: 13,
    menu: "Allergy",
    name: "Allergy",
  },
  {
    id: 14,
    menu: "Immunization Record",
    name: "Immunization_Record",
  },
];

const menu_items = [
  {
    groupName: "Patients",
    open: "patients",
    items: [
      {
        id: 1,
        text: "Medical Record",
        for: "patients",
        icon: <PostAddIcon />,
        path: "/upload-medicalrecord",
      },
      {
        id: 2,
        text: "View HL7 C-CDA Data",
        for: "patients",
        icon: <PostAddIcon />,
        path: "/view-medicalrecord",
      },
      {
        id: 3,
        text: "Test Results",
        for: "patients",
        icon: <EventNoteOutlinedIcon />,
        path: "/results",
      },
      {
        id: 4,
        text: "Medications",
        for: "patients",
        icon: <NoteOutlinedIcon />,
        path: "/medications",
      },
      {
        id: 4,
        text: "Vital Sign",
        for: "patients",
        icon: <NoteOutlinedIcon />,
        path: "/vitalsign",
      },
      {
        id: 4,
        text: "Demographic",
        for: "patients",
        icon: <NoteOutlinedIcon />,
        path: "/demographic",
      },
      {
        id: 10,
        text: "Shared Physicians",
        for: "patients",
        icon: <FaceIcon />,
        path: "/shared-physicians",
      },
      {
        id: 11,
        text: "HL7 Lab Result",
        for: "patients",
        icon: <HealingIcon />,
        path: "/lab-results",
      },
      {
        id: 12,
        text: "Clinical Notes",
        for: "patients",
        icon: <FilterFramesIcon />,
        path: "/clinical-notes",
      },
      {
        id: 13,
        text: "Allergy",
        for: "patients",
        icon: <FlashOnIcon />,
        path: "/allergy-results",
      },
      {
        id: 14,
        text: "Immunization Record",
        for: "patients",
        icon: <ColorizeIcon />,
        path: "/immunization-results",
      },
      {
        id: 5,
        text: "Qustionnaires",
        for: "patients",
        icon: <LiveHelpOutlinedIcon />,
        path: "/questionnaires",
      },
      {
        id: 6,
        text: "Medical/Family History",
        for: "medicalandfamilyhistory",
        open: "medicalandfamilyhistory",
        icon: <TimelineOutlinedIcon />,

        items: [
          {
            text: "Social History",
            for: "medicalandfamilyhistory",
            icon: <AssistantOutlinedIcon />,
            path: "/socialhistory",
          },

          {
            text: "Medical History",
            for: "medicalandfamilyhistory",
            icon: <LocalHospitalOutlinedIcon />,
            path: "/medicalhistory",
          },

          {
            text: "Surgical History",
            for: "medicalandfamilyhistory",
            icon: <PanToolOutlinedIcon />,
            path: "/surgicalhistory",
          },
        ],
      },
      {
        id: 7,
        text: "My Phamacies",
        for: "patients",
        icon: <StoreOutlinedIcon />,
        path: "/phamacies",
      },
      {
        id: 8,
        text: "Billing Summary",
        for: "billing",
        open: "billing",
        icon: <CreditCardIcon />,
        // path: "/billing",
        // path: "/billingOverview",
        items: [
          {
            text: "Overview",
            for: "billing",
            icon: <CreditCardIcon />,
            path: "/billingOverview",
          },
          {
            text: "Billing History",
            for: "billing",
            icon: <HistoryIcon />,
            path: "/paymentHistory",
          },
          {
            text: "Account Info",
            for: "billing",
            icon: <SupervisorAccountIcon />,
            path: "/billingInfo",
          },
          // {
          //   text: "Add a New Invoice",
          //   for: "billing",
          //   icon: <MonetizationOnIcon />,
          //   path: "/addPayment",
          // },
        ],
      },
      {
        id: 9,
        text: "Insurance Summary",
        for: "patients",
        icon: <RecentActorsIcon />,
        path: "/insurance",
      },
    ],
  },
  {
    groupName: "Clinical Studies",
    open: "clinicalstudies",
    items: [
      {
        text: "Clinical Trials",
        open: "clinicaltrials",
        for: "clinicaltrials",
        icon: <PersonOutlineOutlinedIcon />,
        items: [
          {
            text: "Patient Matcher",
            for: "clinicalstudies",
            icon: <LockOutlinedIcon />,
            path: "/clinicalstudies/patientmatcher",
          },
          {
            text: "Study Search",
            for: "clinicalstudies",
            icon: <LockOutlinedIcon />,
            path: "/clinicalstudies/studysearch",
          },
        ],
      },
    ],
  },

  {
    groupName: "Physicians",
    open: "physicians",
    items: [
      {
        text: "Schedules",
        for: "physicians",
        icon: <PersonOutlineOutlinedIcon />,
        path: "/physicianschedule",
      },
      {
        text: "Patient Matcher",
        open: "patientmatcher",
        for: "patientmatcher",
        icon: <PersonOutlineOutlinedIcon />,
        items: [
          {
            text: "Clincal Trials",
            for: "patientmatcher",
            icon: <LockOutlinedIcon />,
            path: "/clincaltrials",
          },
        ],
      },
    ],
  },
  {
    groupName: "Communication",
    open: "communication",
    items: [
      {
        text: "Messages",
        icon: <MessageOutlinedIcon />,
        path: "/communication/messages",
      },
      {
        text: "Contact your Care Team",
        icon: <ContactPhoneOutlinedIcon />,
        path: "/communication/contactTeam",
      },
    ],
  },
  {
    groupName: "Settings",
    open: "settings",
    for: "settings",
    items: [
      {
        text: "Personal Information",
        for: "settings",
        icon: <AccountBoxOutlinedIcon />,
        path: "/personinfo",
      },
      {
        text: "Account Settings",
        open: "accountsettings",
        for: "accountsettings",
        icon: <SettingsOutlinedIcon />,
        // path: "/settings",
        items: [
          {
            text: "Change Password",
            for: "settings",
            icon: <LockOutlinedIcon />,
            path: "/settings/password",
          },
          {
            text: "Change Passcode",
            for: "settings",
            icon: <VpnKeyOutlinedIcon />,
            path: "/settings/passcode",
          },
          {
            text: "Upload File",
            for: "settings",
            icon: <CloudUploadOutlinedIcon />,
            path: "/settings/uploadfile",
          },
          {
            text: "Open 2-Factor Authentication",
            for: "settings",
            icon: <VpnKeyIcon />,
            path: "/settings/enabletwofa",
          },
        ],
      },
    ],
  },
  {
    groupName: "Utilities",
    open: "utilities",
    for: "utilities",
    items: [
      {
        text: "Data Protection",
        open: "dataprotection",
        for: "dataprotection",
        icon: <EnhancedEncryptionIcon />,
        items: [
          {
            text: "Manual Mask Data",
            for: "utilities",
            icon: <LockOutlinedIcon />,
            path: "/utilities/protection/manual-masking",
          },
          {
            text: "Auto Mask Data",
            for: "utilities",
            icon: <BrightnessAuto />,
            path: "/utilities/protection/auto-masking",
          },
          {
            text: "Encrypt File",
            for: "utilities",
            icon: <HttpsIcon />,
            path: "/utilities/protection/encrypt-files",
          },
          {
            text: "Decrypt File",
            for: "utilities",
            icon: <LockOpenIcon />,
            path: "/utilities/protection/decrypt-files",
          },
          // {
          //   text: "Encryption Tool",
          //   for: "utilities",
          //   icon: <BuildIcon />,
          //   path: "/utilities/protection/encrypt-tool",
          // },
          {
            text: "Encrypt File with Temporary Passcode",
            for: "utilities",
            icon: <SecurityIcon />,
            path: "/utilities/protection/encrypt-file-expiration",
          },
          {
            text: "Decrypt File with Temporary Passcode",
            for: "utilities",
            icon: <NoEncryptionIcon />,
            path: "/utilities/protection/decrypt-file-expiration",
          },
          {
            text: "Two-Factor Authentication",
            for: "utilities",
            icon: <VpnKeyIcon />,
            path: "/utilities/protection/two-factor-authentication",
          },
          {
            text: "Authorize Access",
            for: "utilities",
            icon: <SupervisedUserCircleIcon />,
            path: "/utilities/protection/authorize-access",
          },
        ],
      },
    ],
  },
];

export default menu_items;
