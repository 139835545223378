import React, { useContext } from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Button,
  Divider,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from "@apollo/client";
import { Link, useHistory, useParams } from "react-router-dom";
import { PATIENTS_BILLING } from "@cims/common";
import { AppointmentContext } from "../../../services/appointment/appointment.context";
import LoadingIndicator from "../../../components/LoadingIndicator";
import ErrorInfo from "../../../components/ErrorInfo";
import { convertDate } from "../../../utility/commons";
import { AuthenticationContext } from "src/services/authentication/authentication.context";
import { blue } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  bar: {
    display: "flex",
    marginBottom: 20,
  },
  title: {
    flexGrow: 1,
  },
  cards: {
    display: "flex",
    marginBottom: 200,
    justifyContent: "space-between",
  },
  htyCard: {
    maxWidth: 345,
    marginBottom: "10px",
  },
  root: {
    maxWidth: 345,
    marginBottom: "10px",
    textAlign: "center",
  },
  divider: {
    backgroundColor: "#d9e2ee",
    margin: "0 20px",
  },
  payBtn: {
    backgroundColor: "#EB7A77",
    height: 175,
    width: 345,
    fontSize: 25,
  },
  detDivider: {
    backgroundColor: "#d9e2ee",
    margin: theme.spacing(2),
  },
  cardHeader: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true"
        ? "rgba(0,26,72, 0.3)"
        : "rgba(126,211,205,0.5)",
  },
  cardHeaderM: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true"
        ? "rgba(0,26,72, 0.7)"
        : "#FAEBD7",
  },
}));

export default function BillingOverview() {
  const classes = useStyles();
  const { patientId } = useParams();
  const history = useHistory();
  const { curPickedPatient } = useContext(AuthenticationContext);

  // const addManual = true;
  const addManual = false;

  const { data, loading, error } = useQuery(PATIENTS_BILLING, {
    variables: {
      patient_id: Number(curPickedPatient.id),
    },
  });

  const today = new Date();
  const date = convertDate(today);

  const transferDate = (str) => {
    return str.replace("T", " ");
  };

  return (
    <Container>
      <div className={classes.bar}>
        <Typography
          variant="body1"
          className={classes.title}
          component="h2"
          gutterBottom
        >
          {`${curPickedPatient.firstName} ${curPickedPatient.lastName}'s payment history:`}
        </Typography>
      </div>

      {loading && <LoadingIndicator />}
      {error && <ErrorInfo />}

      <Grid container spacing={3}>
        {data &&
          data.allBillingSummaryByPtId.length !== 0 &&
          data.allBillingSummaryByPtId
            .filter((item) => {
              return item.addPayManually !== true;
            })
            .map((item, billingDetailId) => {
              return (
                <Grid key={item.billDetail.id} item xs={12} sm={6} md={4}>
                  <Card className={classes.htyCard}>
                    <CardHeader
                      title="Patient Payment"
                      subheader={transferDate(
                        data.allBillingSummaryByPtId[billingDetailId]
                          .paymentDueDate.toString
                      )}
                      titleTypographyProps={{ variant: "body1" }}
                      subheaderTypographyProps={{ variant: "body2" }}
                      className={classes.cardHeader}
                    />

                    <Divider variant={"middle"} className={classes.divider} />

                    <CardContent>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {`Total Balance: ${data.allBillingSummaryByPtId[billingDetailId].totalBalance}`}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {`Service Department: ${item.billDetail[0].serviceDept}`}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {`Provider Name: ${item.billDetail[0].providerName}`}
                      </Typography>

                      <Divider className={classes.detDivider} />

                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {`Amount Due Now: ${data.allBillingSummaryByPtId[billingDetailId].amountDueNow}`}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {`Insurance: ${item.billDetail[0].insuranceAdjustment}`}
                      </Typography>

                      <Divider className={classes.detDivider} />

                      <Link
                        to={`/patients/${patientId}/paymentHistory/${parseInt(
                          item.id
                        )}`}
                      >
                        <div style={{ textAlign: "center" }}>
                          {addManual ? (
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                            >
                              {`This record is added manually by ${curPickedPatient.firstName} ${curPickedPatient.lastName}.`}
                            </Typography>
                          ) : (
                            <Button
                              style={{
                                backgroundColor:
                                  localStorage.getItem("isDarkMode") === "true"
                                    ? "#828282"
                                    : blue[200],
                              }}
                            >
                              {`View Details`}
                            </Button>
                          )}
                        </div>
                      </Link>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
      </Grid>

      <Divider variant={"middle"} className={classes.divider} />

      <div style={{ paddingTop: "15px" }}>
        <Typography variant="h5" gutterBottom>
          Recent Payments
        </Typography>
      </div>

      <Grid container spacing={3}>
        {data &&
          data.allBillingSummaryByPtId.length !== 0 &&
          data.allBillingSummaryByPtId
            .filter((item, billingDetailId) => {
              return item.addPayManually === true;
            })
            .map((item, billingDetailId) => {
              return (
                <Grid key={item.billDetail.id} item xs={12} sm={6} md={4}>
                  <Card className={classes.htyCard}>
                    <CardHeader
                      title="Patient Payment"
                      subheader={transferDate(item.paymentDueDate.toString)}
                      titleTypographyProps={{ variant: "body1" }}
                      subheaderTypographyProps={{ variant: "body2" }}
                      className={
                        item.addPayManually
                          ? classes.cardHeaderM
                          : classes.cardHeader
                      }
                    />
                    <Divider variant={"middle"} className={classes.divider} />
                    <CardContent>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {`Payment Balance: ${item.addPayDetail[0].paymentBalance}`}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {`Payment To: ${item.addPayDetail[0].paymentTo}`}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {`Payment From: ${item.addPayDetail[0].paymentFrom}`}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {`Provider Name: ${item.addPayDetail[0].providerName}`}
                      </Typography>

                      <Divider className={classes.detDivider} />

                      <Link
                        to={`/patients/${patientId}/paymentDetail/${parseInt(
                          item.id
                        )}`}
                      >
                        <div style={{ textAlign: "center" }}>
                          {addManual ? (
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                            >
                              {`This record is added manually by ${curPickedPatient.firstName} ${curPickedPatient.lastName}.`}
                            </Typography>
                          ) : (
                            <Button
                              style={{
                                backgroundColor:
                                  localStorage.getItem("isDarkMode") === "true"
                                    ? "#828282"
                                    : blue[200],
                              }}
                            >
                              {`View Details`}
                            </Button>
                          )}
                        </div>
                      </Link>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
      </Grid>
    </Container>
  );
}
