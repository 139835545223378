import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import { blue } from "@mui/material/colors";
import { serverUrl } from "@cims/common/";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    marginBottom: "10px",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: blue[100],
  },
}));

export default function PersonCard({ person = {} }) {
  const classes = useStyles();

  const {
    firstName = "Jack",
    lastName = "Chan",
    avatar = `${serverUrl}/avatar/john-doe.jpg`,
  } = person;
  // console.log("person--->", person);

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={<Avatar className={classes.avatar} src={`${avatar}`} />}
        title={`${firstName} ${lastName}`}
        subheader={`${
          person.provider &&
          person.provider.specialty &&
          person.provider.specialty.length !== 0
            ? person.provider.specialty[0].specialty
            : ""
        } ${
          person.provider &&
          person.provider.locations &&
          person.provider.locations.length !== 0
            ? person.provider.locations[0].zipCode
            : ""
        }`}
      />
    </Card>
  );
}
