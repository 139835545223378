import React from "react";
import { TextField } from '@mui/material';

export default function Date(props) {

  const { name, label, value,error=null, onChange } = props;
  
  return (
      <TextField
          variant="outlined"
          label={label}
          name={name}
          value={value}
          onChange={onChange}
          type="datetime-local"
          InputLabelProps={{
            shrink: true,
          }}
          {...(error && {error:true,helperText:error})}
          style={{width: "250px"}}
      />
  )
}
