import { gql } from "@apollo/client";

export const eBinder_GET_USER = gql`
  query GetUser {
    user @client
  }
`;

export const eBinder_GET_CURSTUDY = gql`
  query GetCurStudy {
    curStudy @client
  }
`;

export const eBinder_GET_CURSITE = gql`
  query GetCurSite {
    curSite @client
  }
`;

export const eBinder_GET_CURSUBJECT = gql`
  query GetCurSubject {
    curSubject @client
  }
`;

export const eBinder_GET_CURVISIT = gql`
  query GetCurVisit {
    curVisit @client
  }
`;

export const eBinder_GET_CURFORM = gql`
  query GetCurForm {
    curForm @client
  }
`;

//{"studyId":1}
export const eBinder_GET_STUDY_MENU_MASK = gql`
  query getCurrentStudySourceModel($studyId: Long) {
    getCurrentStudySourceModel(studyId: $studyId) {
      Id
      studyName
      folderColor
      siteSourceModelList {
        Id
        siteName
        folderColor
        subjectInfoSourceModelList {
          Id
          subjectId
          folderColor
          formMaskedSourceModelList {
            Id
            formName
            formCode
            maskedSourceFileList {
              id
              fileName
              location
            }
          }
          visitMaskedSourceModelList {
            Id
            visitName
            visitNo
            visitIndex
            folderColor
            visitType
            formMaskedSourceModelList {
              Id
              formName
              formCode
              formId
              studyFormId
              folderColor
              maskedSourceFileList {
                id
                fileName
                location
              }
            }
          }
        }
      }
    }
  }
`;

//{"studyId":1}
export const eBinder_GET_STUDY_MENU = gql`
  query getCurrentStudyGeneralModel($studyId: Long) {
    getCurrentStudyGeneralModel(studyId: $studyId) {
      Id
      studyName
      siteGeneralModelList {
        Id
        siteName
        siteNo
        subjectInfoGeneralModelList {
          Id
          subjectId
          sourceDataTreeVisitGeneralModelList {
            visitName
            visitNo
            visitIndex
            visitType
            studyVisitMarker
            studyFormList {
              id
              formName
            }
          }
          studyFormList {
            id
            formName
            studyVisit {
              id
              visitName
            }
          }
        }
      }
    }
  }
`;

export const eBinder_GET_ALL_STUDY_NAMES = gql`
  query getAllStudy($page: Int!, $size: Int!) {
    getAllStudy(page: $page, size: $size) {
      id
      studyName
    }
  }
`;

export const eBinder_GET_ALL_STUDIES = gql`
  query getAllStudyPage($page: Int!, $size: Int!) {
    getAllStudyPage(page: $page, size: $size) {
      totalSize
      studies {
        id
        studyName
        studyStatus
        studyDescription
        sponsorName
        protocol
        protocolDescription
        studyCreateTime {
          toString
        }
      }
    }
  }
`;

// {"page": 0, "size": 10}
export const eBinder_GET_STUDIES_BY_USER = gql`
  query getAllStudyWithUserRolePage($page: Int!, $size: Int!) {
    getAllStudyWithUserRolePage(page: $page, size: $size) {
      totalSize
      studies {
        study {
          id
          studyName
          protocol
          studyStatus
          sponsorName
          studyDescription
          protocolDescription
          studyCreateTime {
            toString
          }
        }
        userRoleList
      }
    }
  }
`;

//{"studyId": 1, "page": 0, "size": 10}
export const eBinder_GET_SITES = gql`
  query getAllSitePageByStudyId($studyId: Long, $page: Int!, $size: Int!) {
    getAllSitePageByStudyId(studyId: $studyId, page: $page, size: $size) {
      totalSize
      sites {
        id
        siteAddress
        siteContact
        siteCountry
        siteCreateTime {
          toString
        }
        siteUpdateTime {
          toString
        }
        siteDescription
        siteEmail
        siteName
        sitePhone
        siteStatus
      }
    }
  }
`;
export const eBinder_GET_SITES_NAME = gql`
  query getAllSitePageByStudyId($studyId: Long, $page: Int!, $size: Int!) {
    getAllSitePageByStudyId(studyId: $studyId, page: $page, size: $size) {
      sites {
        id
        siteName
      }
    }
  }
`;

//{"siteId": 1, "page": 0, "size": 1}
export const eBinder_GET_ALL_SUBJECT_INFO_BY_SITEID = gql`
  query getAllSubjectInfoPageBySiteId($siteId: Long, $page: Int!, $size: Int!) {
    getAllSubjectInfoPageBySiteId(siteId: $siteId, page: $page, size: $size) {
      totalSize
      subjectInfos {
        id
        emrId
        randomId
        subjectId
        investigatorId
        sex
        age
        enrollDate {
          toString
        }
        createDate {
          toString
        }
        updateDate {
          toString
        }
        screening
        visit {
          id
        }
      }
    }
  }
`;

//{"subjectinfoId": 2, "page": 0, "size": 10}
export const eBinder_GET_ALL_VISITS_BY_SUBJECTINFOID = gql`
  query getAllVisitPageBySubjectInfoId(
    $subjectInfoId: Long
    $page: Int!
    $size: Int!
  ) {
    getAllVisitPageBySubjectInfoId(
      subjectInfoId: $subjectInfoId
      page: $page
      size: $size
    ) {
      totalSize
      visitModels {
        id
        studyVisitId
        sequenceNo
        subjectInfoId
        visitCreateTime {
          toString
        }
        visitUpdateTime {
          toString
        }
        visitName
        visitNo
        studyId
      }
    }
  }
`;

//{"visitId": 1, "page": 0, "size": 10}
export const eBinder_GET_FORMS = gql`
  query getAllFormsByVisitId($visitId: Long, $page: Int!, $size: Int!) {
    getAllFormsByVisitId(visitId: $visitId, page: $page, size: $size) {
      id
      studyFormId
      formStatus
      sequenceNo
      formCreateTime {
        toString
      }
      formUpdateTime {
        toString
      }
      formName
      formCode
      infoType
    }
  }
`;

//{"formId": 2}
export const eBinder_GET_FORMDETAILS = gql`
  query getAllFileLocationByFormId($formId: Long) {
    getAllFileLocationByFormId(formId: $formId) {
      id
      lastOperatorId
      lastOperatorTime {
        toString
      }
      location
    }
  }
`;

export const eBinder_GET_ALL_SITE_BY_STUDYID_AND_USERID = gql`
  query getAllSiteByStudyIdAndUserId($studyId: Long, $userId: Long) {
    getAllSiteByStudyIdAndUserId(studyId: $studyId, userId: $userId) {
      id
      siteName
      siteCreateTime {
        toString
      }
      siteUpdateTime {
        toString
      }
      siteStatus
      siteDescription
      siteCountry
    }
  }
`;

/*
{"studyName": "study 345", 
"sponsorName": "sponsor C", 
"studyStatus": "enable", 
"studyDescription": "test", 
"protocol": "protocol ABC", 
"protocolDescription": "ABC", 
"operatorId": 1, 
"operatorIP": "0.0.0.0", 
"operatorBrowser": "Chrome"}
*/
export const eBinder_ADD_NEW_STUDY = gql`
  mutation addNewStudy(
    $studyName: String
    $sponsorName: String
    $studyStatus: String
    $studyDescription: String
    $protocol: String
    $protocolDescription: String
    $operatorId: Long
    $operatorIP: String
    $operatorBrowser: String
  ) {
    addNewStudy(
      studyName: $studyName
      sponsorName: $sponsorName
      studyStatus: $studyStatus
      studyDescription: $studyDescription
      protocol: $protocol
      protocolDescription: $protocolDescription
      operatorId: $operatorId
      operatorIP: $operatorIP
      operatorBrowser: $operatorBrowser
    ) {
      id
      studyName
      studyStatus
      studyDescription
      sponsorName
      protocol
      protocolDescription
    }
  }
`;

/*
{
"studyId": 1,
"studyName": "study 345", 
"sponsorName": "sponsor C", 
"studyStatus": "enable", 
"studyDescription": "test", 
"protocol": "protocol ABC", 
"protocolDescription": "ABC", 
"operatorId": 1, 
"operatorIP": "0.0.0.0", 
"operatorBrowser": "Chrome"
}
*/
export const eBinder_EDIT_STUDY = gql`
  mutation updateStudy(
    $studyId: Long
    $studyName: String
    $sponsorName: String
    $studyStatus: String
    $studyDescription: String
    $protocol: String
    $protocolDescription: String
    $operatorId: Long
    $operatorIP: String
    $operatorBrowser: String
  ) {
    updateStudy(
      studyId: $studyId
      studyName: $studyName
      sponsorName: $sponsorName
      studyStatus: $studyStatus
      studyDescription: $studyDescription
      protocol: $protocol
      protocolDescription: $protocolDescription
      operatorId: $operatorId
      operatorIP: $operatorIP
      operatorBrowser: $operatorBrowser
    ) {
      id
      studyName
      studyStatus
      studyDescription
      sponsorName
      protocol
      protocolDescription
    }
  }
`;

/*
{"studyId": 5, 
"siteName": "UK001", 
"siteStatus": "disable", 
"siteDescription": "2333", 
"siteCountry": "UK", 
"siteContact": "tester", 
"sitePhone": "1234567890", 
"siteAddress": "earth", 
"siteEmail": "123@qwe.com", 
"organizationId": 2, 
"operatorId": 1, 
"operatorIP": "0.0.0.0", 
"operatorBrowser": "chrome"}*/
export const eBinder_ADD_NEW_SITE = gql`
  mutation addNewSite(
    $studyId: Long
    $siteName: String
    $siteStatus: String
    $siteDescription: String
    $siteCountry: String
    $siteContact: String
    $sitePhone: String
    $siteAddress: String
    $siteEmail: String
    $organizationId: Long
    $operatorId: Long
    $operatorIP: String
    $operatorBrowser: String
  ) {
    addNewSite(
      studyId: $studyId
      siteName: $siteName
      siteStatus: $siteStatus
      siteDescription: $siteDescription
      siteCountry: $siteCountry
      siteContact: $siteContact
      sitePhone: $sitePhone
      siteAddress: $siteAddress
      siteEmail: $siteEmail
      organizationId: $organizationId
      operatorId: $operatorId
      operatorIP: $operatorIP
      operatorBrowser: $operatorBrowser
    ) {
      id
      siteName
      sitePhone
      siteEmail
      siteStatus
      siteCountry
      siteContact
      siteAddress
      siteCreateTime {
        toString
      }
      siteUpdateTime {
        toString
      }
      subjectInfo {
        id
      }
    }
  }
`;

/** 
 {"siteId": 2, 
 "studyId": 5, 
 "siteName": "US01", 
 "siteStatus": "disable", 
 "siteDescription": "21321312", 
 "siteCountry": "US", 
 "siteContact": "test", 
 "sitePhone": "3214567890", 
 "siteAddress": "eartheq", 
 "siteEmail": "123r@qwe.com", 
 "organizationId": 2, 
 "operatorId": 1, 
 "operatorIP": "128.0.0.0", 
 "operatorBrowser": "chrome"}
*/
export const eBinder_EDIT_SITE = gql`
  mutation updateSite(
    $siteId: Long
    $studyId: Long
    $siteName: String
    $siteStatus: String
    $siteDescription: String
    $siteCountry: String
    $siteContact: String
    $sitePhone: String
    $siteAddress: String
    $siteEmail: String
    $organizationId: Long
    $operatorId: Long
    $operatorIP: String
    $operatorBrowser: String
  ) {
    updateSite(
      siteId: $siteId
      studyId: $studyId
      siteName: $siteName
      siteStatus: $siteStatus
      siteDescription: $siteDescription
      siteCountry: $siteCountry
      siteContact: $siteContact
      sitePhone: $sitePhone
      siteAddress: $siteAddress
      siteEmail: $siteEmail
      organizationId: $organizationId
      operatorId: $operatorId
      operatorIP: $operatorIP
      operatorBrowser: $operatorBrowser
    ) {
      id
      siteName
      sitePhone
      siteEmail
      siteStatus
      siteCountry
      siteContact
      siteAddress
      siteCreateTime {
        toString
      }
      siteUpdateTime {
        toString
      }
      subjectInfo {
        id
      }
    }
  }
`;

/**
 "studyId": 5, 
 "siteId": 2, 
 "sex": "male", 
 "age": "25", 
 "screening": "true", 
 "randomId": 123, 
 "emrId": 234, 
 "subjectId": "UK01-001", 
 "investigatorId": 1, 
 "enrollDate": "2022-04-22 12:00", 
 "operatorId": 1, 
 "operatorIP": "0.0.0.0", 
 "operatorBrowser": "chrome"
 */
export const eBinder_ADD_NEW_SUBJECTINFO = gql`
  mutation addNewSubjectInfo(
    $studyId: Long
    $siteId: Long
    $sex: String
    $age: String
    $screening: String
    $randomId: Long
    $emrId: Long
    $subjectId: String
    $investigatorId: Long
    $enrollDate: String
    $operatorId: Long
    $operatorIP: String
    $operatorBrowser: String
  ) {
    addNewSubjectInfo(
      studyId: $studyId
      siteId: $siteId
      sex: $sex
      age: $age
      screening: $screening
      randomId: $randomId
      emrId: $emrId
      subjectId: $subjectId
      investigatorId: $investigatorId
      enrollDate: $enrollDate
      operatorId: $operatorId
      operatorIP: $operatorIP
      operatorBrowser: $operatorBrowser
    ) {
      id
      emrId
      randomId
      subjectId
      investigatorId
      sex
      age
      enrollDate {
        toString
      }
      createDate {
        toString
      }
      updateDate {
        toString
      }
      screening
      visit {
        id
      }
    }
  }
`;

/**
 "subjectInfoId": 2, 
 "studyId": 5, 
 "siteId": 2, 
 "sex": "female", 
 "age": "26", 
 "screening": "true", 
 "randomId": 124, 
 "emrId": 235, 
 "subjectId": "UK01-001", 
 "investigatorId": 2, 
 "enrollDate": "2022-04-22 14:00", 
 "operatorId": 1, 
 "operatorIP": "0.0.0.0", 
 "operatorBrowser": "chrome"
 */
export const eBinder_EDIT_SUBJECT = gql`
  mutation updateSubjectInfo(
    $subjectInfoId: Long
    $studyId: Long
    $siteId: Long
    $sex: String
    $age: String
    $screening: String
    $randomId: Long
    $emrId: Long
    $subjectId: String
    $investigatorId: Long
    $enrollDate: String
    $operatorId: Long
    $operatorIP: String
    $operatorBrowser: String
  ) {
    updateSubjectInfo(
      subjectInfoId: $subjectInfoId
      studyId: $studyId
      siteId: $siteId
      sex: $sex
      age: $age
      screening: $screening
      randomId: $randomId
      emrId: $emrId
      subjectId: $subjectId
      investigatorId: $investigatorId
      enrollDate: $enrollDate
      operatorId: $operatorId
      operatorIP: $operatorIP
      operatorBrowser: $operatorBrowser
    ) {
      id
      emrId
      randomId
      subjectId
      investigatorId
      sex
      age
      enrollDate {
        toString
      }
      createDate {
        toString
      }
      updateDate {
        toString
      }
      screening
      visit {
        id
      }
    }
  }
`;

/**
 "studyId": 5, 
 "subjectInfoId": 2, 
 "studyVisitId": 2, 
 "operatorId": 1, 
 "operatorIP": "0.0.0.0",
 "operatorBrowser": "chrome"
 */
export const eBinder_ADD_NEW_VISIT = gql`
  mutation addNewVisit(
    $studyId: Long
    $subjectInfoId: Long
    $studyVisitId: Long
    $operatorId: Long
    $operatorIP: String
    $operatorBrowser: String
  ) {
    addNewVisit(
      studyId: $studyId
      subjectInfoId: $subjectInfoId
      studyVisitId: $studyVisitId
      operatorId: $operatorId
      operatorIP: $operatorIP
      operatorBrowser: $operatorBrowser
    ) {
      id
      studyVisitId
      sequenceNo
      visitCreateTime {
        toString
      }
      form {
        id
      }
    }
  }
`;

//{"formCode": "ININT", "formName": "testDEV", "infoType": "false", "visitId": 3}
export const eBinder_ADD_NEW_FORM = gql`
  mutation addNewForm(
    $formCode: String
    $formName: String
    $infoType: String
    $visitId: Long
  ) {
    addNewForm(
      formCode: $formCode
      formName: $formName
      infoType: $infoType
      visitId: $visitId
    ) {
      id
      formName
      formCode
      infoType
      fileLocation {
        id
      }
    }
  }
`;

//{"page": 0, "size": 1}
export const eBinder_GET_ORGAN_NAME = gql`
  query getAllOrganization($page: Int!, $size: Int!) {
    getAllOrganization(page: $page, size: $size) {
      id
      orgName
    }
  }
`;

//{"subjectInfoId": 1}
export const eBinder_GET_FILES_BY_SUBJECTID = gql`
  query getAllFileLocationModelBySubjectId($subjectInfoId: Long) {
    getAllFileLocationModelBySubjectId(subjectInfoId: $subjectInfoId) {
      id
      subjectId
      visitName
      formName
      fileLocation
      fileStatus
      lastOperatorTime {
        toString
      }
    }
  }
`;

//{"visitId": 1}
export const eBinder_GET_FILES_BY_VISITID = gql`
  query getAllFileLocationModelByVisitId($visitId: Long) {
    getAllFileLocationModelByVisitId(visitId: $visitId) {
      id
      subjectId
      visitName
      formName
      fileLocation
      fileStatus
      lastOperatorTime {
        toString
      }
    }
  }
`;

//{"formId": 1}
export const eBinder_GET_FILES_BY_FORMID = gql`
  query getAllFileLocationModelByFormId($formId: Long) {
    getAllFileLocationModelByFormId(formId: $formId) {
      id
      subjectId
      visitName
      formName
      fileLocation
      fileStatus
      lastOperatorTime {
        toString
      }
    }
  }
`;

export const eBinder_GET_OCR_FORM_DATA = gql`
  query getOCRFormData($fileLocationId: Long) {
    getOCRFormData(fileLocationId: $fileLocationId) {
      keyId
      valueId
      tableName
      key
      value
      colName
    }
  }
`;

//{"jsonInput": "[{\"keyId\":3345,\"valueId\":2,\"tableName\":\"st_1_data\",\"value\":\"wow\",\"colName\":\"APDYN\"},{\"keyId\":3346,\"valueId\":2,\"tableName\":\"st_1_data\",\"value\":\"yahaha\",\"colName\":\"STSTAT\"}]", "operatorId": 1, "operatorIP": "0.0.0.0", "operatorBrowser": "chrome", "fileLocationId": 1}
export const eBinder_UPDATE_OCR_FORM = gql`
  mutation updateOCRFormValue(
    $jsonInput: String
    $operatorId: Long
    $operatorIP: String
    $operatorBrowser: String
    $fileLocationId: Long
  ) {
    updateOCRFormValue(
      jsonInput: $jsonInput
      operatorId: $operatorId
      operatorIP: $operatorIP
      operatorBrowser: $operatorBrowser
      fileLocationId: $fileLocationId
    )
  }
`;

export const eBinder_ADD_USER = gql`
  mutation addUser(
    $input: CreatePersonInput
    $email: String
    $adminType: String
    $passcode: String
    $organization: String
    $isActive: String
    $operatorId: Long
    $operatorIP: String
    $operatorBrowser: String
  ) {
    addUser(
      input: $input
      email: $email
      adminType: $adminType
      passcode: $passcode
      organization: $organization
      isActive: $isActive
      operatorId: $operatorId
      operatorIP: $operatorIP
      operatorBrowser: $operatorBrowser
    ) {
      person {
        id
        firstName
        lastName
        email
      }
      isActive
      studyList
      organizationList
      roles
    }
  }
`;

/**
 * {"input": {
 * "firstName": "test233",
 * "lastName": "233test"},
 * "email": "aj0s4s86@iffygame.com",
 * "passcode": "",
 * "studyId": 1,
 * "organization": "CIMS",
 * "isActive": "true",
 * "operatorId": 1,
 * "operatorIP": "0.0.0.0",
 * "operatorBrowser": "chrome"}
 */
export const eBinder_ADD_USER_UNDER_STUDY = gql`
  mutation addUserUnderStudy(
    $input: CreatePersonInput
    $email: String
    $passcode: String
    $studyId: Long
    $organization: String
    $isActive: String
    $operatorId: Long
    $operatorIP: String
    $operatorBrowser: String
  ) {
    addUserUnderStudy(
      input: $input
      email: $email
      passcode: $passcode
      studyId: $studyId
      organization: $organization
      isActive: $isActive
      operatorId: $operatorId
      operatorIP: $operatorIP
      operatorBrowser: $operatorBrowser
    ) {
      person {
        id
      }
      studyList
      isActive
      organizationList
      roles
    }
  }
`;

/**
 * {
 * "input": {
 *  "firstName": "test",
 *  "lastName": "test1",
 *  "phone": "1",
 *  "cellPhone": "1",
 *  "education": "null",
 *  "experience": "null",
 *  "title": "null",
 *  "sex": "null",
 *  "ssn": "null",
 *  "dob": "null"},
 * "email": "_wux448_@besttempmail.com",
 * "roles": ["System Admin", "DM"],
 * "passcode": "passcode",
 * "organization": "CIMS",
 * "isActive": "true",
 * "studyId": 1,
 * "isInvestigator": "true",
 * "operatorId": 1,
 * "operatorIP": "0.0.0.0",
 * "operatorBrowser": "chrome"}
 */
export const eBinder_ADD_USER_WITHROLE_STUDY = gql`
  mutation addUserWithRoleUnderStudy(
    $input: CreatePersonInput
    $email: String
    $roles: [String]
    $passcode: String
    $organization: String
    $isActive: String
    $studyId: Long
    $isInvestigator: String
    $operatorId: Long
    $operatorIP: String
    $operatorBrowser: String
  ) {
    addUserWithRoleUnderStudy(
      input: $input
      email: $email
      roles: $roles
      passcode: $passcode
      organization: $organization
      isActive: $isActive
      studyId: $studyId
      isInvestigator: $isInvestigator
      operatorId: $operatorId
      operatorIP: $operatorIP
      operatorBrowser: $operatorBrowser
    ) {
      isActive
      person {
        id
      }
      roles
    }
  }
`;

export const eBinder_GET_TEMPLATE_FORMS = gql`
  query getALlStudyFormNameByStudyId($studyId: Long) {
    getALlStudyFormNameByStudyId(studyId: $studyId)
  }
`;

export const eBinder_GET_ALL_STUDY_FORM_BY_SUBJECT_INFO_ID = gql`
  query getAllStudyFormBySubjectInfoId($subjectInfoId: Long!) {
    getAllStudyFormBySubjectInfoId(subjectInfoId: $subjectInfoId) {
      id
      formCode
      formName
    }
  }
`;

export const eBinder_GET_ALL_USERS = gql`
  query getAllUser {
    getAllUser {
      person {
        id
        firstName
        lastName
        email
        roles
      }
      studyList
      organizationList
    }
  }
`;

export const eBinder_GET_CURRENT_STUDY_USERS = gql`
  query getCurrentStudyUser($studyId: Long, $page: Int!, $size: Int!) {
    getCurrentStudyUser(studyId: $studyId, page: $page, size: $size) {
      totalSize
      userList {
        person {
          id
          firstName
          lastName
          email
          title
          employmentDate {
            toString
          }
          phone
          cellPhone
        }
        userName
        isActive
        isNewUser
        roles
        studyUserRoleList {
          id
          userRoleList
        }
      }
    }
  }
`;

//{studyId:1}
export const eBinder_GET_USERS_IN_ONE_STUDY = gql`
  query getUserWithStudyModelByStudyId($studyId: Long) {
    getUserWithStudyModelByStudyId(studyId: $studyId) {
      studyId
      studyName
      userList {
        user {
          person {
            id
            firstName
            lastName
            email
            sex
            dob
            ssn
            phone
            cellPhone
            streetAddress
            city
            state
            country
          }
          isActive
          organizationList
        }
        studyRoles
      }
    }
  }
`;

export const eBinder_GET_ALL_USERS_IN_STUDY = gql`
  query getUserWithStudyModel {
    getUserWithStudyModel {
      studyId
      studyName
      userList {
        user {
          person {
            id
            firstName
            lastName
            email
          }
        }
        studyRoles
      }
    }
  }
`;

//{"personId": 1}
export const eBinder_GET_USER_BY_ID = gql`
  query getUserInfoByUserId($personId: Long) {
    getUserInfoByUserId(personId: $personId) {
      person {
        id
        firstName
        lastName
        email
        title
        employmentDate {
          toString
        }
        phone
        cellPhone
        education
        experience
        ssn
        sex
        dob
        roles
        country
        streetAddress
        city
        state
        zipCode
      }
      isActive
      studyList
      organizationList
      roles
    }
  }
`;

/**
 * {"studyId": 5,
 * "userIdList": [7, 8],
 * "operatorId": 1,
 * "operatorIP": "0.0.0.0",
 * "operatorBrowser": "chrome"}
 */
export const eBinder_IMPORT_USERS_TO_STUDY = gql`
  mutation importUserToStudy(
    $studyId: Long
    $userIdList: [Long]
    $operatorId: Long
    $operatorIP: String
    $operatorBrowser: String
  ) {
    importUserToStudy(
      studyId: $studyId
      userIdList: $userIdList
      operatorId: $operatorId
      operatorIP: $operatorIP
      operatorBrowser: $operatorBrowser
    )
  }
`;

/**
 * {"studyId": 1,
 * "userIdList": [1, 2, 3, 4, 5, 6],
 * "operatorId": 1,
 * "operatorIP": "0.0.0.0",
 * "operatorBrowser": "chrome"}
 */
export const eBinder_UPDATE_STUDY_USER_LIST = gql`
  mutation updateStudyUserList(
    $studyId: Long
    $userIdList: [Long]
    $operatorId: Long
    $operatorIP: String
    $operatorBrowser: String
  ) {
    updateStudyUserList(
      studyId: $studyId
      userIdList: $userIdList
      operatorId: $operatorId
      operatorIP: $operatorIP
      operatorBrowser: $operatorBrowser
    )
  }
`;

/**
 * {"userId": 8, 
  "input": {
  "firstName": "Herry", 
    "lastName": "Hong"
  }, 
  "adminType": "system admin", 
  "isActive": "true", 
  "operatorId": 1, 
  "operatorIP": "0.0.0.0", 
  "operatorBrowser": "chrome"}
 */
export const eBinder_UPDATEUSER = gql`
  mutation updateUser(
    $userId: Long
    $input: CreatePersonInput
    $adminType: String
    $isActive: String
    $operatorId: Long
    $operatorIP: String
    $operatorBrowser: String
  ) {
    updateUser(
      userId: $userId
      input: $input
      adminType: $adminType
      isActive: $isActive
      operatorId: $operatorId
      operatorIP: $operatorIP
      operatorBrowser: $operatorBrowser
    ) {
      person {
        id
        firstName
        lastName
        email
        ssn
        dob
        sex
        roles
        avatar
        title
        phone
        cellPhone
        education
        experience
        country
        streetAddress
        city
        state
        zipCode
      }
      isActive
      studyList
      organizationList
      roles
    }
  }
`;

//{"studyId": 1, "roleName": "CRC"}
export const eBinder_GET_STUDY_FORM_ACCESS = gql`
  query getStudyRoleFormAccessByRoleName($studyId: Long, $roleName: String) {
    getStudyRoleFormAccessByRoleName(studyId: $studyId, roleName: $roleName) {
      studyFormId
      studyFormName
      access
    }
  }
`;

//{"userId":1}
export const eBinder_GET_STUDY_ROLES_BY_USERID = gql`
  query getAllStudyRolesByUserId($userId: Long!) {
    getAllStudyRolesByUserId(userId: $userId) {
      userId
      studyId
      userRoleList
    }
  }
`;

/**
 * {"input": 
 *  {
      "userId": 1,
      "operatorId": 1,
      "operatorIP": "0.0.0.0",
      "operatorBrowser": "chrome",
      "pairModel": [
        {
          "studyId": 1,
          "userRoleList": ["CRA"]
        },
        {
          "studyId": 2,
          "userRoleList": ["CRC"]
        }
      ]
    }
  }
 */
export const eBinder_UPDATE_STUDY_ROLES = gql`
  mutation UpdateStudyRolesByPatch($input: StudyUserRoleInput!) {
    updateStudyRolesByPatch(input: $input) {
      id
      userId
      userRoleList
    }
  }
`;

/**
 * {"studyId: 1"}
 */
export const eBinder_GET_SCHEDULE_ACTIVITY_BY_STUDYID = gql`
  query getScheduleActivityByStudyId(
    $studyId: Long!
    $page: Int!
    $size: Int!
  ) {
    getScheduleActivityByStudyId(studyId: $studyId, page: $page, size: $size) {
      visitHeaders {
        name
      }
      bodyList {
        formCode
        id
        name
        selected
      }
      totalSize
      domainList
    }
  }
`;

/**
 * {
  "original": "Study_Protocol.pdf",
  "fileInfo": {
    "studyId": "1",
    "fileName": "Study_Protocol"
  }
}
 */
export const eBinder_UPLOAD_SCHEDULE_ACTIVITY_FILES = gql`
  mutation uploadScheduleActivityFiles(
    $original: Upload
    $fileInfo: ImageFileInfoInput!
  ) {
    uploadScheduleActivityFiles(original: $original, fileInfo: $fileInfo) {
      fileStatus
    }
  }
`;

/**
 * {
  "original": "VitalSigns_t8.pdf",
  "masked": null,
  "fileInfo": {
    "studyId": 7,
    "siteId": 14,
    "studyFormId": 214,
    "studyVisitId": 349,
    "studyFormName": "Vitals and weight",
    "studyVisitName": "Visit 8",
    "operatorId": 10,
    "fileName": "ABC2",
    "subjectId": "9876"
  }
}
 */
export const eBinder_UPLOAD_SOURCE_DATA_FORM = gql`
  mutation uploadImageFiles(
    $original: Upload
    $masked: Upload
    $fileInfo: ImageFileInfoInput!
  ) {
    uploadImageFiles(
      original: $original
      masked: $masked
      fileInfo: $fileInfo
    ) {
      id
      lastOperatorId
      fileStatus
    }
  }
`;

//get all source by site id
// {"studyId": 7, "siteId": 14}
export const eBinder_GET_SOURCE_BY_SITE = gql`
  query getSourceBySite($studyId: Long, $siteId: Long) {
    getSourceBySite(studyId: $studyId, siteId: $siteId) {
      formVisitId
      formName
      studyFormId
      studyVisitId
      fileLocation
      visitName
      visitNo
      subjectId
      subjectInfoId
      uploadTime {
        toString
      }
      fileSize
      maskedStatus
      mappedStatus
      fileStatus
      fileEnabled
      fileName
    }
  }
`;

//{"studyId": 7, "siteId": 14, "page": 1, "size": 10}
export const eBinder_GET_SOURCE_BY_SITE_PAGE = gql`
  query getSourceBySiteWithPage(
    $studyId: Long
    $siteId: Long
    $page: Int!
    $size: Int!
  ) {
    getSourceBySiteWithPage(
      studyId: $studyId
      siteId: $siteId
      page: $page
      size: $size
    ) {
      totalSize
      formVisitModels {
        formVisitId
        formName
        studyFormId
        studyVisitId
        fileLocation
        visitName
        visitNo
        unscheduledVisitId
        unscheduledVisitNo
        unscheduledVisitName
        subjectId
        subjectInfoId
        uploadTime {
          toString
        }
        fileSize
        maskedStatus
        mappedStatus
        fileStatus
        fileEnabled
        fileName
      }
    }
  }
`;

//get all source by subject
// {"studyId": 7, "siteId": 14, "subjectInfoId": 15}
export const eBinder_GET_SOURCE_BY_SUBJECT = gql`
  query getSourceBySubject(
    $studyId: Long
    $siteId: Long
    $subjectInfoId: Long
  ) {
    getSourceBySubject(
      studyId: $studyId
      siteId: $siteId
      subjectInfoId: $subjectInfoId
    ) {
      formVisitId
      formName
      studyFormId
      studyVisitId
      fileLocation
      visitName
      visitNo
      subjectId
      subjectInfoId
      uploadTime {
        toString
      }
      fileSize
      maskedStatus
      mappedStatus
      fileStatus
      fileEnabled
      fileName
    }
  }
`;

//{"studyId": 7, "siteId": 14, "subjectInfoId": 15, "page": 0, "size": 20}
export const eBinder_GET_SOURCE_BY_SUBJECT_PAGE = gql`
  query getSourceBySubjectWithPage(
    $studyId: Long
    $siteId: Long
    $subjectInfoId: Long
    $page: Int!
    $size: Int!
  ) {
    getSourceBySubjectWithPage(
      studyId: $studyId
      siteId: $siteId
      subjectInfoId: $subjectInfoId
      page: $page
      size: $size
    ) {
      totalSize
      formVisitModels {
        formVisitId
        formName
        studyFormId
        studyVisitId
        fileLocation
        visitName
        visitNo
        unscheduledVisitId
        unscheduledVisitNo
        unscheduledVisitName
        subjectId
        subjectInfoId
        uploadTime {
          toString
        }
        fileSize
        maskedStatus
        mappedStatus
        fileStatus
        fileEnabled
        fileName
      }
    }
  }
`;

//get all source by form
//{"studyId": 7, "siteId": 14, "subjectInfoId": 15, "studyFormId": 210}
export const eBinder_GET_SOURCE_BY_FORM = gql`
  query getSourceByForm(
    $studyId: Long
    $siteId: Long
    $subjectInfoId: Long
    $studyFormId: Long
  ) {
    getSourceByForm(
      studyId: $studyId
      siteId: $siteId
      subjectInfoId: $subjectInfoId
      studyFormId: $studyFormId
    ) {
      formVisitId
      formName
      studyFormId
      studyVisitId
      fileSize
      fileLocation
      uploadTime {
        toString
      }
      visitName
      visitNo
      subjectId
      subjectInfoId
      formGeneralModelList {
        Id
        formName
        studyFormId
        formCode
      }
      visitGeneralModelList {
        Id
        visitName
        visitNo
      }
      maskedStatus
      mappedStatus
      fileStatus
      fileEnabled
      fileName
    }
  }
`;

//get all source by visit
//{"studyId": 7, "siteId": 14, "subjectInfoId": 15,"studyVisitId": 286}
export const eBinder_GET_SOURCE_BY_VISIT = gql`
  query getSourceByVisit(
    $subjectInfoId: Long
    $visitIndex: Long
    $visitType: String
    $page: Int!
    $size: Int!
  ) {
    getSourceByVisit(
      subjectInfoId: $subjectInfoId
      visitIndex: $visitIndex
      visitType: $visitType
      page: $page
      size: $size
    ) {
      formVisitId
      formName
      studyFormId
      studyVisitId
      fileSize
      fileLocation
      uploadTime {
        toString
      }
      visitName
      visitNo
      subjectId
      subjectInfoId
      formGeneralModelList {
        Id
        studyFormId
      }
      visitGeneralModelList {
        Id
        visitName
      }
      maskedStatus
      mappedStatus
      fileStatus
      fileEnabled
      fileName
    }
  }
`;

//{"studyId": 7, "siteId": 14, "subjectInfoId": 15,"studyVisitName": "Visit 1"}
export const eBinder_GET_SOURCE_BY_VISIT_NAME = gql`
  query getSourceByVisitName(
    $studyId: Long
    $siteId: Long
    $subjectInfoId: Long
    $studyVisitName: String
  ) {
    getSourceByVisitName(
      studyId: $studyId
      siteId: $siteId
      subjectInfoId: $subjectInfoId
      studyVisitName: $studyVisitName
    ) {
      formVisitId
      formName
      studyFormId
      fileSize
      fileLocation
      visitName
      visitNo
      subjectId
      subjectInfoId
      uploadTime {
        toString
      }
      maskedStatus
      mappedStatus
      fileStatus
      fileEnabled
      fileName
    }
  }
`;

//get all source by form and visit
//{"studyId": 7, "siteId": 14, "subjectInfoId": 15, "studyFormId": 214, "studyVisitName": "Visit 1"}
export const eBinder_GET_SOURCE_BY_FORM_ID_VISIT_ID = gql`
  query getSourceByFormIdVisitId(
    $studyId: Long
    $siteId: Long
    $subjectInfoId: Long
    $studyFormId: Long
    $studyVisitId: Long
  ) {
    getSourceByFormIdVisitId(
      studyId: $studyId
      siteId: $siteId
      subjectInfoId: $subjectInfoId
      studyFormId: $studyFormId
      studyVisitId: $studyVisitId
    ) {
      formVisitId
      formName
      studyFormId
      fileSize
      fileLocation
      visitName
      visitNo
      subjectId
      subjectInfoId
      studyVisitId
      uploadTime {
        toString
      }
      maskedStatus
      mappedStatus
      fileStatus
      fileEnabled
      fileName
    }
  }
`;

export const eBinder_GET_SOURCE_BY_FORM_AND_VISIT = gql`
  query getSourceByFormAndVisit(
    $subjectInfoId: Long
    $studyFormId: Long
    $visitIndex: Long
    $visitType: String
    $page: Int!
    $size: Int!
  ) {
    getSourceByFormAndVisit(
      subjectInfoId: $subjectInfoId
      studyFormId: $studyFormId
      visitIndex: $visitIndex
      visitType: $visitType
      page: $page
      size: $size
    ) {
      formVisitId
      formName
      studyFormId
      studyVisitId
      fileSize
      fileLocation
      uploadTime {
        toString
      }
      visitName
      visitNo
      unscheduledVisitId
      unscheduledVisitNo
      unscheduledVisitName
      subjectId
      subjectInfoId
      formGeneralModelList {
        Id
        studyFormId
      }
      visitGeneralModelList {
        Id
        visitName
      }
      maskedStatus
      mappedStatus
      fileStatus
      fileEnabled
      fileName
    }
  }
`;

export const eBinder_GET_ALL_USER_SITE_ROLE_ACCESS_UNDER_STUDY = gql`
  query getAllUserSiteRoleAccessUnderStudy($userId: Long!, $studyId: Long!) {
    getAllUserSiteRoleAccessUnderStudy(userId: $userId, studyId: $studyId) {
      id
      access
      user {
        userName
      }
      site {
        id
        siteName
      }
    }
  }
`;

export const eBinder_GET_ALL_SUBJECTINFO_BY_SITEID = gql`
  query getAllSubjectInfoBySiteId($siteId: Long!) {
    getAllSubjectInfoBySiteId(siteId: $siteId) {
      id
      subjectId
    }
  }
`;

export const eBinder_GET_OCR_JSON_BY_FVLID = gql`
  query getOcrJsonByFVLId($formVisitLocationId: Long!) {
    getOcrJsonByFVLId(formVisitLocationId: $formVisitLocationId) {
      success
      result
    }
  }
`;

export const eBinder_GET_ALL_LLM_LOG_BY_STUDY_WITH_PAGE = gql`
  query getLLMLogByStudyWithPage($studyId: Long, $page: Int!, $size: Int!) {
    getLLMLogByStudyWithPage(studyId: $studyId, page: $page, size: $size) {
      totalSize
      llmOperationLogList {
        id
        fileName
        location
        message
        fileState
        studyId
        formVisitLocationId
        processTime {
          toString
        }
      }
    }
  }
`;

export const eBinder_GET_ALL_LLM_LOG_BY_FVL_ID = gql`
  query getAllLLMLogByFVLId($formVisitLocationId: Long!) {
    getAllLLMLogByFVLId(formVisitLocationId: $formVisitLocationId) {
      id
      fileName
      location
      message
      fileState
      studyId
      formVisitLocationId
      operatorId
      operatorIp
      operatorBrowser
      processTime {
        toString
      }
    }
  }
`;

export const eBinder_GET_FORM_VISIT_LOCATION = gql`
  query getFormVisitLocation($formVisitLocationId: Long!) {
    getFormVisitLocation(formVisitLocationId: $formVisitLocationId) {
      id
      lastOperatorId
      lastOperatorTime {
        toString
      }
      location
      sequenceNo
      fileStatus
      fileName
      fileSize
      form {
        id
        studyForm {
          formName
          formCode
        }
      }
      fileEnabled
    }
  }
`;

export const eBinder_GET_PAGED_STUDY_FORM_DESIGNED_VOS = gql`
  query getPagedStudyFormDesignedVOs($studyId: Long, $page: Int!, $size: Int!) {
    getPagedStudyFormDesignedVOs(studyId: $studyId, page: $page, size: $size) {
      designed
      studyForm {
        id
        formName
        formCode
        infoType
      }
    }
  }
`;

export const eBinder_GET_ALL_DESIGN_BY_STUDY_FORM_ID = gql`
  query getAllByStudyFormId($studyFormId: Long) {
    getAllByStudyFormId(studyFormId: $studyFormId) {
      id
      standard
      partNo
      sequenceNo
      fieldLabel
      fieldName
      fieldControlType
      fieldValue
      studyId
      studyFormId
      codeList {
        id
        sequenceNo
        fieldKey
        fieldValue
        studyId
        studyFormId
        standard
      }
    }
  }
`;

export const eBinder_BATCH_SAVE_STUDY_FORM_DESIGN = gql`
  mutation batchSaveStudyFormDesign(
    $studyFormId: Long!
    $studyFormDesignList: [StudyFormDesignInput]!
  ) {
    batchSaveStudyFormDesign(
      studyFormId: $studyFormId
      studyFormDesignList: $studyFormDesignList
    ) {
      id
      standard
      fieldLabel
      fieldName
    }
  }
`;

export const eBinder_INITIALIZE_STUDY_FORM_DESIGN_BY_STUDY_ID = gql`
  mutation initializeStudyFormDesignByStudyId($studyId: Long!) {
    initializeStudyFormDesignByStudyId(studyId: $studyId) {
      success
      result
      referenceId
    }
  }
`;

export const eBinder_GET_STUDY_FORM_BY_ID = gql`
  query getStudyFormById($studyFormId: Long) {
    getStudyFormById(studyFormId: $studyFormId) {
      id
      formName
      formCode
      infoType
    }
  }
`;

export const eBinder_GET_CURRENT_MASKED_SOURCE_FILE = gql`
  query getCurrentMaskedSourceFile($formVisitLocationId: Long) {
    getCurrentMaskedSourceFile(formVisitLocationId: $formVisitLocationId) {
      requestApiStr
      formVisitLocation {
        id
        fileName
        fileSize
        fileEnabled
        location
        fileStatus
      }
      maskingTemplate {
        id
        state
        imageMasks {
          x
          y
          w
          h
          r
          g
          b
          page
          dedName
          partNo
          sequenceNo
          fieldLabel
          fieldControlType
        }
      }
    }
  }
`;

export const eBinder_SAVE_MASKED_REVIEW_FILE = gql`
  mutation saveMaskedReviewFile(
    $formVisitLocationId: Long
    $maskInputList: [ImageMaskInput]
    $operatorInfoInput: OperatorInfoInput
  ) {
    saveMaskedReviewFile(
      formVisitLocationId: $formVisitLocationId
      maskInputList: $maskInputList
      operatorInfoInput: $operatorInfoInput
    ) {
      id
      location
      fileName
      fileSize
    }
  }
`;

export const eBinder_TRIGGER_OCR = gql`
  mutation triggerOcr(
    $formVisitLocationId: Long
    $operatorInfoInput: OperatorInfoInput
  ) {
    triggerOcr(
      formVisitLocationId: $formVisitLocationId
      operatorInfoInput: $operatorInfoInput
    ) {
      success
      result
    }
  }
`;

export const eBinder_UPLOAD_SOURCE_FILE = gql`
  mutation uploadSourceFile(
    $source: Upload
    $fileInfo: ImageFileInfoInput
    $operatorInfoInput: OperatorInfoInput
  ) {
    uploadSourceFile(
      source: $source
      fileInfo: $fileInfo
      operatorInfoInput: $operatorInfoInput
    ) {
      success
      result
    }
  }
`;

export const eBinder_GET_CURRENT_SUBJECT_INFO_FVL_BY_STATUS = gql`
  query getCurrentSubjectInfoFVLByStatus(
    $siteId: Long
    $subjectInfoId: Long
    $ocrStatusCode: Int
    $page: Int!
    $size: Int!
  ) {
    getCurrentSubjectInfoFVLByStatus(
      siteId: $siteId
      subjectInfoId: $subjectInfoId
      ocrStatusCode: $ocrStatusCode
      page: $page
      size: $size
    ) {
      totalSize
      fvlList {
        id
        fileName
        fileStatus
        fileEnabled
        form {
          studyForm {
            id
            formName
            formCode
          }
          formStatus
          subjectInfo {
            subjectId
          }
        }
      }
      fvlqList {
        formVisitLocation {
          id
          fileName
          fileStatus
          fileEnabled
          form {
            studyForm {
              id
              formName
              formCode
            }
            formStatus
            subjectInfo {
              subjectId
            }
          }
        }
        queryStatus
      }
    }
  }
`;

export const eBinder_GET_CURRENT_SUBJECT_INFO_FVL_AFTER_STATUS = gql`
  query getCurrentSubjectInfoFVLAfterStatus(
    $siteId: Long
    $subjectInfoId: Long
    $ocrStatusCode: Int
    $page: Int!
    $size: Int!
  ) {
    getCurrentSubjectInfoFVLAfterStatus(
      siteId: $siteId
      subjectInfoId: $subjectInfoId
      ocrStatusCode: $ocrStatusCode
      page: $page
      size: $size
    ) {
      totalSize
      fvlList {
        id
        fileName
        fileStatus
        fileEnabled
        form {
          studyForm {
            id
            formName
            formCode
          }
          formStatus
          subjectInfo {
            subjectId
          }
        }
      }
      fvlqList {
        formVisitLocation {
          id
          fileName
          fileStatus
          fileEnabled
          form {
            studyForm {
              id
              formName
              formCode
            }
            formStatus
            subjectInfo {
              subjectId
            }
          }
        }
        queryStatus
      }
    }
  }
`;

export const eBinder_DELETE_SOURCE_FILE = gql`
  mutation deleteSourceFile(
    $formVisitLocationId: Long
    $operatorInfoInput: OperatorInfoInput
  ) {
    deleteSourceFile(
      formVisitLocationId: $formVisitLocationId
      operatorInfoInput: $operatorInfoInput
    ) {
      success
      result
      referenceId
    }
  }
`;

export const eBinder_UPDATE_STUDY_FORM_BY_PATCH = gql`
  mutation updateStudyFormByPatch(
    $inputList: [StudyFormUpdateInput]
    $operatorInfoInput: OperatorInfoInput
  ) {
    updateStudyFormByPatch(
      inputList: $inputList
      operatorInfoInput: $operatorInfoInput
    ) {
      success
      result
    }
  }
`;

export const eBinder_CREATE_NEW_UNSCHEDULED_VISIT = gql`
  mutation createNewUnscheduledVisit(
    $unscheduledVisitInput: UnscheduledVisitInput
  ) {
    createNewUnscheduledVisit(unscheduledVisitInput: $unscheduledVisitInput) {
      success
      result
    }
  }
`;

export const eBinder_UPDATE_UNSCHEDULED_VISIT = gql`
  mutation updateUnscheduledVisit(
    $unscheduledVisitInput: UnscheduledVisitInput
    $operatorInfoInput: OperatorInfoInput
  ) {
    updateUnscheduledVisit(
      unscheduledVisitInput: $unscheduledVisitInput
      operatorInfoInput: $operatorInfoInput
    ) {
      success
      result
    }
  }
`;

export const eBinder_GET_CURRENT_SCHEMA_LOCATIONS = gql`
  query getCurrentStudyAllSchemaLocation(
    $studyId: Long
    $page: Int!
    $size: Int!
  ) {
    getCurrentStudyAllSchemaLocation(
      studyId: $studyId
      page: $page
      size: $size
    ) {
      id
      fileName
      fileExt
      fileEnabled
      fileStatus
      sequenceNo
      studyId
    }
  }
`;

export const eBinder_GET_ALL_STUDY_FORM_BY_STUDY_ID = gql`
  query getAllStudyFormByStudyId($studyId: Long, $page: Int!, $size: Int!) {
    getAllStudyFormByStudyId(studyId: $studyId, page: $page, size: $size) {
      totalSize
      studyForms {
        id
        formName
        formCode
        infoType
      }
    }
  }
`;

export const eBinder_ADD_NEW_SUBJECT_INFO_AUTO = gql`
  mutation addNewSubjectInfoAuto(
    $subjectInfoInput: SubjectInfoInput
    $operatorInfoInput: OperatorInfoInput
  ) {
    addNewSubjectInfoAuto(
      subjectInfoInput: $subjectInfoInput
      operatorInfoInput: $operatorInfoInput
    ) {
      id
      subjectId
      study {
        id
      }
      site {
        id
      }
    }
  }
`;

export const eBinder_GET_ALL_SITE_PAGE_BY_STUDY_ID = gql`
  query getAllSitePageByStudyId($studyId: Long, $page: Int!, $size: Int!) {
    getAllSitePageByStudyId(studyId: $studyId, page: $page, size: $size) {
      totalSize
      sites {
        id
        siteName
        siteCreateTime {
          toString
        }
        siteUpdateTime {
          toString
        }
        siteNo
        siteStatus
        siteDescription
        siteCountry
        siteContact
        sitePhone
        siteAddress
        siteEmail
      }
    }
  }
`;

export const eBinder_ADD_NEW_SITE_ORGANIZATION = gql`
  mutation addNewSite(
    $studyId: Long
    $createSiteInput: CreateSiteInput
    $operatorInfoInput: OperatorInfoInput
  ) {
    addNewSite(
      studyId: $studyId
      createSiteInput: $createSiteInput
      operatorInfoInput: $operatorInfoInput
    ) {
      id
      siteName
      siteCreateTime {
        toString
      }
      siteUpdateTime {
        toString
      }
      siteNo
      siteStatus
      siteDescription
      siteCountry
      siteContact
      sitePhone
      siteAddress
      siteEmail
    }
  }
`;

export const eBinder_EDIT_NEW_SITE_ORGANIZATION = gql`
  mutation editNewSite(
    $studyId: Long
    $createSiteInput: CreateSiteInput
    $operatorInfoInput: OperatorInfoInput
  ) {
    editNewSite(
      studyId: $studyId
      createSiteInput: $createSiteInput
      operatorInfoInput: $operatorInfoInput
    ) {
      id
      siteName
      siteCreateTime {
        toString
      }
      siteUpdateTime {
        toString
      }
      siteNo
      siteStatus
      siteDescription
      siteCountry
      siteContact
      sitePhone
      siteAddress
      siteEmail
    }
  }
`;

export const eBinder_ADD_USER_WITH_ROLE_AND_SITE_ACCESS_UNDER_STUDY = gql`
  mutation addUserWithRoleAndSiteAccessUnderStudy(
    $studyId: Long
    $createPersonInput: CreatePersonInput
    $addUserBasicInfoInput: AddUserBasicInfoInput
    $addUserRoleInfoInput: AddUserRoleInfoInput
    $addUserSiteAccessInput: AddUserSiteAccessInput
    $operatorInfoInput: OperatorInfoInput
  ) {
    addUserWithRoleAndSiteAccessUnderStudy(
      studyId: $studyId
      createPersonInput: $createPersonInput
      addUserBasicInfoInput: $addUserBasicInfoInput
      addUserRoleInfoInput: $addUserRoleInfoInput
      addUserSiteAccessInput: $addUserSiteAccessInput
      operatorInfoInput: $operatorInfoInput
    ) {
      person {
        id
        email
      }
      roles
      isActive
      passcode
      studyList {
        id
      }
      organizationList
      userSiteRoleAccessList {
        site {
          id
        }
        access
      }
      studyUserRoleList {
        userRoleList
        status
        isInvestigator
      }
      userSiteRoleDefaultAccessList {
        defaultAccess
        study {
          id
        }
      }
    }
  }
`;

export const eBinder_GET_CURRENT_STUDY_ALL_ORGANIZATIONS = gql`
  query getCurrentStudyAllOrganizations(
    $studyId: Long
    $page: Int!
    $size: Int!
  ) {
    getCurrentStudyAllOrganizations(
      studyId: $studyId
      page: $page
      size: $size
    ) {
      totalSize
      siteModels {
        Id
        siteName
        siteCreateTime {
          toString
        }
        siteUpdateTime {
          toString
        }
        siteNo
        siteStatus
        siteDescription
        siteCountry
        siteContact
        sitePhone
        siteAddress
        siteEmail
        orgTypeId
        orgType
      }
    }
  }
`;

export const eBinder_GET_FVL_COUNT_BY_FILE_STATUS = gql`
  query getFvlCountByFileStatus($studyId: Long) {
    getFvlCountByFileStatus(studyId: $studyId) 
    
  }
`;

export const eBinder_DISABLE_CURRENT_STUDY = gql`
  mutation disableCurrentStudy(
    $studyId: Long
    $operatorInfoInput: OperatorInfoInput
  ) {
    disableCurrentStudy(
      studyId: $studyId
      operatorInfoInput: $operatorInfoInput
    ) {
      result
      success
    }
  }
`;
