import { gql } from "@apollo/client";

export const ALL_HEALTH_SYSTEM = gql`
  query allHealthSystem {
    allHealthSystem {
      id
      systemName
      status
      organization {
        id
        type
        name
        email
        phone
        status
        locations {
          id
          locationName
          address
          stateName
          zipCode
          telNumb
        }
      }
    }
  }
`;

export const FIND_ALL_ORGANIZATION = gql`
  query getAllOrganization {
    allOrganization {
      id
      type
      name
      email
      phone
      status
      locations {
        id
        locationName
        address
        stateName
        zipCode
        telNumb
      }
    }
  }
`;

export const FIND_ORGANIZATION_BYID = gql`
  query orgsByHealthId($hs_id: ID!) {
    orgsByHealthId(hs_id: $hs_id) {
      id
      type
      name
      email
      phone
      status
      locations {
        id
        locationName
        address
        stateName
        zipCode
        telNumb
      }
    }
  }
`;

export const ADD_ONE_ORG_BYHSID = gql`
  mutation addNewOrgByHSId(
    $hs_id: ID!
    $org: OrganizationInput
    $locations: [LocationsInput]
  ) {
    addNewOrgByHSId(hs_id: $hs_id, org: $org, locations: $locations) {
      name
      type
      email
      phone
      status
      locations {
        locationName
        address
        stateName
        zipCode
        telNumb
      }
    }
  }
`;

export const ADD_ONE_ORG = gql`
  mutation addNewOrg($org: OrganizationInput!, $locs: [LocationsInput]) {
    addNewOrg(org: $org, locs: $locs) {
      name
      type
      email
      phone
      status
      locations {
        locationName
        address
        stateName
        zipCode
        telNumb
      }
    }
  }
`;

export const ADD_NEW_HEALTH_SYSTEM = gql`
  mutation addNewHealthSystem($hs: HealthSystemInput) {
    addNewHealthSystem(hs: $hs) {
      id
      systemName
      organization {
        id
        type
        name
        email
        phone
        status
        locations {
          id
          locationName
          address
          stateName
          zipCode
          telNumb
        }
      }
    }
  }
`;

export const ADD_NEW_LOCATION = gql`
  mutation addLocationsToOrg($org_id: ID!, $locations: [LocationsInput]) {
    addLocationsToOrg(org_id: $org_id, locations: $locations) {
      name
      type
      email
      phone
      status
      locations {
        locationName
        address
        stateName
        zipCode
        telNumb
      }
    }
  }
`;

export const DELETE_HS = gql`
  mutation delAHealthSystem($id: ID!) {
    delAHealthSystem(id: $id) {
      id
    }
  }
`;

export const DELETE_ORGANIZATION = gql`
  mutation delOrganization($id: ID!) {
    delOrganization(id: $id) {
      id
      type
      name
      email
      phone
      status
      locations {
        id
        locationName
        address
        stateName
        zipCode
        telNumb
        status
      }
    }
  }
`;

export const DELETE_LOCATION = gql`
  mutation delLocation($locationId: ID!) {
    delLocation(locationId: $locationId) {
      id
      locationName
      address
      stateName
      zipCode
      telNumb
      status
    }
  }
`;

export const UPDATE_HS = gql`
  mutation updateHealthSystemName($id: ID!, $systemName: String) {
    updateHealthSystemName(id: $id, systemName: $systemName)
  }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation updateOrganizationFields(
    $id: ID!
    $type: String
    $name: String
    $email: String
    $phone: String
  ) {
    updateOrganizationFields(
      id: $id
      type: $type
      name: $name
      email: $email
      phone: $phone
    )
  }
`;

export const UPDATE_LOCATION = gql`
  mutation updateLocation($locationId: ID!, $locIn: LocationsInput) {
    updateLocation(locationId: $locationId, locIn: $locIn) {
      id
      locationName
      address
      stateName
      zipCode
      telNumb
      status
    }
  }
`;

export const getStatus = () => [
  { id: "1", title: "Active" },
  { id: "2", title: "Inactive" },
];
export const getState = () => [
  { id: "1", title: "AL" },
  { id: "2", title: "AK" },
  { id: "3", title: "AZ" },
  { id: "4", title: "AR" },
  { id: "5", title: "CA" },
  { id: "6", title: "CO" },
  { id: "7", title: "CT" },
  { id: "8", title: "DE" },
  { id: "9", title: "DC" },
  { id: "10", title: "FL" },
  { id: "11", title: "GA" },
  { id: "12", title: "HI" },
  { id: "13", title: "ID" },
  { id: "14", title: "IL" },
  { id: "15", title: "IN" },
  { id: "16", title: "IA" },
  { id: "17", title: "KS" },
  { id: "18", title: "KY" },
  { id: "19", title: "LA" },
  { id: "20", title: "ME" },
  { id: "21", title: "MD" },
  { id: "22", title: "MA" },
  { id: "23", title: "MI" },
  { id: "24", title: "MN" },
  { id: "25", title: "MS" },
  { id: "26", title: "MO" },
  { id: "27", title: "MT" },
  { id: "28", title: "NE" },
  { id: "29", title: "NV" },
  { id: "30", title: "NH" },
  { id: "31", title: "NJ" },
  { id: "32", title: "NM" },
  { id: "33", title: "NY" },
  { id: "34", title: "NC" },
  { id: "35", title: "ND" },
  { id: "36", title: "OH" },
  { id: "37", title: "OK" },
  { id: "38", title: "OR" },
  { id: "39", title: "PA" },
  { id: "40", title: "RI" },
  { id: "41", title: "SC" },
  { id: "42", title: "SD" },
  { id: "43", title: "TN" },
  { id: "44", title: "TX" },
  { id: "45", title: "UT" },
  { id: "46", title: "VT" },
  { id: "47", title: "VA" },
  { id: "48", title: "WA" },
  { id: "49", title: "WV" },
  { id: "50", title: "WI" },
  { id: "51", title: "WY" },
];
