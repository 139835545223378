import { gql } from "@apollo/client";

export const FIND_DATA_MASKINGS = gql`
  query FindDataMaskings($person_id: ID!) {
    findDataMaskings(person_id: $person_id) {
      id
      fileLocation
      timestamp
    }
  }
`;

export const FIND_DATA_MASKINGS_AUTO = gql`
  query FindDataMaskingsAuto($person_id: ID!) {
    findDataMaskingsAuto(person_id: $person_id) {
      id
      fileLocation
      timestamp
    }
  }
`;

export const FIND_ENCRYPT_FILES = gql`
  query findEncryptFiles($person_id: ID!) {
    findEncryptFiles(person_id: $person_id) {
      id
      personId
      fileLocation
      timestamp
      sourceOfData
      folder
    }
  }
`;

export const FIND_ENCRYPT_FILES_PAGE = gql`
  query FindEncryptFilesPage($page: Int!, $size: Int!, $person_id: ID!) {
    findEncryptFilesPage(page: $page, size: $size, person_id: $person_id) {
      id
      fileLocation
      timestamp
      sourceOfData
    }
  }
`;

export const FIND_ENCRYPT_FILENAME = gql`
  query FindEncryptFileName($rowId: ID!) {
    findEncryptFileName(rowId: $rowId)
  }
`;

export const DELETE_ENCRYPT_FILE = gql`
  mutation deleteMedicalRecordById($id: Long) {
    deleteMedicalRecordById(id: $id) {
      id
      personId
      fileLocation
      timestamp
      sourceOfData
      folder
    }
  }
`;

export const MANUAL_MASK_DATA = gql`
  mutation ManualMaskData($personId: ID!, $file: String, $content: String) {
    manualMaskData(personId: $personId, file: $file, content: $content) {
      id
    }
  }
`;

export const STORE_MASKING_INFO = gql`
  mutation StoreMaskingInfo($personId: ID!, $pdfFile: String) {
    storeMaskingInfo(personId: $personId, pdfFile: $pdfFile) {
      id
      personId
      fileLocation
      timestamp
    }
  }
`;

export const STORE_ENCRYPTFILE_INFO = gql`
  mutation StoreEncryptFileInfo($personId: ID!, $pdfFile: String) {
    storeEncryptFileInfo(personId: $personId, pdfFile: $pdfFile) {
      id
      personId
      fileLocation
      timestamp
    }
  }
`;

export const DEACTIVATE_DATA_MASKING = gql`
  mutation DeactivateDataMasking($id: ID!) {
    deactivateDataMasking(id: $id)
  }
`;

export const DEACTIVATE_DATA_MASKINGS = gql`
  mutation DeactivateDataMaskings($ids: [ID]!) {
    deactivateDataMaskings(ids: $ids)
  }
`;

export const DEACTIVATE_DATA_MASKING_BEFORE = gql`
  mutation DeactivateDataMaskingBefore($dateTime: String!) {
    deactivateDataMaskingBefore(dateTime: $dateTime)
  }
`;

export const DEACTIVATE_DATA_MASKING_AUTO = gql`
  mutation DeactivateDataMaskingAuto($id: ID!) {
    deactivateDataMaskingAuto(id: $id)
  }
`;

export const DEACTIVATE_DATA_MASKING_AUTOS = gql`
  mutation DeactivateDataMaskingAutos($ids: [ID]!) {
    deactivateDataMaskingAutos(ids: $ids)
  }
`;

export const DEACTIVATE_DATA_MASKING_AUTO_BEFORE = gql`
  mutation DeactivateDataMaskingAutoBefore($dateTime: String!) {
    deactivateDataMaskingAutoBefore(dateTime: $dateTime)
  }
`;

export const DEACTIVATE_ENCRYPT_FILE = gql`
  mutation DeactivateEncryptFile($id: ID!) {
    deactivateEncryptFile(id: $id)
  }
`;

export const DEACTIVATE_ENCRYPT_FILES = gql`
  mutation DeactivateEncryptFiles($ids: [ID]!) {
    deactivateEncryptFiles(ids: $ids)
  }
`;

export const DEACTIVATE_ENCRYPT_FILE_BEFORE = gql`
  mutation DeactivateEncryptFileBefore($dateTime: String!) {
    deactivateEncryptFileBefore(dateTime: $dateTime)
  }
`;
