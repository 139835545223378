import React, { useState,useContext } from "react";
import {
  TableCell,
  TableRow,
  Typography,
  Container,
  Fab,
  Modal,
  Backdrop,
  Fade,
  Button,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useForm } from "../../communication/components/useForm";

import AddIcon from "@mui/icons-material/Add";
import { useMutation, useQuery } from "@apollo/client";
import {GET_STUDIES, ADD_STUDIES} from "src/services/mmr/patient-matcher.service";
import { AuthenticationContext } from "src/services/authentication/authentication.context";
import { useHistory , useParams} from "react-router-dom";
import { CustomTable } from "@cims/common/";
import { blue } from '@mui/material/colors';

const initialFValues = {
  sponsor: "",
  titleofstudy: "",
  protocolnumber: "",
  inclusion:"",
  exclusion:"",
};

function createData(title, protocolNum,sponsor,inclusionCriteria,exclusionCriteria) {
    return {
      title,
      protocolNum,
      sponsor,
      inclusionCriteria,
      exclusionCriteria,
    };
}
const useStyles = makeStyles((theme) => ({
    addBtn: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: 20,
      // height: "50px",
    },
    addBtnIcon: {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
      color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
      "&:hover": {
        backgroundColor:
          localStorage.getItem("isDarkMode") === "true" ? "#BDC0BA" : blue[700],
        color: "#fff",
      },
    },
  
    btn: {
      marginTop: 20,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  
    paper: {
      backgroundColor: "#f5f3f0", //theme.palette.background.paper,
      border: "1px solid #666666",
      width: 700,
      maxHeight: 700,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      overflow: "auto",
    },
  
    htyCard: {
      maxWidth: 345,
      marginBottom: "10px",
    },
  
    cardHeader: {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true"
          ? "rgba(0,26,72, 0.3)"
          : "rgba(126,211,205,0.5)",
    },
  
    divider: {
      backgroundColor: "#d9e2ee",
      margin: "0 20px",
    },
  
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }));


const useRowStyles = makeStyles({
    root: {
      "& > *": {
        borderBottom: "unset",
      },
    },
    addBtn: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: 10,
      // height: "40px",
    },
    addBtnIcon: {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
      color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
      "&:hover": {
        backgroundColor:
          localStorage.getItem("isDarkMode") === "true" ? "#BDC0BA" : blue[700],
        color: "#fff",
      },
    },
});

const useModalStyles = makeStyles((theme) => ({
    paper: {
      backgroundColor: "#f5f3f0", //theme.palette.background.paper,
      border: "1px solid #666666",
      width: 700,
      maxHeight: 700,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      overflow: "auto",
    },
    tf: {
      marginBottom: 15,
    },
    btn: {
      marginTop: 20,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightMedium,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }));




export default function PatientMatcher() {
    let rowss = [];
    const classes = useRowStyles();
    const myStyles = useStyles();
    const modalClasses = useModalStyles();
    const [showError, setShowError] = useState(false);
    const headCells = [
      {
        id: "sponsor",
        numeric: false,
        disablePadding: true,
        label: "Sponsor",
        align: "left",
      },
      {
        id: "title",
        numeric: false,
        disablePadding: false,
        label: "Title of Study",
        align: "right",
      },
      {
        id: "protocolNum",
        numeric: true,
        disablePadding: false,
        label: "Protocol Number",
        align: "right",
      },
      {
        id: "inclusionCriteria",
        numeric: true,
        disablePadding: false,
        label: "Inclusion Criteria",
        align: "right",
      },
      {
        id: "exclusionCriteria",
        numeric: true,
        disablePadding: false,
        label: "Exclusion Criteria",
        align: "right",
      },
    ];
  
    const StyledTableRow = withStyles((theme) => ({
        root: {
          "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);

    const {
      user,
    } = useContext(AuthenticationContext);
    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true);
    const history = useHistory();
    const firstUserName = history.location.state.firstUserName;
    const lastUserName = history.location.state.lastUserName;
    const userID = history.location.state.id;
    // console.log("ID: ",userID);
    const title = `Studies of Participant  ${lastUserName}`;
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(5);
    
    const {
      data: studydata,
      loading: studydataloading,
      error: studydataerror,
    } = useQuery(GET_STUDIES, {variables: { uId: userID ,page: page, size: size },});

    // console.log("Retrieve data from Server: ", studydata);
    studydata &&
    studydata.getStudiesCTsIECriteriasByStudiesCTsId.studiesCTsIECriterias.map((item) => {
        // console.log("organization: name  " + item.name);
        // console.log("organization: type  " + item.type);
        // console.log(" organization, locations  " + item.locations);
        rowss.push(
          createData(
            item.title,
            item.protocolNum,
            item.sponsor,
            item.inclusionCriteria,
            item.exclusionCriteria,
            
          )
        );
    });
    // console.log("rowss", rowss);
  
    return(
        <Container>
            <div className={modalClasses.margin} >
                <Button 
                    variant="outlined" 
                    onClick={() =>
                    history.push(`/clinicalstudies/patientmatcher`)
                    }
                    size="large"
                    color="primary"
                >
                    Return
                </Button>
            </div>
            <div className={classes.root}>
                <Typography variant="body1" gutterBottom component="div">
                    Studies Associated with Selected Participant
                </Typography>
            </div>

            {studydata && studydata.getStudiesCTsIECriteriasByStudiesCTsId && (
            <CustomTable
                headCells={headCells}
                rows={rowss}
                title={title}
                rowsPerPage={size}
                setRowsPerPage={setSize}
                rowsPerPageOptions={[10, 20, 30]}
                page={page}
                setPage={setPage}
                totalSize={studydata.getStudiesCTsIECriteriasByStudiesCTsId.totalSize}
            />)}
        
        </Container>
        
        
    );
}