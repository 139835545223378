/* eslint-disable no-unused-vars */
import React, { useState, useContext } from "react";
import {
  Grid,
  InputBase,
  Container,
  Paper,
  IconButton,
  Button,
  ButtonGroup,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from "@mui/icons-material/Search";
import { useQuery } from "@apollo/client";
import { SEARCH_PATIENTS_BY_NAME } from "@cims/common";
import PersonCard from "../../../components/PersonCard";
import { Link } from "react-router-dom";
import LoadingIndicator from "../../../components/LoadingIndicator";
import ErrorInfo from "../../../components/ErrorInfo";
import { AuthenticationContext } from "src/services/authentication/authentication.context";
import { Pagination } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 300,
    marginBottom: 20,
  },
  container: {
    // overflow: "auto",
    // height: 700,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  pagination: {
    display: "flex",
    marginTop: 30,
    justifyContent: "center",
  },
}));

const Patients = () => {
  const classes = useStyles();
  const [text, setText] = useState("");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const { data, loading, error } = useQuery(SEARCH_PATIENTS_BY_NAME, {
    variables: { page: page - 1, size, searchStr: text },
  });

  const handleChange = (event, value) => {
    setPage(value);
  };

  const changeSize = (size) => {
    setPage(1);
    setSize(size);
  };

  const { pickingPatient, openTopMenu, setOpenTopMenu } = useContext(
    AuthenticationContext
  );

  const setAccess = () => {
    //
  };

  return (
    <Container size="sm">
      <Paper component="form" className={classes.root}>
        <InputBase
          className={classes.input}
          placeholder="Search patient by name"
          inputProps={{ "aria-label": "search patient by name" }}
          onChange={(e) => setText(e.target.value)}
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
          size="large">
          <SearchIcon />
        </IconButton>
      </Paper>
      {loading && <LoadingIndicator />}
      {error && <ErrorInfo />}
      {/* <div className={classes.container}> */}
      <Grid container spacing={3} className={classes.container}>
        {data &&
          data.searchAllPatientsByName !== null &&
          data.searchAllPatientsByName.persons.map((person) => {
            return (
              <Grid key={person.id} item xs={12} md={4} lg={3}>
                <Link
                  to={`/patients/${person.id}`}
                  onClick={() => {
                    pickingPatient(person);
                    setOpenTopMenu({
                      ...openTopMenu,
                      patients: true,
                    });
                  }}
                >
                  <PersonCard person={person} />
                </Link>
              </Grid>
            );
          })}
      </Grid>
      {data && data.searchAllPatientsByName !== null && (
        <>
          <div className={classes.pagination}>
            <ButtonGroup
              color="primary"
              aria-label="outlined primary button group"
            >
              {/* <Button onClick={() => changeSize(5)}>5</Button> */}
              <Button onClick={() => changeSize(10)}>10</Button>
              <Button onClick={() => changeSize(20)}>20</Button>
              <Button onClick={() => changeSize(50)}>50</Button>
            </ButtonGroup>
          </div>
          {Math.ceil(Number(data.searchAllPatientsByName.totalSize) / size) >
            1 && (
            <div className={classes.pagination}>
              <Pagination
                variant="outlined"
                color="primary"
                count={Math.ceil(
                  Number(data.searchAllPatientsByName.totalSize) / size
                )}
                page={page}
                onChange={handleChange}
              />
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default Patients;
