import { gql } from "@apollo/client";

export const eBinder_GET_SDTM_DOMAIN_MODEL_BY_DOMAIN = gql`
  query getSdtmDomainModelByDomain($domain: String!) {
    getSdtmDomainModelByDomain(domain: $domain) {
      id
      domain
      variablename
      variablelabel
      type
      codelist
    }
  }
`;

export const eBinder_GET_ALL_SDTM_DOMAIN = gql`
  query getAllSdtmDomain {
    getAllSdtmDomain {
      domainfullname
      domainname
    }
  }
`;

export const eBinder_GET_ALL_SDTM_VERSION = gql`
  query getAllSdtmVersion {
    getAllSdtmVersion {
      verGuid
      verType
      verNo
      verLanguage
      verNote
    }
  }
`;
