import React, { useState, useRef } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import Container from "@mui/material/Container";
import { FormControl, FormLabel, Radio, RadioGroup } from "@mui/material";
import { useHistory } from "react-router";
import { useMutation, useQuery } from "@apollo/client";
import { UHX_SIGNUP, GET_SECURITY_QUESTIONS } from "@cims/common/";
import LoadingIndicator from "src/components/LoadingIndicator";
import Alert from '@mui/material/Alert';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.cims.com/">
        www.cims.com
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  btn: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  btnSig: {
    marginTop: 5,
    display: "flex",
    justifyContent: "flex-end",
    // alignItems: "center",
  },
  sigPad: {
    width: "100%",
    height: "100%",
  },
  sigDiv: {
    width: 200,
    height: 50,
    marginRight: 50,
    border: "1px solid",
  },
  sigImage: {
    // backgroundSize: "200px 50px",
    width: 200,
    height: 50,
    // backgroundColor: "white",
  },
}));

export default function SignUp() {
  const classes = useStyles();
  const history = useHistory();

  const [isPatient, setIsPatient] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [emailInfo, setEmailInfo] = useState(false);
  const [accessDis, setAccessDis] = useState(true);
  const [alert, setAlert] = useState(true);
  const [alertContent, setAlertContent] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [signupInfo, setSignupInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    password2: "",
    role: "PATIENT",
    consentAgreement: "no",
    userAgreement: "no",
    accessCode: "",
    question1: "1",
    question2: "2",
    question3: "3",
    answer1: "",
    answer2: "",
    answer3: "",
  });
  console.log("signupInfo--->", signupInfo);

  const onChangeHandler = (e) => {
    setSignupInfo({
      ...signupInfo,
      [e.target.name]: e.target.value,
    });
  };

  const onEmailChangeHandle = (e) => {
    setSignupInfo({
      ...signupInfo,
      [e.target.name]: e.target.value,
    });
    if (
      !/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
        e.target.value
      )
    ) {
      setEmailInfo(true);
    } else {
      setEmailInfo(false);
    }
  };

  const [signUpUXH, { data, loading, error }] = useMutation(UHX_SIGNUP);

  const {
    data: securityQuestions,
    loading: securityQuestionLoading,
    error: securityQuestionError,
  } = useQuery(GET_SECURITY_QUESTIONS);

  const tempArr = [
    {
      id: 1,
      content: "question 1...",
    },
    {
      id: 2,
      content: "question 2...",
    },
    {
      id: 3,
      content: "question 3...",
    },
    {
      id: 4,
      content: "question 4...",
    },
    {
      id: 5,
      content: "question 5...",
    },
  ];

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                onChange={onChangeHandler}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                onChange={onChangeHandler}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                onChange={onEmailChangeHandle}
                // autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={onChangeHandler}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password2"
                label="Confirm Password"
                type="password"
                id="password2"
                onChange={onChangeHandler}
                // autoComplete="current-password"
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    value="consentAgreement"
                    color="primary"
                    onChange={(e) => {
                      setAccessDis(!accessDis);
                    }}
                  />
                }
                label="If you have the access code, please check this then input at below"
              />
            </Grid>
            {!accessDis && (
              <Grid item xs={12}>
                <TextField
                  autoComplete="aCode"
                  name="accessCode"
                  variant="outlined"
                  required
                  fullWidth
                  id="accessCode"
                  label="Access Code"
                  autoFocus
                  onChange={onChangeHandler}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  You will register as a:
                </FormLabel>
                <RadioGroup
                  aria-label="role"
                  name="role"
                  defaultValue="PATIENT"
                  onChange={(e) => {
                    // setRole(e.target.value);
                    setSignupInfo({
                      ...signupInfo,
                      role: e.target.value,
                    });
                    if (e.target.value === "PATIENT") setIsPatient(true);
                    else setIsPatient(false);
                  }}
                >
                  <FormControlLabel
                    value="PATIENT"
                    control={<Radio color="primary" />}
                    label="Patient"
                  />
                  <FormControlLabel
                    value="PHYSICIAN"
                    control={<Radio color="primary" />}
                    label="Physician"
                  />
                  <FormControlLabel
                    value="EMRADMIN"
                    control={<Radio color="primary" />}
                    label="EMR Administrator"
                  />
                  <FormControlLabel
                    value="EDCADMIN"
                    control={<Radio color="primary" />}
                    label="EDC Administrator"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {isPatient && (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="consentAgreement"
                      color="primary"
                      onChange={(e) => {
                        setSignupInfo({
                          ...signupInfo,
                          consentAgreement: e.target.checked ? "yes" : "no",
                        });
                        // setDisabled(false);
                      }}
                    />
                  }
                  label="Consent Agreement: I Agree to allow my medical record to be used in clinical trial."
                />
              </Grid>
            )}
            {/* {securityQuestions && (
              <>
                <Grid item xs={12}>
                  <TextField
                    name="question1"
                    fullWidth
                    select
                    label="Security question 1:"
                    // helperText="Please select status"
                    variant="outlined"
                    value={signupInfo.question1}
                    onChange={onChangeHandler}
                  >
                    {securityQuestions.getAllSecurityQuestions.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.question}
                        </option>
                      );
                    })}
                  </TextField>
                  <TextField
                    style={{ paddingTop: 10 }}
                    variant="outlined"
                    required
                    fullWidth
                    id="answer1"
                    label="Your answer for question 1"
                    name="answer1"
                    onChange={onChangeHandler}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="question2"
                    fullWidth
                    select
                    label="Security question 2:"
                    // helperText="Please select status"
                    variant="outlined"
                    value={signupInfo.question2}
                    onChange={onChangeHandler}
                  >
                    {securityQuestions.getAllSecurityQuestions
                      .filter((item) => item.id !== signupInfo.question1)
                      .map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.question}
                          </option>
                        );
                      })}
                  </TextField>
                  <TextField
                    style={{ paddingTop: 10 }}
                    variant="outlined"
                    required
                    fullWidth
                    id="answer2"
                    label="Your answer for question 2"
                    name="answer2"
                    onChange={onChangeHandler}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="question3"
                    fullWidth
                    select
                    label="Security question 3:"
                    // helperText="Please select status"
                    variant="outlined"
                    value={signupInfo.question3}
                    onChange={onChangeHandler}
                  >
                    {securityQuestions.getAllSecurityQuestions
                      .filter(
                        (item) =>
                          item.id !== signupInfo.question1 &&
                          item.id !== signupInfo.question2
                      )
                      .map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.question}
                          </option>
                        );
                      })}
                  </TextField>
                  <TextField
                    style={{ paddingTop: 10 }}
                    variant="outlined"
                    required
                    fullWidth
                    id="answer3"
                    label="Your answer for question 3"
                    name="answer3"
                    onChange={onChangeHandler}
                  />
                </Grid>
              </>
            )} */}
          </Grid>

          {alert && (
            <Alert severity={alertSeverity} style={{ marginTop: 15 }}>
              {alertContent}
            </Alert>
          )}

          {error && (
            <Alert severity="error" style={{ marginTop: 15 }}>
              {`Sign up error: ${error}`}
            </Alert>
          )}

          {loading && <LoadingIndicator />}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={disabled}
            className={classes.submit}
            onClick={(e) => {
              const {
                firstName,
                lastName,
                email,
                password,
                password2,
                role,
                consentAgreement,
                accessCode,
                // question1,
                // question2,
                // question3,
                // answer1,
                // answer2,
                // answer3,
              } = signupInfo;
              e.preventDefault();
              if (
                firstName === "" ||
                lastName === "" ||
                email === "" ||
                password === "" ||
                password2 === ""
                // answer1 === "" ||
                // answer2 === "" ||
                // answer3 === ""
              ) {
                setAlert(true);
                setAlertSeverity("error");
                setAlertContent("* All fields are required");
              } else if (password !== password2) {
                setAlert(true);
                setAlertSeverity("error");
                setAlertContent("Two passwords are not matching.");
              } else if (emailInfo) {
                setAlert(true);
                setAlertSeverity("error");
                setAlertContent("Please input right email format");
              } else {
                setAlert(false);
                signUpUXH({
                  variables: {
                    userInfo: {
                      email,
                      password,
                    },
                    personInput: {
                      firstName,
                      lastName,
                      accessCode,
                    },
                    passcode: "passcode",
                    role,
                    consentAgreement,
                    // userSQInput: {
                    //   q1Id: question1,
                    //   q2Id: question2,
                    //   q3Id: question3,
                    //   q1Ans: answer1,
                    //   q2Ans: answer2,
                    //   q3Ans: answer3,
                    // },
                  },
                  // {
                  //   "userInfo": {"email": "ijnwz52d@besttempmail.com", "password": "123456"},
                  //   "personInput": {"firstName": "geng16", "lastName": "da16", "accessCode": ""},
                  //   "passcode": "passcode", "role": "PATIENT", "consentAgreement": "yes",
                  //     "userSQInput": {
                  //     "q1Id": 7, "q1Ans": "an7",
                  //     "q2Id": 4, "q2Ans": "an4",
                  //     "q3Id": 9, "q3Ans": "an9"
                  //   }
                  // }
                })
                  .then(() => {
                    setAlert(true);
                    setAlertSeverity("success");
                    setAlertContent(
                      "Sign up success! Will be back to login page in 2 seconds"
                    );
                    setTimeout(() => {
                      history.push("./login");
                    }, 2000);
                  })
                  .catch((e) => console.log(e));
              }
            }}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}
