import React, { useState, useContext } from "react";
import { Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { ALL_TEST_RESULTS } from "@cims/common/services/testresults.service";
import { AuthenticationContext } from "src/services/authentication/authentication.context";
import TableComponent from "src/components/TableComponent";
import LoadingIndicator from "src/components/LoadingIndicator";
import ErrorInfo from "src/components/ErrorInfo";
import { GlobalContext } from "src/services/global/global.context";

export default function TestResults() {
  const { user } = useContext(AuthenticationContext);
  const { pageSize } = useContext(GlobalContext);

  const [testResultData, setTestResultData] = useState(null);
  const [size, setSize] = useState(pageSize);
  const [page, setPage] = useState(0);

  const { loading: testresultsLoading, error: testresultsError } = useQuery(
    ALL_TEST_RESULTS,
    {
      variables: { patient_id: Number(user.person.patient.id) },
      onCompleted: (data) => {
        const results = data.allTestResultByPatientId.map((item) => {
          const obj = {
            id: item.id,
            name: item.name,
            orderDate: item.orderDate.toString,
            resultDate: item.resultDate.toString,
            status: item.status,
            provider: `${item.provider.firstName} ${item.provider.lastName}`,
          };
          return obj;
        });
        setTestResultData(results);
      },
    }
  );

  const headCells = [
    {
      id: "id",
      numeric: false,
      label: "ID",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "name",
      numeric: false,
      label: "Name",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "orderDate",
      numeric: false,
      label: "Order Date",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "resultDate",
      numeric: false,
      label: "Result Date",
      headerAlign: "left",
    },
    {
      id: "status",
      numeric: false,
      label: "Status",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "provider",
      numeric: false,
      label: "Provider Name",
      headerAlign: "left",
      align: "left",
    },
  ];

  return (
    <div>
      <Typography variant="body1" gutterBottom component="div">
        <h3>Test Results</h3>
      </Typography>
      {testresultsLoading && <LoadingIndicator />}
      {testresultsError && <ErrorInfo />}
      {testResultData && (
        <TableComponent
          headCells={headCells}
          rows={testResultData}
          rowsPerPage={size}
          setRowsPerPage={setSize}
          page={page}
          setPage={setPage}
          totalSize={testResultData.length}
        />
      )}
    </div>
  );
}
