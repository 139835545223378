import { CircularProgress } from "@mui/material";
import React from "react";

export default function LoadingIndicator() {
  return (
    <div style={{ display: "flex", justifyContent: "center", margin: 20 }}>
      <CircularProgress />
    </div>
  );
}
