/** Params:
 * headCells = [],                      table header
  rows = [],                            data rows
  title = "",                           table title
  rowsPerPage = 20,                      rows per page
  setRowsPerPage = () => {},            function to change rows per page
  rowsPerPageOptions = [20, 30, 50],     options for rows per page
  page = 0,                             current page number
  setPage = () => {},                   function to change current page
  totalSize = 0,                        data row total number
  allowViewSingle = false,              allow to click the view button to view a single item
  viewSingle = () => {},                function to view a single item
  allowView = false,                    allow to view row data
  viewRows = () => {},                  function to view row data
  allowDelete = false,                  allow to delete row data
  deleteRows = () => {},                function to delete rows, params: IDs array
  allowEdit = false,                    allow to edit row data
  editRow = () => {},                   function to edit row, params: id
  allowEditAccess = false,              allow to edit user access
  editAccess = () => {},                function to edit access, params: id
  allowSDV = false,                     allow sdv
  sdvRow = () => {},                    function of SDV, params: id
  allowSelect = false,                  allow select
  selectRow = () => {},                 function of select, params: id
  sortBy = () => {}                     sort by categrery, params: categery name and order("desc" or "asc")
  hasToolbar = true,                    has Toolbar or not
  dataHasPagination = true,             data from database has pagination or not
  isDense = false                       Dense padding
 */

/** Sample:
 * 
 * const headCells = [
    {
      id: "fromUserName",
      numeric: false,
      disablePadding: false,
      label: "Filename",
      path: ["fileLocation"],
    },
    {
      id: "timestamp",
      numeric: false,
      disablePadding: false,
      label: "Uploaded Date",
      path: ["timestamp"],
    },

    {
      id: "source",
      numeric: false,
      disablePadding: false,
      label: "Source of Data",
      path: ["sourceOfData"],
    },
  ];
 * <CustomTable
    headCells={headCells}
    rows={encryptedFile.findEncryptFilesPage}
    title={"Uploaded Medical Documents"}
    rowsPerPage={size}
    setRowsPerPage={setSize}
    rowsPerPageOptions={[5, 10, 20]}
    page={page}
    setPage={setPage}
    totalSize={30}
    hasToolbar={false}
    allowDelete={true}
    deleteRows={testDelete}
    allowView={true}
    viewRows={viewing}
    allowViewSingle={true}
    viewSingle={viewFile}
    sortBy={sortBy}
  />
 */

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten } from "@mui/material/styles";
import { makeStyles, withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FilterListIcon from "@mui/icons-material/FilterList";
import EditIcon from "@mui/icons-material/Edit";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { blue } from "@mui/material/colors";

const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: blue[700],
    // color: theme.palette.common.white,
  },
  // body: {
  //   fontSize: 14,
  // },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead({
  headCells,
  allowViewSingle = false,
  allowEdit = false,
  allowEditAccess = false,
  allowSDV = false,
  allowSelect = false,
  sortBy = () => {},
  ...props
}) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    hasToolbar = true,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
    sortBy(property, order.toUpperCase());
  };
  const sortHandler = (name, o) => {
    sortBy(name, o);
  };

  return (
    <TableHead>
      <TableRow>
        {hasToolbar && (
          <StyledTableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </StyledTableCell>
        )}
        {allowViewSingle && (
          <StyledTableCell padding="checkbox">
            <Tooltip title="View">
              <IconButton aria-label="view">
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          </StyledTableCell>
        )}
        {allowEdit && (
          <StyledTableCell padding="checkbox">
            Edit
            {/* <Tooltip title="Edit">
              <IconButton aria-label="edit">
                <EditIcon />
              </IconButton>
            </Tooltip> */}
          </StyledTableCell>
        )}
        {allowEditAccess && (
          <StyledTableCell padding="checkbox">Access</StyledTableCell>
        )}

        {allowSDV && (
          <StyledTableCell padding="checkbox">
            <Typography>Verify</Typography>
          </StyledTableCell>
        )}
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            // align="left"
            align={`${headCell.headerAlign}`}
            // padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              // onClick={() => sortHandler(headCell.id, order)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
        {allowSelect && <StyledTableCell>Pick Role</StyledTableCell>}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = ({
  title = "",
  allowDelete = false,
  deleteRows = () => {},
  allowView = false,
  viewRows = () => {},
  allowEdit = false,
  editRow = () => {},
  allowEditAccess = false,
  editAccess = () => {},
  allowSDV = false,
  sdvRow = () => {},
  allowSelect = false,
  selectRow = () => {},
  selected = [],
  ...props
}) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      )}

      {numSelected > 0 ? (
        <>
          {allowView && (
            <Tooltip title="View">
              <IconButton aria-label="view" onClick={() => viewRows(selected)}>
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          )}
          {allowDelete && (
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                onClick={() => deleteRows(selected)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function CustomTable({
  headCells = [],
  rows = [],
  title = "",
  rowsPerPage = 20,
  setRowsPerPage = () => {},
  rowsPerPageOptions = [20, 30, 50],
  page = 0,
  setPage = () => {},
  totalSize = 0,
  allowViewSingle = false,
  viewSingle = () => {},
  allowView = false,
  viewRows = () => {},
  allowDelete = false,
  deleteRows = () => {},
  allowEdit = false,
  editRow = () => {},
  allowEditAccess = false,
  editAccess = () => {},
  allowSDV = false,
  sdvRow = () => {},
  allowSelect = false,
  selectRow = () => {},
  sortBy = () => {},
  hasToolbar = true,
  dataHasPagination = true,
  isDense = false,
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  // const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(isDense);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, totalSize - page * rowsPerPage);

  const newRows = dataHasPagination
    ? rows
    : stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {hasToolbar && (
          <EnhancedTableToolbar
            title={title}
            numSelected={selected.length}
            allowView={allowView}
            viewRows={viewRows}
            allowDelete={allowDelete}
            deleteRows={deleteRows}
            allowEdit={allowEdit}
            editRow={editRow}
            allowEditAccess={allowEditAccess}
            editAccess={editAccess}
            allowSDV={allowSDV}
            sdvRow={sdvRow}
            allowSelect={allowSelect}
            selectRow={selectRow}
            selected={selected}
          />
        )}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              allowViewSingle={allowViewSingle}
              allowEdit={allowEdit}
              allowEditAccess={allowEditAccess}
              allowSDV={allowSDV}
              allowSelect={allowSelect}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={totalSize}
              headCells={headCells}
              sortBy={sortBy}
              hasToolbar={hasToolbar}
            />
            <TableBody>
              {
                // stableSort(rows, getComparator(order, orderBy))
                //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                newRows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <StyledTableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      {hasToolbar && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                      )}

                      {allowViewSingle && (
                        <TableCell padding="checkbox">
                          {/* <Tooltip title="View"> */}
                          <IconButton
                            aria-label="view"
                            onClick={() => viewSingle(row.id)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                          {/* </Tooltip> */}
                        </TableCell>
                      )}

                      {allowEdit && (
                        <TableCell padding="checkbox">
                          <IconButton
                            aria-label="edit"
                            onClick={() => editRow(row.id)}
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      )}

                      {allowEditAccess && (
                        <TableCell padding="checkbox">
                          <IconButton
                            aria-label="edit"
                            onClick={() => editAccess(row.id)}
                          >
                            <VpnKeyIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      )}

                      {allowSDV && (
                        <TableCell padding="checkbox">
                          <IconButton
                            aria-label="sdv"
                            onClick={() => sdvRow(row.id)}
                          >
                            <LibraryAddCheckIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      )}

                      {headCells.map((headCell, i) => {
                        let rowValue = row,
                          rowValue2 = row,
                          rowValue3 = row,
                          rowValue4 = row;
                        for (let j = 0; j < headCell.path.length; j++) {
                          rowValue = rowValue[headCell.path[j]];
                        }
                        if (headCell.path2) {
                          for (let j = 0; j < headCell.path2.length; j++) {
                            rowValue2 = rowValue2[headCell.path2[j]];
                          }
                        }
                        if (headCell.path3) {
                          for (let j = 0; j < headCell.path3.length; j++) {
                            rowValue3 = rowValue3[headCell.path3[j]];
                          }
                        }
                        if (headCell.path4) {
                          for (let j = 0; j < headCell.path4.length; j++) {
                            rowValue4 = rowValue4[headCell.path4[j]];
                          }
                        }

                        let rowValueTotal = `${rowValue}${
                          !headCell.path2 ? "" : " " + rowValue2
                        }${!headCell.path3 ? "" : " " + rowValue3}${
                          !headCell.path4 ? "" : " " + rowValue4
                        }`;

                        const testingIcon = (
                          <div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <div></div>
                              <div
                                style={{
                                  width: 20,
                                  height: 8,
                                  backgroundColor: "red",
                                  border: "solid 1px",
                                  margin: "1px",
                                }}
                              ></div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <div></div>
                              <div
                                style={{
                                  width: 20,
                                  height: 8,
                                  backgroundColor: "green",
                                  border: "solid 1px",
                                  margin: "1px",
                                }}
                              ></div>
                            </div>
                          </div>
                        );

                        if (headCell.func) {
                          rowValueTotal = headCell.func(rowValueTotal, row.id);
                        }

                        if (i === 0) {
                          return (
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              // padding="none"
                              key={Math.random()}
                              align={`${headCell.align}`}
                            >
                              {/* {row[headCell.id]} */}
                              {rowValueTotal}
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell
                              key={Math.random()}
                              align={`${headCell.align}`}
                              // align="right"
                            >
                              {headCell.before ? (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: 5,
                                  }}
                                >
                                  {testingIcon}
                                  <div>{rowValueTotal}</div>
                                </div>
                              ) : (
                                rowValueTotal
                              )}
                            </TableCell>
                          );
                        }
                      })}
                      {allowSelect && (
                        <TableCell padding="checkbox">
                          <IconButton
                            aria-label="select role"
                            onClick={() => selectRow(row.id)}
                          >
                            <KeyboardDoubleArrowRightIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      )}
                    </StyledTableRow>
                  );
                })
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={totalSize}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </div>
  );
}
