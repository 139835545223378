/*
 * @Author: your name
 * @Date: 2022-03-08 13:30:57
 * @LastEditTime: 2022-03-10 10:34:46
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /cims-dev/packages/UHX/src/pages/utilities/pages/TwoFactorAuthentication.page.js
 */
import React, { useState, useContext, useEffect } from "react";
import {
  Container,
  Typography,
  TextField,
  Grid,
  Button,
  Modal,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import { Form, useForm } from "../../communication/components/useForm";
import Controls from "src/pages/communication/components/controls/Controls";
import LoadingIndicator from "../../../components/LoadingIndicator";
import ErrorInfo from "../../../components/ErrorInfo";
import { useQuery, useMutation } from "@apollo/client";

import { 
  GET_TWOFA_INFO,
  UPDATE_TWOFA_PHONE,
  UPDATE_TWOFA_EMAIL,
  UPDATE_TWOFA_PREFER
 } from "@cims/common";
import { AuthenticationContext } from "src/services/authentication/authentication.context";
import { Refresh } from "@mui/icons-material";
import { blue } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: 20,
    marginBottom: 20,
    display: "block",
    "& .MuiInputBase-root.Mui-disabled": {
      cursor: "not-allowed",
      pointerEvents: "auto",
      color: "#000",
    },
    "& .MuiFormLabel-root.Mui-disabled": {
      color: "#000",
    },
  },
  paper: {
    position: "absolute",
    width: 340,
    height: 300,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 3),
    top: "25%",
    left: "45%",
    textAlign: "center",
  },
  btnCancel: {
    color: "#21B1F6",
    borderColor: blue[300],
    marginTop: 20,
    margin: 10,
  },
  radioGroup: {
    margin: theme.spacing(1),
  },
  radioItem: {
    margin: theme.spacing(1),
    paddingLeft: 30
  },
  radioButton: {
    margin: theme.spacing(1),
  },
}));

const initialFValues = {
  id: 0,
  phone_2fa: "",
  email_2fa: "",
};

export const TwoFactorAuthentication = ({}) => {

  const classes = useStyles();

  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [prefer, setPrefer] = useState("phone");
  const [updatePhoneFlag, setUpdatePhoneFlag] = useState(false);
  const [updateEmailFlag, setUpdateEmailFlag] = useState(false);
  const [updatePreferFlag, setUpdatePreferFlag] = useState(true);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
  useForm(initialFValues, true);

  const { user, contextHeaders } = useContext(AuthenticationContext);

  const {
    data: twofaData,
    loading: twofaLoading,
    error: twofaError,
  } = useQuery(GET_TWOFA_INFO, {
    variables: { patient_id: user.person.patient ? Number(user.person.patient.id) : 0 },
    context: contextHeaders,
  });
  console.log("twofaData");
  console.log(twofaData);

  useEffect(() => {
    if (twofaData && twofaData.find2faInfoByPtId.length !== 0 && user.person.patient) {
      setPrefer(twofaData.find2faInfoByPtId[0].prefer);
      setPhone(twofaData.find2faInfoByPtId[0].phoneNumber);
      setEmail(twofaData.find2faInfoByPtId[0].emailAddress);
    }
  }, [twofaData]);

  const [updatePhone, { dataPhone, loadingPhone, errorPhone }] = useMutation(
    UPDATE_TWOFA_PHONE,
    {
      context: contextHeaders,
      refetchQueries: [
        {
          query: GET_TWOFA_INFO,
          variables: {
            patient_id: Number(user.person.patient.id)
          },
          context: contextHeaders,
        },
      ],
    }
  );

  const updateNewPhone = (values) => {
    updatePhone({
      variables: {
        patient_id: Number(user.person.patient.id),
        phoneNumber: formatPhoneNumber(values.phone_2fa),
      },
    });
  };

  const [updateEmail, { dataEmail, loadingEmail, errorEmail }] = useMutation(
    UPDATE_TWOFA_EMAIL,
    {
      context: contextHeaders,
      refetchQueries: [
        {
          query: GET_TWOFA_INFO,
          variables: {
            patient_id: Number(user.person.patient.id)
          },
          context: contextHeaders,
        },
      ],
    }
  );

  const updateNewEmail = (values) => {
    updateEmail({
      variables: {
        patient_id: Number(user.person.patient.id),
        emailAddress: values.email_2fa,
      },
    });
  };

  const [updatePrefer, { dataPrefer, loadingPrefer, errorPrefer }] = useMutation(
    UPDATE_TWOFA_PREFER,
    {
      variables: {
        patient_id: Number(user.person.patient.id),
        prefer: prefer,
      },
      context: contextHeaders,
      refetchQueries: [
        {
          query: GET_TWOFA_INFO,
          variables: {
            patient_id: Number(user.person.patient.id)
          },
          context: contextHeaders,
        },
      ],
    }
  );

  const handlePhoneUpdate = () => { setUpdatePhoneFlag(true); };
  const handlePhoneClose = () => {
    resetForm();
    setUpdatePhoneFlag(false);
  };
  const handlePhoneSubmit = (e) => {
    e.preventDefault();
    updateNewPhone(values);
    handlePhoneClose();
    resetForm();
  };

  const handleEmailUpdate = () => { setUpdateEmailFlag(true); };
  const handleEmailClose = () => {
    resetForm();
    setUpdateEmailFlag(false);
  };
  const handleEmailSubmit = (e) => {
    e.preventDefault();
    updateNewEmail(values);
    handleEmailClose();
    resetForm();
  };

  const handlePreferChange = (e) => {
    setPrefer(e.target.value);
    setUpdatePreferFlag(false);
  };

  const handlePreferUpdate = (e) => {
    setUpdatePreferFlag(true);
    updatePrefer();
  };

  const formatPhoneNumber = (str) => {
    if (str.length !== 10) return;
    str = str.substr(0, 3) + "-" + str.substr(3, 3) + "-" + str.substr(6, 4);
    return str;
  };

  const updatePhonePage = (
    <div className={classes.paper}>
      <h3 id="add-invoice-title">Update Phone Number</h3>
      <Form onSubmit={handlePhoneSubmit}>
        <Grid container>
          <Grid item xs={1}>
            <Controls.Input
              label="Input New Phone Number"
              name="phone_2fa"
              value={values.phone_2fa}
              onChange={handleInputChange}
              error={errors.phone_2fa}
            />
          </Grid>
        </Grid>
        <div className={classes.invoiceBtn}>
          <Controls.Button
            type="submit"
            text="Submit"
            style={{ width: "94px" }}
          />
          <Controls.Button
            text="Cancel"
            color="default"
            onClick={handlePhoneClose}
            style={{ width: "94px" }}
          />
        </div>
      </Form>
    </div>
  );

  const updateEmailPage = (
    <div className={classes.paper}>
      <h3 id="add-invoice-title">Update Email Address</h3>
      <Form onSubmit={handleEmailSubmit}>
        <Grid container>
          <Grid item xs={1}>
            <Controls.Input
              label="Input New Email Address"
              name="email_2fa"
              value={values.email_2fa}
              onChange={handleInputChange}
              error={errors.email_2fa}
            />
          </Grid>
        </Grid>
        <div className={classes.invoiceBtn}>
          <Controls.Button
            type="submit"
            text="Submit"
            style={{ width: "94px" }}
          />
          <Controls.Button
            text="Cancel"
            color="default"
            onClick={handleEmailClose}
            style={{ width: "94px" }}
          />
        </div>
      </Form>
    </div>
  );

  return (
    <Container size="sm">
      <Typography
        variant="h6"
        color="textSecondary"
        component="h2"
        gutterBottom
      >
        View and Update your 2FA information
      </Typography>

      {/* <div>
        {alert ? <Alert severity={alertSeverity}>{alertContent}</Alert> : <></>}
      </div> */}

      {user.person.patient == null ? 
        <Typography
          variant="h7"
          color="textSecondary"
          component="h2"
          gutterBottom
        >
          You are not patient.
        </Typography> : null
      }

      {twofaData && user.person.patient && 
      <div>
      <form noValidate autoComplete="off">

        <TextField
          type="text"
          className={classes.field}
          label="Phone Number"
          value={phone}
          variant="outlined"
          color="secondary"
          disabled={true}
          fullWidth
        />

        <TextField
          type="text"
          className={classes.field}
          label="Email Address"
          value={email}
          variant="outlined"
          color="secondary"
          disabled={true}
          fullWidth
        />

        <FormControl component="fieldset">
          <FormLabel component="legend">
            Choose Your Prefer Way to Send Authentication Code
          </FormLabel>
          <RadioGroup
            row 
            aria-label="preferWay" 
            name="preferWay" 
            value={prefer} 
            onChange={handlePreferChange}
            className={classes.radioGroup}
          >
            <FormControlLabel 
              className={classes.radioItem}
              value="phone" 
              control={<Radio />} 
              label="Phone" 
            />

            <FormControlLabel 
              className={classes.radioItem}
              value="email" 
              control={<Radio />} 
              label="Email" 
            />

            <div style={{ paddingLeft: 30 }}>
              <Button 
                className={classes.radioButton}
                variant="contained" 
                color="secondary" 
                disabled={updatePreferFlag}
                onClick={() => {
                  handlePreferUpdate();
                }}
              >
                Update
              </Button>
            </div>

          </RadioGroup>
        </FormControl>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="outlined"
            size="large"
            className={classes.btnCancel}
            onClick={handlePhoneUpdate}
          >
            Update Phone Number
          </Button>

          <Button
            variant="outlined"
            size="large"
            className={classes.btnCancel}
            onClick={handleEmailUpdate}
          >
            Update Email Address
          </Button>
        </div>
      </form>

      <div>
        <Modal open={updatePhoneFlag} onClose={handlePhoneClose}>
          {updatePhonePage}
        </Modal>
      </div>

      <div>
        <Modal open={updateEmailFlag} onClose={handleEmailClose}>
          {updateEmailPage}
        </Modal>
      </div>

      </div>
      }
    </Container>
  );

}

export default TwoFactorAuthentication;
