import React, { useState, useContext } from "react";

import { Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { ALL_DEMOGRAPHICS_BY_ID } from "@cims/common/";
import { AuthenticationContext } from "src/services/authentication/authentication.context";
import { GlobalContext } from "src/services/global/global.context";
import LoadingIndicator from "src/components/LoadingIndicator";
import ErrorInfo from "src/components/ErrorInfo";
import TableComponent from "src/components/TableComponent";

export default function Demographic() {
  const { user } = useContext(AuthenticationContext);
  const { pageSize } = useContext(GlobalContext);

  const [demographicsData, setDemographicsData] = useState(null);
  const [size, setSize] = useState(pageSize);
  const [page, setPage] = useState(0);

  const { loading: demographicsLoading, error: demographicsError } = useQuery(
    ALL_DEMOGRAPHICS_BY_ID,
    {
      variables: { patientId: Number(user.person.patient.id) },
      onCompleted: (data) => {
        const results = data.allDemographicsByPatientId.map((item) => {
          const obj = {
            dateOfBirth: item.dateOfBirth,
            dateOfDeath: item.dateOfDeath,
            race: item.race,
            ethnicity: item.ethnicity,
            sex: item.sex,
            country: item.country,
            preferredLanguage: item.preferredLanguage,
            dataSource: item.dataSource,
          };
          return obj;
        });

        setDemographicsData(results);
      },
    }
  );
  console.log("result=====>", demographicsData);
  console.log("error===>", demographicsError);
  const headCells = [
    {
      id: "dateOfBirth",
      numeric: false,
      label: "Date of Birth",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "dateOfDeath",
      numeric: false,
      label: "Date of Death",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "race",
      numeric: false,
      label: "Race",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "ethnicity",
      numeric: false,
      label: "Ethnicity",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "sex",
      numeric: false,
      label: "Sex",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "country",
      numeric: false,
      label: "Country",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "preferredLanguage",
      numeric: false,
      label: "Preferred Language",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "dataSource",
      numeric: false,
      label: "Data Source",
      headerAlign: "left",
      align: "left",
    },
  ];

  return (
    <div>
      <Typography variant="body1" gutterBottom component="div">
        <h3>Demographics</h3>
      </Typography>
      {demographicsLoading && <LoadingIndicator />}
      {demographicsError && <ErrorInfo />}
      {demographicsData && (
        <TableComponent
          headCells={headCells}
          rows={demographicsData}
          rowsPerPage={size}
          setRowsPerPage={setSize}
          page={page}
          setPage={setPage}
          totalSize={demographicsData.length}
        />
      )}
    </div>
  );
}
