import React, { useState, useContext } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import makeStyles from '@mui/styles/makeStyles';
import TextField from "@mui/material/TextField";
import { useHistory } from "react-router-dom";
import { AuthenticationContext } from "../../../services/authentication/authentication.context";
import { useEffect } from "react";
import axios from "axios";
import Alert from '@mui/material/Alert';
import { serverUrl } from "@cims/common/";

const useStyles = makeStyles({
  field: {
    marginTop: 20,
    marginBottom: 20,
    display: "block",
  },
  error: {
    marginTop: 20,
  },
});

export default function Login() {
  const classes = useStyles();
  const history = useHistory();
  const [passcode, setPasscode] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [passcodeError, setPasscodeError] = useState(false);
  const [fileUrlError, setFileUrlError] = useState(false);

  const [formData, setFormData] = useState(null);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const { user, contextHeaders } = useContext(AuthenticationContext);
  const [uploadedFile, setUploadedFile] = useState("");
  const [uploadedFileUrl, setUploadedFileUrl] = useState("");
  const [fileName, setFileName] = useState(null);

  const { onLogin, loginError, isLoading } = useContext(AuthenticationContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    setPasscodeError(false);

    if (passcode == "") {
      setPasscodeError(true);
      setAlert(true);
      setAlertSeverity("error");
      setAlertContent("Your Temporary Passcode is blank!");
    }
    // if (fileUrl == "") {
    //   setFileUrlError(true);
    // }
    if (passcode) {
      decryptFileFromURL();
    }
  };

  const encryptedFileURL = window.location.href;
  const siteUrl = serverUrl;

  useEffect(() => {
    console.log("Download page...");
    if (localStorage.getItem("user")) history.push("/");
  });

  const decryptFileFromURL = () => {
    const arrays = encryptedFileURL.split("/");
    const filename = arrays[arrays.length - 1]; // Or parts.pop();
    const personId = arrays[arrays.length - 2];
    axios
      .get(
        siteUrl +
          "/api/decryptfile/" +
          personId +
          "/" +
          passcode +
          "/" +
          filename,
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        console.log("response", response.data.fileUrl);
        setUploadedFile(filename);
        setUploadedFileUrl(response.data.fileUrl);
        setAlert(true);
        setAlertSeverity("success");
        setAlertContent(
          "The file [" +
            filename +
            "] has been decrypted and downloaded successfully!"
        );
        console.log("decryptFile, filename: ", filename);

        var file = new Blob([response.data]);
        const url = window.URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${filename}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.log("decrypted", false);
        setAlert(true);
        setAlertSeverity("error");
        setAlertContent(
          "Check your Download Link, Temporary Passcode, and then try it again!"
        );
        console.log("decrypted error" + error);
      });
  };

  return (
    <Container size="sm">
      <Typography variant="h6" color="textSecondary" gutterBottom>
        Please enter the temporary passcode to decrypt and download the file.
      </Typography>

      <div>
        {alert ? <Alert severity={alertSeverity}>{alertContent}</Alert> : <></>}
      </div>

      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <TextField
          type="password"
          className={classes.field}
          onChange={(e) => setPasscode(e.target.value)}
          label="Temporary Passcode"
          variant="outlined"
          color="primary"
          fullWidth
          required
          error={passcodeError}
        />

        {/* <TextField
          className={classes.field}
          onChange={(e) => setFileUrl(e.target.value)}
          label="Ecrypted File URL"
          variant="outlined"
          color="primary"
          fullWidth
          required
          error={fileUrlError}
        /> */}

        <center>
          <div>
            <Button
              className={classes.iconButton}
              aria-label="Attach"
              color="primary"
              onClick={handleSubmit}
              color="primary"
              variant="contained"
            >
              Decrypt and Download File
            </Button>
          </div>
        </center>

        {loginError !== undefined && (
          <Typography
            className={classes.error}
            variant="body1"
            color="error"
            gutterBottom
          >
            {loginError.toString()}
          </Typography>
        )}
      </form>
    </Container>
  );
}
