import React, { useContext } from "react";
import { Grid, Typography } from "@mui/material";

import AppointmentTypeCard from "../components/AppointmentTypeCard";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
import { AppointmentContext } from "../../../services/appointment/appointment.context";
import LoadingIndicator from "../../../components/LoadingIndicator";
import ErrorInfo from "../../../components/ErrorInfo";
import { AuthenticationContext } from "src/services/authentication/authentication.context";

const ScheduleAppointment = () => {
  // const { data, loading, error } = useQuery(ALL_SCHEDULE_APPT);
  // console.log("data--->", data);
  const {
    appointments,
    appointmentsLoading,
    appointmentsError,
    yourAppointment,
    pickingAppointment,
  } = useContext(AppointmentContext);
  const { curPickedPatient } = useContext(AuthenticationContext);
  // console.log("yourAppointment--->", yourAppointment);
  return (
    <Container size="sm">
      <Typography
        variant="body1"
        component="p"
        gutterBottom
        style={{ marginBottom: 20 }}
      >
        {`Making an appointment for ${curPickedPatient.firstName} ${curPickedPatient.lastName} | Please choose one catergary`}
      </Typography>

      {appointmentsLoading && <LoadingIndicator />}
      {appointmentsError && <ErrorInfo />}

      <Grid container spacing={3}>
        {appointments &&
          appointments.allScheduleAppt.map((appointment) => {
            return (
              <Grid key={appointment.id} item xs={12} md={4} lg={3}>
                <Link
                  to={`/patients/${curPickedPatient.id}/visits/scheduling/${appointment.id}`}
                  onClick={() => {
                    pickingAppointment({
                      appt: appointment.name,
                      subjectId: appointment.id,
                    });
                  }}
                >
                  <AppointmentTypeCard appointments={appointment} />
                </Link>
              </Grid>
            );
          })}
      </Grid>
    </Container>
  );
};

export default ScheduleAppointment;
