import {
  FIND_ORGANIZATION_BYID,
  ADD_ONE_ORG_BYHSID,
  ADD_NEW_LOCATION,
  getState,
  DELETE_ORGANIZATION,
  DELETE_LOCATION,
  UPDATE_LOCATION,
  UPDATE_ORGANIZATION,
} from "@cims/common";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "../../communication/components/useForm";

import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

import Modal from "@mui/material/Modal";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "react-quill/dist/quill.snow.css";
import Button from "@mui/material/Button";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Fade,
  Backdrop,
  TextField,
  Typography,
} from "@mui/material";

import React, { useState } from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { blue } from '@mui/material/colors';

function getModalStyle() {
  // const top = 50 + rand();
  // const left = 50 + rand();
  const top = 15;
  const left = 35;
  const textAlign = "center";
  return {
    top: `${top}%`,
    left: `${left}%`,
    // transform: `translate(-${top}%, -${left}%)`,
    // textAlign: `${}`,
  };
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
    // backgroundColor: "green",
    // color: theme.palette.common.white,
    color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
    // height: "40px",
  },
  addBtnIcon: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
    color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
    "&:hover": {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#BDC0BA" : blue[700],
      color: "#fff",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50px",
  },
  addBtnIcon: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
    color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
    "&:hover": {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#BDC0BA" : blue[700],
      color: "#fff",
    },
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const useModalStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "#f5f3f0", //theme.palette.background.paper,
    border: "1px solid #666666",
    width: 700,
    maxHeight: 700,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "auto",
  },
  tf: {
    marginBottom: 8,
  },
  btn: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function createData(id, name, type, email, phone, status, locations) {
  return {
    id,
    name,
    type,
    email,
    phone,
    status,
    locations,
  };
}

function Row(props) {
  const initialValues = {
    locationName: "",
    address: "",
    state: "AL",
    zip: "",
    locationPhone: "",
  };
  const {
    row,
    hsId,
    deleteOrg,
    setOpenDelOrg,
    setOpenDelLoc,
    setOrgId,
    setLocId,
  } = props;
  // console.log("row--->", row);
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const modalClasses = useModalStyles();

  const [openModal, setOpenModal] = useState(false);
  const [showError, setShowError] = useState(false);

  const [openEditLoc, setOpenEditLoc] = useState(false);
  const [curLoc, setCurLoc] = useState({});

  const [openEditOrg, setOpenEditOrg] = useState(false);
  const [curOrg, setCurOrg] = useState({});

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const [
    addLocationsToOrg,
    { data: newLocationData, error: newLocationError },
  ] = useMutation(ADD_NEW_LOCATION, {
    refetchQueries: [
      {
        query: FIND_ORGANIZATION_BYID,
        variables: {
          hs_id: Number(hsId),
        },
      },
    ],
  });

  const [
    updateLocation,
    { data: newEditLocationData, error: newEditLocationError },
  ] = useMutation(UPDATE_LOCATION, {
    refetchQueries: [
      {
        query: FIND_ORGANIZATION_BYID,
        variables: {
          hs_id: Number(hsId),
        },
      },
    ],
  });

  const [
    updateOrganizationFields,
    { data: newEditOrgData, error: newEditOrgError },
  ] = useMutation(UPDATE_ORGANIZATION, {
    refetchQueries: [
      {
        query: FIND_ORGANIZATION_BYID,
        variables: {
          hs_id: Number(hsId),
        },
      },
    ],
  });

  return (
    <React.Fragment>
      <div>
        {/** add loc */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={modalClasses.modal}
          open={openModal}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <div className={modalClasses.paper}>
              <div style={{ width: "100%" }}>
                <TextField
                  className={modalClasses.tf}
                  fullWidth
                  required
                  name="locationName"
                  id="location-name"
                  label="Location name"
                  variant="outlined"
                  value={values.lacationName}
                  onChange={handleInputChange}
                />

                <TextField
                  className={modalClasses.tf}
                  fullWidth
                  required
                  name="address"
                  id="location-address"
                  label="Address"
                  variant="outlined"
                  value={values.address}
                  onChange={handleInputChange}
                />
                <TextField
                  id="organization-status"
                  name="state"
                  select
                  label="State"
                  // value="none"
                  // onChange={handleChange}
                  // SelectProps={{
                  //   native: true,
                  // }}
                  helperText="Please select status"
                  variant="outlined"
                  value={values.state}
                  onChange={handleInputChange}
                >
                  {getState().map((item) => (
                    <option key={item.id} value={item.title}>
                      {item.title}
                    </option>
                  ))}
                </TextField>
                <TextField
                  className={modalClasses.tf}
                  fullWidth
                  required
                  name="zip"
                  id="location-zip"
                  label="Zip code"
                  variant="outlined"
                  value={values.zip}
                  onChange={handleInputChange}
                />
                <TextField
                  className={modalClasses.tf}
                  fullWidth
                  required
                  name="locationPhone"
                  id="location-phone"
                  label="Telephone Number"
                  variant="outlined"
                  value={values.locationPhone}
                  onChange={handleInputChange}
                  error={errors.locationPhone}
                />
              </div>

              {showError && (
                <div className={modalClasses.btn}>
                  <Typography color="error">
                    All fields are required.
                  </Typography>
                </div>
              )}
              <div className={modalClasses.btn}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    console.log("id--->", row.id);
                    let pass = true;
                    for (let key in values) {
                      if (values[key] === "") {
                        pass = false;
                        setShowError(true);
                      }
                    }
                    if (pass) {
                      setShowError(false);
                      addLocationsToOrg({
                        variables: {
                          org_id: Number(row.id),
                          locations: [
                            {
                              locationName: values.locationName,
                              telNumb: values.locationPhone,
                              address: values.address,
                              stateName: values.state,
                              zipCode: values.zip,
                              status: "active",
                            },
                          ],
                        },
                      }).then(() => {
                        resetForm();
                        handleClose();
                        console.log("successful!");
                      });
                    }
                  }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Fade>
        </Modal>

        {/** edit org */}
        <Modal
          className={modalClasses.modal}
          open={openEditOrg}
          onClose={() => setOpenEditOrg(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openEditOrg}>
            <div className={modalClasses.paper}>
              <div style={{ width: "100%" }}>
                <TextField
                  className={modalClasses.tf}
                  fullWidth
                  required
                  name="organizationName"
                  id="organization-name"
                  label="Organization name"
                  variant="outlined"
                  defaultValue={
                    curOrg !== {} ? curOrg.name : values.organizationName
                  }
                  value={values.organizationName}
                  onChange={handleInputChange}
                />

                <TextField
                  className={modalClasses.tf}
                  fullWidth
                  required
                  name="organizationType"
                  id="organization-type"
                  label="Organization type"
                  variant="outlined"
                  defaultValue={
                    curOrg !== {} ? curOrg.type : values.organizationType
                  }
                  value={values.organizationType}
                  onChange={handleInputChange}
                />

                <TextField
                  className={modalClasses.tf}
                  fullWidth
                  required
                  name="organizationPhone"
                  id="organization-phone"
                  label="Organization phone number"
                  variant="outlined"
                  defaultValue={
                    curOrg !== {} ? curOrg.phone : values.organizationPhone
                  }
                  value={values.organizationPhone}
                  onChange={handleInputChange}
                  error={errors.organizationPhone}
                />
                <TextField
                  className={modalClasses.tf}
                  fullWidth
                  required
                  name="organizationEmail"
                  id="organization-email"
                  label="Organization email"
                  variant="outlined"
                  defaultValue={
                    curOrg !== {} ? curOrg.email : values.organizationEmail
                  }
                  value={values.organizationEmail}
                  onChange={handleInputChange}
                  error={errors.organizationEmail}
                />
              </div>

              {showError && (
                <div className={modalClasses.btn}>
                  <Typography color="error">
                    All fields are required.
                  </Typography>
                </div>
              )}
              <div className={modalClasses.btn}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    console.log("id--->", row.id);
                    let pass = true;

                    if (
                      values.organizationName === "" ||
                      values.organizationType === "" ||
                      values.organizationPhone === "" ||
                      values.organizationEmail === ""
                    ) {
                      pass = false;
                      setShowError(true);
                    }

                    if (pass) {
                      setShowError(false);
                      updateOrganizationFields({
                        variables: {
                          id: curOrg.id,
                          name: values.organizationName,
                          type: values.organizationType,
                          phone: values.organizationPhone,
                          email: values.organizationEmail,
                        },
                      }).then(() => {
                        resetForm();
                        setOpenEditOrg(false);
                        console.log("edit org successful!");
                      });
                    }
                  }}
                >
                  Submit
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: 20 }}
                  onClick={() => {
                    setOpenEditOrg(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Fade>
        </Modal>

        {/** edit location table */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={modalClasses.modal}
          open={openEditLoc}
          onClose={() => setOpenEditLoc(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openEditLoc}>
            <div className={modalClasses.paper}>
              <div style={{ width: "100%" }}>
                <TextField
                  className={modalClasses.tf}
                  fullWidth
                  required
                  name="locationName"
                  id="location-name"
                  label="Location name"
                  variant="outlined"
                  defaultValue={
                    curLoc !== {} ? curLoc.locationName : values.lacationName
                  }
                  value={values.lacationName}
                  onChange={handleInputChange}
                />

                <TextField
                  className={modalClasses.tf}
                  fullWidth
                  required
                  name="address"
                  id="location-address"
                  label="Address"
                  variant="outlined"
                  defaultValue={curLoc !== {} ? curLoc.address : values.address}
                  value={values.address}
                  onChange={handleInputChange}
                />
                <TextField
                  id="organization-status"
                  name="state"
                  select
                  label="State"
                  helperText="Please select status"
                  variant="outlined"
                  defaultValue={curLoc !== {} ? curLoc.stateName : values.state}
                  value={values.state}
                  onChange={handleInputChange}
                >
                  {getState().map((item) => (
                    <option key={item.id} value={item.title}>
                      {item.title}
                    </option>
                  ))}
                </TextField>
                <TextField
                  className={modalClasses.tf}
                  fullWidth
                  required
                  name="zip"
                  id="location-zip"
                  label="Zip code"
                  variant="outlined"
                  defaultValue={curLoc !== {} ? curLoc.zipCode : values.zip}
                  value={values.zip}
                  onChange={handleInputChange}
                />
                <TextField
                  className={modalClasses.tf}
                  fullWidth
                  required
                  name="locationPhone"
                  id="location-phone"
                  label="Telephone Number"
                  variant="outlined"
                  defaultValue={
                    curLoc !== {} ? curLoc.telNumb : values.locationPhone
                  }
                  value={values.locationPhone}
                  onChange={handleInputChange}
                  error={errors.locationPhone}
                />
              </div>

              {showError && (
                <div className={modalClasses.btn}>
                  <Typography color="error">
                    All fields are required.
                  </Typography>
                </div>
              )}
              <div className={modalClasses.btn}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    console.log("id--->", row.id);
                    let pass = true;
                    for (let key in values) {
                      if (values[key] === "") {
                        pass = false;
                        setShowError(true);
                      }
                    }
                    if (pass) {
                      setShowError(false);
                      console.log("values--->", values);
                      updateLocation({
                        variables: {
                          locationId: Number(curLoc.id),
                          locIn: {
                            locationName: values.locationName,
                            telNumb: values.locationPhone,
                            address: values.address,
                            stateName: values.state,
                            zipCode: values.zip,
                            status: "active",
                          },
                        },
                      }).then(() => {
                        resetForm();
                        setOpenEditLoc(false);
                        console.log("edit successful!");
                      });
                    }
                  }}
                >
                  Submit
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: 20 }}
                  onClick={() => {
                    setOpenEditLoc(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
      <TableRow className={classes.root}>
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <IconButton
            aria-label="edit location"
            size="small"
            onClick={() => {
              setOpenEditOrg(true);
              setCurOrg(row);
              setValues({
                organizationName: row.name,
                organizationType: row.type,
                organizationPhone: row.phone,
                organizationEmail: row.email,
              });
            }}
          >
            <EditOutlinedIcon />
          </IconButton>
          <IconButton
            aria-label="delete organization"
            size="small"
            onClick={() => {
              setOpenDelOrg(true);
              setOrgId(Number(row.id));
              // deleteOrg(row.id);
            }}
          >
            <DeleteForeverIcon />
          </IconButton>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row.name}
        </StyledTableCell>
        <StyledTableCell align="left">{row.type}</StyledTableCell>
        <StyledTableCell align="left">{row.email}</StyledTableCell>
        <StyledTableCell align="left">{row.phone}</StyledTableCell>
        <StyledTableCell align="left">{row.status}</StyledTableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="subtitle1" gutterBottom component="div">
                Locations
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Address</TableCell>
                    <TableCell align="left">Zip Code</TableCell>
                    <TableCell align="left">state Name</TableCell>
                    <TableCell align="left">tel Numb</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.locations.map((historyRow) => (
                    <TableRow key={historyRow.id}>
                      <TableCell component="th" scope="row">
                        <IconButton
                          aria-label="delete location"
                          size="small"
                          onClick={() => {
                            // console.log("======");
                            setOpenEditLoc(true);
                            setCurLoc(historyRow);
                            setValues({
                              locationName: historyRow.locationName,
                              address: historyRow.address,
                              state: historyRow.stateName,
                              zip: historyRow.zipCode,
                              locationPhone: historyRow.telNumb,
                            });

                            // setOrgId(Number(row.id));
                            // deleteOrg(row.id);
                          }}
                        >
                          <EditOutlinedIcon />
                        </IconButton>
                        <IconButton
                          aria-label="delete location"
                          size="small"
                          onClick={() => {
                            setOpenDelLoc(true);
                            setLocId(Number(historyRow.id));
                            console.log("locId--->", Number(historyRow.id));
                          }}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {historyRow.locationName}
                      </TableCell>
                      <TableCell>
                        <a
                          target="_blank"
                          href={`http://maps.google.com/?q=${historyRow.address},${historyRow.stateName},${historyRow.zipCode}`}
                        >
                          {historyRow.address}
                        </a>
                      </TableCell>
                      <TableCell align="left">{historyRow.zipCode}</TableCell>
                      <TableCell align="left">{historyRow.stateName}</TableCell>
                      <TableCell align="left">{historyRow.telNumb}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <div className={classes.addBtn}>
                <Fab
                  className={classes.addBtnIcon}
                  aria-label="edit"
                  onClick={handleOpen}
                >
                  <AddIcon />
                </Fab>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        locationName: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        zipCode: PropTypes.string.isRequired,
        stateName: PropTypes.string.isRequired,
        telName: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

const initialFValues = {
  organizationName: "",
  organizationType: "",
  organizationPhone: "",
  organizationEmail: "",
  organizationStatus: "active",
  locationName: "",
  address: "",
  state: "AL",
  zip: "",
  locationPhone: "",
};

export default function OrganizationTable() {
  const { hsId } = useParams();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openDelOrg, setOpenDelOrg] = useState(false);
  const [openDelLoc, setOpenDelLoc] = useState(false);
  const [orgId, setOrgId] = useState(0);
  const [locId, setLocId] = useState(0);
  const modalClasses = useModalStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);
  const history = useHistory();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true);

  const handleSubmit = (e) => {
    e.preventDefault();
    createOneOrg();
  };
  const [addNewOrgByHSId, { data: newOrgData, error: newOrgError }] =
    useMutation(ADD_ONE_ORG_BYHSID, {
      refetchQueries: [
        {
          query: FIND_ORGANIZATION_BYID,
          variables: {
            hs_id: Number(hsId),
          },
        },
      ],
    });
  const createOneOrg = () => {
    addNewOrgByHSId({
      variables: {
        hs_id: Number(hsId),
        org: {
          name: values.organizationName,
          type: values.organizationType,
          email: values.organizationEmail,
          phone: values.organizationPhone,
          status: "active",
        },
        locations: [
          {
            locationName: values.locationName,
            address: values.address,
            stateName: values.state,
            zipCode: values.zip,
            status: "active",
          },
        ],
      },
    }).then(() => {
      handleClose();
      resetForm();
      console.log("successful!");
    });
  };

  const [delOrganization, { data: delOrgData, error: delOrgError }] =
    useMutation(DELETE_ORGANIZATION, {
      refetchQueries: [
        {
          query: FIND_ORGANIZATION_BYID,
          variables: {
            hs_id: Number(hsId),
          },
        },
      ],
    });

  const deleteOrg = (id) => {
    delOrganization({
      variables: {
        id: Number(id),
      },
    }).then(() => {
      console.log("delete org successful!");
      setOpenDelOrg(false);
    });
  };

  const [delLocation, { data: delLocData, error: delLocError }] = useMutation(
    DELETE_LOCATION,
    {
      refetchQueries: [
        {
          query: FIND_ORGANIZATION_BYID,
          variables: {
            hs_id: Number(hsId),
          },
        },
      ],
    }
  );

  const deleteLoc = (id) => {
    delLocation({
      variables: {
        locationId: Number(id),
      },
    }).then(() => {
      console.log("delete loc successful!");
      setOpenDelLoc(false);
    });
  };

  /* get data from server */
  let rowss = [];
  const { data, loading, error } = useQuery(FIND_ORGANIZATION_BYID, {
    variables: {
      hs_id: Number(hsId),
    },
  });
  {
    data &&
      data.orgsByHealthId.map((item) => {
        // console.log("organization: name  " + item.name);
        // console.log("organization: type  " + item.type);
        // console.log(" organization, locations  " + item.locations);
        rowss.push(
          createData(
            item.id,
            item.name,
            item.type,
            item.email,
            item.phone,
            item.status,
            item.locations
          )
        );
      });
  }
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rowss.length - page * rowsPerPage);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [showError, setShowError] = useState(false);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Locations </StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell align="left">Type</StyledTableCell>
                <StyledTableCell align="left">Email</StyledTableCell>
                <StyledTableCell align="left">Phone</StyledTableCell>
                <StyledTableCell align="left">Status</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowss
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <Row
                    key={row.name}
                    row={row}
                    hsId={hsId}
                    deleteOrg={deleteOrg}
                    setOpenDelOrg={setOpenDelOrg}
                    setOpenDelLoc={setOpenDelLoc}
                    setOrgId={setOrgId}
                    setLocId={setLocId}
                  />
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.pagination}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            colSpan={3}
            component="div"
            count={rowss.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Paper>

      <div className={classes.addBtn}>
        <Fab
          className={classes.addBtnIcon}
          aria-label="edit"
          onClick={handleOpen}
        >
          <AddIcon />
        </Fab>
      </div>
      <div>
        <Modal
          className={modalClasses.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={modalClasses.paper}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={modalClasses.heading}>
                    Add an organization
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ width: "100%" }}>
                    <TextField
                      className={modalClasses.tf}
                      fullWidth
                      required
                      name="organizationName"
                      id="organization-name"
                      label="Organization name"
                      variant="outlined"
                      value={values.organizationName}
                      onChange={handleInputChange}
                      error={errors.organizationName}
                    />

                    <TextField
                      className={modalClasses.tf}
                      fullWidth
                      required
                      name="organizationType"
                      id="organization-type"
                      label="Organization type"
                      variant="outlined"
                      value={values.organizationType}
                      onChange={handleInputChange}
                      error={errors.organizationType}
                    />
                    <TextField
                      className={modalClasses.tf}
                      fullWidth
                      required
                      name="organizationPhone"
                      id="organization-phone"
                      label="Organization phone number"
                      variant="outlined"
                      value={values.organizationPhone}
                      onChange={handleInputChange}
                      error={errors.organizationPhone}
                    />
                    <TextField
                      className={modalClasses.tf}
                      fullWidth
                      required
                      name="organizationEmail"
                      id="organization-email"
                      label="Organization email"
                      variant="outlined"
                      value={values.organizationEmail}
                      onChange={handleInputChange}
                      error={errors.organizationEmail}
                    />

                    {/* <TextField
                      id="organization-status"
                      name="organizationStatus"
                      select
                      label="Status"
                      helperText="Please select status"
                      variant="outlined"
                      value={values.organizationStatus}
                      onChange={handleInputChange}
                    >
                      <option key="active" value="active">
                        {"active"}
                      </option>
                      <option key="inactive" value="inactive">
                        {"inactive"}
                      </option>
                    </TextField> */}
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography className={modalClasses.heading}>
                    Add a location
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ width: "100%" }}>
                    <TextField
                      className={modalClasses.tf}
                      fullWidth
                      required
                      name="locationName"
                      id="location-name"
                      label="Location name"
                      variant="outlined"
                      value={values.lacationName}
                      onChange={handleInputChange}
                    />

                    <TextField
                      className={modalClasses.tf}
                      fullWidth
                      required
                      name="address"
                      id="location-address"
                      label="Address"
                      variant="outlined"
                      value={values.address}
                      onChange={handleInputChange}
                    />
                    <TextField
                      id="organization-status"
                      name="state"
                      select
                      label="State"
                      // value="none"
                      // onChange={handleChange}
                      // SelectProps={{
                      //   native: true,
                      // }}
                      helperText="Please select status"
                      variant="outlined"
                      value={values.state}
                      onChange={handleInputChange}
                    >
                      {getState().map((item) => (
                        <option key={item.id} value={item.title}>
                          {item.title}
                        </option>
                      ))}
                    </TextField>
                    <TextField
                      className={modalClasses.tf}
                      fullWidth
                      required
                      name="zip"
                      id="location-zip"
                      label="Zip code"
                      variant="outlined"
                      value={values.zip}
                      onChange={handleInputChange}
                    />
                    <TextField
                      className={modalClasses.tf}
                      fullWidth
                      required
                      name="locationPhone"
                      id="location-phone"
                      label="Telephone Number"
                      variant="outlined"
                      value={values.locationPhone}
                      onChange={handleInputChange}
                      error={errors.locationPhone}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
              {showError && (
                <div className={modalClasses.btn}>
                  <Typography color="error">
                    All fields are required.
                  </Typography>
                </div>
              )}
              <div className={modalClasses.btn}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    let pass = true;
                    for (let key in values) {
                      if (values[key] === "") {
                        pass = false;
                        setShowError(true);
                      }
                    }
                    if (pass) {
                      setShowError(false);
                      createOneOrg();
                    }
                  }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Fade>
        </Modal>

        {/* delete org modal */}
        <Modal
          className={modalClasses.modal}
          open={openDelOrg}
          onClose={() => setOpenDelOrg(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openDelOrg}>
            <div className={modalClasses.paper}>
              <div className={modalClasses.btn}>
                <Typography>
                  Are you sure you want to delete the organization?
                </Typography>
              </div>

              <div className={modalClasses.btn}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    // console.log("delete...");
                    deleteOrg(orgId);
                  }}
                >
                  Yes
                </Button>
                <Button
                  style={{ marginLeft: 20 }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setOpenDelOrg(false);
                  }}
                >
                  No
                </Button>
              </div>
            </div>
          </Fade>
        </Modal>

        {/* delete location modal */}
        <Modal
          className={modalClasses.modal}
          open={openDelLoc}
          onClose={() => setOpenDelLoc(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openDelLoc}>
            <div className={modalClasses.paper}>
              <div className={modalClasses.btn}>
                <Typography>
                  Are you sure you want to delete the location?
                </Typography>
              </div>

              <div className={modalClasses.btn}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    // console.log("delete...");
                    deleteLoc(locId);
                  }}
                >
                  Yes
                </Button>
                <Button
                  style={{ marginLeft: 20 }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setOpenDelLoc(false);
                  }}
                >
                  No
                </Button>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    </div>
  );
}
