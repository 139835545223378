import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  InputBase,
  Container,
  Paper,
  IconButton,
  ButtonGroup,
  Button,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from "@mui/icons-material/Search";
import { useQuery, useMutation } from "@apollo/client";
import {
  ADD_PHYSICIAN,
  ALLPHYSICIANS,
  SEARCH_ALLCARETEAM_BY_NAME,
} from "@cims/common";
import PersonCard from "../../../components/PersonCard";
import { Link } from "react-router-dom";
import { AppointmentContext } from "../../../services/appointment/appointment.context";
import LoadingIndicator from "../../../components/LoadingIndicator";
import ErrorInfo from "../../../components/ErrorInfo";
import { useForm } from "../components/useForm";
import { ALL_HEALTH_SYSTEM } from "@cims/common";
import { AuthenticationContext } from "../../../services/authentication/authentication.context";
import { Pagination } from '@mui/material';
import { blue } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  top: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 300,
    // marginBottom: 20,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  btn: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  addBtnIcon: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
    color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
    "&:hover": {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#BDC0BA" : blue[700],
      color: "#fff",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#f5f3f0", //theme.palette.background.paper,
    border: "1px solid #666666",
    width: 700,
    maxHeight: 700,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "auto",
  },
  tf: {
    marginBottom: 8,
  },
  pagination: {
    display: "flex",
    marginTop: 30,
    justifyContent: "center",
  },
}));

const initialValues = {
  firstName: "",
  lastName: "",
  dob: "",
  ssn: "",
  sex: "male",
  email: "",
  password: "",
  passcode: "",
  phone: "",
  cellPhone: "",
  education: "",
  experience: "",
  // employmentDate: "",
  title: "",
  role: "STAFF",
  hs: "",
  org: "",
  locationId: [],
};

const ContactCareTeam = () => {
  const classes = useStyles();
  const { user, contextHeaders, pickingPhysician } = useContext(
    AuthenticationContext
  );
  // console.log("user--->", user);
  const [text, setText] = useState("");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const { data, loading, error } = useQuery(SEARCH_ALLCARETEAM_BY_NAME, {
    variables: { page: page - 1, size, searchStr: text },
  });

  const {
    data: hsData,
    loading: hsLoading,
    error: hsError,
  } = useQuery(ALL_HEALTH_SYSTEM);

  const [createAPhysician, { data: addPhyData, error: addPhyError }] =
    useMutation(ADD_PHYSICIAN, {
      context: contextHeaders,
      refetchQueries: [
        {
          query: ALLPHYSICIANS,
        },
      ],
    });

  const [careteam, setCareteam] = useState([]);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true);

  // useEffect(() => {
  //   if (data) setCareteam(data.allCareTeamPage);
  // }, [data]);

  const onSearch = (e) => {
    // const value = e.target.value;
    // const newCareteam = data.allCareTeamPage.filter((careteam) => {
    //   const name = `${careteam.firstName.toLowerCase()} ${careteam.lastName.toLowerCase()}`;
    //   return name.toLowerCase().includes(value.toLowerCase());
    // });
    // setCareteam(newCareteam);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const changeSize = (size) => {
    setPage(1);
    setSize(size);
  };

  return (
    <Container size="sm">
      <div className={classes.top}>
        <Paper component="form" className={classes.root}>
          <InputBase
            className={classes.input}
            placeholder="Search Care Team by Name"
            inputProps={{ "aria-label": "search care team by name" }}
            onChange={(e) => setText(e.target.value)}
          />
          <IconButton
            type="submit"
            className={classes.iconButton}
            aria-label="search"
            size="large">
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>
      {loading && <LoadingIndicator />}
      {error && <ErrorInfo />}
      <Grid container spacing={3}>
        {data &&
          data.searchAllCareTeamByName !== null &&
          data.searchAllCareTeamByName.persons.map((person) => {
            return (
              <Grid key={person.id} item xs={12} md={4} lg={3}>
                <Link
                  to={`/communication/${person.id}`}
                  onClick={() => {
                    pickingPhysician(person);
                  }}
                >
                  <PersonCard person={person} />
                </Link>
              </Grid>
            );
          })}
      </Grid>
      {data && data.searchAllCareTeamByName !== null && (
        <>
          <div className={classes.pagination}>
            <ButtonGroup
              color="primary"
              aria-label="outlined primary button group"
            >
              {/* <Button onClick={() => changeSize(5)}>5</Button> */}
              <Button onClick={() => changeSize(10)}>10</Button>
              <Button onClick={() => changeSize(20)}>20</Button>
              <Button onClick={() => changeSize(50)}>50</Button>
            </ButtonGroup>
          </div>
          {Math.ceil(Number(data.searchAllCareTeamByName.totalSize) / size) >
            1 && (
            <div className={classes.pagination}>
              <Pagination
                variant="outlined"
                color="primary"
                count={Math.ceil(
                  Number(data.searchAllCareTeamByName.totalSize) / size
                )}
                page={page}
                onChange={handleChange}
              />
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default ContactCareTeam;
