import { Button, Paper, Typography } from "@mui/material";
import React, { useState } from "react";

export default function Slot({ time, changeStatus }) {
  const [color, setColor] = useState("primary");
  const [text, setText] = useState(time.status);
  return (
    <Paper
      style={{ display: "flex", padding: 5, width: 170, alignItems: "center" }}
    >
      <Button
        onClick={() => {
          setColor(color === "primary" ? "error" : "primary");
          setText(text === "available" ? "unavailable" : "available");
          changeStatus({
            startTime: time.startTime,
            endTime: time.endTime,
            status: text === "available" ? "unavailable" : "available",
          });
        }}
      >
        {time.startTime.slice(0, 5)}
      </Button>
      <Typography color={color}>{text}</Typography>
    </Paper>
  );
}
