import { BrowserRouter as Router } from "react-router-dom";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from "@mui/material";
import Layout from "./navigation/Layout";
// import ApolloClient from "./apollo.config";
// import { ApolloClient } from "@cims/common";

import { ApolloProvider } from "@apollo/client/react";
import AuthenticationContextProvider from "./services/authentication/authentication.context";
import Routes from "./navigation/Routes";
import { ThemeContext } from "./services/theme/theme.context";
import { useContext, useEffect, useState } from "react";
import { AppointmentContextProvider } from "./services/appointment/appointment.context";
import { Helmet } from "react-helmet";
import { persistCache, LocalStorageWrapper } from "apollo3-cache-persist";
import { ApolloClient, InMemoryCache, makeVar } from "@apollo/client";
import { authLink, splitLink } from "./apollo.config";

import { blue, lightBlue } from "@mui/material/colors";
import { GlobalContextProvider } from "./services/global/global.context";

export const userVar = makeVar(null);

function App() {
  const { darkMode } = useContext(ThemeContext);

  const theme = createTheme(
    adaptV4Theme({
      palette: {
        mode: darkMode ? "dark" : "light",
        primary: {
          main: blue[500],
        },
        secondary: {
          main: lightBlue[500],
        },
      },
      overrides: {
        MuiAppBar: {
          colorPrimary: {
            backgroundColor: "#037bfc",
          },
        },
      },
      // typography: {
      //   fontFamily: "Quicksand",
      //   fontWeightLight: 400,
      //   fontWeightRegular: 500,
      //   fontWeightMedium: 600,
      //   fontWeightBold: 700,
      // },
    })
  );

  // const theme = createMuiTheme({
  //   overrides: {
  //     MuiAppBar: {
  //       colorPrimary: {
  //         backgroundColor: "#FFC0CB"
  //       }
  //     }
  //   },
  //   palette: {     mode: "dark"   } });

  const [client, setClient] = useState(null);

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          user: {
            read() {
              return userVar();
            },
          },
        },
      },
    },
  });

  useEffect(() => {
    persistCache({
      cache,
      storage: new LocalStorageWrapper(window.localStorage),
      trigger: "write",
    }).then(() => {
      setClient(
        new ApolloClient({
          link: authLink.concat(splitLink),
          cache,
        })
      );
    });
  }, []);

  if (!client) return <div>loading...</div>;
  return (
    <div className="App">
      <Helmet>
        <script src="https://source.zoom.us/1.9.9/lib/vendor/react.min.js"></script>
        <script src="https://source.zoom.us/1.9.9/lib/vendor/react-dom.min.js"></script>
        <script src="https://source.zoom.us/1.9.9/lib/vendor/redux.min.js"></script>
        <script src="https://source.zoom.us/1.9.9/lib/vendor/redux-thunk.min.js"></script>
        <script src="https://source.zoom.us/1.9.9/lib/vendor/lodash.min.js"></script>
        <script src="https://source.zoom.us/zoom-meeting-1.9.9.min.js"></script>
        {/* <script type="text/javascript" src="/pages/zoom/index2.js" />
        <script type="text/javascript" src="/pages/zoom/meeting.js" />
        <script type="text/javascript" src="/pages/zoom/tool.js" />
        <script type="text/javascript" src="/pages/zoom/vconsole.min.js" /> */}

        <script src="/js/jquery-1.12.0.min.js"></script>
        <script src="/js/core.js"></script>
        <link
          rel="stylesheet"
          type="text/css"
          media="all"
          href="/css/cda.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          media="all"
          href="/css/pure-min.css"
        />
        <link
          rel="stylesheet"
          href="http://netdna.bootstrapcdn.com/font-awesome/4.6.0/css/font-awesome.css"
        />
        <link rel="stylesheet" href="/css/pureextension.css" />
        <link rel="stylesheet" href="/css/marketing.css" />
        <script src="/js/packery.pkgd.min.js"></script>
        <script src="/js/draggabilly.pkgd.min.js"></script>
        <script src="/js/xslt/xslt.js"></script>
        <script src="/js/core.js"></script>
        {/* <script src="/js/purejs.js"></script> */}
      </Helmet>
      {/* <DrawerLeft /> */}
      <ApolloProvider client={client}>
        <Router>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <AuthenticationContextProvider>
                <GlobalContextProvider>
                  <AppointmentContextProvider>
                    <Layout>
                      <Routes />
                    </Layout>
                  </AppointmentContextProvider>
                </GlobalContextProvider>
              </AuthenticationContextProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </Router>
      </ApolloProvider>
    </div>
  );
}

export default App;
