import { gql } from "@apollo/client";

export const UPDATE_PERSON = gql`
    mutation updatePerson($input: UpdatePersonInput!){
        updatePerson(input: $input){
            ssn
            sex
            dob
            phone
            education
            employmentDate{iso}
            experience
            title
        }
    }
`;

export const UPDATE_PASSWORD = gql`
mutation UpdatePassword($personId: ID!, $input: UpdatePasswordInput!) { 
    updatePassword(personId: $personId, input: $input)
     { person {id} }
  }
`;

export const UPDATE_PASSCODE = gql`
mutation UpdatePasscode($personId: ID!, $input: UpdatePasswordInput!) { 
    updatePasscode(personId: $personId, input: $input)
     { person {id} }
  }
`;

export const UPDATE_CONTACTINFO2 = gql`
   mutation UpdateContactInfo2($contactInfoInput: ContactInfoInput!, $personId: ID!){
       updateContactInfo2(contactInfoInput:$contactInfoInput, personId: $personId){
               id,country, streetAddress,city, state, zip,
               homePhone, cellPhone, workPhone, email, preferContact
           }
   }
`
 
