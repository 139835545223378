import React, { useState, useContext, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from "react-router-dom";
import { AuthenticationContext } from "../../../services/authentication/authentication.context";
import { useQuery, useMutation } from "@apollo/client";
import Alert from '@mui/material/Alert';
import { serverUrl, UPLOAD_ATTACHMENT } from "@cims/common";
import axios from "axios";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

const useStyles = makeStyles({
  field: {
    marginTop: 20,
    marginBottom: 20,
    display: "block",
  },
});

export const Home = ({ fileCallBack }) => {
  const [newPasscode, setNewPasscode] = useState("");
  const [encryptedFileURL, setEncyptedFileURL] = useState("");
  const [passwordError, setNewPasscodeError] = useState(false);
  const [encryptedFileUrlError, setEncryptedFileUrlError] = useState(false);

  const classes = useStyles();
  const history = useHistory();
  const [formData, setFormData] = useState(null);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const { user, contextHeaders } = useContext(AuthenticationContext);
  const [uploadedFile, setUploadedFile] = useState("");
  const [uploadedFileUrl, setUploadedFileUrl] = useState("");
  const [fileName, setFileName] = useState(null);
  const [passcode, setPasscode] = useState(null);
  const handleFileCallBack = (childData) => {
    console.log("childData", childData);
    setFileName(childData);
  };

  const [uploadFile, { error: uploadError }] = useMutation(UPLOAD_ATTACHMENT, {
    variables: {
      attachment: selectedFile,
      messageId: 0,
    },
    context: contextHeaders,
  });

  // useEffect(() => {
  //   if (user === null) history.push("/login");
  // });

  const handleSubmit = (e) => {};

  const token = contextHeaders.headers.authorization;
  const siteUrl = serverUrl;

  const checkPasscode = (e) => {
    e.preventDefault();
    setNewPasscodeError(false);
    setEncryptedFileUrlError(false);

    if (newPasscode === "") {
      setNewPasscodeError(true);
      setAlert(true);
      setAlertSeverity("error");
      setAlertContent("Your Temporary Passcode is blank!");
    } else if (encryptedFileURL === "") {
      setEncryptedFileUrlError(true);
      setAlert(true);
      setAlertSeverity("error");
      setAlertContent("Your Encrypted File URL is blank!");
    } else if (newPasscode) {
      // handleFileUpload();
      decryptFileFromURL();
    }
  };

  const decryptFileFromURL = () => {
    const arrays = encryptedFileURL.split("/");
    const filename = arrays[arrays.length - 1]; // Or parts.pop();
    const personId = arrays[arrays.length - 2];
    axios
      .get(
        siteUrl +
          "/api/decryptfile/" +
          personId +
          "/" +
          newPasscode +
          "/" +
          filename,
        {
          responseType: "blob",
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        console.log("response", response.data.fileUrl);
        setUploadedFile(filename);
        setUploadedFileUrl(response.data.fileUrl);
        setAlert(true);
        setAlertSeverity("success");
        setAlertContent(
          "The file [" +
            filename +
            "] has been decrypted and downloaded successfully!"
        );
        console.log("decryptFile, filename: ", filename);
        fileCallBack = { handleFileCallBack };
        //fileCallBack(filename);

        var file = new Blob([response.data]);
        const url = window.URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${filename}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.log("decrypted", false);
        setAlert(true);
        setAlertSeverity("error");
        // setAlertContent("" + error);
        setAlertContent(
          "Check your Encrypt File URL, Temporary Passcode, and then try it again!"
        );
      });
  };

  return (
    <Container size="sm">
      <Typography
        variant="h6"
        color="textSecondary"
        component="h2"
        gutterBottom
      >
        Decrypt File With Temporary Passcode
      </Typography>

      <div>
        {alert ? <Alert severity={alertSeverity}>{alertContent}</Alert> : <></>}
      </div>

      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <TextField
          type="password"
          className={classes.field}
          onChange={(e) => setNewPasscode(e.target.value)}
          label="Temporary Passcode"
          variant="outlined"
          color="secondary"
          fullWidth
          required
          error={passwordError}
        />

        <TextField
          type="text"
          className={classes.field}
          onChange={(e) => setEncyptedFileURL(e.target.value)}
          label="Encrypted File URL"
          variant="outlined"
          color="secondary"
          fullWidth
          required
          error={encryptedFileUrlError}
        />

        <center>
          <div>
            {uploadedFileUrl ? (
              <div>
                <p>Decrypted File Name: {uploadedFileUrl}</p>
              </div>
            ) : (
              <p></p>
            )}
          </div>
          <div>
            <Button
              className={classes.iconButton}
              aria-label="Attach"
              color="primary"
              onClick={checkPasscode}
              color="primary"
              variant="contained"
            >
              Decrypt File
            </Button>
          </div>
        </center>
      </form>
    </Container>
  );
};

export default Home;
