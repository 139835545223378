import { gql } from "@apollo/client";

export const FIND_EMAIL_MESSAGES = gql`
  query FindEmailMessages($person_id: ID!) {
    findEmailMessages(person_id: $person_id) {
      id
      fromUserId
      toUserId
      fromUserName
      toUserName
      subject
      text
      attachmentLocation
      timestamp
    }
  }
`;

export const FIND_PATIENTS_BY_PHYSICIAN = gql`
  query PatientsByPhysicianId($physicianId: ID!) {
    patientsByPhysicianId(physicianId: $physicianId) {
      id
      person {id}
      firstName
      lastName
    }
  }
`;

export const FIND_PATIENTS_BY_CURRENT_USER = gql`
  query PatientsByCurrentUser {
    patientsByCurrentUser {
      id
      person {id}
      firstName
      lastName
    }
  }
`;

export const GET_ALL_MSG_SUBJECT = gql `
    query allMsgSubjects{
      allMsgSubjects{
        id, subject
      }
    }
`;

export const SEND_MESSAGE = gql`
  mutation SendMessage($fromUserId: ID!, $toUserId: ID!, $subject: String, $text: String!, $attachmentLocation: String) {
    sendMessage(fromUserId: $fromUserId, toUserId: $toUserId, subject: $subject, text: $text, attachmentLocation: $attachmentLocation) {
      id
    }
  }
`;

export const DEACTIVATE_MESSAGE = gql`
  mutation DeactivateMessage($id: ID!) {
    deactivateMessage(id: $id)
  }
`;

export const DEACTIVATE_MESSAGES = gql`
  mutation DeactivateMessages($ids: [ID]!) {
    deactivateMessages(ids: $ids)
  }
`;

export const DEACTIVATE_MESSAGES_BEFORE = gql`
  mutation DeactivateMessageBefore($dateTime: String!) {
    deactivateMessageBefore(dateTime: $dateTime)
  }
`;

export const UPLOAD_ATTACHMENT = gql`
  mutation UploadAttachment($attachment: Upload!, $messageId: ID) {
    uploadAttachment(attachment: $attachment, messageId: $messageId)
    {id}
  }
`;