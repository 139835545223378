import { gql } from "@apollo/client";

export const FIND_PATIENT_BY_PERSON_ID = gql`
  query FindPatientByPersonId($personId: ID!) {
    findPatientByPersonId(personId: $personId) {
      id,
      firstName,
      lastName,
      address
    }
  }
`;

export const ALL_BILLING_SUMMARY_BY_PERSON_ID = gql`
  query AllBillingSummaryByPersonId($personId: ID!) {
    allBillingSummaryByPersonId(personId: $personId) {
      id
      addPayManually
      paymentDueDate {
        toString
      }
      paidDate {
        toString
      }
      totalBalance
      amountDueNow
      guarantorNumb
      respLastName
      respFirstName
      respAddress
      respCellPhone
      billDetail {
        id
        serviceDate {
          toString
        }
        serviceDept
        providerName
        charge
        insuranceAdjustment
        totalBalance
      }
      addPayDetail {
        id
        paymentDate {
          toString
        }
        paymentTo
        paymentFrom
        paymentBalance
        providerName
      }
      patient {
        lastName
        firstName
        insuranceDetail {
          id
          insuranceName
          groupNumber
          groupName
          planName
          subscriberAddress
          subscriberId
          subscriberName
          subscriberSSN
        }
        paymentDetail {
          id
          paymentType
          cardHolderName
          cardType
          cardNumber
          cardExpirationDate {
            toString
          }
        }
      }
    }
  }
`;

export const GET_INSURANCE_DETAIL_BY_PATIENT_ID = gql`
  query getInsuranceDetailByPatientId($patientId: ID!) {
    getInsuranceDetailByPatientId(patientId: $patientId) {
      id
      insuranceName
      groupNumber
      groupName
      planName
      subscriberAddress
      subscriberId
      subscriberName
      subscriberSSN
    }
  }
`;