import React, { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { GET_ALL_APPTS } from "@cims/common";
import { UPDATE_APPT } from "@cims/common";
import { useQuery, useMutation } from "@apollo/client";
import { Container, Typography, Grid, Button } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ApptCard from "../components/ApptCard";
import { AppointmentContext } from "../../../services/appointment/appointment.context";
import LoadingIndicator from "../../../components/LoadingIndicator";
import ErrorInfo from "../../../components/ErrorInfo";
import { AuthenticationContext } from "src/services/authentication/authentication.context";

const useStyles = makeStyles((theme) => ({
  bar: {
    display: "flex",
    marginBottom: 20,
  },
  title: {
    flexGrow: 1,
  },
}));
export default function PatientScheduleList() {
  const { patientId } = useParams();
  const history = useHistory();
  const {
    changeIsReschedule,
    isReschedule,
    changeRescheduledFrom,
    pickingAppointment,
  } = useContext(AppointmentContext);
  const { curPickedPatient } = useContext(AuthenticationContext);
  const classes = useStyles();

  const {
    data: apptData,
    loading: apptLoading,
    error: apptError,
    subscribeToMore,
  } = useQuery(GET_ALL_APPTS, {
    variables: { patientId: Number(patientId) },
  });

  const [updateApptPatientStatusById, { error: cancelError }] = useMutation(
    UPDATE_APPT,
    {
      refetchQueries: [
        {
          query: GET_ALL_APPTS,
          variables: { patientId: Number(patientId) },
        },
        //TODO
        // {
        //   query: GET_TIMESLOTS,
        //   variables: {
        //     providerId: Number(providerId),
        //     locationId: Number(locationId),
        //     startTime: `${date} 00:00:00`, //"2021-05-05 00:00:00", //
        //     endTime: `${date} 24:00:00`, //"2021-05-05 24:00:00", //
        //   },
        // },
      ],
    }
  );

  const cancelAppt = (id) => {
    updateApptPatientStatusById({
      variables: {
        apptId: id,
        status: "cancelled",
        notes: "rescheduleByPatient",
      },
    });
  };

  const rescheduleAppt = (
    originalApptId,
    originalApptTime,
    location,
    locationId,
    subjectId,
    provider,
    providerId,
    patientId
  ) => {
    changeIsReschedule(true);
    changeRescheduledFrom("patient");
    pickingAppointment({
      originalApptId,
      originalApptTime,
      location,
      locationId,
      subjectId,
      provider,
      providerId,
      patientId,
    });
    history.push(
      `/patients/${patientId}/visits/scheduling/${subjectId}/${locationId}/${providerId}`
    );
  };

  // console.log("app--->", apptData);

  return (
    <Container>
      <div className={classes.bar}>
        <Typography
          variant="body1"
          className={classes.title}
          // color="textSecondary"
          component="h2"
          gutterBottom
        >
          {`${curPickedPatient.firstName} ${curPickedPatient.lastName}'s all appointments:`}
        </Typography>
        <Button
          variant="contained"
          onClick={() =>
            history.push(`/patients/${patientId}/visits/scheduling`)
          }
        >
          Make a new appointment
        </Button>
      </div>
      {apptLoading && <LoadingIndicator />}
      {apptError && <ErrorInfo />}
      <Grid container spacing={3}>
        {apptData &&
          apptData.allApptByPtIdDesc.map((item) => {
            return (
              <Grid key={item.id} item xs={12} md={6}>
                <ApptCard
                  appt={item}
                  cancelAppt={cancelAppt}
                  rescheduleAppt={rescheduleAppt}
                />
              </Grid>
            );
          })}
      </Grid>
    </Container>
  );
}
