import {
  Avatar,
  Badge,
  Button,
  Checkbox,
  FilledInput,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import makeStyles from '@mui/styles/makeStyles';
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import HouseIcon from "@mui/icons-material/House";
import EventIcon from "@mui/icons-material/Event";
import WcOutlinedIcon from "@mui/icons-material/WcOutlined";
import SendIcon from "@mui/icons-material/Send";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import EmailBody from "../../patients/pages/Email_Body.page";
import { AuthenticationContext } from "src/services/authentication/authentication.context";

import {
  CONVERSATION,
  CREATE_CHAT,
  CHAT_SUBSCRIPTION,
  PERSON_BYID,
  GIVE_PHYSICIAN_ACCESS,
} from "@cims/common";
import { capitalizeFirstLetter } from "@cims/utils";
import { PATIENT_MENUS } from "src/navigation/menu";
import LoadingIndicator from "src/components/LoadingIndicator";
import ErrorInfo from "src/components/ErrorInfo";
import { Alert } from '@mui/material';

import { blue, red } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
    padding: 20,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
  },
  hr: {
    marginTop: 20,
    marginBottom: 20,
    maxWidth: 800,
  },
  avatar: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  content: {
    display: "flex",
    alignItems: "center",
    margin: 10,
  },
  texting: {
    position: "absolute",
    width: 450,
    // maxHeight: 500,
    height: 500,
    right: 30,
    bottom: 10,
    zIndex: 99,
    display: "flex",
    flexDirection: "column",
    // overflow: "auto",
  },
  textContent: {
    flexGrow: 1,
    // backgroundColor: "navy",
    // color: "white",
    overflow: "auto",
    padding: 10,
    display: "flex",
    flexDirection: "column-reverse",
  },
  inputField: {
    // position: "absolute",
    // bottom: 0,
  },
  access: {
    margin: 20,
    padding: 20,
  },
}));

function Info({ icon, content }) {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      {icon}
      <Typography className={classes.avatar}>{content}</Typography>
    </div>
  );
}

export default function PhysicianDetails() {
  const { providerId } = useParams();
  const classes = useStyles();
  const { user, curPickedPhysician, isPatient, curPickedPatient } = useContext(
    AuthenticationContext
  );
  const [emailPopup, setEmailPopup] = useState(false);

  const [openText, setOpenText] = useState(false);
  const [text, setText] = useState("");
  const [openAccess, setOpenAccess] = useState(false);

  const {
    data: personData,
    loading: personLoading,
    error: personError,
  } = useQuery(PERSON_BYID, {
    variables: {
      personId: providerId,
    },
  });

  const {
    data: textingData,
    loading: textsLoading,
    error: textsError,
    subscribeToMore,
  } = useQuery(CONVERSATION, {
    variables: {
      fromUserId: Number(user.person.id),
      toUserId: Number(curPickedPhysician.id),
    },
  });

  const [createChat, { data: chatData, error: sendTextError }] =
    useMutation(CREATE_CHAT);
  const sendText = () => {
    if (text !== "")
      createChat({
        variables: {
          fromUserId: Number(user.person.id),
          toUserId: Number(curPickedPhysician.id),
          text: text,
        },
      }).then(() => {
        setText("");
      });
  };

  const subscribeToNewText = () =>
    subscribeToMore({
      document: CHAT_SUBSCRIPTION,
      variables: {
        fromUserId: Number(user.person.id),
        toUserId: Number(curPickedPhysician.id),
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newText = subscriptionData.data.chatAdded;
        const obj = {};
        obj.conversation = [newText, ...prev.conversation];
        return obj;
      },
    });

  useEffect(() => {
    subscribeToNewText();
  }, []);

  let accessArr = [];
  const [showAlert, setShowAlert] = useState(false);

  const [
    updateShareMedicalRecordAgreement,
    {
      data: giveAccessData,
      error: giveAccessError,
      loading: giveAccessLoading,
    },
  ] = useMutation(GIVE_PHYSICIAN_ACCESS);

  return <>
    <Paper className={classes.root}>
      <div className={classes.toolbar}>
        <Avatar className={classes.avatar} src={curPickedPhysician.avatar} />
        <Typography>{`${curPickedPhysician.firstName} ${curPickedPhysician.lastName}`}</Typography>
      </div>
      {/* <Divider /> */}
      <hr className={classes.hr} />
      <Info
        icon={<WcOutlinedIcon />}
        content={`Gender: ${personData?.person?.sex || "Not provided"}`}
      />
      <Info
        icon={<EventIcon />}
        content={`DOB: ${personData?.person?.dob || "Not provided"}`}
      />
      <Info
        icon={<PhoneAndroidIcon />}
        content={`Phone: ${personData?.person?.phone || "Not provided"}`}
      />
      <Info
        icon={<MailOutlineIcon />}
        content={`Email: ${personData?.person?.email || "Not provided"}`}
      />
      <Info
        icon={<HouseIcon />}
        content={`Address: ${personData?.person?.address || "Not provided"}`}
      />
    </Paper>

    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Button
        variant="contained"
        color="primary"
        onClick={() => setEmailPopup(true)}
      >
        {" "}
        Send Message{" "}
      </Button>
      <Button
        style={{ marginLeft: 20 }}
        variant="contained"
        color="primary"
        onClick={() => setOpenText(!openText)}
      >
        Text
      </Button>
      {isPatient && (
        <Button
          style={{ marginLeft: 20 }}
          variant="contained"
          color="primary"
          onClick={() => setOpenAccess(!openAccess)}
        >
          Give Access
        </Button>
      )}
      <EmailBody
        trigger={emailPopup}
        setTrigger={setEmailPopup}
        toEmail={curPickedPhysician}
        select={true}
        // select={isPhysician}
      >
        <p> this is email button triggered popup </p>
      </EmailBody>
      {openAccess && (
        <Paper className={classes.access}>
          <Typography>Give access to physician...</Typography>
          <Grid container spacing={2}>
            {PATIENT_MENUS &&
              PATIENT_MENUS.map((menu) => {
                return (
                  <Grid key={menu.id} item xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={menu.id}
                          color="primary"
                          name={menu.name}
                          onChange={(e) => {
                            if (e.target.checked) {
                              accessArr.push(e.target.value);
                            } else {
                              const index = accessArr.indexOf(e.target.value);
                              if (index > -1) {
                                accessArr.splice(index, 1);
                              }
                            }
                          }}
                        />
                      }
                      label={menu.menu}
                    />
                  </Grid>
                );
              })}
          </Grid>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {giveAccessLoading && <LoadingIndicator />}
            {giveAccessError && <ErrorInfo />}
            {showAlert && (
              <Alert style={{ width: 500 }} severity="success">
                You successfully give access right!
              </Alert>
            )}
            <Button
              style={{ marginTop: 20, width: 150 }}
              variant="contained"
              color="primary"
              onClick={() => {
                //submit
                updateShareMedicalRecordAgreement({
                  variables: {
                    personId: Number(curPickedPatient.id),
                    shId: Number(curPickedPhysician.id),
                    choosedId: accessArr,
                  },
                })
                  .then((data) => {
                    setShowAlert(true);
                    setTimeout(function () {
                      setOpenAccess(false);
                    }, 3000);
                  })
                  .catch((e) => console.log(e));
              }}
            >
              Submit
            </Button>
          </div>
        </Paper>
      )}
      {openText && (
        <Paper className={classes.texting}>
          {/* <div> */}
          <IconButton
            onClick={() => setOpenText(false)}
            style={{ width: 20, height: 20 }}
            size="large">
            <Badge>
              <HighlightOffIcon />
            </Badge>
          </IconButton>

          <div className={classes.textContent}>
            {textingData &&
              [...textingData.conversation].map((item) => {
                const times = item.timestamp.toString.split("T");
                let name = "Me";
                let color = red[500];
                if (item.fromUserId === curPickedPhysician.id) {
                  name = capitalizeFirstLetter(curPickedPhysician.firstName);
                  color = blue[500];
                }
                return (
                  <div key={item.id}>
                    <Typography variant="caption">
                      {`${times[0]} ${times[1].slice(0, 5)}`}
                    </Typography>
                    <div style={{ display: "flex", marginTop: -8 }}>
                      <Typography
                        style={{
                          fontWeight: 600,
                          marginRight: 10,
                          color: `${color}`,
                        }}
                      >
                        {`${name}:`}
                      </Typography>
                      <Typography>{item.text}</Typography>
                    </div>
                  </div>
                );
              })}
          </div>
          <FormControl
            // className={clsx(classes.margin, classes.textField)}
            className={classes.inputField}
            variant="filled"
            fullWidth
          >
            {/* <InputLabel htmlFor="filled-adornment-password">Password</InputLabel> */}
            <FilledInput
              id="filled-adornment-password"
              type="text"
              multiline
              fullWidth
              value={text}
              onChange={(e) => setText(e.target.value)}
              // onSubmit={() => sendText()}
              onKeyDown={(e) => {
                console.log("keycode--->", e.keyCode);
                if (e.keyCode === 13) sendText();
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => sendText()}
                    edge="end"
                    size="large">
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {/* </div> */}
        </Paper>
      )}
    </div>
  </>;
}
