import { gql } from "@apollo/client";

export const ALL_ALLERGY_BY_PATIENT_ID = gql`
  query allAllergyByPatientId($patient_id: ID!) {
    allAllergyByPatientId(patient_id: $patient_id) {
      id
      substance
      reaction
      serverity
      startDate
      status
      dataSource
    }
  }
`;

export const ALL_IMMUNIZATION_BY_PATIENT_ID = gql`
  query allImmunizationByPatientId($patient_id: ID!) {
    allImmunizationByPatientId(patient_id: $patient_id) {
      id
      vaccine
      vaccineDate
      status
      dataSource
    }
  }
`;

//{"patient_id": 6}
export const ALL_VITAL_SIGN = gql`
  query getAllVitalSignsByPtId($patient_id: ID!) {
    allVitalSignsByPtId(patient_id: $patient_id) {
      id
      loincCode
      vitalSigns
      vitalSingsDate
      readingLow
      unit
      dataSource
      timestamp {
        toString
      }
    }
  }
`;
