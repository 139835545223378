import EditorToolbar, {
  modules,
  formats,
} from "../../../components/EditorToolbar";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import ReactQuill from "react-quill";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import makeStyles from '@mui/styles/makeStyles';
import AttachFileIcon from "@mui/icons-material/AttachFile";
import axios from "axios";

import {
  FIND_PATIENTS_BY_PHYSICIAN,
  SEND_MESSAGE,
  GET_ALL_MSG_SUBJECT,
  serverUrl,
} from "@cims/common";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import React, { useContext, useState } from "react";
import { AuthenticationContext } from "../../../services/authentication/authentication.context";
import { AppointmentContext } from "../../../services/appointment/appointment.context";

const useModalStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 680,
    height: 660,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function getModalStyle() {
  // const top = 50 + rand();
  // const left = 50 + rand();
  const top = 15;
  const left = 35;
  return {
    top: `${top}%`,
    left: `${left}%`,
    // transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function EmailBody(props) {
  var reactQuillRef = null;
  const modalClasses = useModalStyles();
  const [recipient, setRecipient] = useState(props.toEmail.id);
  const [isPhysician, setIsPhysician] = useState(props.select);
  const [isStaff, setIsStaff] = useState(props.select);
  const [newSubject, setNewSubject] = useState("");
  const [quillObject, setQuillObject] = useState(null);
  const classes = useStyles();
  const { user, contextHeaders, curPickedPatient } = useContext(
    AuthenticationContext
  );
  const { patientId } = useParams();
  const [open, setOpen] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [formData, setFormData] = useState(null);
  const [modalStyle] = useState(getModalStyle);

  const handleChange = (event) => {
    // console.log("event.target.value", event.target.value);
    setRecipient(Number(event.target.value));
  };

  const {
    data: recipientsData,
    loading: apptLoading2,
    error: apptError2,
  } = useQuery(FIND_PATIENTS_BY_PHYSICIAN, {
    variables: { physicianId: Number(user.person.id) },
    context: contextHeaders,
  });

  let recipientList;

  if (recipientsData) {
    recipientList = recipientsData.patientsByPhysicianId;
    // console.log("recipientsData", recipientsData);
  }

  const checkCharacterCount = (event) => {
    // console.log("length", reactQuillRef.getEditor().getLength());
    // console.log("bodyText", bodyText);
    const limted = 500;
    const quillEditor = reactQuillRef.getEditor();
    // console.log("quillEditor", quillEditor);

    setQuillObject(reactQuillRef);

    const totalChar = quillEditor.getLength() - 1;

    if (quillEditor.getLength() > limted + 1)
      quillEditor.deleteText(limted, totalChar);
  };
  const [bodyText, setBodyText] = useState("");
  const [uploadedFile, setUploadedFile] = useState("");
  const [uploadedFileUrl, setUploadedFileUrl] = useState("");

  const handleClose = () => {
    setOpen(false);
    props.setTrigger(false);
  };

  const handleToEmail = () => {
    setRecipient(props.toEmail);
  };
  const CustomBottomBar = () => (
    <div className="ths-space-y-10">
      <Grid container direction="row" alignItems="center">
        <Grid item>
          <IconButton
            className={classes.iconButton}
            aria-label="Attach"
            color="primary"
            onClick={handleCallImage}
            size="large">
            <AttachFileIcon />
          </IconButton>
          {uploadedFile}
        </Grid>
      </Grid>
    </div>
  );

  const [sendMessage, { error: cancelError }] = useMutation(SEND_MESSAGE, {
    variables: {
      fromUserId: 0,
      toUserId: recipient,
      subject: newSubject,
      text: bodyText,
      attachmentLocation: uploadedFileUrl,
    },
    context: contextHeaders,
  });

  const handleCallImage = () => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute(
      "accept",
      ".xlsx,.xls,image/*,.doc,.docx,.ppt,.pptx,.txt,.pdf"
    );
    input.click();

    input.onchange = async () => {
      var file: any = input.files[0];
      var formData = new FormData();

      // formData.append('image', file);
      formData.append("file", file);
      // formData.append("attachment", file);
      // console.log("file", file);
      var fileName = file.name;

      setFormData(file);
      setFileName(fileName);

      // console.log("formData", file);
      // console.log("fileName", fileName);

      // const res = uploadAttachment();
      const res = UploadFiles(formData, fileName);
    };
  };
  const token = contextHeaders.headers.authorization;
  const siteUrl = serverUrl;

  const UploadFiles = (formData, filename) => {
    axios
      .post(
        siteUrl + "/api/uploadfile/" + user.person.id + "/" + filename + "/msg",
        formData,
        {
          headers: {
            "Content-Type": "application/form-data",
            Authorization: token,
          },
        }
      )
      .then((response) => {
        // console.log("response", response.data.fileUrl);
        setUploadedFile(filename);
        setUploadedFileUrl(response.data.fileUrl);
      })
      .catch((error) => {
        console.log("uploaded", false);
      });
  };
  //console.log(" email body curPickedPatient: ", curPickedPatient.id,  curPickedPatient.lastName, curPickedPatient.firstName)
  const {
    data: subjectsData,
    loading: apptLoading3,
    error: apptError3,
  } = useQuery(GET_ALL_MSG_SUBJECT, {
    context: contextHeaders,
  });

  let subjectList = [];
  /*
    if (subjectsData) {
        subjectList = subjectsData.allMsgSubjects;
        console.log("subjectsData", subjectsData);
        console.log("subjectList", subjectList);
        {subjectList?.map( (subObject) => {
                return(
                    subjectList1.push({id:subObject.id, title:subObject.subject})
                );
            })};
    }
    */
  if (subjectsData) {
    {
      subjectsData.allMsgSubjects?.map((subObject) => {
        return subjectList.push({ id: subObject.id, title: subObject.subject });
      });
    }
  }

  // console.log("subjectLis:", subjectList);

  /*
    const questionList1 = [  { id: "1", title: "Non-Urgent Medical Question" },
                            { id: "2", title: "Prescriptiion Question" },
                            { id: "3", title: "Test Results Question" },
                            { id: "4", title: "Visit Follow-Up Question" },
                            { id: "5", title: "Appointment Question" },
                            { id: "6", title: "Attachment: Immunization Records" },
                            { id: "7", title: "Attachment: Living Will/Advanced Directive" },
                            { id: "8", title: "Attachment: Extenrnal Medical Records" },
                            { id: "9", title: "Attachment: Medical Records Release" },
                            { id: "10", title: "Attachment: Form (FMLA, Disability)" },
                            { id: "11", title: "Attachment: Photos" },
                          ];
    console.log("questionsList Array: ", questionList1);
    */
  let subject;
  // console.log("EmailBody isPhysician ", isPhysician);
  if (isPhysician || isStaff) {
    subject = (
      <TextField
        id="newSubject"
        label="Subject"
        fullWidth
        select
        onChange={(e) => setNewSubject(e.target.value)}
        SelectProps={{
          native: true,
        }}
      >
        {subjectList?.map((option) => {
          return (
            <option key={option.id} value={option.title}>
              {option.title}
            </option>
          );
        })}
      </TextField>
    );
  } else {
    subject = (
      <TextField
        id="newSubject"
        label="Subject"
        fullWidth
        onChange={(e) => setNewSubject(e.target.value)}
      ></TextField>
    );
  }
  return props.trigger ? (
    <div style={modalStyle} className={modalClasses.paper}>
      <h2 id="simple-modal-title">New Message</h2>
      <p id="simple-modal-description">
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            required
            id="outlined-required"
            label="Recipient"
            defaultValue={
              props.toEmail.firstName + " " + props.toEmail.lastName
            }
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">To:</InputAdornment>
              ),
            }}

            //onChange={e => setRecipient(e.target.value)}
          />
          {subject}
          <p />
          <EditorToolbar quillObject={quillObject} />
          {/* <ReactQuill ref={(el) => { reactQuillRef = el }} modules={modules} formats={formats}
                   onKeyDown={checkCharacterCount} onChange={(value) => this.props.onChange(value), setBodyText}
                   theme="snow" value={bodyText} style={{ height: 23 + 'em' }} /> */}
          <ReactQuill
            ref={(el) => {
              reactQuillRef = el;
            }}
            modules={modules}
            formats={formats}
            onKeyDown={checkCharacterCount}
            onChange={
              ((value) => this.props.onChange(value),
              checkCharacterCount,
              setBodyText)
            }
            theme="snow"
            value={bodyText}
            style={{ height: 16 + "em" }}
          />
          <CustomBottomBar />
        </form>
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "3vh",
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={() => props.setTrigger(false)}
          /*
               <Button variant="contained" color="secondary" onClick={() => {
                 handleClose();
               }}
               */
        >
          Close
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            // handleToEmail();
            sendMessage();
            handleClose();
          }}
        >
          Send
        </Button>
      </div>
    </div>
  ) : (
    ""
  );
}
