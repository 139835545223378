import { useState } from "react";
// import brain from "brain.js/src/index";
// const network = new brain.NeuralNetwork();
// network.train([
//   { input: [0, 0, 0], output: [0] },
//   { input: [0, 0, 1], output: [0] },
//   { input: [0, 1, 0], output: [0] },
//   { input: [1, 0, 0], output: [1] },
//   { input: [1, 1, 1], output: [1] },
// ]);
// export const output = network.run([1, 1, 0]);

export const convertDate = (date) => {
  var yyyy = date.getFullYear().toString();
  var mm = (date.getMonth() + 1).toString();
  var dd = date.getDate().toString();

  var mmChars = mm.split("");
  var ddChars = dd.split("");

  return (
    yyyy +
    "-" +
    (mmChars[1] ? mm : "0" + mmChars[0]) +
    "-" +
    (ddChars[1] ? dd : "0" + ddChars[0])
  );
};

/**set timeslots */
const timeslotsTotal = [
  "08:00",
  "08:15",
  "08:30",
  "08:45",
  "09:00",
  "09:15",
  "09:30",
  "09:45",
  "10:00",
  "10:15",
  "10:30",
  "10:45",
  "11:00",
  "11:15",
  "11:30",
  "11:45",
  "12:00",
  "12:15",
  "12:30",
  "12:45",
  "13:00",
  "13:15",
  "13:30",
  "13:45",
  "14:00",
  "14:15",
  "14:30",
  "14:45",
  "15:00",
  "15:15",
  "15:30",
  "15:45",
  "16:00",
  "16:15",
  "16:30",
  "16:45",
  "17:00",
  "17:15",
  "17:30",
  "17:45",
  "18:00",
  "18:15",
  "18:30",
  "18:45",
];

const getEndTime = (startTime, timeInterval) => {
  let firstPart = startTime.split(":")[0];
  let secondPart = startTime.split(":")[1];
  if (timeInterval === "60") {
    const first = Number(firstPart) + 1;
    if (first === 9) firstPart = `0${first}`;
    else firstPart = String(first);
  } else if (timeInterval === "30") {
    if (secondPart === "00") secondPart = "30";
    else {
      secondPart = "00";
      const first = Number(firstPart) + 1;
      if (first === 9) firstPart = `0${first}`;
      else firstPart = String(first);
    }
  } else {
    if (secondPart == "45") {
      secondPart = "00";
      const first = Number(firstPart) + 1;
      if (first === 9) firstPart = `0${first}`;
      else firstPart = String(first);
    } else {
      secondPart = String(Number(secondPart) + 15);
    }
  }
  return `${firstPart}:${secondPart}`;
};

export const getTimeslots = (timeInterval) => {
  const arr1 = timeslotsTotal
    .filter((time) => {
      if (timeInterval === "60") return time.includes("00");
      else if (timeInterval === "30")
        return time.includes("00") || time.includes("30");
      else return true;
    })
    .map((time) => {
      const endTime = `${getEndTime(time, timeInterval)}:00`;
      const startTime = `${time}:00`;
      const obj = {
        startTime,
        endTime,
        status: "available",
      };
      return obj;
    });

  return arr1;
};
