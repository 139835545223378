import { gql } from "@apollo/client";

export const PHARMACY_DETAIL_BY_PATIENTID = gql`
  mutation  addPharmacyDetail($patient_id:ID!, $pharmacy: String, $pharmacyAddress: String, $telNo: String, $status: String){
    addPharmacyDetail(patient_id:$patient_id, pharmacy: $pharmacy, pharmacyAddress: $pharmacyAddress, telNo: $telNo, status: $status){
      id, pharmacy, pharmacyAddress, status, patient{id}
    } 
  } 
`

export const ACTIVE_PHARMACY_DETAIL_BY_PATIENTID_DEFAULT = gql`
  query activePharmacyDetailByPatientIdDefault($id: ID!){
    activePharmacyDetailByPatientIdDefault(id: $id){
      id, pharmacy, pharmacyAddress, telNo, status, defaultPharmacy
    }   	
  }  	
`

export const ADD_PHARMACY_DETAIL = gql`
  mutation addPharmacyDetail($patient_id:ID!, $pharmacy: String, $pharmacyAddress: String, $telNo: String, $status: String){
    addPharmacyDetail(patient_id:$patient_id, pharmacy: $pharmacy, pharmacyAddress: $pharmacyAddress, telNo: $telNo, status: $status){
        id, pharmacy, pharmacyAddress, status, patient{id}
    } 
  } 
`

export const UPDATE_PHARMACYDETAIL = gql`
  mutation updatePharmacyDetail($id: ID!, $pharmacy: String, $pharmacyAddress: String, $telNo: String, $status: String){
    updatePharmacyDetail(id: $id, pharmacy: $pharmacy, pharmacyAddress: $pharmacyAddress, telNo: $telNo, status: $status){
        id, pharmacy, pharmacyAddress, telNo, status
    } 
  } 
`

export const DEL_PHARMACY_DETAIL = gql`
  mutation delPharmacyDetail($id: ID!){
    delPharmacyDetail(id: $id){
        id, pharmacy, pharmacyAddress, status, patient{id}
    }
  }
`
export const CVS_LOCATION_BY_ASC = gql`
  query csvLocationByASC($zipCode: String!){
    csvLocationByASC(zipCode: $zipCode){
      cvs_address, cvs_city, cvs_state, cvs_zipcode, cvs_telnum
    } 
  } 
`
