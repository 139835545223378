import { gql } from "@apollo/client";

export const eBinder_GET_ALL_ORGANIZATIONS = gql`
  query getAllOrganization($page: Int!, $size: Int!) {
    getAllOrganization(page: $page, size: $size) {
      id
      orgTypeId
      orgName
      orgPhone
      orgEmail
      orgStatus
      orgCountry
      orgContact
      orgAddress
      orgCreateTime {
        toString
      }
      orgUpdateTime {
        toString
      }
      orgDescription
    }
  }
`;

export const eBinder_GET_ALL_ORGANIZATION_MODEL_PAGE = gql`
  query getAllOrganizationModelPage($page: Int!, $size: Int!) {
    getAllOrganizationModelPage(page: $page, size: $size) {
      totalSize
      organizations {
        id
        orgName
        orgTypeId
        orgTypeName
        orgPhone
        orgEmail
        orgStatus
        orgCountry
        orgContact
        orgAddress
        orgCreateTime {
          toString
        }
        orgUpdateTime {
          toString
        }
        orgDescription
      }
    }
  }
`;

export const eBinder_GET_ALL_ORGANIZATION_TYPES = gql`
  query getAllOrganizationType {
    getAllOrganizationType {
      id
      typeName
    }
  }
`;

export const eBinder_ADD_ORGANIZATION = gql`
  mutation addNewOrganization(
    $orgTypeId: Long
    $orgName: String
    $orgCountry: String
    $orgStatus: String
    $orgContact: String
    $orgPhone: String
    $orgEmail: String
    $orgAddress: String
    $orgDescription: String
    $operatorId: Long
    $operatorIP: String
    $operatorBrowser: String
  ) {
    addNewOrganization(
      orgTypeId: $orgTypeId
      orgName: $orgName
      orgCountry: $orgCountry
      orgStatus: $orgStatus
      orgContact: $orgContact
      orgPhone: $orgPhone
      orgEmail: $orgEmail
      orgAddress: $orgAddress
      orgDescription: $orgDescription
      operatorId: $operatorId
      operatorIP: $operatorIP
      operatorBrowser: $operatorBrowser
    ) {
      id
      orgTypeId
      orgName
      orgPhone
      orgEmail
      orgStatus
      orgCountry
      orgContact
      orgAddress
      orgCreateTime {
        toString
      }
      orgUpdateTime {
        toString
      }
      orgDescription
    }
  }
`;

export const eBinder_EDIT_ORGANIZATION = gql`
  mutation updateOrganization(
    $orgId: Long
    $orgTypeId: Long
    $orgName: String
    $orgCountry: String
    $orgStatus: String
    $orgContact: String
    $orgPhone: String
    $orgEmail: String
    $orgAddress: String
    $orgDescription: String
    $operatorId: Long
    $operatorIP: String
    $operatorBrowser: String
  ) {
    updateOrganization(
      orgId: $orgId
      orgTypeId: $orgTypeId
      orgName: $orgName
      orgCountry: $orgCountry
      orgStatus: $orgStatus
      orgContact: $orgContact
      orgPhone: $orgPhone
      orgEmail: $orgEmail
      orgAddress: $orgAddress
      orgDescription: $orgDescription
      operatorId: $operatorId
      operatorIP: $operatorIP
      operatorBrowser: $operatorBrowser
    ) {
      id
      orgTypeId
      orgName
      orgPhone
      orgEmail
      orgStatus
      orgCountry
      orgContact
      orgAddress
      orgCreateTime {
        toString
      }
      orgUpdateTime {
        toString
      }
      orgDescription
    }
  }
`;
