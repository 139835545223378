import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten } from "@mui/material/styles";
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";

import {
  FIND_DATA_MASKINGS_AUTO,
  MANUAL_MASK_DATA,
  STORE_MASKING_INFO,
  DEACTIVATE_DATA_MASKING_AUTO,
  DEACTIVATE_DATA_MASKING_AUTOS,
  FIND_PATIENTS_BY_PHYSICIAN,
  UPLOAD_ATTACHMENT,
  serverUrl,
} from "@cims/common";

import { useQuery, useMutation } from "@apollo/client";
import { AuthenticationContext } from "../../../services/authentication/authentication.context";
// import { MessageContext } from "../../../services/message/message.context";

import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import EditorToolbar2, { modules, formats } from "./EditorToolbar2";
import axios from "axios";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  // const top = 50 + rand();
  // const left = 50 + rand();
  const top = 15;
  const left = 35;
  return {
    top: `${top}%`,
    left: `${left}%`,
    // transform: `translate(-${top}%, -${left}%)`,
  };
}

const useModalStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 680,
    height: 660,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    fontSize: 16,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

var rows = [];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (array == null) return;

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "fromUserName",
    numeric: false,
    disablePadding: false,
    label: "Filename",
  },
  {
    id: "timestamp",
    numeric: false,
    disablePadding: false,
    label: "Masked Date",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <StyledTableRow>
        <StyledTableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.timestamp}
              direction={orderBy === headCell.timestamp ? order : "desc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.timestamp ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

var selectedRowIds;
var globalContextHeaders;
var globalUser;

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;
  console.log();

  const [deactivateDataMaskingAutos, { error: deactivateError2 }] = useMutation(
    DEACTIVATE_DATA_MASKING_AUTOS,
    {
      variables: {
        ids: selectedRowIds,
      },
      //context: globalContextHeaders,
      refetchQueries: [
        {
          query: FIND_DATA_MASKINGS_AUTO,
          variables: { person_id: Number(globalUser.person.id) },
          //context: globalContextHeaders,
        },
      ],
    }
  );

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Auto Masked Files
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton
            aria-label="delete"
            onClick={() => {
              deactivateDataMaskingAutos();
            }}
            size="large">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list" size="large">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function AutoDataMaskingListTable() {
  const classes = useStyles();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("timestamp");
  const [selected, setSelected] = useState([]);

  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { user, contextHeaders } = useContext(AuthenticationContext);

  globalContextHeaders = contextHeaders;
  globalUser = user;

  // console.log("personId", user.person.id);

  const modalClasses = useModalStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);

  const [recipient, setRecipient] = useState(0);
  const [newSelected, setNewSelected] = useState([]);
  const [messageId, setMessageId] = useState("");
  const [fromUserName, setFromUserName] = useState("");
  const [fromUserId, setFromUserId] = useState("");
  const [subject, setSubject] = useState("");
  const [sentDate, setSentDate] = useState("");
  const [viewBodyText, setViewBodyText] = useState("");
  const [viewId, setViewId] = useState(0);

  const handleChange = (event) => {
    // console.log("event.target.value", event.target.value);
    setRecipient(Number(event.target.value));
  };

  const handleOpen = () => {
    setUploadedFile("");
    setUploadedFileUrl("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleViewOpen = () => {
    setViewOpen(true);
  };

  const handleViewClose = () => {
    setViewOpen(false);
  };

  const {
    data: recipientsData,
    loading: apptLoading2,
    error: apptError2,
  } = useQuery(FIND_PATIENTS_BY_PHYSICIAN, {
    variables: { physicianId: Number(user.person.id) },
    //context: contextHeaders,
  });

  let recipientList;

  if (recipientsData) {
    recipientList = recipientsData.patientsByPhysicianId;
    // console.log("recipientsData", recipientsData);
  }

  const [bodyText, setBodyText] = useState("");
  const [newSubject, setNewSubject] = useState("");
  const [uploadedFile, setUploadedFile] = useState("");
  const [uploadedFileUrl, setUploadedFileUrl] = useState("");
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [uploadedHtmlFile, setUploadedHtmlFile] = useState("");
  const [filename, setFilename] = useState(null);
  const [quillObj, setQuillObj] = useState(null);

  const [maskedFile, setMaskedFile] = useState("");

  const [maskData, { error: cancelError }] = useMutation(MANUAL_MASK_DATA, {
    variables: {
      personId: Number(user.person.id),
      file: filename,
      content: bodyText,
    },
    //context: contextHeaders,
    refetchQueries: [
      {
        query: FIND_DATA_MASKINGS_AUTO,
        variables: { person_id: Number(globalUser.person.id) },
        //context: globalContextHeaders,
      },
    ],
  });

  const [storeData, { error: cancelStoreError }] = useMutation(
    STORE_MASKING_INFO,
    {
      variables: {
        personId: Number(user.person.id),
        pdfFile: uploadedFileName,
      },
      //context: contextHeaders,
      refetchQueries: [
        {
          query: FIND_DATA_MASKINGS_AUTO,
          variables: { person_id: Number(globalUser.person.id) },
          //context: globalContextHeaders,
        },
      ],
    }
  );

  const [deactivateDataMaskingAuto, { error: deactivateError }] = useMutation(
    DEACTIVATE_DATA_MASKING_AUTO,
    {
      variables: {
        id: viewId,
      },
      //context: contextHeaders,
      refetchQueries: [
        {
          query: FIND_DATA_MASKINGS_AUTO,
          variables: { person_id: Number(user.person.id) },
          //context: contextHeaders,
        },
      ],
    }
  );

  const [deactivateDataMaskingAutos, { error: deactivateError2 }] = useMutation(
    DEACTIVATE_DATA_MASKING_AUTOS,
    {
      variables: {
        ids: selectedRowIds,
      },
      //context: contextHeaders,
      refetchQueries: [
        {
          query: FIND_DATA_MASKINGS_AUTO,
          variables: { person_id: Number(user.person.id) },
          //context: contextHeaders,
        },
      ],
    }
  );

  const [formData, setFormData] = useState(null);
  const [fileName, setFileName] = useState(null);

  const [uploadAttachment, { error: uploadError }] = useMutation(
    UPLOAD_ATTACHMENT,
    {
      variables: {
        attachment: formData,
        messageId: 0,
      },
      //context: contextHeaders,
    }
  );

  var reactQuillRef = null;
  const [quillObject, setQuillObject] = useState(null);

  const checkCharacterCount = (event) => {
    // console.log("length", reactQuillRef.getEditor().getLength());
    // console.log("bodyText", bodyText);
    const limted = 500;
    const quillEditor = reactQuillRef.getEditor();
    // console.log("quillEditor", quillEditor);

    setQuillObject(reactQuillRef);

    const totalChar = quillEditor.getLength() - 1;

    if (quillEditor.getLength() > limted + 1)
      quillEditor.deleteText(limted, totalChar);
  };

  const CustomViewBottomBar = () => (
    <div className="ths-space-y-10">
      <Grid container direction="row" alignItems="center">
        <Grid item>
          <IconButton
            className={classes.iconButton}
            aria-label="Attach"
            color="primary"
            onClick={handleViewCallImage}
            size="large">
            <EnhancedEncryptionIcon />
          </IconButton>
          {maskedFile}
        </Grid>
      </Grid>
    </div>
  );

  const handleUploadFile = (option) => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute(
      "accept",
      ".xlsx,.xls,image/*,.doc,.docx,.ppt,.pptx,.txt,.pdf"
    );
    input.click();

    input.onchange = async () => {
      var file: any = input.files[0];
      var formData = new FormData();

      // formData.append('image', file);
      formData.append("file", file);
      // formData.append("attachment", file);
      var fileName = file.name;

      setFormData(file);
      setFileName(fileName);

      console.log("formData", file);
      console.log("fileName2", fileName);

      const res = UploadFiles(formData, fileName, option);
    };
  };

  // const authToken = localStorage.getItem("auth_token");
  // if (authToken !== null) {
  // var token = `Bearer ${value}`;
  // console.log("Authorization", token);
  // }
  const token = contextHeaders.headers.authorization;
  const siteUrl = serverUrl;
  //const siteUrl = "http://localhost:8080";
  const UploadFiles = (formData, filename, option) => {
    console.log("id=" + user.person.id);
    console.log("filename=" + filename);

    axios
      .post(
        siteUrl +
          "/api/uploadfile/" +
          user.person.id +
          "/" +
          filename +
          "/" +
          option,
        formData,
        {
          headers: {
            "Content-Type": "application/form-data",
            Authorization: token,
          },
        }
      )
      .then((response) => {
        console.log("response", response.data);
        setUploadedFile(filename);
        setUploadedFileUrl(response.data.fileUrl);
        var fileUrl = response.data.fileUrl;
        var fileUrlSplit = fileUrl.split("/");
        var maskedfileName = fileUrlSplit[fileUrlSplit.length - 1];
        setUploadedFileName(maskedfileName);
        console.log("maskedfileName ", maskedfileName);
        storeData();
      })
      .catch((error) => {
        console.log("uploaded", false);
      });
  };

  const handleViewCallImage = (maskedFile) => {
    console.log("messageId=" + messageId);
    axios({
      url: siteUrl + "/api/downloadPDF/" + user.person.id + "/" + maskedFile,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        console.log("response", response);
        var file = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${maskedFile}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const state = { filename: "", quillObj: null };
  const handleFileCallBack = (childData) => {
    // console.log("childData", childData);
    setFilename(childData);
  };
  const handleQuillCallBack = (childData) => {
    setQuillObj(childData);
  };

  const getHTMLContent = () => {
    // console.log("bodyText", bodyText);
    // console.log("filename", filename);
    // // console.log("quillObj", quillObj);
  };

  const body = (
    <div style={modalStyle} className={modalClasses.paper}>
      <h2 id="simple-modal-title">Auto Mask Data</h2>
      <p id="simple-modal-description">
        <form className={classes.root} noValidate autoComplete="off">
          <EditorToolbar2
            user={user}
            quillCallBack={handleQuillCallBack}
            fileCallBack={handleFileCallBack}
          />
          {/* <ReactQuill ref={(el) => { reactQuillRef = el }} modules={modules} formats={formats}
            onKeyDown={checkCharacterCount} onChange={(value) => this.props.onChange(value), setBodyText}
            theme="snow" value={bodyText} style={{ height: 23 + 'em' }} /> */}
          <ReactQuill
            ref={(el) => {
              reactQuillRef = el;
            }}
            modules={modules}
            formats={formats}
            onKeyDown={checkCharacterCount}
            onChange={
              ((value) => this.props.onChange(value),
              checkCharacterCount,
              setBodyText)
            }
            theme="snow"
            value={bodyText}
            style={{ height: 29 + "em" }}
          />
          {/* <CustomBottomBar /> */}
        </form>
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "3vh",
          marginTop: "1px",
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            getHTMLContent();
            maskData();
            handleClose();
          }}
        >
          Mask
        </Button>
      </div>
    </div>
  );

  const formatDate = (dateString) => {
    const options = {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  // console.log(formatDate(sentDate));

  // const history = useHistory();
  const [value, setValue] = useState();
  const refresh = () => {
    // it re-renders the component
    setValue({});
  };

  const viewBody = (
    <div style={modalStyle} className={modalClasses.paper}>
      <h2 id="view-modal-title">View Masked Data</h2>
      <p id="view-modal-description">
        <TextField
          id="subject"
          label=" "
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Filename:</InputAdornment>
            ),
          }}
          defaultValue={subject}
          disabled
        />
        <TextField
          id="date"
          label=" "
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Masked Date:</InputAdornment>
            ),
          }}
          defaultValue={formatDate(sentDate)}
          disabled
        />
        <p />
        <EditorToolbar2 />
        <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          value={viewBodyText}
          onChange={setViewBodyText}
          style={{ height: 22 + "em" }}
          readOnly="true"
        />
        <CustomViewBottomBar />
        <p />
      </p>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "3vh",
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            handleViewClose();
          }}
        >
          Close
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            deactivateDataMaskingAuto();
            handleViewClose();
            // history.push("/communication/messages");
            // window.open("/communication/messages");
            // window.location.reload();
            refresh();
          }}
        >
          Delete
        </Button>
      </div>
    </div>
  );

  const {
    data: maskedData,
    loading: apptLoading,
    error: apptError,
  } = useQuery(FIND_DATA_MASKINGS_AUTO, {
    variables: { person_id: Number(user.person.id) },
    //context: contextHeaders,
  });

  if (maskedData) {
    // console.log("maskedData", maskedData);
    rows = maskedData.findDataMaskingsAuto;
    // console.log("rows", rows[0].timestamp);
    // console.log("rows", rows);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      // Assign to global for delete button in Toolbar
      selectedRowIds = newSelecteds;

      return;
    }
    setSelected([]);
    // Assign to global for delete button in Toolbar
    selectedRowIds = [];
  };

  const handleRowClick = (event, row) => {
    if (event.target.tagName == "TD") {
      var maskedFile = row.fileLocation.split("/").pop();
      setMaskedFile(maskedFile);

      setMessageId(row.id);
      setSubject(maskedFile);
      setSentDate(row.timestamp);
      setViewBodyText("test123");
      setViewId(row.id);

      // View Message
      // handleViewOpen();
      handleViewCallImage(maskedFile);
    } else {
      // Checkbox

      const selectedIndex = selected.indexOf(row.id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row.id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      // console.log("newSelected", newSelected);
      setSelected(newSelected);
      // Assign to global for delete button in Toolbar
      selectedRowIds = newSelected;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleCheckbox = (event) => {
    if (event.target.type == "checkbox") {
      console.log("checkbox", event.currentTarget.checked);

      if (event.currentTarget.checked) event.currentTarget.checked = false;
      else event.currentTarget.checked = true;

      // stop the bubbling to prevent firing the row's click event
      event.stopPropagation();
    }
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const [isChecked, setIsChecked] = useState(true);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const truncate = (str) => {
    return str.length > 100 ? str.substring(0, 97) + "..." : str;
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <StyledTableRow
                      hover
                      onClick={(event) => handleRowClick(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <StyledTableCell
                        padding="checkbox"
                        style={{ width: "1%" }}
                      >
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "50%" }}>
                        {row.fileLocation.split("/").pop()}
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "25%" }}>
                        {formatDate(row.timestamp)}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              {emptyRows > 0 && (
                <StyledTableRow
                  style={{ height: (dense ? 33 : 53) * emptyRows }}
                >
                  <StyledTableCell colSpan={6} />
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "0vh",
        }}
      >
        {/* <Fab color="secondary" aria-label="edit" onClick={handleOpen}>
          <EnhancedEncryptionIcon />
        </Fab> */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => handleUploadFile("NULLIFY")}
        >
          Nullify
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => handleUploadFile("HASH")}
        >
          Hash
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => handleUploadFile("REDACTION")}
        >
          Redaction
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => handleUploadFile("LAST_FOUR")}
        >
          Last 4 Digits
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => handleUploadFile("FIRST_FOUR")}
        >
          First 4 Digits
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => handleUploadFile("YEAR_ONLY")}
        >
          Year Only
        </Button>
      </div>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>
        <Modal
          open={viewOpen}
          onClose={handleViewClose}
          aria-labelledby="view-modal-title"
          aria-describedby="view-modal-description"
        >
          {viewBody}
        </Modal>
      </div>
    </div>
  );
}
