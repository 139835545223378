import {
  Container,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Link,
  CardHeader,
  TextField,
  Modal,
  Backdrop,
  Fade,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableBody,
  TableHead,
  RadioGroup,
  Radio,
  FormControlLabel,
  Paper,
  TablePagination,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  GET_ALL_CONDITIONCT_BY_NAME,
  GET_ALL_CONDITIONCT_BY_CATEGORY,
} from "@cims/common/services/clinicaltrials.service";
import { blue } from '@mui/material/colors';

function createData(id, name) {
  return {
    id,
    name,
  };
}

const useStyles = makeStyles((theme) => ({
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 20,
    // height: "50px",
  },
  addBtnIcon: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
    color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
    "&:hover": {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#BDC0BA" : blue[700],
      color: "#fff",
    },
  },

  btn: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  modaltext: {
    marginTop: 20,
    flexDirection: "column",
    justifyContent: "center",
  },

  paper: {
    backgroundColor: "#f5f3f0", //theme.palette.background.paper,
    border: "1px solid #666666",
    width: 700,
    maxHeight: 700,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "auto",
  },

  htyCard: {
    marginBottom: "10px",
    flex: 1,
    flexDirection: "row",
  },

  cardHeader: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true"
        ? "rgba(0,26,72, 0.3)"
        : "rgba(126,211,205,0.5)",
  },

  divider: {
    backgroundColor: "#d9e2ee",
    margin: "0 20px",
  },

  hyperlink: {
    paddingLeft: 30,
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
    // height: "40px",
  },
  addBtnIcon: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
    color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
    "&:hover": {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#BDC0BA" : blue[700],
      color: "#fff",
    },
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
    // backgroundColor: "green",
    // color: theme.palette.common.white,
    color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export default function PatientMatcherClincalTrials() {
  const myStyles = useStyles();
  const history = useHistory();
  const classes = useRowStyles();

  const preventDefault = (e) => e.preventDefault();
  const [openAlertCND, setOpenAletCND] = useState(false);
  const [searchFunc, setSearchFunc] = useState(GET_ALL_CONDITIONCT_BY_CATEGORY);
  const [dataKey, setDataKey] = useState("getAllConditionsCTsByNameASC");
  const [searchBy, setSearchBy] = useState("Name");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [text, setText] = useState("");

  let rowss1 = [];
  let rowss2 = [];

  const {
    data: data1,
    loading: loading1,
    error: error1,
  } = useQuery(GET_ALL_CONDITIONCT_BY_CATEGORY, {
    variables: { page: page - 1, size: size },
  });

  const {
    data: data2,
    loading: loading2,
    error: error2,
  } = useQuery(GET_ALL_CONDITIONCT_BY_NAME, {
    variables: { page: page - 1, size: size },
  });

  const changeSearch = (e) => {
    setText("");
    const name = e.target.value;
    setSearchBy(name);
    if (name === "name") {
      setSearchFunc(GET_ALL_CONDITIONCT_BY_NAME);
      setDataKey("getAllConditionsCTsByNameASC");
    } else if (name === "category") {
      setSearchFunc(GET_ALL_CONDITIONCT_BY_CATEGORY);
      setDataKey("getAllConditionsCTsByCategoryIdASC");
    }
  };
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  {
    data1 &&
      data1.getAllConditionsCTsByCategoryIdASC &&
      data1.getAllConditionsCTsByCategoryIdASC.conditionsCTs.map((item) => {
        // console.log("organization: name  " + item.name);
        // console.log("organization: type  " + item.type);
        // console.log(" organization, locations  " + item.locations);
        rowss1.push(createData(item.id, item.name));
      });
  }
  console.log(rowss1);

  return (
    <Container>
      <Card className={myStyles.htyCard}>
        <CardContent className={myStyles.cardHeader}>
          <Typography variant="body1" gutterBottom component="div">
            Patient Matcher Clinical Trials is a database of privately and
            publicly funded clinical studies conducted around the world.
          </Typography>
          <Typography variant="body1" gutterBottom component="div">
            Explore 394,619 research studies in all 50 states and in 220
            countries.
          </Typography>
          <Typography
            display="inline"
            variant="body1"
            gutterBottom
            component="div"
          >
            See&nbsp;
          </Typography>
          <Link display="inline" href="#" onClick={preventDefault}>
            listed clinical studies
          </Link>
          <Typography
            display="inline"
            variant="body1"
            gutterBottom
            component="div"
          >
            &nbsp;related to the coronavirus disease(COVID-19)
          </Typography>
        </CardContent>
      </Card>

      <Card className={myStyles.htyCard}>
        <CardContent className={myStyles.cardHeader}>
          <Typography
            display="inline"
            variant="body1"
            gutterBottom
            component="div"
          >
            Important: Listing a study does not mean it has been evaluated by
            the U.S. Federal Government. Read our
          </Typography>
          <Link display="inline" href="#" onClick={preventDefault}>
            &nbsp;disclaimer
          </Link>
          <Typography
            display="inline"
            variant="body1"
            gutterBottom
            component="div"
          >
            &nbsp;for details.
          </Typography>
          <Typography
            display="inline"
            variant="body1"
            gutterBottom
            component="div"
          >
            Before participating in a study, talk to your health care provider
            and learn about the
          </Typography>
          <Link display="inline" href="#" onClick={preventDefault}>
            &nbsp;risks and potential benefits.
          </Link>
        </CardContent>
      </Card>

      <Card className={myStyles.htyCard}>
        <CardHeader
          className={myStyles.cardHeader}
          subheader={`Find a Study(all fields optional)`}
        />
        <CardContent>
          <Link
            display="inline"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setOpenAletCND(true);
            }}
          >
            Condition or disease
          </Link>
          <Typography
            display="inline"
            variant="body1"
            gutterBottom
            component="div"
          >
            (For example: breast cancer)
          </Typography>
          <div>
            <TextField
              id="outlined-search"
              fullWidth
              type="search"
              variant="outlined"
            />
          </div>
        </CardContent>

        <CardActions>
          <Button
            style={{
              marginLeft: 10,
              backgroundColor:
                localStorage.getItem("isDarkMode") === "true"
                  ? "#828282"
                  : blue[200],
            }}
            onClick={(e) => {
              e.preventDefault();
              history.push(`/physicians/:providerId/ClincalTrialsSearchResult`);
            }}
          >
            {`Search`}
          </Button>
          <Link
            display="inline"
            href="#"
            underline="always"
            onClick={(e) => {
              e.preventDefault();
              history.push(`/physicians/:providerId/ctadvancedsearch`);
            }}
            className={myStyles.hyperlink}
          >
            Advanced Search
          </Link>
        </CardActions>
      </Card>

      <Paper className={classes.paper}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowss1
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <StyledTableRow hover tabIndex={-1}>
                      <StyledTableCell align="left" style={{ width: "15%" }}>
                        {row.id}
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "15%" }}>
                        {row.name}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.pagination}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rowss1.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Paper>

      {/*Modal for Condition or diease */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={myStyles.modal}
        open={openAlertCND}
        onClose={() => setOpenAletCND(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAlertCND}>
          <div className={myStyles.paper}>
            <div className={myStyles.modaltext}>
              <Typography underline="always">Condition/disease:</Typography>
              <Typography>
                The disease, disorder, syndrome, illness, or injury that is
                being studied. On ClinicalTrials.gov, conditions may also
                include other health-related issues, such as lifespan, quality
                of life, and health risks.
              </Typography>
            </div>

            <div className={myStyles.btn}>
              <Button
                style={{ marginLeft: 20 }}
                variant="contained"
                color="primary"
                onClick={() => {
                  setOpenAletCND(false);
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </Container>
  );
}
