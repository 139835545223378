import React, { useState, useContext } from "react";
import {
  Container,
  Typography,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableBody,
  TableHead,
  Paper,
} from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import { useQuery } from "@apollo/client";
import { SOCIAL_HISTORY_BYPTID, MEDICAL_HISTORY_BYPTID, SURGICAL_HISTORY_BYPTID, ALL_MEDICAL_HISTORY} from "@cims/common/services/medical-history.service";
import { useHistory, useParams} from "react-router-dom";
import { AuthenticationContext } from "src/services/authentication/authentication.context";
import { blue } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 20,
    // height: "50px",
  },
  addBtnIcon: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
    color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
    "&:hover": {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#BDC0BA" : blue[700],
      color: "#fff",
    },
  },

  btn: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  paper: {
    backgroundColor: "#f5f3f0", //theme.palette.background.paper,
    border: "1px solid #666666",
    width: 700,
    maxHeight: 700,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "auto",
  },

  htyCard: {
    maxWidth: 345,
    marginBottom: "10px",
  },

  cardHeader: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true"
        ? "rgba(0,26,72, 0.3)"
        : "rgba(126,211,205,0.5)",
  },

  divider: {
    backgroundColor: "#d9e2ee",
    margin: "0 20px",
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function MedicalFamilyHistory() {
  const myStyles = useStyles();
  const { patientId } = useParams();
  const history = useHistory();
  const { curPickedPatient } = useContext(AuthenticationContext);

  const {
    data: socialhistory,
    loading: socialhistoryLoading,
    error: socalhistoryError,
  } = useQuery(SOCIAL_HISTORY_BYPTID, { variables: { patient_id: Number(curPickedPatient.patient.id) } });

  const {
    data: medicalhistory,
    loading: medicalhistoryLoading,
    error: medicalhistoryError,
  } = useQuery(MEDICAL_HISTORY_BYPTID, { variables: { patient_id: Number(curPickedPatient.patient.id) } });

  const {
    data: surgicalhistory,
    loading: surgicalhistoryLoading,
    error: surgicalhistoryError,
  } = useQuery(SURGICAL_HISTORY_BYPTID, { variables: { patient_id: Number(curPickedPatient.patient.id) } });

  console.log("Social->", socialhistory);


  return (
    <Container>
        <Typography variant="body1" gutterBottom component="div">
            Social History:
        </Typography>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Events</TableCell>
                <TableCell align="right">Result</TableCell>
                <TableCell align="right">Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {socialhistory && socialhistory.socialHistoryByPtId.map((social, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" >
                    {social.socialEvent}
                  </TableCell>
                  <TableCell align="right">{social.result}</TableCell>
                  <TableCell align="right">{social.personalNote}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

    

        <Typography variant="body1" gutterBottom component="div">
            Medical History:
        </Typography>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Events</TableCell>
                <TableCell align="right">Result</TableCell>
                <TableCell align="right">Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {medicalhistory && medicalhistory.medicalHistoryByPtId.map((medical, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" >
                    {medical.diagnosis}
                  </TableCell>
                  <TableCell align="right">{medical.diagnDate.iso}</TableCell>
                  <TableCell align="right">{medical.personalNote}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant="body1" gutterBottom component="div">
            Surgical History:
        </Typography>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Events</TableCell>
                <TableCell align="right">Result</TableCell>
                <TableCell align="right">Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {surgicalhistory && surgicalhistory.surgicalHistoryByPtId.map((surgical, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" >
                    {surgical.procedureName}
                  </TableCell>
                  <TableCell align="right">{surgical.procedureDate.iso}</TableCell>
                  <TableCell align="right">{surgical.personalNote}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        
    </Container>
  );
}
