import Input from "./Input";
import Select from "./Select";
import Button from "./Button";
import Date from "./Date";

const Controls = {
    Input,
    Select,
    Button,
    Date
}

export default Controls;