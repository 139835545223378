import { gql } from "@apollo/client";

export const eBinder_UPLOAD_IMAGE_FILES = gql`
  mutation uploadImageFiles(
    $original: Upload
    $masked: Upload
    $fileInfo: ImageFileInfoInput!
  ) {
    uploadImageFiles(
      original: $original
      masked: $masked
      fileInfo: $fileInfo
    ) {
      id
      location
      sequenceNo
      fileStatus
      maskStatus
    }
  }
`;

export const eBinder_GET_MASKING_TEMPLATES_BY_ID = gql`
  query getMaskingTemplateById($id: Long!) {
    getMaskingTemplateById(id: $id) {
      id
      name
      location
      description
      status
      createTime {
        toString
      }
      updateTime {
        toString
      }
      site {
        id
        siteName
      }
      studyForm {
        id
        formName
      }
      templateType
      imageMasks {
        id
        x
        y
        h
        w
        r
        g
        b
        page
        dedName
        partNo
        sequenceNo
        fieldLabel
        fieldControlType
        templateType
      }
    }
  }
`;

export const eBinder_GET_MASKING_TEMPLATES_BY_STUDY_FORM_AND_SITE = gql`
  query getMaskingTemplatesByStudyFormAndSite(
    $studyFormId: Int!
    $siteId: Int!
  ) {
    getMaskingTemplatesByStudyFormAndSite(
      studyFormId: $studyFormId
      siteId: $siteId
    ) {
      id
      name
      location
      description
      status
      createTime {
        toString
      }
      updateTime {
        toString
      }
      site {
        id
        siteName
      }
      studyForm {
        id
        formName
      }
      templateType
      imageMasks {
        x
        y
        h
        w
        r
        g
        b
        page
        dedName
        partNo
        sequenceNo
      }
    }
  }
`;

export const eBinder_GET_ALL_MASKING_TEMPLATES = gql`
  query getAllMaskingTemplatesByPage($page: Int!, $size: Int!) {
    getAllMaskingTemplatesByPage(page: $page, size: $size) {
      totalSize
      maskingTemplates {
        id
        name
        location
        description
        status
        createTime {
          toString
        }
        updateTime {
          toString
        }
        site {
          id
          siteName
        }
        studyForm {
          id
          formName
        }
        templateType
        imageMasks {
          x
          y
          h
          w
          r
          g
          b
          page
          dedName
          partNo
          sequenceNo
        }
      }
    }
  }
`;

export const eBinder_SAVE_OR_UPDATE_MASKING_TEMPLATE = gql`
  mutation saveOrUpdateMaskingTemplate(
    $original: Upload!
    $masked: Upload
    $id: ID
    $fileInfo: ImageFileInfoInput!
  ) {
    saveOrUpdateMaskingTemplate(
      original: $original
      masked: $masked
      id: $id
      fileInfo: $fileInfo
    ) {
      id
      name
      location
      description
      status
      createTime {
        toString
      }
      updateTime {
        toString
      }
      studyForm {
        id
      }
      site {
        id
      }
      templateType
      updateUser
      state
      imageMasks {
        x
        y
        h
        w
        r
        g
        b
        page
        dedName
        partNo
        sequenceNo
        fieldLabel
        fieldControlType
      }
    }
  }
`;

export const eBinder_CREATE_MASKING_MAPPING_TEMPLATE = gql`
  mutation createMaskingMappingTemplate(
    $original: Upload!
    $id: ID
    $fileInfo: ImageFileInfoInput!
  ) {
    createMaskingMappingTemplate(
      original: $original
      id: $id
      fileInfo: $fileInfo
    ) {
      id
      name
      location
      description
      status
      createTime {
        toString
      }
      updateTime {
        toString
      }
      studyForm {
        id
      }
      site {
        id
      }
      templateType
      updateUser
      state
      imageMasks {
        x
        y
        h
        w
        r
        g
        b
        page
        dedName
        partNo
        sequenceNo
        fieldLabel
        fieldControlType
      }
    }
  }
`;

export const eBinder_UPLOAD_MAPPING_IMAGE_FILES = gql`
  mutation uploadMappingImageFiles($id: ID, $fileInfo: ImageFileInfoInput!) {
    uploadMappingImageFiles(id: $id, fileInfo: $fileInfo) {
      id
      name
      location
      description
      status
      createTime {
        toString
      }
      updateTime {
        toString
      }
      studyForm {
        id
      }
      site {
        id
      }
      templateType
      updateUser
      state
      imageMasks {
        id
        x
        y
        h
        w
        r
        g
        b
        page
        dedName
        partNo
        sequenceNo
        fieldLabel
        fieldControlType
        templateType
      }
    }
  }
`;
export const eBinder_GET_FORM_TEMPLATES_BY_STYDY = gql`
  query getFormTemplatesByStudy($studyId: Int!) {
    getFormTemplatesByStudy(studyId: $studyId) {
      siteId
      siteName
      studyFromTemplates {
        siteId
        studyFormId
        formName
        fromTemplates {
          templateId
          status
          location
          fileName
          description
          studyFormId
          formName
          formCode
          templateType
        }
      }
    }
  }
`;

export const eBinder_GET_FORM_TEMPLATES_BY_SITE = gql`
  query getFormMaskingTemplatesBySite(
    $studyId: Int!
    $siteId: Int!
    $templateType: String!
    $page: Int!
    $size: Int!
  ) {
    getFormMaskingTemplatesBySite(
      studyId: $studyId
      siteId: $siteId
      templateType: $templateType
      page: $page
      size: $size
    ) {
      totalSize
      data {
        studyForm {
          id
          formName
          formCode
        }
        site {
          id
          siteName
        }
        maskingTemplate {
          id
          name
          location
          description
          status
          state
          templateType
          subjectId
          createUser
          createTime {
            toString
          }
          updateUser
          updateTime {
            toString
          }
        }
      }
    }
  }
`;

export const eBinder_GET_FILE_BLOB = gql`
  query ($fileLoc: String!) {
    fileUrl(fileLoc: $fileLoc) @rest(type: "fileUrl", path: "{args.fileLoc}")
  }
`;

export const eBinder_GET_FORM_MAPPING_TEMPLATES_BY_STYDY = gql`
  query getFormTemplatesByStudy($studyId: Int!) {
    getFormTemplatesByStudy(studyId: $studyId) {
      siteId
      siteName
      studyFromTemplates {
        siteId
        studyFormId
        formName
        fromTemplates {
          templateId
          status
          location
          fileName
          description
          studyFormId
          formName
          formCode
          templateType
        }
      }
    }
  }
`;

export const eBinder_GET_SITE_MAPPING_TEMPLATES_BY_STYDY = gql`
  query getSiteMappingTemplatesByStudy($studyId: Int!, $operatorId: Long!) {
    getSiteMappingTemplatesByStudy(studyId: $studyId, operatorId: $operatorId) {
      siteId
      siteName
      mappingState
      studyFormMaskingTemplates {
        siteId
        studyFormId
        formName
        formTemplateModel {
          templateId
          status
          state
          location
          fileName
          description
          studyFormId
          formName
          formCode
          siteId
          siteName
          templateType
          subjectId
          imageWidth
          imageHeight
          studyId
        }
      }
    }
  }
`;

export const eBinder_GET_USER_SITE_TEMPLATE_STATS = gql`
  query getUserSiteTemplateStats($userId: Long!, $studyId: Long!) {
    getUserSiteTemplateStats(userId: $userId, studyId: $studyId) {
      siteId
      siteName
      access
      maskingTemplateCount
      totalTemplateCount
    }
  }
`;

export const eBinder_GET_FORM_VISIT_STATICS_BY_STUDY_ID_AND_USER_ID = gql`
  query getFormVisitStaticsByStudyIdAndUserId($studyId: Long!, $userId: Long!) {
    getFormVisitStaticsByStudyIdAndUserId(studyId: $studyId, userId: $userId) {
      siteId
      siteName
      subjectInfoId
      subjectId
      fileStatus
      count
      ocrPendingCount
      ocProcessingCount
      ocrCompletedCount
      ocrFailedCount
      ocrLLMCompletedCount
      ocrLLMFailedCount
      ocrSdcConfirmedCount
    }
  }
`;

export const eBinder_GET_ALL_FLV_BY_SUBJECT_INFO_ID = gql`
  query getAllFVLBySubjectInfoId($subjectInfoId: Long!) {
    getAllFVLBySubjectInfoId(subjectInfoId: $subjectInfoId) {
      id
      lastOperatorId
      lastOperatorTime {
        toString
      }
      location
      sequenceNo
      fileStatus
      fileName
      fileSize
      form {
        id
        studyForm {
          id
          formName
          formCode
        }
      }
    }
  }
`;

export const eBinder_GET_CURRENT_MASKED_SOURCE_FILE = gql`
  query getCurrentMaskedSourceFile($formVisitLocationId: Long) {
    getCurrentMaskedSourceFile(formVisitLocationId: $formVisitLocationId) {
      formVisitLocation {
        id
        fileName
        fileSize
        fileEnabled
        location
      }
      maskingTemplate {
        id
        state
        imageMasks {
          x
          y
          w
          h
          r
          g
          b
          page
          dedName
          partNo
          sequenceNo
          fieldLabel
          fieldControlType
        }
      }
    }
  }
`;
