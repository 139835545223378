import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { serverUrl } from "@cims/common/";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    marginBottom: "10px",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: blue[100],
  },
}));

export default function AppointmentTypeCard({ appointments = {} }) {
  const classes = useStyles();

  const {
    name = "Lab Appointment",
    status = "available",
    avatar = `${serverUrl}/avatar/john-doe.jpg`,
  } = appointments;
  // console.log("appointments--->", appointments);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="body1" component="p">
          {name}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {status}
        </Typography>
      </CardContent>
    </Card>
  );
}
