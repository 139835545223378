import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  InputBase,
  Container,
  Paper,
  IconButton,
  Typography,
  Button,
  Modal,
  Fade,
  TextField,
  Backdrop,
  ButtonGroup,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from "@mui/icons-material/Search";
import { useQuery, useMutation } from "@apollo/client";
import {
  ADD_PHYSICIAN,
  SEARCH_PHYSICIANS_SORTING,
  SEARCH_PHYSICIANS_BY_NAME,
  SEARCH_PHYSICIANS_BY_SPEC,
  SEARCH_PHYSICIANS_BY_ZIP,
  GET_ALL_SPECIALTY_NAME,
  CustomTable,
  ALLPHYSICIANS,
} from "@cims/common";
import PersonCard from "../../../components/PersonCard";
import { Link, useHistory } from "react-router-dom";
import LoadingIndicator from "../../../components/LoadingIndicator";
import ErrorInfo from "../../../components/ErrorInfo";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { useForm } from "../../communication/components/useForm";
import { ALL_HEALTH_SYSTEM } from "@cims/common";
import { AuthenticationContext } from "../../../services/authentication/authentication.context";
import { Pagination } from '@mui/material';
import { blue } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  top: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
    marginTop: 20,
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 300,
    // marginRight: 20,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  btn: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  addBtnIcon: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
    color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
    "&:hover": {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#BDC0BA" : blue[700],
      color: "#fff",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#f5f3f0", //theme.palette.background.paper,
    border: "1px solid #666666",
    width: 700,
    maxHeight: 700,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "auto",
  },
  tf: {
    marginBottom: 8,
  },
  pagination: {
    display: "flex",
    marginTop: 30,
    justifyContent: "center",
  },
}));

const initialValues = {
  firstName: "",
  lastName: "",
  dob: "",
  ssn: "",
  sex: "male",
  email: "",
  password: "",
  passcode: "",
  phone: "",
  cellPhone: "",
  education: "",
  experience: "",
  // employmentDate: "",
  title: "",
  role: "PHYSICIAN",
  hs: "",
  org: "",
  locationId: [],
};

const Physicians = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user, contextHeaders, pickingPhysician } = useContext(
    AuthenticationContext
  );
  // console.log("user--->", user);
  const [searchBy, setSearchBy] = useState("name");
  const [text, setText] = useState("");
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [sorting, setSorting] = useState("last_name ASC");
  const [searchFunc, setSearchFunc] = useState(SEARCH_PHYSICIANS_SORTING);
  const [dataKey, setDataKey] = useState("searchAllPhysiciansByZipCodeSort");
  // const { data, loading, error } = useQuery(searchFunc, {
  //   variables: { page: page, size, searchStr: text },
  // });

  /**testing pagination */
  const { client, data: PhyData } = useQuery(ALLPHYSICIANS, {
    variables: {
      offset: 1,
      limit: 10,
      testing: "testing",
    },
  });
  console.log("all physician----------->", PhyData);
  console.log("client---->", client);
  /**testing pagination */

  const { data, loading, error } = useQuery(searchFunc, {
    variables: { page, size, searchStr: text, sorting },
  });
  console.log("data--->", data);

  const { data: specialtyData } = useQuery(GET_ALL_SPECIALTY_NAME);

  const changeSearch = (e) => {
    setText("");
    const name = e.target.value;
    setSearchBy(name);
    if (name === "name") {
      setSearchFunc(SEARCH_PHYSICIANS_BY_NAME);
      setDataKey("searchAllPhysiciansByName");
    } else if (name === "specialty") {
      setSearchFunc(SEARCH_PHYSICIANS_BY_SPEC);
      setDataKey("searchAllPhysiciansBySpecialty");
    } else if (name === "zip") {
      setSearchFunc(SEARCH_PHYSICIANS_BY_ZIP);
      setDataKey("searchAllPhysiciansByZipCode");
    }
  };

  const onChangeHandler = (e) => {
    setText(e.target.value);
  };

  const {
    data: hsData,
    loading: hsLoading,
    error: hsError,
  } = useQuery(ALL_HEALTH_SYSTEM);

  const [createAPhysician, { data: addPhyData, error: addPhyError }] =
    useMutation(ADD_PHYSICIAN, {
      context: contextHeaders,
      refetchQueries: [
        {
          query: SEARCH_PHYSICIANS_BY_NAME,
        },
      ],
    });

  const [openAdd, setOpenAdd] = useState(false);
  const [showAddButton, setShow] = useState(
    user.person.roles.includes("ADMIN") ? true : false
  );

  const [hsArr, setHsArr] = useState(hsData ? hsData.allHealthSystem : []);
  const [hsValue, setHsValue] = useState(
    hsData?.allHealthSystem[0]?.id ? hsData.allHealthSystem[0].id : ""
  );
  const [hsText, setHsText] = useState(
    hsData?.allHealthSystem[0]?.systemName
      ? hsData.allHealthSystem[0].systemName
      : ""
  );
  const [orgArr, setOrgArr] = useState(
    hsData?.allHealthSystem[0]?.organization
      ? hsData.allHealthSystem[0].organization
      : []
  );
  const [orgValue, setOrgValue] = useState(
    hsData?.allHealthSystem[0]?.organization[0]?.id
      ? hsData.allHealthSystem[0].organization[0].id
      : ""
  );
  const [orgText, setOrgText] = useState(
    hsData?.allHealthSystem[0]?.organization[0]?.name
      ? hsData.allHealthSystem[0].organization[0].name
      : ""
  );
  const [locArr, setLocArr] = useState(
    hsData?.allHealthSystem[0]?.organization[0]?.locations
      ? hsData.allHealthSystem[0].organization[0].locations
      : []
  );
  const [locValue, setLocValue] = useState(
    hsData?.allHealthSystem[0]?.organization[0]?.locations[0]?.id
      ? hsData.allHealthSystem[0].organization[0].locations[0].id
      : ""
  );
  const [locText, setLocText] = useState(
    hsData?.allHealthSystem[0]?.organization[0]?.locations[0]?.locationName
      ? hsData.allHealthSystem[0].organization[0].locations[0].locationName
      : ""
  );

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const changeSize = (size) => {
    setPage(1);
    setSize(size);
  };

  /**************************** */

  /** testing table */
  const headCells = [
    {
      id: "firstName",
      numeric: false,
      disablePadding: true,
      label: "Name",
      headerAlign: "left",
      align: "center",
      path: ["firstName"],
      path2: ["lastName"],
    },
    // {
    //   id: "lastName",
    //   numeric: false,
    //   disablePadding: true,
    //   label: "Last Name",
    //   headerAlign: "center",
    //   align: "center",
    //   path: ["lastName"],
    // },
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "ID",
      headerAlign: "center",
      align: "center",
      path: ["provider", "id"],
    },
  ];

  const testDelete = (Ids) => {
    console.log("IDs--->", Ids);
  };

  const testView = (Ids) => {
    console.log("IDs--->", Ids);
  };

  const testViewSingle = (id) => {
    console.log("id--->", id);
    pickingPhysician(data[dataKey].persons.filter((item) => item.id === id)[0]);
    history.push(`/physicians/${id}`);
  };

  const sortBy = (name, order) => {
    console.log("name--->", name);
    console.log("order-->", order);
    if (name === "firstName") setSorting(`first_name ${order}`);
    if (name === "lastName") setSorting(`last_name ${order}`);
  };

  /**************** */

  return (
    <Container size="sm">
      <div>
        <Modal
          className={classes.modal}
          open={openAdd}
          onClose={() => setOpenAdd(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openAdd}>
            <div className={classes.paper}>
              <Typography style={{ marginBottom: 20 }}>
                Add a new physician:
              </Typography>
              <div style={{ width: "100%" }}>
                <TextField
                  className={classes.tf}
                  fullWidth
                  required
                  name="firstName"
                  id="firstName"
                  label="First name"
                  variant="outlined"
                  value={values.firstName}
                  onChange={handleInputChange}
                />
                <TextField
                  className={classes.tf}
                  fullWidth
                  required
                  name="lastName"
                  id="lastName"
                  label="Last name"
                  variant="outlined"
                  value={values.lastName}
                  onChange={handleInputChange}
                />
                <TextField
                  className={classes.tf}
                  fullWidth
                  name="dob"
                  id="dob"
                  label="Date of Birth"
                  variant="outlined"
                  value={values.dob}
                  onChange={handleInputChange}
                />
                <TextField
                  className={classes.tf}
                  fullWidth
                  name="ssn"
                  id="ssn"
                  label="SSN"
                  variant="outlined"
                  value={values.ssn}
                  onChange={handleInputChange}
                />
                <TextField
                  id="sex"
                  name="sex"
                  select
                  label="Sex"
                  helperText="Please select sex"
                  variant="outlined"
                  value={values.sex}
                  onChange={handleInputChange}
                >
                  <option key="male" value="male">
                    {"male"}
                  </option>
                  <option key="female" value="female">
                    {"female"}
                  </option>
                  <option key="other" value="other">
                    {"other"}
                  </option>
                </TextField>
                <TextField
                  className={classes.tf}
                  fullWidth
                  required
                  name="email"
                  id="email"
                  label="Email"
                  variant="outlined"
                  value={values.email}
                  onChange={handleInputChange}
                />
                <TextField
                  className={classes.tf}
                  fullWidth
                  required
                  name="password"
                  id="password"
                  label="Password"
                  variant="outlined"
                  value={values.password}
                  onChange={handleInputChange}
                />
                <TextField
                  className={classes.tf}
                  fullWidth
                  required
                  name="passcode"
                  id="passcode"
                  label="Passcode"
                  variant="outlined"
                  value={values.passcode}
                  onChange={handleInputChange}
                />
                <TextField
                  className={classes.tf}
                  fullWidth
                  name="phone"
                  id="phone"
                  label="Phone"
                  variant="outlined"
                  value={values.phone}
                  onChange={handleInputChange}
                />
                <TextField
                  className={classes.tf}
                  fullWidth
                  name="cellPhone"
                  id="cellPhone"
                  label="CellPhone"
                  variant="outlined"
                  value={values.cellPhone}
                  onChange={handleInputChange}
                />
                <TextField
                  className={classes.tf}
                  fullWidth
                  name="education"
                  id="education"
                  label="Education"
                  variant="outlined"
                  value={values.education}
                  onChange={handleInputChange}
                />
                <TextField
                  className={classes.tf}
                  fullWidth
                  name="experience"
                  id="experience"
                  label="Experience"
                  variant="outlined"
                  value={values.experience}
                  onChange={handleInputChange}
                />
                <TextField
                  className={classes.tf}
                  fullWidth
                  name="title"
                  id="title"
                  label="Title"
                  variant="outlined"
                  value={values.title}
                  onChange={handleInputChange}
                />
                {/* <TextField
                  id="role"
                  name="role"
                  select
                  label="Role"
                  helperText="Please select role"
                  variant="outlined"
                  value={values.role}
                  onChange={handleInputChange}
                >
                  <option key="PHYSICIAN" value="PHYSICIAN">
                    {"PHYSICIAN"}
                  </option>
                  <option key="ADMIN" value="ADMIN">
                    {"ADMIN"}
                  </option>
                  <option key="STAFF" value="STAFF">
                    {"STAFF"}
                  </option>
                  <option key="PATIENT" value="PATIENT">
                    {"PATIENT"}
                  </option>
                </TextField> */}
                <TextField
                  style={{ marginRight: 20 }}
                  id="hs"
                  name="hs"
                  select
                  label="Health System"
                  helperText="Please select health system"
                  variant="outlined"
                  value={hsValue}
                  onChange={(e) => {
                    setHsValue(e.target.value);
                    const hs = hsData.allHealthSystem.filter(
                      (item) => item.id === e.target.value
                    );
                    setOrgArr(hs[0].organization);
                    setOrgValue(hs[0].organization[0].id);
                    setLocArr(hs[0].organization[0].locations);
                    setLocValue(hs[0].organization[0].locations[0].id);
                  }}
                >
                  {hsData &&
                    hsData.allHealthSystem.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.systemName}
                      </option>
                    ))}
                </TextField>
                <TextField
                  style={{ marginRight: 20 }}
                  id="org"
                  name="org"
                  select
                  label="Organization"
                  helperText="Please select organization"
                  variant="outlined"
                  value={orgValue}
                  onChange={(e) => {
                    setOrgValue(e.target.value);
                    const org = orgArr.filter(
                      (item) => item.id === e.target.value
                    );
                    setLocArr(org[0].locations);
                    setLocValue(org[0].locations[0].id);
                  }}
                >
                  {hsData &&
                    orgArr.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </TextField>
                <TextField
                  id="loc"
                  name="loc"
                  select
                  label="Location"
                  helperText="Please select location"
                  variant="outlined"
                  value={locValue}
                  onChange={(e) => {
                    setLocValue(e.target.value);
                    // const org = orgArr.filter(
                    //   (item) => item.id === e.target.value
                    // );
                    // setLocArr(org[0].locations);
                    // setLocValue(org[0].locations[0].id);
                  }}
                >
                  {hsData &&
                    locArr.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.locationName}
                      </option>
                    ))}
                </TextField>
              </div>

              {/* {showError && (
                <div className={classes.btn}>
                  <Typography color="error">
                    All fields are required.
                  </Typography>
                </div>
              )} */}
              {addPhyError && (
                <div className={classes.btn}>
                  <Typography color="error">
                    {addPhyError.toString()}
                  </Typography>
                </div>
              )}
              <div className={classes.btn}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    // console.log("id--->", row.id);
                    // let pass = true;
                    // for (let key in values) {
                    //   if (values[key] === "") {
                    //     pass = false;
                    //     setShowError(true);
                    //   }
                    // }
                    // if (pass) {
                    //   setShowError(false);
                    //   console.log("values--->", values);
                    createAPhysician({
                      variables: {
                        userInfo: {
                          email: values.email,
                          password: values.password,
                        },
                        personInput: {
                          firstName: values.firstName,
                          lastName: values.lastName,
                          phone: values.phone,
                          cellPhone: values.cellPhone,
                          education: values.education,
                          experience: values.experience,
                          title: values.title,
                          ssn: values.ssn,
                          sex: values.sex,
                          dob: values.dob,
                        },
                        // role: "PHYSICIAN",
                        passcode: values.passcode,
                        locationIds: [Number(locValue)],
                      },
                    })
                      .then(() => {
                        resetForm();
                        setOpenAdd(false);
                        console.log("edit successful!");
                      })
                      .catch((e) => {
                        console.log("error--->", e);
                      });
                    // }
                  }}
                >
                  Submit
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: 20 }}
                  onClick={() => {
                    setOpenAdd(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
      <div className={classes.top}>
        {searchBy !== "specialty" && (
          <Paper component="form" className={classes.root}>
            <InputBase
              className={classes.input}
              placeholder={`Search physicians by ${searchBy}`}
              inputProps={{ "aria-label": "search physicians by name" }}
              onChange={(e) => setText(e.target.value)}
              value={text}
            />
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search"
              size="large">
              <SearchIcon />
            </IconButton>
          </Paper>
        )}
        {searchBy === "specialty" && (
          <TextField
            select
            label="Specialty"
            helperText="Please select specialty"
            variant="outlined"
            value={text}
            onChange={(e) => onChangeHandler(e)}
          >
            {specialtyData &&
              specialtyData.getAllSpecialtyType.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
          </TextField>
        )}
        <RadioGroup
          style={{ marginLeft: 20 }}
          row
          aria-label="position"
          name="position"
          defaultValue="name"
          onChange={(e) => changeSearch(e)}
        >
          <FormControlLabel
            value="name"
            control={<Radio color="primary" />}
            label="By name"
          />
          <FormControlLabel
            value="specialty"
            control={<Radio color="primary" />}
            label="By specialty"
          />
          <FormControlLabel
            value="zip"
            control={<Radio color="primary" />}
            label="By zip"
          />
        </RadioGroup>
        {showAddButton && (
          <div style={{ marginLeft: 20 }}>
            <Fab
              className={classes.addBtnIcon}
              aria-label="add new physician button"
              onClick={() => setOpenAdd(true)}
            >
              <AddIcon />
            </Fab>
          </div>
        )}
      </div>
      {/** testing table */}
      {data && data[dataKey] !== null && (
        <CustomTable
          headCells={headCells}
          rows={data[dataKey].persons}
          title={"title"}
          rowsPerPage={size}
          setRowsPerPage={setSize}
          rowsPerPageOptions={[10, 20, 30]}
          page={page}
          setPage={setPage}
          totalSize={Number(data[dataKey].totalSize)}
          allowDelete={true}
          deleteRows={testDelete}
          allowView={true}
          viewRows={testView}
          allowViewSingle={true}
          viewSingle={testViewSingle}
          sortBy={sortBy}
        />
      )}
      {/******************************/}
      {loading && <LoadingIndicator />}
      {error && <ErrorInfo />}
      <Grid container spacing={3}>
        {data &&
          data[dataKey] !== null &&
          data[dataKey].persons.map((person) => {
            return (
              <Grid key={person.id} item xs={12} md={4} lg={3}>
                <Link
                  to={`/physicians/${person.id}`}
                  onClick={() => {
                    pickingPhysician(person);
                  }}
                >
                  <PersonCard person={person} />
                </Link>
              </Grid>
            );
          })}
      </Grid>
      {data && data[dataKey] !== null && (
        <>
          <div className={classes.pagination}>
            <ButtonGroup
              color="primary"
              aria-label="outlined primary button group"
            >
              {/* <Button onClick={() => changeSize(5)}>5</Button> */}
              <Button onClick={() => changeSize(10)}>10</Button>
              <Button onClick={() => changeSize(20)}>20</Button>
              <Button onClick={() => changeSize(50)}>50</Button>
            </ButtonGroup>
          </div>
          {Math.ceil(Number(data[dataKey].totalSize) / size) > 1 && (
            <div className={classes.pagination}>
              <Pagination
                variant="outlined"
                color="primary"
                count={Math.ceil(Number(data[dataKey].totalSize) / size)}
                page={page}
                onChange={handleChange}
              />
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default Physicians;
