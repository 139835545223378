import React, { useState, useContext } from "react";
import {
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableBody,
  TableHead,
  Paper,
  TablePagination,
  Typography,
  Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { MEDICAL_HISTORY_BYPTID } from "@cims/common/services/medical-history.service";
import { useGenealStyles } from "@cims/common";
import { AuthenticationContext } from "src/services/authentication/authentication.context";
import { blue } from "@mui/material/colors";
import { GlobalContext } from "src/services/global/global.context";

function createData(id, diagnosis, diagnDate, personalNote) {
  return {
    id,
    diagnosis,
    diagnDate,
    personalNote,
  };
}

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
    // height: "40px",
  },
  addBtnIcon: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
    color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
    "&:hover": {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#BDC0BA" : blue[700],
      color: "#fff",
    },
  },
  btn: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default function SocialHistory() {
  const { user } = useContext(AuthenticationContext);
  const { pageSize } = useContext(GlobalContext);

  const [medicalHistoryData, setMedicalHistoryData] = useState(null);
  const [size, setSize] = useState(pageSize);
  const [page, setPage] = useState(0);

  console.log("general style--->", useGenealStyles);
  let rowss = [];
  const classes = useRowStyles();
  // const { user, curPickedPatient } = useContext(AuthenticationContext);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const {
    data: medicalhistory,
    loading: medicalhistoryLoading,
    error: medicalhistoryError,
  } = useQuery(MEDICAL_HISTORY_BYPTID, {
    variables: { patient_id: Number(user.person.patient.id) },
    onCompleted: (data) => {
      const results = data.medicalHistoryByPtId.map((item) => {
        const obj = {
          id: item.id,
          diagnosis: item.diagnosis,
          diagnDate: item.diagnDate,
          personalNote: item.personalNote,
        };
        return obj;
      });
      setMedicalHistoryData(results);
    },
  });

  {
    medicalhistory &&
      medicalhistory.medicalHistoryByPtId.map((item) => {
        // console.log("organization: name  " + item.name);
        // console.log("organization: type  " + item.type);
        // console.log(" organization, locations  " + item.locations);
        rowss.push(
          createData(item.id, item.diagnosis, item.diagnDate, item.personalNote)
        );
      });
  }
  console.log(rowss);

  // const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rowss.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
      // backgroundColor: "green",
      // color: theme.palette.common.white,
      color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  return (
    <div className={classes.root}>
      <Typography variant="body1" gutterBottom component="div">
        <h3>Medical History</h3>
      </Typography>
      <hr></hr>
      <Paper className={classes.paper}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Diagnosis</StyledTableCell>
                <StyledTableCell>Personal Notes</StyledTableCell>
                <StyledTableCell align="left">Diagnosis Date</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowss
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <StyledTableRow hover tabIndex={-1}>
                      <StyledTableCell align="left" style={{ width: "2%" }}>
                        {row.id}
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "15%" }}>
                        {row.diagnosis}
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "15%" }}>
                        {row.personalNote}
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "15%" }}>
                        {row.diagnDate.iso}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.pagination}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rowss.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
      <div className={classes.btn}>
        <Button
          style={{ marginRight: 50 }}
          variant="contained"
          color="primary"
          onClick={() => {
            //
          }}
        >
          Upload your Medical history
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            //
          }}
        >
          Manully input your Medical history
        </Button>
      </div>
    </div>
  );
}
