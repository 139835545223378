import { gql } from "@apollo/client";

export const GET_ALL_CONDITIONCT_BY_NAME = gql`
    query getAllConditionsCTsByNameASC($searchStr: String, $page: Int, $size: Int){
        getAllConditionsCTsByNameASC(searchStr: $searchStr, page: $page, size: $size){
        totalSize, conditionsCTs{name, numberofstudies}
        } 
    } 
`;

export const GET_ALL_CONDITIONCT_BY_CATEGORY = gql`
    query getAllConditionsCTsByCategoryIdASC($page: Int, $size: Int){
        getAllConditionsCTsByCategoryIdASC(page: $page, size: $size){
            totalSize, conditionsCTs{id, name, numberofstudies}   
        }
    }
`;
