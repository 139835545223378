import { gql } from "@apollo/client";

export const eBinder_GET_SUGGEST_FIELD_NAMES = gql`
query getFieldNameSuggestbyTableNameMpStudyId($tableName: String, $studyId: Long) {
  getFieldNameSuggestbyTableNameMpStudyId(tableName: $tableName, studyId: $studyId) 
  }
`;

export const eBinder_GET_SCHEMA_BY_STUDY_AND_TABLE_NAME = gql`
query getSchemaFromDBByTableNameMpStudyId($tableName: String, $studyId: Long) {
  getSchemaFromDBByTableNameMpStudyId(tableName: $tableName, studyId: $studyId) {
      Id   
      fieldName
      fieldLabel
      fieldType
      fieldFormat
      controlType
      dataSource
      qDescription
      defaultValue
      mappingValue
      tableName
      tableId
      fielddedName
      fieldNameSuggest
      pageNum
      studyId
      formCreateTime {
          toString
      }
      formUpdateTime {
          toString
      }
      rowIndex  
    }
  }
`;

export const eBinder_UPDATE_SUGGEST_FIELD_NAME = gql`
mutation updateFieldNameSuggestById($field_id: Long, $field_name_suggest: String){
    updateFieldNameSuggestById(field_id: $field_id, field_name_suggest: $field_name_suggest) 
  }
`;


export const eBinder_GET_ALL_FIELD_NAME_BY_TABLE_NAME = gql`
query getAllFieldNameByTableName($formCode: String, $formName: String) {
  getAllFieldNameByTableName(formCode: $formCode, formName:$formName) 
  }
`;
