import {
  Container,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Grid,
  CardHeader,
  RadioGroup,
  FormControlLabel,
  Radio,
  ButtonGroup,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Pagination } from '@mui/material';
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { GET_ALL_CONDITIONCT_BY_NAME, GET_ALL_CONDITIONCT_BY_CATEGORY } from "@cims/common/services/clinicaltrials.service";
import { useQuery} from "@apollo/client";
import { blue } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 20,
    // height: "50px",
  },
  addBtnIcon: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
    color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
    "&:hover": {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#BDC0BA" : blue[700],
      color: "#fff",
    },
  },

  btn: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  
  modaltext:{
    marginTop: 20,
    flexDirection:"column",
    justifyContent: "center",
  },

  paper: {
    backgroundColor: "#f5f3f0", //theme.palette.background.paper,
    border: "1px solid #666666",
    width: 700,
    maxHeight: 700,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "auto",
  },

  htyCard: {
    maxWidth: 1000,
    marginBottom: "10px",
    flex: 1,
    flexDirection:"row",
    marginTop:10,
  },

  cardHeader: {
    
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true"
        ? "rgba(0,26,72, 0.3)"
        : "rgba(126,211,205,0.5)",
  },

  divider: {
    backgroundColor: "#d9e2ee",
    margin: "0 20px",
  },

  hyperlink:{
    paddingLeft:30,
  },


  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  pagination: {
    display: "flex",
    marginTop: 30,
    justifyContent: "center",
  },

}));

export default function ClinicalTrialsAdvancedSearch(){
  const myStyles = useStyles();
  const history = useHistory();
  const preventDefault = (e) => e.preventDefault();
  return(
    <div>hello</div>
  );
      
}