import { gql } from "@apollo/client";

export const ALL_MEDICAL_HISTORY = gql`
    query mFHistory($patient_id: ID!){
        mFHistory(patient_id: $patient_id){
            patient_id
        }
    }
`;

export const SOCIAL_HISTORY_BYPTID = gql`
    query socialHistoryByPtId($patient_id: ID!){
      socialHistoryByPtId(patient_id: $patient_id){
        id, socialEvent, result, personalNote
      }
    }
`;

export const MEDICAL_HISTORY_BYPTID = gql`
    query medicalHistoryByPtId($patient_id: ID!){
      medicalHistoryByPtId(patient_id: $patient_id){
        id, diagnosis, diagnDate{iso}, personalNote
      }
    }
`;

export const SURGICAL_HISTORY_BYPTID = gql`
    query surgicalHistoryByPtId ($patient_id: ID!){
      surgicalHistoryByPtId(patient_id: $patient_id){
        id, procedureName, procedureDate{iso}, personalNote
      }
    }
`;

export const FAMILYMED_HISTORY_BYPTID = gql`
    query familyMedHistoryByPtId($patient_id: ID!){
      familyMedHistoryByPtId(patient_id: $patient_id){
        id, dob, ethnicity, sex, presentHealth, causeOfDeath,ageAtDiagnosis, ageAtDeath, relationship
      }
    }
`;
