import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { blue } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import { FormControlLabel, Switch } from "@mui/material";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: blue[200],
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export default function TableComponent({
  headCells = [],
  rows = [],
  rowsPerPage = 20,
  setRowsPerPage = () => {},
  rowsPerPageOptions = [10, 20, 50],
  page = 0,
  setPage = () => {},
  totalSize = 0,
  isDense = true,
}) {
  const classes = useStyles();
  const [dense, setDense] = useState(isDense);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, totalSize - page * rowsPerPage);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 700 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          size={dense ? "small" : "medium"}
        >
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  className={classes.tableHeader}
                  key={headCell.id}
                  align={headCell.headerAlign}
                  //   style={{ minWidth: column.minWidth }}
                >
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    style={{ height: (dense ? 33 : 53) * 0 }}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                  >
                    {headCells.map((headCell) => {
                      return (
                        <TableCell key={headCell.id} align={headCell.align}>
                          {row[headCell.id]}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.pagination}>
        <FormControlLabel
          control={
            <Switch
              checked={dense}
              onChange={() => {
                setDense(!dense);
              }}
            />
          }
          label="Dense"
        />
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={totalSize}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </Paper>
  );
}
