import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import { Link } from "react-router-dom";
import NotificationPiece from "./Notification-piece";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
  },
  text: {
    fontWeight: 700,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function Notification({ data, changeOpenNotification }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {data.map((item) => {
        return (
          <NotificationPiece
            key={item.id}
            notification={item}
            changeOpenNotification={changeOpenNotification}
          />
        );
      })}
    </div>
  );
}
