import { useMutation, useQuery } from "@apollo/client";
import {
  Container,
  Grid,
  Button,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Modal,
  Fade,
  Backdrop,
  TextField,
  Fab,
  IconButton,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import React, { useState } from "react";

import { Link } from "react-router-dom";
import {
  ADD_NEW_HEALTH_SYSTEM,
  ALL_HEALTH_SYSTEM,
  DELETE_HS,
  UPDATE_HS,
  getState,
} from "@cims/common";
import { useForm } from "../../communication/components/useForm";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { blue } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#f5f3f0", //theme.palette.background.paper,
    border: "1px solid #666666",
    width: 700,
    maxHeight: 700,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "auto",
  },
  tf: {
    marginBottom: 8,
  },
  btn: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 20,
    // height: "50px",
  },
  addBtnIcon: {
    backgroundColor:
      localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
    color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
    "&:hover": {
      backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#BDC0BA" : blue[700],
      color: "#fff",
    },
  },
  hsCard: {
    display: "flex",
    alignItems: "center",
    padding: 10,
  },
  hsName: {
    flexGrow: 1,
  },
}));

const initialFValues = {
  healthSystemName: "",
  healthSystemStatus: "active",
  organizationName: "",
  organizationType: "",
  organizationPhone: "",
  organizationEmail: "",
  organizationStatus: "active",
  locationName: "",
  address: "",
  state: "AL",
  zip: "",
  locationPhone: "",
};

export default function HealthSystem() {
  const classes = useStyles();
  const { data, loading, error } = useQuery(ALL_HEALTH_SYSTEM);

  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlet] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [itemId, setItemId] = useState(0);
  const [curHsName, setCurHsName] = useState("");
  const [showError, setShowError] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true);

  const [addNewHealthSystem, { data: newHealthData, error: newHealthError }] =
    useMutation(ADD_NEW_HEALTH_SYSTEM, {
      refetchQueries: [
        {
          query: ALL_HEALTH_SYSTEM,
        },
      ],
    });

  const [delAHealthSystem, { data: delHealthData, error: delHealthError }] =
    useMutation(DELETE_HS, {
      refetchQueries: [
        {
          query: ALL_HEALTH_SYSTEM,
        },
      ],
    });

  const [
    updateHealthSystemName,
    { data: editHealthData, error: editHealthError },
  ] = useMutation(UPDATE_HS, {
    refetchQueries: [
      {
        query: ALL_HEALTH_SYSTEM,
      },
    ],
  });

  const setKeyError = (key) => {
    if (values[key] === "")
      setErrors({
        ...errors,
        [key]: true,
      });
    else
      setErrors({
        ...errors,
        [key]: false,
      });
  };

  const validateForm = () => {
    setKeyError("healthSystemName");
    setKeyError("organizationName");
    setKeyError("organizationType");
    setKeyError("organizationPhone");
    setKeyError("organizationEmail");
    setKeyError("locationName");
    setKeyError("address");
    setKeyError("zip");
    setKeyError("locationPhone");
  };

  return (
    <Container>
      {/* add hs modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  Add a health system
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ width: "100%" }}>
                  <TextField
                    className={classes.tf}
                    fullWidth
                    required
                    name="healthSystemName"
                    id="health-system-name"
                    label="Health system name"
                    variant="outlined"
                    value={values.healthSystemName}
                    onChange={handleInputChange}
                    error={errors.healthSystemName}
                  />
                  {/* <TextField
                    id="health-system-status"
                    name="healthsystemStatus"
                    select
                    label="Status"
                    helperText="Please select status"
                    variant="outlined"
                    value={values.healthSystemStatus}
                    onChange={handleInputChange}
                  >
                    <option key="active" value="active">
                      {"active"}
                    </option>
                    <option key="inactive" value="inactive">
                      {"inactive"}
                    </option>
                  </TextField> */}
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  Add an organization
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ width: "100%" }}>
                  <TextField
                    className={classes.tf}
                    fullWidth
                    required
                    name="organizationName"
                    id="organization-name"
                    label="Organization name"
                    variant="outlined"
                    value={values.organizationName}
                    onChange={handleInputChange}
                    error={errors.organizationName}
                  />

                  <TextField
                    className={classes.tf}
                    fullWidth
                    required
                    name="organizationType"
                    id="organization-type"
                    label="Organization type"
                    variant="outlined"
                    value={values.organizationType}
                    onChange={handleInputChange}
                    error={errors.organizationType}
                  />
                  <TextField
                    className={classes.tf}
                    fullWidth
                    required
                    name="organizationPhone"
                    id="organization-phone"
                    label="Organization phone number"
                    variant="outlined"
                    value={values.organizationPhone}
                    onChange={handleInputChange}
                    error={errors.organizationPhone}
                  />
                  <TextField
                    className={classes.tf}
                    fullWidth
                    required
                    name="organizationEmail"
                    id="organization-email"
                    label="Organization email"
                    variant="outlined"
                    value={values.organizationEmail}
                    onChange={handleInputChange}
                    error={errors.organizationEmail}
                  />

                  <TextField
                    id="organization-status"
                    name="organizationStatus"
                    select
                    label="Status"
                    helperText="Please select status"
                    variant="outlined"
                    value={values.organizationStatus}
                    onChange={handleInputChange}
                  >
                    <option key="active" value="active">
                      {"active"}
                    </option>
                    <option key="inactive" value="inactive">
                      {"inactive"}
                    </option>
                  </TextField>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography className={classes.heading}>
                  Add a location
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ width: "100%" }}>
                  <TextField
                    className={classes.tf}
                    fullWidth
                    required
                    name="locationName"
                    id="location-name"
                    label="Location name"
                    variant="outlined"
                    value={values.lacationName}
                    onChange={handleInputChange}
                  />

                  <TextField
                    className={classes.tf}
                    fullWidth
                    required
                    name="address"
                    id="location-address"
                    label="Address"
                    variant="outlined"
                    value={values.address}
                    onChange={handleInputChange}
                  />
                  <TextField
                    id="organization-status"
                    name="state"
                    select
                    label="State"
                    // value="none"
                    // onChange={handleChange}
                    // SelectProps={{
                    //   native: true,
                    // }}
                    helperText="Please select status"
                    variant="outlined"
                    value={values.state}
                    onChange={handleInputChange}
                  >
                    {getState().map((item) => (
                      <option key={item.id} value={item.title}>
                        {item.title}
                      </option>
                    ))}
                  </TextField>
                  <TextField
                    className={classes.tf}
                    fullWidth
                    required
                    name="zip"
                    id="location-zip"
                    label="Zip code"
                    variant="outlined"
                    value={values.zip}
                    onChange={handleInputChange}
                  />
                  <TextField
                    className={classes.tf}
                    fullWidth
                    required
                    name="locationPhone"
                    id="location-phone"
                    label="Telephone Number"
                    variant="outlined"
                    value={values.locationPhone}
                    onChange={handleInputChange}
                    error={errors.locationPhone}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
            {showError && (
              <div className={classes.btn}>
                <Typography color="error">All fields are required.</Typography>
              </div>
            )}
            <div className={classes.btn}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  let pass = true;
                  for (let key in values) {
                    if (values[key] === "") {
                      pass = false;
                      setShowError(true);
                    }
                  }
                  if (pass) {
                    setShowError(false);
                    addNewHealthSystem({
                      variables: {
                        hs: {
                          systemName: values.healthSystemName,
                          status: "active",
                          organizations: {
                            name: values.organizationName,
                            type: values.organizationType,
                            email: values.organizationEmail,
                            phone: values.organizationPhone,
                            status: "active",
                            locations: {
                              locationName: values.locationName,
                              address: values.address,
                              stateName: values.state,
                              zipCode: values.zip,
                              telNumb: values.locationPhone,
                              country: "US",
                              // status: "active",
                            },
                          },
                        },
                      },
                    }).then(() => {
                      resetForm();
                      handleClose();
                      console.log("successful!");
                    });
                  }
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>

      {/* edit hs modal */}
      <Modal
        className={classes.modal}
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openEdit}>
          <div className={classes.paper}>
            <div style={{ width: "100%" }}>
              <TextField
                className={classes.tf}
                fullWidth
                required
                name="healthSystemName"
                id="health-system-name"
                label="Health system name"
                variant="outlined"
                defaultValue={
                  curHsName !== "" ? curHsName : values.healthSystemName
                }
                value={values.healthSystemName}
                onChange={handleInputChange}
                error={errors.healthSystemName}
              />
            </div>

            {showError && (
              <div className={classes.btn}>
                <Typography color="error">All fields are required.</Typography>
              </div>
            )}
            <div className={classes.btn}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (values.systemName !== "") {
                    setShowError(false);
                    updateHealthSystemName({
                      variables: {
                        id: itemId,
                        systemName: values.healthSystemName,
                      },
                    }).then(() => {
                      resetForm();
                      setOpenEdit(false);
                      console.log("edit successful!");
                    });
                  }
                }}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: 20 }}
                onClick={() => {
                  setOpenEdit(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>

      {/* delete hs modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAlert}
        onClose={() => setOpenAlet(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAlert}>
          <div className={classes.paper}>
            <div className={classes.btn}>
              <Typography>
                Are you sure you want to delete the health system?
              </Typography>
            </div>

            <div className={classes.btn}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  console.log("delete...");
                  delAHealthSystem({
                    variables: {
                      id: itemId,
                    },
                  }).then(() => {
                    setOpenAlet(false);
                  });
                }}
              >
                Yes
              </Button>
              <Button
                style={{ marginLeft: 20 }}
                variant="contained"
                color="primary"
                onClick={() => {
                  setOpenAlet(false);
                }}
              >
                No
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>

      <Grid container spacing={3}>
        {data &&
          data.allHealthSystem.map((item) => (
            // {fakeData.map((item) => (
            <Grid key={item.id} item xs={12} md={6} lg={6}>
              <Paper className={classes.hsCard}>
                <Link
                  className={classes.hsName}
                  to={`/healthsystem/organizations/${item.id}`}
                >
                  <Typography style={{ color: "black" }}>
                    {item.systemName}
                  </Typography>
                </Link>
                <IconButton
                  onClick={() => {
                    setItemId(Number(item.id));
                    setCurHsName(item.systemName);
                    setValues({
                      systemName: item.systemName,
                    });
                    setOpenEdit(true);
                  }}
                  aria-label="settings"
                  size="large">
                  <EditOutlinedIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setOpenAlet(true);
                    setItemId(Number(item.id));
                  }}
                  aria-label="settings"
                  size="large">
                  <DeleteForeverIcon />
                </IconButton>
              </Paper>
            </Grid>
          ))}
      </Grid>
      <div className={classes.addBtn}>
        <Fab
          className={classes.addBtnIcon}
          aria-label="add a new health system button"
          onClick={handleOpen}
        >
          <AddIcon />
        </Fab>
      </div>
    </Container>
  );
}
