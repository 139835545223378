import React, { useState, createContext } from "react";
import { useHistory, withRouter } from "react-router-dom";

import { LOGIN, LOGOUT } from "@cims/common";

import { useMutation, useApolloClient } from "@apollo/client";
import { useLocalStorage } from "@cims/utils";
import { PATIENT_MENUS } from "src/navigation/menu";
// import { useLocalStorage } from "../../utility/commons";

export const AuthenticationContext = createContext();

const AuthenticationContextProvider = ({ children }) => {
  const client = useApolloClient();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [contextHeaders, setContextHeaders] = useState({
    headers: {
      authorization: `${localStorage.getItem("auth_token")}`,
    },
  });

  const [curPickedPatient, setCurPickedPatient] = useLocalStorage(
    "curPatient",
    null
  );
  const [curPickedPhysician, setCurPickedPhysician] = useLocalStorage(
    "curPhysician",
    null
  );

  const [openTopMenu, setOpenTopMenu] = useState({});
  const [isPatient, setIsPatient] = useState(false);
  const [isPhysician, setIsPhysician] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  let allowArr = [];
  for (let i = 0; i < PATIENT_MENUS.length; i++) {
    allowArr.push(PATIENT_MENUS[i].id);
  }

  const [patientAllow, setPatientAllow] = useState(
    user?.person?.roles.includes("PATIENT") ? allowArr : []
  );

  const resetRole = () => {
    setIsPatient(false);
    setIsPhysician(false);
    setIsAdmin(false);
  };

  const pickingPatient = (patient) => {
    setCurPickedPatient(patient);
  };

  const pickingPhysician = (physician) => {
    setCurPickedPhysician(physician);
  };

  const storeUserData = (value) => {
    const jsonValue = JSON.stringify(value);
    localStorage.setItem("user", jsonValue);
  };

  const storeToken = (value) => {
    localStorage.setItem("auth_token", value);
  };

  const getUserData = () => {
    return localStorage.getItem("user");
  };

  const changeSettings = (newSettings) => {
    setUser({
      ...user,
      ...newSettings,
    });
  };

  const [login, { data, loading: loginLoading, error: loginError }] =
    useMutation(LOGIN);
  const [logout, { error: logoutError }] = useMutation(LOGOUT);

  const onLogin = (email, password) => {
    login({
      variables: { email: email, password: password },
    })
      .then((u) => {
        storeToken(u.data.login.token);
        storeUserData(u.data.login);
        setContextHeaders({
          headers: {
            authorization: `Bearer ${u.data.login.token}`,
          },
        });
        setUser(u.data.login);
        setIsLoading(false);

        if (u.data.login.person.roles.includes("PATIENT")) {
          setCurPickedPatient(u.data.login.person);
          setIsPatient(true);
          setPatientAllow(allowArr);
        }
        if (u.data.login.person.roles.includes("PHYSICIAN")) {
          setCurPickedPhysician(u.data.login.person);
          setIsPhysician(true);
        }
        history.push("/");
      })
      .catch((e) => {
        if (e !== undefined) {
          console.log("error====", e);
        }
      });

    // .catch((e) => {
    //   setIsLoading(false);
    //   setError(e.toString());
    // });
  };

  const onRegister = (email, password, repeatedPassword) => {
    setIsLoading(true);
    if (password !== repeatedPassword) {
      // setError("Error: Passwords do not match");
      return;
    }
  };

  const cleanLocal = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("auth_token");
    localStorage.removeItem("yourAppointment");
    localStorage.removeItem("isReschedule");
    localStorage.removeItem("curPatient");
    localStorage.removeItem("curPhysician");
    // changeIsReschedule(false);
    // pickingAppointment({});
    pickingPatient(null);
    pickingPhysician(null);
    // changeRescheduledFrom("");
  };

  const onLogout = () => {
    logout({
      variables: {
        msg: "User logout!",
      },
      context: contextHeaders,
    })
      .then(() => {
        client.clearStore();
        history.push("/login");
        setUser(null);
        cleanLocal();
        resetRole();
      })
      .catch((e) => {
        history.push("/login");
        setUser(null);
        cleanLocal();
        resetRole();
        console.log("err--->", e);
      });

    // setUser(null);
    // cleanLocal();
    // history.push("/login");
  };

  // useEffect(() => {
  //   setHeaders();
  // }, []);

  return (
    <AuthenticationContext.Provider
      value={{
        isAuthenticated: !!user,
        user,
        isLoading,
        loginError,
        loginLoading,
        contextHeaders,
        curPickedPatient,
        curPickedPhysician,
        openTopMenu,
        isPatient,
        isPhysician,
        patientAllow,
        setPatientAllow,
        setOpenTopMenu,
        setUser,
        pickingPatient,
        pickingPhysician,
        getUserData,
        onLogin,
        onRegister,
        onLogout,
        changeSettings,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
export default withRouter(AuthenticationContextProvider);
