import { gql } from "@apollo/client";

export const ALLPEOPLE = gql`
  query AllPoeple($page: Int!, $size: Int!) {
    allPeople(page: $page, size: $size) {
      id
      firstName
      lastName
      avatar
      email
      title
      phone
      education
      experience
      roles
    }
  }
`;

export const ALLPHYSICIANS = gql`
  query allPhysicians {
    allPhysicians {
      id
      provider {
        id
        locations {
          id
          locationName
        }
      }
      email
      phone
      education
      experience
      firstName
      lastName
      roles
      avatar
      dob
      ssn
      sex
    }
  }
`;

export const ALLPHYSICIANSPAGE = gql`
  query allPhysiciansPage($page: Int!, $size: Int!) {
    allPhysiciansPage(page: $page, size: $size) {
      id
      provider {
        id
        locations {
          id
          locationName
        }
      }
      email
      education
      experience
      firstName
      lastName
      roles
      avatar
      dob
      ssn
      sex
      phone
    }
  }
`;

export const ADD_PHYSICIAN = gql`
  mutation createAPhysician(
    $userInfo: CreateUserInput!
    $personInput: CreatePersonInput!
    $passcode: String!
    $locationIds: [Long]
  ) {
    createAPhysician(
      userInfo: $userInfo
      personInput: $personInput
      passcode: $passcode
      locationIds: $locationIds
    ) {
      id
      lastName
      firstName
      sex
      ssn
      dob
      phone
      contactinfo {
        country
        city
        state
      }
      provider {
        id
        firstName
        lastName
        title
        locations {
          id
          locationName
          address
          zipCode
          stateName
          telNumb
          status
        }
      }
    }
  }
`;

export const ALLPATIENTS = gql`
  query allPatients {
    allPatients {
      dob
      ssn
      sex
      id
      email
      phone
      firstName
      lastName
      roles
      avatar
      patient {
        id
      }
    }
  }
`;

export const SEARCH_PHYSICIANS_SORTING = gql`
  query searchAllPhysiciansByZipCodeSort(
    $page: Int!
    $size: Int!
    $searchStr: String
    $sorting: String
  ) {
    searchAllPhysiciansByZipCodeSort(
      page: $page
      size: $size
      searchStr: $searchStr
      sorting: $sorting
    ) {
      totalSize
      persons {
        id
        lastName
        firstName
        avatar
        provider {
          id
          lastName
          firstName
          title
          locations {
            zipCode
          }
        }
      }
    }
  }
`;

export const SEARCH_PATIENTS_BY_NAME = gql`
  query searchAllPatientsByName($page: Int!, $size: Int!, $searchStr: String) {
    searchAllPatientsByName(page: $page, size: $size, searchStr: $searchStr) {
      totalSize
      persons {
        id
        lastName
        firstName
        avatar
      }
    }
  }
`;

export const PATIENT_DETAILS_BYID = gql`
  query PatientByPersonId($personId: ID!) {
    patientByPersonId(personId: $personId) {
      id
      lastName
      firstName
      avatar
      email
      title
      employmentDate {
        toString
      }
      phone
      cellPhone
      education
      experience
      ssn
      sex
      dob
    }
  }
`;

export const PERSON_BYID = gql`
  query person($personId: ID!) {
    person(personId: $personId) {
      id
      lastName
      firstName
      avatar
      email
      title
      employmentDate {
        toString
      }
      phone
      cellPhone
      education
      experience
      ssn
      sex
      dob
    }
  }
`;

export const SEARCH_PHYSICIANS_BY_NAME = gql`
  query searchAllPhysiciansByName(
    $page: Int!
    $size: Int!
    $searchStr: String
  ) {
    searchAllPhysiciansByName(page: $page, size: $size, searchStr: $searchStr) {
      totalSize
      persons {
        id
        lastName
        firstName
        avatar
        provider {
          id
          lastName
          firstName
          title
          specialty {
            specialty
          }
          locations {
            zipCode
          }
        }
      }
    }
  }
`;

export const SEARCH_PHYSICIANS_BY_SPEC = gql`
  query searchAllPhysiciansBySpecialty(
    $page: Int!
    $size: Int!
    $searchStr: String
  ) {
    searchAllPhysiciansBySpecialty(
      page: $page
      size: $size
      searchStr: $searchStr
    ) {
      totalSize
      persons {
        id
        lastName
        firstName
        avatar
        provider {
          id
          lastName
          firstName
          title
          specialty {
            specialty
          }
          locations {
            zipCode
          }
        }
      }
    }
  }
`;

export const GET_ALL_SPECIALTY_NAME = gql`
  query getAllSpecialtyType {
    getAllSpecialtyType
  }
`;

export const SEARCH_PHYSICIANS_BY_ZIP = gql`
  query searchAllPhysiciansByZipCode(
    $page: Int!
    $size: Int!
    $searchStr: String
  ) {
    searchAllPhysiciansByZipCode(
      page: $page
      size: $size
      searchStr: $searchStr
    ) {
      totalSize
      persons {
        id
        lastName
        firstName
        avatar
        provider {
          id
          lastName
          firstName
          title
          specialty {
            specialty
          }
          locations {
            zipCode
          }
        }
      }
    }
  }
`;

export const SEARCH_ALLCARETEAM_BY_NAME = gql`
  query searchAllCareTeamByName($page: Int!, $size: Int!, $searchStr: String) {
    searchAllCareTeamByName(page: $page, size: $size, searchStr: $searchStr) {
      totalSize
      persons {
        id
        lastName
        firstName
        avatar
      }
    }
  }
`;

export const ALLPATIENTS_NAME = gql`
  query allPatientsByASC($page: Int!, $size: Int!) {
    allPatientsByASC(page: $page, size: $size) {
      id
      firstName
      lastName
      avatar
    }
  }
`;

export const ALLPATIENTSPAGE = gql`
  query allPatientsPage($page: Int!, $size: Int!) {
    allPatientsPage(page: $page, size: $size) {
      id
      email
      firstName
      lastName
      roles
      avatar
      patient {
        id
      }
    }
  }
`;

export const ALLCARETEAMPAGE = gql`
  query allCareTeamPage($page: Int!, $size: Int!) {
    allCareTeamPage(page: $page, size: $size) {
      id
      email
      education
      experience
      firstName
      lastName
      roles
      avatar
      dob
      ssn
      sex
      phone
    }
  }
`;

export const ADD_NEW_PAYMENT = gql`
  mutation addNewPayment(
    $patient_id: ID!
    $guarantorNumb: Long
    $paymentDate: String
    $paymentTo: String
    $paymentFrom: String
    $providerName: String
    $paymentBalance: Float
  ) {
    addNewPayment(
      patient_id: $patient_id
      guarantorNumb: $guarantorNumb
      paymentDate: $paymentDate
      paymentTo: $paymentTo
      paymentFrom: $paymentFrom
      providerName: $providerName
      paymentBalance: $paymentBalance
    ) {
      paymentDueDate {
        toString
      }
      paidDate {
        toString
      }
      totalBalance
      amountDueNow
      guarantorNumb
      addPayDetail {
        id
        paymentDate {
          toString
        }
        paymentTo
        paymentFrom
        paymentBalance
        providerName
      }
    }
  }
`;

export const ADD_NEW_INVOICE = gql`
  mutation addNewInvoice(
    $patient_id: ID!
    $guarantorNumb: Long
    $paidDate: String
    $totalBalance: Float
    $serviceDept: String
    $providerName: String
    $charge: Float
    $insuranceAdjustment: Float
  ) {
    addNewInvoice(
      patient_id: $patient_id
      guarantorNumb: $guarantorNumb
      paidDate: $paidDate
      totalBalance: $totalBalance
      serviceDept: $serviceDept
      providerName: $providerName
      charge: $charge
      insuranceAdjustment: $insuranceAdjustment
    ) {
      paymentDueDate {
        toString
      }
      paidDate {
        toString
      }
      totalBalance
      amountDueNow
      guarantorNumb
      billDetail {
        id
        serviceDate {
          toString
        }
        serviceDept
        providerName
        charge
        insuranceAdjustment
        totalBalance
      }
    }
  }
`;

export const PATIENTS_BILLING = gql`
  query allBillingSummaryByPtId($patient_id: ID!) {
    allBillingSummaryByPtId(patient_id: $patient_id) {
      id
      addPayManually
      paymentDueDate {
        toString
      }
      paidDate {
        toString
      }
      totalBalance
      amountDueNow
      guarantorNumb
      respLastName
      respFirstName
      respAddress
      respCellPhone
      billDetail {
        id
        serviceDate {
          toString
        }
        serviceDept
        providerName
        charge
        insuranceAdjustment
        totalBalance
      }
      addPayDetail {
        id
        paymentDate {
          toString
        }
        paymentTo
        paymentFrom
        paymentBalance
        providerName
      }
      patient {
        lastName
        firstName
        insuranceDetail {
          id
          insuranceName
          groupNumber
          groupName
          planName
          subscriberAddress
          subscriberId
          subscriberName
          subscriberSSN
        }
        paymentDetail {
          id
          paymentType
          cardHolderName
          cardType
          cardNumber
          cardExpirationDate {
            toString
          }
        }
      }
    }
  }
`;

//***Texting */
export const CONVERSATION = gql`
  query Conversation($fromUserId: ID!, $toUserId: ID!) {
    conversation(fromUserId: $fromUserId, toUserId: $toUserId) {
      id
      fromUserId
      toUserId
      text
      timestamp {
        toString
      }
    }
  }
`;

export const CREATE_CHAT = gql`
  mutation CreateChat($fromUserId: ID!, $toUserId: ID!, $text: String!) {
    createChat(fromUserId: $fromUserId, toUserId: $toUserId, text: $text) {
      id
      fromUserId
      toUserId
      text
    }
  }
`;

export const CHAT_SUBSCRIPTION = gql`
  subscription OnChatAdded($fromUserId: ID!, $toUserId: ID!) {
    chatAdded(fromUserId: $fromUserId, toUserId: $toUserId) {
      id
      fromUserId
      toUserId
      text
      timestamp {
        toString
      }
    }
  }
`;

export const ALL_ZOOM_MEETINGS = gql`
  query allZoomMeetings {
    allZoomMeetings {
      zoomId
      url
      passcode
      callerId
      calleeId
      status
    }
  }
`;

export const UPDATE_ZOOM_MEETING = gql`
  mutation UpdateZoomMeeting($zoomId: ID!, $callerId: ID!, $calleeId: ID!) {
    updateZoomMeeting(
      zoomId: $zoomId
      callerId: $callerId
      calleeId: $calleeId
    ) {
      zoomId
      url
      passcode
      callerId
      calleeId
      status
    }
  }
`;

export const GIVE_PHYSICIAN_ACCESS = gql`
  mutation updateShareMedicalRecordAgreement(
    $personId: ID!
    $shId: Long
    $choosedId: [Long]
  ) {
    updateShareMedicalRecordAgreement(
      personId: $personId
      shId: $shId
      choosedId: $choosedId
    )
  }
`;

export const GET_PATIENT_ACCESS = gql`
  query getChooseCategoriesById($personId: Long, $sharedPersonId: Long) {
    getChooseCategoriesById(
      personId: $personId
      sharedPersonId: $sharedPersonId
    )
  }
`;

export const PHYSICIANS_BY_PATIENT_ID = gql`
  query physiciansByPatientId($patientId: ID!) {
    physiciansByPatientId(patientId: $patientId) {
      id
      firstName
      lastName
      title
      roles
      education
      experience
      phone
    }
  }
`;

export const testData = [1, 2, 3, 4];
