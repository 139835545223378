import {
    Container,
    Typography,
    Card,
    CardContent,
    CardActions,
    Button,
    Grid,
    CardHeader,
    RadioGroup,
    FormControlLabel,
    Radio,
    ButtonGroup,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Pagination } from '@mui/material';
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { GET_ALL_CONDITIONCT_BY_NAME, GET_ALL_CONDITIONCT_BY_CATEGORY } from "@cims/common/services/clinicaltrials.service";
import { useQuery} from "@apollo/client";
import { render } from "react-dom";
import { useEffect } from "react";
import { blue } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 20,
    // height: "50px",
},
addBtnIcon: {
    backgroundColor:
    localStorage.getItem("isDarkMode") === "true" ? "#828282" : blue[300],
    color: localStorage.getItem("isDarkMode") === "true" ? "#fff" : "#000",
    "&:hover": {
    backgroundColor:
        localStorage.getItem("isDarkMode") === "true" ? "#BDC0BA" : blue[700],
    color: "#fff",
    },
},

btn: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
},

modaltext:{
    marginTop: 20,
    flexDirection:"column",
    justifyContent: "center",
},

paper: {
    backgroundColor: "#f5f3f0", //theme.palette.background.paper,
    border: "1px solid #666666",
    width: 700,
    maxHeight: 700,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "auto",
},

htyCard: {
    maxWidth: 1000,
    marginBottom: "10px",
    flex: 1,
    flexDirection:"row",
    marginTop:10,
},

cardHeader: {
    
    backgroundColor:
    localStorage.getItem("isDarkMode") === "true"
        ? "rgba(0,26,72, 0.3)"
        : "rgba(126,211,205,0.5)",
},

divider: {
    backgroundColor: "#d9e2ee",
    margin: "0 20px",
},

hyperlink:{
    paddingLeft:30,
},


modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
},

pagination: {
    display: "flex",
    marginTop: 30,
    justifyContent: "center",
},

}));

export default function ClinicalTrialsSearchResult(props){
    const myStyles = useStyles();
    const history = useHistory();
    const preventDefault = (e) => e.preventDefault();
    const [searchBy, setSearchBy] = useState("Name");
    const [text, setText] = useState("");
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [searchFunc, setSearchFunc] = useState(GET_ALL_CONDITIONCT_BY_CATEGORY);
    const [dataKey, setDataKey] = useState("getAllConditionsCTsByNameASC");

    console.log("size: ",size, "page: ",page);

    const {result} = this.props.history;
    console.log("result", result.state.studydata)
   

    const handleChange = (event, value) => {
      setPage(value);
    };
  
    const changeSearch = (e) => {
      setText("");
      const name = e.target.value;
      setSearchBy(name);
      if (name === "name") {
        setSearchFunc(GET_ALL_CONDITIONCT_BY_NAME);
        setDataKey("getAllConditionsCTsByNameASC");
      } else if (name === "category") {
        setSearchFunc(GET_ALL_CONDITIONCT_BY_CATEGORY);
        setDataKey("getAllConditionsCTsByCategoryIdASC");
      } 
      
    };

    const changeSize = (size) => {
      setPage(1);
      setSize(size);
    };

    
    
    
    return(
        <div>
            <Button 
            variant="outlined" 
            onClick={() =>
                history.push(`/clinicalstudies/patientmatcherresult`)
            }
            size="large"
            color="primary"
            >
            Return
            </Button>

            
        </div>
    );
    

    
}