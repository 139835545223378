import { Typography } from "@mui/material";
import React from "react";

export default function ErrorInfo() {
  return (
    <div style={{ display: "flex", justifyContent: "center", margin: 20 }}>
      <Typography variant="body2" color="error">
        Something went wrong retrieving the data
      </Typography>
    </div>
  );
}
