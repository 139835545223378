import React, { useContext, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import TextField from "@mui/material/TextField";
import { AppointmentContext } from "../../../services/appointment/appointment.context";
import { AuthenticationContext } from "../../../services/authentication/authentication.context";
import {
  ADD_ONE_APPT,
  CREATE_NOTIFICATION,
  GET_TIMESLOTS,
  NOTIFICATIONS_BY_PERSON,
  RESCHEDULE_APPT,
} from "@cims/common";
import { useQuery, useMutation } from "@apollo/client";
import { Button, Container, Grid, Typography, Paper } from "@mui/material";
import { convertDate } from "../../../utility/commons";
import LoadingIndicator from "../../../components/LoadingIndicator";
import ErrorInfo from "../../../components/ErrorInfo";
import { GET_ALL_APPTS, GET_ALL_PHYSICIAN_APPTS } from "@cims/common";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: 20,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  apptConfirm: {
    marginTop: 20,
    padding: 20,
  },
  confirmBtn: {
    marginLeft: 20,
  },
}));

export default function ScheduleAppointmentTimeslot() {
  const classes = useStyles();
  const history = useHistory();
  const today = new Date();
  const [date, setDate] = useState(convertDate(today));
  const [picked, setPicked] = useState(false);
  const [conformationAppt, setConformationAppt] = useState(false);

  const {
    yourAppointment,
    pickingAppointment,
    isReschedule,
    rescheduledFrom,
    changeIsReschedule,
  } = useContext(AppointmentContext);

  const { providerId, locationId, provider } = yourAppointment;
  const { contextHeaders, user, curPickedPatient } = useContext(
    AuthenticationContext
  );

  const [createOneApptPatient, { data: addApptData, error: addApptError }] =
    useMutation(ADD_ONE_APPT, {
      refetchQueries: [
        {
          query: GET_ALL_APPTS,
          variables: { patientId: Number(curPickedPatient.id) },
        },
        {
          query: GET_ALL_PHYSICIAN_APPTS,
          variables: { providerId: Number(providerId) },
        },
        {
          query: GET_TIMESLOTS,
          variables: {
            providerId: Number(providerId),
            locationId: Number(locationId),
            startTime: `${date} 00:00:00`, //"2021-05-05 00:00:00", //
            endTime: `${date} 24:00:00`, //"2021-05-05 24:00:00", //
          },
        },
      ],
    });

  const [
    createOneNotification,
    { data: newNotificationData, error: newNotificationError },
  ] = useMutation(CREATE_NOTIFICATION, {
    refetchQueries: [
      {
        query: NOTIFICATIONS_BY_PERSON,
        variables: {
          person_id: Number(user.person.id),
        },
      },
    ],
  });

  const creatingNotification = (id, name) => {
    createOneNotification({
      variables: {
        person_id: Number(id),
        message: `You made an appointment with ${name}`,
        webLink: `/patients/${id}/visits`,
        mobileLink: "Visits",
        status: "unread",
      },
    });
  };

  const makeAppt = (time) => {
    createOneApptPatient({
      variables: {
        patientId: Number(curPickedPatient.id),
        providerId: Number(yourAppointment.providerId),
        apptTime: time, //"2021-08-12 15:30:00",
        apptLocation: yourAppointment.location,
      },
    }).then(() => {
      creatingNotification(curPickedPatient.id, provider);
      creatingNotification(
        providerId,
        `${curPickedPatient.firstName} ${curPickedPatient.lastName}`
      );
      setConformationAppt(true);
      setTimeout(function () {
        setConformationAppt(false);
        setPicked(false);
        history.push(`/patients/${curPickedPatient.id}/visits`);
      }, 2000);
    });
  };

  const [
    rescheduleApptPatient,
    { data: rescheduleApptData, error: rescheduleApptError },
  ] = useMutation(RESCHEDULE_APPT, {
    refetchQueries: [
      {
        query: GET_ALL_APPTS,
        variables: { patientId: Number(curPickedPatient.id) },
      },
      {
        query: GET_ALL_PHYSICIAN_APPTS,
        variables: { providerId: Number(providerId) },
      },
      {
        query: GET_TIMESLOTS,
        variables: {
          providerId: Number(providerId),
          locationId: Number(locationId),
          startTime: `${date} 00:00:00`, //"2021-05-05 00:00:00", //
          endTime: `${date} 24:00:00`, //"2021-05-05 24:00:00", //
        },
      },
    ],
  });

  const reschedule = (time) => {
    rescheduleApptPatient({
      variables: {
        newApptTime: time,
        originalApptId: Number(yourAppointment.originalApptId),
        originalApptTime: yourAppointment.originalApptTime,
        notes: "rescheduleByPatient",
      },
    }).then(() => {
      setConformationAppt(true);
      changeIsReschedule(false);
      setTimeout(function () {
        setConformationAppt(false);
        setPicked(false);
        if (rescheduledFrom === "patient")
          history.push(`/patients/${curPickedPatient.id}/visits`);
        else history.push(`/physicians/${providerId}/physicianschedule`);
      }, 2000);
    });
  };

  const {
    data: timeslotData,
    loading: timeslotLoading,
    error: timeslotError,
  } = useQuery(GET_TIMESLOTS, {
    variables: {
      providerId: Number(providerId),
      locationId: Number(locationId),
      startTime: `${date} 00:00:00`, //"2021-05-05 00:00:00", //
      endTime: `${date} 24:00:00`, //"2021-05-05 24:00:00", //
    },
    context: contextHeaders,
  });

  const transform = (str) => {
    const i = str.indexOf("T");
    return str.slice(i + 1, str.length);
  };

  console.log("timeslotData--->", timeslotData);

  return (
    <Container>
      <Typography
        variant="body1"
        component="p"
        gutterBottom
        style={{ marginBottom: 20 }}
      >
        {`Making an appointment for ${curPickedPatient.firstName} ${curPickedPatient.lastName} | Please pick date and time`}
      </Typography>
      <form className={classes.container} noValidate>
        <TextField
          id="date"
          label="Pick a date"
          type="date"
          defaultValue={date} //"2017-05-24"
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            // console.log("value--->", e.target.value);
            setDate(e.target.value);
          }}
        />
      </form>

      {timeslotLoading && <LoadingIndicator />}
      {timeslotError && <ErrorInfo />}

      <Grid container spacing={3}>
        {timeslotData &&
          timeslotData.apptByStartEndTime
            .filter((timeslot) => {
              return timeslot.status === "available";
            })
            .map((timeSlot) => {
              return (
                <Grid key={timeSlot.id} item xs={6} md={2}>
                  <Button
                    variant="outlined"
                    onClick={(e) => {
                      setPicked(true);
                      pickingAppointment({
                        timeSlot: transform(timeSlot.startTime.toString),
                      });
                    }}
                  >
                    {transform(timeSlot.startTime.toString)}
                  </Button>
                </Grid>
              );
            })}
      </Grid>

      {picked && (
        <Paper className={classes.apptConfirm}>
          <Typography>Your appointment:</Typography>
          <Typography>{`${yourAppointment.appt}`}</Typography>
          <Typography>{`${yourAppointment.location}`}</Typography>
          <Typography>{`${yourAppointment.provider}`}</Typography>
          <Typography>{`${date} ${yourAppointment.timeSlot}`}</Typography>
          <div
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-evenly",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                setPicked(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className={classes.confirmBtn}
              variant="outlined"
              onClick={() => {
                const apptTime = `${date} ${yourAppointment.timeSlot}:00`;
                if (isReschedule) reschedule(apptTime);
                else makeAppt(apptTime);
              }}
            >
              Confirm
            </Button>
          </div>
          {conformationAppt && (
            <Typography>Thank you, your appointment is confirmed.</Typography>
          )}
          {addApptError && (
            <Typography variant="body1" color="error">
              Something went wrong to make an appointment. Pleae try again
            </Typography>
          )}
        </Paper>
      )}
    </Container>
  );
}
