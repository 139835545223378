import { gql } from "@apollo/client";

export const FIND_LAB_RESULTS_BY_ID = gql`
  query findLabResultsById($patient_id: ID!) {
    findLabResultsById(patient_id: $patient_id) {
      id
      orderControlORC
      placerOrderNumberORC
      dateTimeOfTransactionORC
      orderingProviderORC
      placerOrderNumberOBR
      fillerOrderNumberORB
      priorityOBR
      observationDateTimeOBR
      orderingProviderOBR
      resultsRptStatusChngDateTimeOBR
      resultStatusOBR
      patient {
        id
      }
      obxObservationResult {
        id
        setIDOBX
        valueTypeOBX
        observationIdentifierOBX
        unitsOBX
        referencesRangeOBX
        abnormalFlagsOBX
        observResultStatusOBX
      }
    }
  }
`;